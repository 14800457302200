/**
 *
 */
import {Select, Space, Upload} from 'antd';
import {inject, observer} from "mobx-react";
import React from "react";
import {Button} from "../../../../components/antExtendedComponent/Button";
import {Notify} from "../../../../components/Notify";
import {MESSAGE_4_1, MESSAGE_4_2, PIECE_JOINTE_CONFIDENTIALITES_TYPES, UPLOAD_DOCUMENT_ACCEPT, UPLOAD_DOCUMENT_MAX} from "../../../../config/Constantes.logic";
import {MICRO_SERVICE_URLS} from "../../../../config/Constantes.urls";
import microService from "../../../../helpers/MicroService";
import {key, newId} from "../../../../helpers/Utils";
import {DRAWERS_NAMES} from "../../../../stores/NavigationStore";
import {closeDrawer} from "../../../common/components/drawerManager";

/**
 *
 */

export default inject("store")(observer(class PieceJointeSpecifique extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store
	}
	
	//modele
	
	state = {
		confidentiel: "",
		fileList: [],
		isUploading: false,
		isFileLoaded: false,
		isLoading: false,
		documentName: "",
		progress: 0
		
	};
	/**
	 * si au moins une pj , on envoie un mail
	 * @param Inscription
	 */
	sendmail = (Inscription) => {
		
		if (Inscription.PieceJointeSpecifiques.length === 1) {
			let MailOption = {}
			/**
			 * apprenant
			 */
			MailOption.action = MESSAGE_4_2
			MailOption.To = [{
				Email: Inscription.Apprenant.emailPrincipal,
				Name: Inscription.Apprenant.fullname
			}]
			microService.post(MICRO_SERVICE_URLS.SEND_MAIL, MailOption, (Response => {
				console.log(Response)
			}))
			/**
			 * pôle formation
			 */
			MailOption = {}
			MailOption.action = MESSAGE_4_1
			MailOption.To = [{
				Email: Inscription.Apprenant.emailPrincipal,
				Name: Inscription.Apprenant.fullname
			}]
			microService.post(MICRO_SERVICE_URLS.SEND_MAIL, MailOption, (Response => {
				console.log(Response)
			}))
			
		}
	}
	reinitializeState = () => {
		this.setState({
			confidentiel: "",
			fileList: [],
			isUploading: false,
			isFileLoaded: false,
			isLoading: false,
			documentName: "",
			progress: 0
		});
	}
	handleUpload = () => {
		//
		this.setState({isUploading: true})
		//
		const that = this
		const {fileList} = this.state;
		const {store, Inscription} = this.props
		const {InscriptionPieceJointeSpecifiqueStore} = store
		
		let file = fileList[0]
		
		const reader = new FileReader();
		reader.readAsArrayBuffer(file);
		//
		reader.onload = function (e) {
			
			let InscriptionPieceJointeSpecifique = {}
			InscriptionPieceJointeSpecifique._id = newId()
			InscriptionPieceJointeSpecifique.Inscription = Inscription._id
			InscriptionPieceJointeSpecifique.confidentiel = that.state.confidentiel
			InscriptionPieceJointeSpecifique.originalName = file.name
			InscriptionPieceJointeSpecifique.name = that.state.documentName || file.name
			InscriptionPieceJointeSpecifique.type = file.type
			InscriptionPieceJointeSpecifique.extension = file.name.split(".").reverse()[0]
			InscriptionPieceJointeSpecifique.size = file.size
			//
			let formData = new FormData();
			formData.append('File', file);
			formData.append('InscriptionPieceJointeSpecifique', JSON.stringify(InscriptionPieceJointeSpecifique));
			Notify()
			InscriptionPieceJointeSpecifiqueStore.create(formData, succeeded => {
				Notify(succeeded)
				that.reinitializeState()
				//
				closeDrawer(DRAWERS_NAMES.PIECE_JOINTE_SPECIFIQUE_FORM_DRAWER)
				//
				that.sendmail(Inscription)
				//
			})
			
		}
		
	};
	
	render() {
		/**
		 *
		 */
		const {isUploading, confidentiel, fileList} = this.state;
		/**
		 *
		 */
		const uploadProps = {
			accept: UPLOAD_DOCUMENT_ACCEPT.join(","),
			fileList,
			onRemove: file => {
				this.setState(state => {
					return {
						fileList: [],
					};
				});
			},
			beforeUpload: file => {
				
				const sizeMaxMo = (UPLOAD_DOCUMENT_MAX / 1024 / 1024).toFixed(2) + " Mo"
				const currentSizeMaxMo = (file.size / 1024 / 1024).toFixed(2) + " Mo"
				if (!UPLOAD_DOCUMENT_ACCEPT.includes(file.type)) {
					Notify(false, "Le format de ce document n'est pas accepté")
					return false
				}
				if (file.size > UPLOAD_DOCUMENT_MAX) {
					alert("La taille maximale d'un document est de " + sizeMaxMo + ", votre document est de " + currentSizeMaxMo)
					return false
				}
				//on ne veut gèrer qu'un fichier à la fois
				this.setState({isFileLoaded: true})
				this.setState(state => ({
					// fileList: [...state.fileList, file],
					fileList: [file],
				}));
				return false;
			},
			
		};
		return (
			<>
				<Space direction={"vertical"}>
					<Upload {...uploadProps}>
						<Button
							type="primary"
						>1. Sélectionnez un document
						</Button>
					</Upload>
					{
						fileList.length > 0 &&
						<>
							<label>
								Type de confidentialité
							</label>
							<Select
								style={{width: 150}}
								onChange={value => this.setState({confidentiel: value})}
							
							>
								{
									Object.values(PIECE_JOINTE_CONFIDENTIALITES_TYPES).map(item =>
										<Select.Option key={key()} value={item.value}>
											{item.tag}
										</Select.Option>
									)
								}
							</Select>
							
							{
								confidentiel !== "" && <Button
									type="primary"
									onClick={this.handleUpload}
									//disabled={fileList.length === 0}
									loading={isUploading}
								>
									{isUploading ? 'Ajout du document en cours..' : '2. Envoyer le document'}
								</Button>
							}
						
						</>
					}
				
				</Space>
			</>
		
		);
	}
	
}))

