import {onAction, types} from "mobx-state-tree"

/**
 *
 */
import {ENV, ENV_DEV, MICRO_SERVICE_URLS} from "../config/Constantes.urls";
import MicroService from "../helpers/MicroService";
//
import {ApprenantStore} from "./ApprenantStore";
import {InscriptionEvaluationStore} from "./InscriptionEvaluationStore";
import {PreProspectStore} from "./PreProspectStore";
//
import {CommentaireStore} from "./CommentaireStore";

import {CountryStore} from "./CountryStore";
import {FavoriteStore} from "./FavoriteStore";
import {FormationStore} from "./FormationStore";
//
import {InscriptionStore} from "./InscriptionStore";
import {InscriptionPieceJointeSpecifiqueStore} from "./InscriptionPieceJointeSpecifiqueStore";
import {InscriptionPieceJointeStore} from "./InscriptionPieceJointeStore";
import {InscriptionProjetProfessionnelStore} from "./InscriptionProjetProfessionnelStore";
import {InscriptionSessionStore} from "./InscriptionSessionStore";
import {InscriptionClasseVirtuelleStore} from "./InscriptionClasseVirtuelleStore";

import {DRAWERS_NAMES, NavigationStore} from "./NavigationStore"
import {RdvStore} from "./RdvStore";
//
import {ReferentielCommentaireTagStore} from "./ReferentielCommentaireTagStore";
import {ReferentielPieceJointeStore} from "./ReferentielPieceJointeStore";
import {ReferentielSourceFinancementStore} from "./ReferentielSourceFinancementStore";
import {ReferentielPubliPostageTemplateStore} from "./ReferentielPubliPostageTemplateStore";
import {PubliPostageDocumentStore} from "./PubliPostageDocumentStore";
//
//
import {UserStore} from "./UserStore";
import {openDrawer} from "../pages/common/components/drawerManager";
import {TAB_KEY_APPRENANT_INSCRIPTIONS} from "../pages/apprenant/Apprenant";

/**
 *
 */

/**
 *
 */
const RootStore = types
	.model({
		/**
		 * tout ajout d'un store, ajouter son [#NomDuStore#Store]
		 */
		NavigationStore: types.maybe(NavigationStore),
		FavoriteStore: types.maybe(FavoriteStore),
		
		CountryStore: types.maybe(CountryStore),
		UserStore: types.maybe(UserStore),
		ReferentielPieceJointeStore: types.maybe(ReferentielPieceJointeStore),
		ReferentielSourceFinancementStore: types.maybe(ReferentielSourceFinancementStore),
		ReferentielCommentaireTagStore: types.maybe(ReferentielCommentaireTagStore),
		ReferentielPubliPostageTemplateStore: types.maybe(ReferentielPubliPostageTemplateStore),
		PubliPostageDocumentStore: types.maybe(PubliPostageDocumentStore),
		
		FormationStore: types.maybe(FormationStore),
		ApprenantStore: types.maybe(ApprenantStore),
		InscriptionEvaluationStore: types.maybe(InscriptionEvaluationStore),
		PreProspectStore: types.maybe(PreProspectStore),
		
		InscriptionStore: types.maybe(InscriptionStore),
		InscriptionPieceJointeStore: types.maybe(InscriptionPieceJointeStore),
		InscriptionPieceJointeSpecifiqueStore: types.maybe(InscriptionPieceJointeSpecifiqueStore),
		InscriptionProjetProfessionnelStore: types.maybe(InscriptionProjetProfessionnelStore),
		InscriptionSessionStore: types.maybe(InscriptionSessionStore),
		InscriptionClasseVirtuelleStore: types.maybe(InscriptionClasseVirtuelleStore),
		
		RdvStore: types.maybe(RdvStore),
		CommentaireStore: types.maybe(CommentaireStore),
		//
		_t: 0
		
	})
	
	.actions((self) => ({
		
		notifyUIChanges() {
			self._t = Date.now()
		},
		start() {
			self.loadData(true)
		},
		refresh() {
			self.loadData(false)
		},
		
		/**
		 * si on veut faire apparaître le loader
		 * @param refresh boolean
		 */
		loadData(refresh, callBack) {
			self.NavigationStore.setLoading(refresh)
			/**
			 *
			 */
			const anneeCreationSelected = sessionStorage.anneeCreationSelected || new Date().getFullYear()
			sessionStorage.anneeCreationSelected = anneeCreationSelected
			window.anneeCreation = anneeCreationSelected
			/**
			 *
			 */
			let url = MICRO_SERVICE_URLS.ALL
			/**
			 * pour test local en important le resultat d'un appel ajax au niveau prod
			 */
			if (ENV === ENV_DEV) {
				//url = "/data.json"
				if (url === "/data.json") {
					console.error("************** utilisation de data.json en cours, copie json de la prod  **************")
				}
			}
			
			MicroService.get(url, Response => {
				//
				switch (Response) {
					default :
						self.populateStores(Response)
						self.notifyUIChanges()
						break
					
				}
				//
				self.NavigationStore.setLoading(false)
				callBack && callBack(Response)
				//
			})
			
		},
		/**
		 *
		 */
		populateStores(Response) {
			//
			const populateStore = (Records, Collection) => {
				//Collection.clear()
				Records.forEach(Record => {
					try {
						Collection.set(Record._id, Record)
					} catch (e) {
						console.log("populateStores", e)
					}
					
				})
			}
			
			/**
			 *
			 */
			window.Constantes = Response.Constantes
			/**
			 *
			 */
			self.UserStore.setAuthenticatedUser(Response.AuthenticatedUser._id)
			/**
			 * Users
			 */
			populateStore(Response.Users, self.UserStore.Users)
			/**
			 * Favorites
			 */
			populateStore(Response.Favorites, self.FavoriteStore.Favorites)
			/**
			 * Pays
			 */
			populateStore(Response.Countries, self.CountryStore.Countries)
			/**
			 * Formations
			 */
			populateStore(Response.Formations, self.FormationStore.Formations)
			/*
			const CoursMap={}
			Response.Formations.forEach(Formation=>{
				Formation.OffreFormation.ComposantAssocies.forEach(ComposantAssocie=>{
					const Composant=ComposantAssocie.Composant
				
					if(Composant.composantType==="COURS")
					{
						CoursMap[Composant._id]={_id:Composant._id,libelleCourt:Composant.intituleCourt}
					}
					
				})
				
			})
			
			 */
			
			/**
			 * Apprenants
			 */
			populateStore(Response.Apprenants, self.ApprenantStore.Apprenants)
			/**
			 * InscriptionEvaluations
			 */
			populateStore(Response.InscriptionEvaluations, self.InscriptionEvaluationStore.InscriptionEvaluations)
			/**
			 * PreProspects
			 */
			populateStore(Response.PreProspects, self.PreProspectStore.PreProspects)
			
			/**
			 * Inscriptions
			 */
			
			populateStore(Response.Inscriptions, self.InscriptionStore.Inscriptions)
			/**
			 * InscriptionPieceJointes
			 */
			populateStore(Response.InscriptionPieceJointes, self.InscriptionPieceJointeStore.InscriptionPieceJointes)
			/**
			 * InscriptionPieceJointeSpecifiques
			 */
			populateStore(Response.InscriptionPieceJointeSpecifiques, self.InscriptionPieceJointeSpecifiqueStore.InscriptionPieceJointeSpecifiques)
			/**
			 * InscriptionProjetProfessionnels
			 */
			populateStore(Response.InscriptionProjetProfessionnels, self.InscriptionProjetProfessionnelStore.InscriptionProjetProfessionnels)
			/**
			 * InscriptionSessions
			 */
			populateStore(Response.InscriptionSessions, self.InscriptionSessionStore.InscriptionSessions)
			/**
			 * InscriptionClasseVirtuelles
			 */
			populateStore(Response.InscriptionClasseVirtuelles, self.InscriptionClasseVirtuelleStore.InscriptionClasseVirtuelles)
			
			/**
			 * Commentaires
			 */
			populateStore(Response.Commentaires, self.CommentaireStore.Commentaires)
			
			/**
			 * Rdvs
			 */
			populateStore(Response.Rdvs, self.RdvStore.Rdvs)
			/**
			 * ReferentielPubliPostageTemplates
			 */
			populateStore(Response.ReferentielPubliPostageTemplates, self.ReferentielPubliPostageTemplateStore.ReferentielPubliPostageTemplates)
			/**
			 * PubliPostageDocuments
			 */
			populateStore(Response.PubliPostageDocuments, self.PubliPostageDocumentStore.PubliPostageDocuments)
			
			/**
			 * ReferentielPieceJointes
			 */
			populateStore(Response.ReferentielPieceJointes, self.ReferentielPieceJointeStore.ReferentielPieceJointes)
			/**
			 * ReferentielSourceFinancements
			 */
			populateStore(Response.ReferentielSourceFinancements, self.ReferentielSourceFinancementStore.ReferentielSourceFinancements)
			/**
			 * ReferentielCommentaireTags
			 */
			populateStore(Response.ReferentielCommentaireTags, self.ReferentielCommentaireTagStore.ReferentielCommentaireTags)
			/**
			 *
			 
			if (ENV === ENV_DEV && Response.Apprenants.length > 0) {
				const Records = Object.values(self.ApprenantStore.getAll())
				Records[0].edit()
				openDrawer(DRAWERS_NAMES.APPRENANT_FORM_DRAWER)
			} */
			if (ENV === ENV_DEV) {
				
				if (sessionStorage.currentApprenant) {
					const Records = Object.values(self.ApprenantStore.getAll())
					const Found = Records.find(Record => Record._id === sessionStorage.currentApprenant)
					if (Found) {
						Found.edit()
						self.ApprenantStore.setApprenantPageTabsActiveKey(TAB_KEY_APPRENANT_INSCRIPTIONS)
						openDrawer(DRAWERS_NAMES.APPRENANT_FORM_DRAWER)
						//openDrawer(DRAWERS_NAMES.MAQUETTE_FORM_DRAWER)
					}
				}
				
			}
		}
		
	}))
/**
 *
 */

export const Store = RootStore.create(
	{
		NavigationStore: NavigationStore.create(),
		FavoriteStore: FavoriteStore.create(),
		CountryStore: CountryStore.create(),
		UserStore: UserStore.create(),
		ApprenantStore: ApprenantStore.create(),
		InscriptionEvaluationStore: InscriptionEvaluationStore.create(),
		PreProspectStore: PreProspectStore.create(),
		//
		ReferentielPieceJointeStore: ReferentielPieceJointeStore.create(),
		ReferentielSourceFinancementStore: ReferentielSourceFinancementStore.create(),
		ReferentielCommentaireTagStore: ReferentielCommentaireTagStore.create(),
		ReferentielPubliPostageTemplateStore: ReferentielPubliPostageTemplateStore.create(),
		
		//
		InscriptionStore: InscriptionStore.create(),
		InscriptionPieceJointeStore: InscriptionPieceJointeStore.create(),
		InscriptionPieceJointeSpecifiqueStore: InscriptionPieceJointeSpecifiqueStore.create(),
		InscriptionProjetProfessionnelStore: InscriptionProjetProfessionnelStore.create(),
		InscriptionSessionStore: InscriptionSessionStore.create(),
		InscriptionClasseVirtuelleStore: InscriptionClasseVirtuelleStore.create(),
		//
		
		FormationStore: FormationStore.create(),
		//
		RdvStore: RdvStore.create(),
		CommentaireStore: CommentaireStore.create(),
		//
		PubliPostageDocumentStore: PubliPostageDocumentStore.create(),
		
	}
)
/**
 *
 */
if (ENV !== ENV_DEV) {
	document.addEventListener('click', function (event) {
		send({action: "click"})
	});
	onAction(Store, (call) => {
		if (["edit", "save", "destroy", "create"].includes(call.name)) {
			console.log(call.name)
			try {
				const arr = call.path.split("/")
				const data = {
					action: call.name,
					model: arr[1].replace("Store", ""),
					objectId: arr.reverse()[0]
				}
				send(data)
			} catch (e) {
				console.log("onAction", e)
			}
			
		}
		
	})
	const send = (data) => {
		MicroService.create(MICRO_SERVICE_URLS.LOG, {Record: data}, (Response) => {
			console.log(Response)
		})
	}
}
/**
 *scss
 */
