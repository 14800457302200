import {values} from "mobx";
import {applySnapshot, getParent, types} from "mobx-state-tree";
import {InscriptionModel} from "./InscriptionStore";
import {MICRO_SERVICE_URLS} from "../config/Constantes.urls";
import MicroService from "../helpers/MicroService";
import {YES_NO_LABEL_TAG} from "../components/SelectYesNo";

/**
 *
 */
const CURRENT_MICRO_SERVICE_URL = MICRO_SERVICE_URLS.INSCRIPTION_CLASSE_VIRTUELLE
//
export const InscriptionClasseVirtuelleModel = types
	.model({
		_id: types.identifier,
		Inscription: types.maybeNull(types.reference(InscriptionModel)),
		ClasseVirtuelle: types.frozen({
			_id: "",
			libelle: ""
		}),
		participation: types.optional(types.boolean, false),
		
		createdAt: types.maybeNull(types.string, ""),
		updatedAt: types.maybeNull(types.string, ""),
		//
		Createur: types.frozen({_id: "", fullname: ""}),
		Modificateur: types.frozen({_id: "", fullname: ""}),
		//
		_isOnEdition: types.optional(types.boolean, false),
		_isNew: types.optional(types.boolean, false),
		
	})
	.views((self) => ({
		get isDeletable() {
			return true;
		},
		get participationTag() {
			return YES_NO_LABEL_TAG[self.participation];
		},
	}))
	.actions((self) => ({
		edit() {
			getParent(self, 2).setRecordOnEdition(self);
		},
		update(EditedRecord) {
			applySnapshot(self, Object.assign(self, EditedRecord));
			
		},
		
		/**
		 *
		 * @param callback
		 */
		save(callback) {
			/**
			 * create
			 */
			let url = CURRENT_MICRO_SERVICE_URL
			let service = MicroService.create
			let data = {Record: self}
			//
			if (self._isNew === false) {
				url = url + "/" + self._id
				service = MicroService.update
			}
			//
			service(url, data, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					let Record = Response.Record
					getParent(self, 2).addToStore(Record)
				}
				callback(succeeded)
			})
			
		},
		destroy(callback) {
			MicroService.delete(CURRENT_MICRO_SERVICE_URL + "/" + self._id, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					getParent(self, 2).removeFromStore(self._id)
				}
				callback(succeeded)
				
			})
			
		}
	}));
/**
 * store
 */
export const InscriptionClasseVirtuelleStore = types
	.model({
		InscriptionClasseVirtuelles: types.map(InscriptionClasseVirtuelleModel),
		SelectedRecord: types.maybeNull(types.reference(InscriptionClasseVirtuelleModel, {})),
	}).actions((self) => ({
		create(ClasseVirtuelle, Inscription) {
			const Composant = ClasseVirtuelle.Composant
			
			let newRecord = InscriptionClasseVirtuelleModel.create({
				_isNew: true,
				_id: ClasseVirtuelle._id,
				ClasseVirtuelle: {
					_id: Composant._id,
					libelle: Composant.intitule
				},
				Inscription: Inscription._id,
			})
			self.addToStore(newRecord)
			return newRecord
		},
		setRecordOnEdition(SelectedRecord) {
			self.removeSetOnEditionForAll()
			SelectedRecord._isOnEdition = true
			self.SelectedRecord = SelectedRecord
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 */
		removeSetOnEditionForAll() {
			const Records = self.InscriptionClasseVirtuelles
			values(Records).forEach((Record) => {
				Record._isOnEdition = false
				if (Record._isNew === true) {
					Records.delete(Record._id)
				}
			})
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 * @param Record
		 */
		addToStore(Record) {
			self.InscriptionClasseVirtuelles.set(Record._id, Record)
			getParent(self, 1).notifyUIChanges()
		},
		removeFromStore(_id) {
			self.InscriptionClasseVirtuelles.delete(_id)
			getParent(self, 1).notifyUIChanges()
		},
	}))
	
	.views((self) => ({
		getAll() {
			const Records = values(self.InscriptionClasseVirtuelles)
			return Records
		},
		getById(_id) {
			const Records = values(self.InscriptionClasseVirtuelles)
			return Records.find(Record => Record._id === _id)
		},
		getByInscription(_id) {
			const Records = values(self.InscriptionClasseVirtuelles)
			return Records.filter(Record => Record.Inscription._id === _id)
		},
	}));



