/**
 *
 */
import {Upload} from 'antd';
import {inject, observer} from "mobx-react";
import React from "react";
import {Button} from "../../../../components/antExtendedComponent/Button";
import {SpaceVertical} from "../../../../components/antExtendedComponent/Space";
import {Notify} from "../../../../components/Notify";
import {MESSAGE_3_4, UPLOAD_DOCUMENT_ACCEPT, UPLOAD_DOCUMENT_MAX} from "../../../../config/Constantes.logic";
import {MICRO_SERVICE_URLS} from "../../../../config/Constantes.urls";
import microService from "../../../../helpers/MicroService";
import {newId} from "../../../../helpers/Utils";
import {DRAWERS_NAMES} from "../../../../stores/NavigationStore";
import {closeDrawer} from "../../../common/components/drawerManager";

/**
 *
 */
export default inject("store")(observer(class PieceJointeForm extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store
	}
	
	//modele
	
	state = {
		fileList: [],
		isUploading: false,
		isFileLoaded: false,
		isLoading: false,
		progress: 0
		
	};
	/**
	 * si toutes les pj obligatoires sont envoyées
	 * @param Inscription
	 */
	sendmail = (Inscription) => {
		if (Inscription.mustSendMail) {
			
			const MailOption = {}
			MailOption.action = MESSAGE_3_4
			MailOption.To = [{
				Email: Inscription.Apprenant.emailPrincipal,
				Name: Inscription.Apprenant.fullname
			}]
			microService.post(MICRO_SERVICE_URLS.SEND_MAIL, MailOption, (Response => {
				console.log(Response)
			}))
			
		}
	}
	reinitializeState = () => {
		this.setState({
			fileList: [],
			isUploading: false,
			isFileLoaded: false,
			isLoading: false,
			progress: 0
		});
	}
	handleUpload = () => {
		//
		this.setState({isUploading: true})
		//
		const that = this
		const {fileList} = this.state;
		const {store, Inscription, ReferentielPieceJointe, OldPieceJointe} = this.props
		const {InscriptionPieceJointeStore} = store
		
		let file = fileList[0]
		
		const reader = new FileReader();
		reader.readAsArrayBuffer(file);
		//
		reader.onload = function (e) {
			
			let InscriptionPieceJointe = {}
			InscriptionPieceJointe._id = newId()
			InscriptionPieceJointe.Inscription = Inscription._id
			InscriptionPieceJointe.ReferentielPieceJointe = ReferentielPieceJointe._id
			InscriptionPieceJointe.originalName = file.name
			InscriptionPieceJointe.type = file.type
			InscriptionPieceJointe.extension = file.name.split(".").reverse()[0]
			InscriptionPieceJointe.size = file.size
			//
			let formData = new FormData();
			formData.append('File', file);
			formData.append('InscriptionPieceJointe', JSON.stringify(InscriptionPieceJointe));
			Notify()
			InscriptionPieceJointeStore.create(formData, succeeded => {
				Notify(succeeded)
				that.reinitializeState()
				if (OldPieceJointe && OldPieceJointe._id) {
					console.log(OldPieceJointe._id)
					InscriptionPieceJointeStore.removeFromStore(OldPieceJointe._id)
				}
				
				closeDrawer(DRAWERS_NAMES.PIECE_JOINTE_FORM_DRAWER)
				//
				that.sendmail(Inscription)
				//
			})
			
		}
		
	};
	
	render() {
		/**
		 *
		 */
		const {isUploading, fileList} = this.state;
		/**
		 *
		 */
		const uploadProps = {
			accept: UPLOAD_DOCUMENT_ACCEPT.join(","),
			fileList,
			onRemove: file => {
				this.setState(state => {
					return {
						fileList: [],
					};
				});
			},
			beforeUpload: file => {
				
				const sizeMaxMo = (UPLOAD_DOCUMENT_MAX / 1024 / 1024).toFixed(2) + " Mo"
				const currentSizeMaxMo = (file.size / 1024 / 1024).toFixed(2) + " Mo"
				if (!UPLOAD_DOCUMENT_ACCEPT.includes(file.type)) {
					Notify(false, "Le format de ce document n'est pas accepté")
					return false
				}
				if (file.size > UPLOAD_DOCUMENT_MAX) {
					alert("La taille maximale d'un document est de " + sizeMaxMo + ", votre document est de " + currentSizeMaxMo)
					return false
				}
				//on ne veut gèrer qu'un fichier à la fois
				this.setState({isFileLoaded: true})
				this.setState(state => ({
					// fileList: [...state.fileList, file],
					fileList: [file],
				}));
				return false;
			},
			
		};
		return (
			<>
				<SpaceVertical>
					<Upload {...uploadProps}>
						<Button
							type="primary"
						>1. Sélectionnez un document
						</Button>
					</Upload>
					{fileList.length > 0 &&
						
						<Button
							type="primary"
							onClick={this.handleUpload}
							//disabled={fileList.length === 0}
							loading={isUploading}
						>
							{isUploading ? 'Ajout du document en cours..' : '2. Envoyer le document'}
						</Button>
						
					}
				</SpaceVertical>
			</>
		
		);
	}
	
}))

