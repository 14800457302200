/**
 *
 */
import {Button, Tooltip} from "antd";
import {inject, observer} from "mobx-react";
import React from "react";
import {Icon} from "../../../components/Icon";
import {Notify} from "../../../components/Notify";
/**
 *
 */
import {COLOR_YELLOW} from "../../../config/Constantes.ui";
import {newId} from "../../../helpers/Utils";

/**
 *
 */


export const FavoriteButton = inject("store")(observer(props => {
	
	const {Record, store, fontSize, placement} = props
	const {FavoriteStore} = store
	let style = {fontSize: fontSize || 16}
	let title
	let IconName
	/**
	 * le passage dans les attributs est toujours de type string
	 */
	const Favorite = FavoriteStore.isFavorite(Record._id)
	if (Favorite) {
		title = "Retirer des favoris"
		IconName = "Unfavorite"
		style.color = COLOR_YELLOW
		
	} else {
		title = "Mettre en favori"
		IconName = "AddFavorite"
		style.opacity = 0.4
	}
	//
	const Onclick = () => {
		Notify()
		if (!Favorite) {
			FavoriteStore.create(
				{
					_id: newId(),
					belongTo: Record._id
				}, (succeeded) => {
					Notify(succeeded)
				})
			
		} else {
			Favorite.destroy(succeeded => {
					Notify(succeeded)
				}
			)
		}
		
	}
	//
	return <Tooltip title={title}>
		<Button
			type={"link"}
			icon={<Icon name={IconName} style={style}/>}
			onClick={Onclick}
		>
		</Button>
	</Tooltip>
}))
