import {ConfigProvider} from "antd";
import locale from "antd/locale/fr_FR";
import days from 'dayjs';
import 'dayjs/locale/fr';
import {Provider} from "mobx-react";
import React from "react";
import socketIOClient from "socket.io-client";
import {CONTEXT_AUTHENTICATION_WITH_SESSION, CONTEXT_AUTHENTICATION_WITHOUT_SESSION, CONTEXT_FORMULAIRE_CONTACT} from "../../config/Constantes.logic";
import {Theme} from "../../config/Constantes.ui";
import {ENV, ENV_PROD, MICRO_SERVICE_BASE_URL} from "../../config/Constantes.urls";
import {getFingerPrint} from "../../helpers/Utils";
import {Store} from "../../stores/Store";
import {PreProspectRegistration} from "../preProspect/PreProspectRegistration";
import {App} from "./App";
import {ProfilSwitcher} from "./ProfilSwitcher";
import {ROUTE_DEFAULT} from "./NavigationSider";

/**
 *
 */
const socket = socketIOClient(MICRO_SERVICE_BASE_URL);
/**
 *
 */
days.locale('fr')
/**
 * crée le 13/01/2023 par  par nordine.zetoutou@agrosupdijon.fr
 */

export const initApp = async () => {
	/**
	 *
	 */
	
	/**
	 *
	 */
	const fingerPrint = await getFingerPrint()
	const client = fingerPrint.visitorId
	/**
	 *
	 */
	const searchParams = new URL(window.location.href).searchParams
	//
	const context = Store.NavigationStore.userContext || searchParams.get("context")
	const sidInUrl = Store.NavigationStore.sid || searchParams.get("sid");
	const logout = searchParams.get("Logout");
	//
	window.location.href.includes("reset") && sessionStorage.clear()
	//
	/**
	 *
	 */
	let app = ""
	switch (context) {
		case CONTEXT_FORMULAIRE_CONTACT:
			Store.NavigationStore.setUserContext(CONTEXT_FORMULAIRE_CONTACT)
			app = <PreProspectRegistration/>
			break
		case CONTEXT_AUTHENTICATION_WITHOUT_SESSION:
			if (sidInUrl) {
				Store.NavigationStore.setUserContext(CONTEXT_AUTHENTICATION_WITHOUT_SESSION)
				Store.NavigationStore.setSid(sidInUrl)
				Store.NavigationStore.setClient(client)
			}
			history.pushState({}, null, "/Inscriptions");
			Store.start()
			app = <App/>
			break
		//
		default:
			/**
			 * une redirection pour authentification est faite par axios : voir fichier Microservices
			 */
			if (!sidInUrl) {
				
				window.location.replace(MICRO_SERVICE_BASE_URL + "/Auth/client/" + client);
			}
			Store.NavigationStore.setUserContext(CONTEXT_AUTHENTICATION_WITH_SESSION)
			Store.NavigationStore.setSid(sidInUrl)
			Store.NavigationStore.setClient(client)
			const pathname = sessionStorage.pathname
			history.pushState({}, null, pathname ||ROUTE_DEFAULT);
			Store.start()
			//
			app = <React.StrictMode><App/>{ENV !== ENV_PROD && <ProfilSwitcher/>}</React.StrictMode>
			
			break
		
	}
	/**
	 *
	 */
	socket.on("PREPROSPECT_DEMANDE_NON_ENVOYEE", (arg) => {
		if (!arg) {
			return false
		}
		Store.PreProspectStore.addToStore(arg.PreProspect)
	})
	//
	socket.on("PREPROSPECT_DEMANDE_ENVOYEE", (arg) => {
		if (!arg) {
			return false
		}
		const Records = arg.Records
		Store.UserStore.addToStore(Records.User)
		Store.ApprenantStore.addToStore(Records.Apprenant)
		Store.PreProspectStore.addToStore(Records.PreProspect)
		Store.InscriptionStore.addToStore(Records.Inscription)
		Store.InscriptionProjetProfessionnelStore.addToStore(Records.InscriptionProjetProfessionnel)
		
	})
	/**
	 *
	 */
	socket.on("REFRESH_ALL", (arg) => {
		if (!arg) {
			return false
		}
		if (arg.client !== client) {
			Store.refresh(false)
		}
	})
	/**
	 * est envoyé par le back lors de l'insertion d'un log
	 */
	socket.on("WARNING_IF_EDITING_APPRENANT", (arg) => {
		if (!arg) {
			return false
		}
		if (arg.client !== client) {
			Store.ApprenantStore.addOrRemoveToApprenantEditeds(arg)
		}
		
	})
	/**
	 *
	 */
	
	return <Provider store={Store}>
		<ConfigProvider locale={locale} theme={Theme}>
			{app}
		</ConfigProvider>
	</Provider>
	
	/**
	 *
	 */
	
}
