/**
 *
 */
import {Alert, Checkbox, DatePicker, Form} from 'antd';

import days from 'dayjs';
import {inject, observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Button} from "../../../../components/antExtendedComponent/Button";
import {SpaceVertical} from "../../../../components/antExtendedComponent/Space";
import {FORM_DEFAULT_RULE_REQUIRED} from "../../../../config/Constantes.ui";
import {MICRO_SERVICE_URLS} from "../../../../config/Constantes.urls";
import microService from "../../../../helpers/MicroService";
import {toLocalDateTime} from "../../../../helpers/Utils";
import {Notify} from "../../../../components/Notify";

/**
 *
 */

export const Radiation = inject("store")(observer(props => {
	/**
	 *
	 */
	const [form] = Form.useForm();
	const [isFormEnabled, setIsFormEnabled] = useState(false)
	const [checked, setChecked] = useState(false)
	/**
	 *
	 */
	const {store} = props
	
	/**
	 *
	 */
	const
		{
			InscriptionStore,
			ApprenantStore,
			UserStore
		} = store
	
	/**
	 *
	 */
	const Apprenant = ApprenantStore.SelectedRecord
	const Inscription = InscriptionStore.SelectedRecord
	const AuthenticatedUser = UserStore.AuthenticatedUser
	let Radiation = {...Apprenant.Radiation}
	//
	
	//(ENV !== ENV_DEV) &&
	/**
	 *
	 */
	const isReadOnly = (Radiation.date && Radiation.date < new Date().toISOString().split("T")[0]) || false
	//
	useEffect(() => {
		if (Radiation.date) {
			Radiation.date = days(Radiation.date, 'YYYY-MM-DD');
			setIsFormEnabled(!isReadOnly)
		}
		form.setFieldsValue(Radiation);
	})
	
	/**
	 *
	 */
	
	/**
	 *
	 * @param  form values
	 * @returns {boolean}
	 */
	const onFinish = () => {
		
		form.validateFields()
			.then((values) => {
				//
				console.log(values.confirme)
				if (values.confirme === false) {
					Notify(false, "Vous devez confirmer la radiation.");
					return false
				}
				Radiation.date = values.date.format("YYYY-MM-DD")
				Radiation.Agent = AuthenticatedUser.toJSON()
				Radiation.confirme = values.confirme
				Radiation.createdAt = new Date()
				//
				Apprenant.update({Radiation: Radiation})
				//
				Notify()
				//
				Apprenant.save(succeeded => {
					Notify(succeeded)
					if (succeeded === true) {
						microService.create(MICRO_SERVICE_URLS.APPRENANT_RADIATION, {}, (Response) => {
							//
							store.refresh()
							//
						})
					}
				})
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	/**
	 *
	 */
	const ButtonSave = <div className={"center"}>
		<Button block onClick={onFinish}>Enregistrer</Button>
	</div>
	/**
	 *
	 */
	const Message = () => {
		const Radiation = Inscription && Inscription.Radiation
		if (Radiation) {
			const agent = Radiation.Agent.fullname
			const message = <>
				<div>Demande de la radiation effectuée par <b>{agent}</b> le {toLocalDateTime(Radiation.createdAt)}.</div>
			</>
			return Radiation.date && <Alert type={"warning"} showIcon message={message}/>
		}
		
	}
	
	/**
	 *
	 */
	const FORM_PROPS = {
		disabled: isReadOnly,
		form: form,
		colon: false,
	}
	return (
		
		<SpaceVertical className={"fadein shadow-2 padding"}>
			{/*  */}
			<Message/>
			{/*  */}
			
			
			{/*  */}
			<Form  {...FORM_PROPS} >
				{/*  */}
				<Form.Item name={"confirme"} valuePropName="checked" label={" "} rules={FORM_DEFAULT_RULE_REQUIRED}>
					<Checkbox>Je confirme la radiation de <b>{Apprenant.fullname}</b></Checkbox>
				</Form.Item>
				
				<Form.Item name={"date"} label={"Date d'effet de l'Radiation"} rules={FORM_DEFAULT_RULE_REQUIRED}>
					<DatePicker
						placeholder={""}
						inputReadOnly
						allowClear={false}
						format={'DD/MM/YYYY'}
						disabledDate={d => d && d.isBefore(days().add(-1, 'day'))}
					/>
				</Form.Item>
				{/*  */}
				{ButtonSave}
				{/*  */}
			</Form>
		</SpaceVertical>
	
	);
}))

