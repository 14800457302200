import {SyncOutlined} from "@ant-design/icons";
import {Button, Tooltip} from "antd";
import {inject, observer} from "mobx-react";
import React, {useState} from "react";
import {Notify} from "../../components/Notify";
import {BUTTON_DEFAULT_PROPS} from "../../config/Constantes.ui";
import {MICRO_SERVICE_URLS} from "../../config/Constantes.urls";
import microService from "../../helpers/MicroService";
import {CONTEXT_ACTION_SYNCHRONISER_FORMATIONS} from "../../config/Constantes.contexts";

/**
 *
 */
export const RefreshFromApiFormationButton = inject("store")(observer(props => {
	/**
	 *
	 */
	const {store} = props
	const {FormationStore, InscriptionSessionStore, UserStore} = store
	//
	
	const Profil = UserStore.getAllowedContext(CONTEXT_ACTION_SYNCHRONISER_FORMATIONS)
	if (!(Profil && Profil.isModifier)) {
		return null
	}
	
	//
	const [isLoading, setIsLoading] = useState(false)
	/**
	 *
	 */
	const onClick = () => {
		//
		setIsLoading(true)
		//
		microService.get(MICRO_SERVICE_URLS.IMPORT_FORMATION, (Response) => {
			//
			Response.Formations.forEach(Formation => {
				FormationStore.addToStore(Formation)
			})
			//
			Response.InscriptionSessions.forEach(InscriptionSession => {
				InscriptionSessionStore.addToStore(InscriptionSession)
			})
			//
			Response.Users.forEach(User => {
				UserStore.addToStore(User)
			})
			//
			setIsLoading(false)
			//
			Notify(Response.OPERATION_SUCCEED)
			//
		})
		//
	}
	/**
	 *
	 */
	return <Tooltip
		title={"Synchroniser la liste des formations à partir de la plateforme de l'offre de formations"}
		placement={"bottomRight"}
	>
		<Button
			{...BUTTON_DEFAULT_PROPS}
			size={"small"}
			loading={isLoading}
			icon={<SyncOutlined/>}
			//
			onClick={onClick}>Synchroniser les formations
		</Button>
	</Tooltip>
	/**
	 *
	 */
}))
