/**
 *
 */
import alasql from "alasql";
import {Button, Table, Tooltip} from "antd";
import {inject, observer} from "mobx-react";
import React from "react";
import {IconButton} from "../../components/IconButton";
import {YES_NO_LABEL} from "../../components/SelectYesNo";
import {YesNoTag} from "../../components/YesNoTag";
import {DIPLOMATION_CHOIX_INSCRIPTION_VALUES, DIPLOMATION_RESULTAT_VALUES} from "../../config/Constantes.logic";
/**
 *
 */
import {COLUMN_ICON_WIDTH, DEFAULT_PAGINATION, DEFAULT_TABLE_SCROLL} from "../../config/Constantes.ui";
import {toLocalDate} from "../../helpers/Utils";
import {DRAWERS_NAMES} from "../../stores/NavigationStore";
import {Apprenant, TAB_KEY_APPRENANT_INSCRIPTIONS} from "../apprenant/Apprenant";
import {CreateurModificateurIcon} from "../common/components/CreateurModificateurIcon";
import {destroyAllDrawers, openDrawer} from "../common/components/drawerManager";

/**
 *
 */
export const Diplomations = inject("store")(observer(props => {
	/**
	 *
	 */
	const {store} = props
	const {InscriptionStore, ApprenantStore} = store
	const Records = InscriptionStore.exportDiplomation()
	const apprenantDrawerName = DRAWERS_NAMES.APPRENANT_FORM_DRAWER
	/**
	 *
	 */
	const exportExcel = () => {
		const ANNEE_EXAMEN = "Année examen"
		const ColumnsToExport = {
			"numero": "Inscription N°",
			"Apprenant.nom": "Nom",
			"Apprenant.prenom": "Prénom",
			"_FormationSuivie.libelle": "Formation suivie",
			"_FormationSuivie.anneeExamen": ANNEE_EXAMEN,
			"Diplomation.resultat": "Résultat",
			"Diplomation.diplomeEnvoiDate": "Diplôme envoyé le",
			"Diplomation.radiationExamen": "Radiation",
			"Diplomation.choixReinscription": "Choix réinscription",
			
		}
		const Rows = []
		Records.forEach(Record => {
			const Row = {}
			const fields = Object.keys(ColumnsToExport)
			fields.forEach((field, index) => {
				let value = Record[field]
				let title = ColumnsToExport[field]
				Row[title] = value
				// si besoin de formatter certains champs
				switch (field) {
					case "Apprenant.nom":
						value = Record.Apprenant.nomUsage
						Row[title] = value
						break
					case "Apprenant.prenom":
						value = Record.Apprenant.prenom
						Row[title] = value
						break
					case "_FormationSuivie.libelle":
						value = (Record._FormationSuivie && Record._FormationSuivie.libelle) || ""
						Row[title] = value
						break
					case "_FormationSuivie.anneeExamen":
						value = (Record._FormationSuivie && Record._FormationSuivie.anneeExamen) || "Sans année examen"
						Row[title] = value
						break
					case "Diplomation.resultat":
						const resultat = Record.Diplomation.resultat
						value = (resultat && DIPLOMATION_RESULTAT_VALUES[resultat].label) || ""
						Row[title] = value
						break
					
					case "Diplomation.diplomeEnvoiDate":
						
						const diplomeEnvoiDate = Record.Diplomation.diplomeEnvoiDate
						value = (diplomeEnvoiDate && toLocalDate(diplomeEnvoiDate)) || ""
						Row[title] = value
						break
					case "Diplomation.radiationExamen":
						const radiationExamen = Record.Diplomation.radiationExamen
						value = (radiationExamen && YES_NO_LABEL[radiationExamen]) || ""
						Row[title] = value
						break
					case "Diplomation.choixReinscription":
						const choixReinscription = Record.Diplomation.choixReinscription
						value = (choixReinscription && DIPLOMATION_CHOIX_INSCRIPTION_VALUES[choixReinscription].label) || ""
						Row[title] = value
						break
				}
				
			})
			Rows.push(Row)
		})
		/**
		 * pour avoir plusieurs feuilles excel
		 */
		let map = {}
		// on répartit par année examen
		Rows.forEach(Row => {
			if (!map[Row[ANNEE_EXAMEN]]) {
				map[Row[ANNEE_EXAMEN]] = []
			}
			map[Row[ANNEE_EXAMEN]].push(Row)
		})
		////
		map["Tous"] = Rows
		//
		const AllRows = Object.values(map)
		//
		let opts = []
		for (const anneeExamen in map) {
			const sheetid = anneeExamen + " (" + map[anneeExamen].length + ")"
			opts.push({sheetid: sheetid, header: true})
		}
		//
		const fileName = "diplomations_" + sessionStorage.anneeCreationSelected + ".xlsx"
		/**
		 * AllRows = [[],[]]
		 */
		alasql.promise('SELECT INTO XLSX("' + fileName + '",?) FROM ?', [opts, AllRows])
			.then(function (res) {
			})
			.catch(function (err) {
				console.log('Error:', err);
			});
		
	}
	/**
	 *
	 */
	const edit = (Inscription) => {
		Inscription.Apprenant.edit()
		InscriptionStore.setSelectedRecord(Inscription)
		ApprenantStore.setApprenantPageTabsActiveKey(TAB_KEY_APPRENANT_INSCRIPTIONS)
		destroyAllDrawers()
		openDrawer(apprenantDrawerName)
	}
	/**
	 *
	 */
	const ButtonExportToXls = (
		<Tooltip title="Exporter la liste des inscrits sous format excel (xlsx)">
			<Button
				
				shape="circle"
				type="primary"
				icon={<IconButton name={"ExcelDocument"} type="button"/>}
				onClick={(e) => exportExcel()}
			/>
		</Tooltip>
	)
	/**
	 *
	 */
	const Columns = [
		/**
		 *
		 */
		{
			dataIndex: "numeroTag",
			title: <>{ButtonExportToXls}</>,
			className: "center",
			width: 60,
			fixed: "left",
		},
		/**
		 *
		 */
		{
			dataIndex: "fullname",
			title: "Nom et prénom",
			ellipsis: true,
			width: 150,
			fixed: "left",
			render: (text, Record) =>
				<a href="/" onClick={(e) => {
					e.preventDefault();
					edit(Record)
				}}>{Record.Apprenant.fullname}
				</a>
		},
		/**
		 *
		 */
		{
			dataIndex: "_FormationSuivie",
			title: "Formation suivie",
			width: 200,
			ellipsis: true,
			render: (text, Record) => Record._FormationSuivie && Record._FormationSuivie.libelle
		},
		/**
		 *
		 */
		{
			dataIndex: "_FormationSuivie.anneeExamen",
			title: "Année examen",
			width: 70,
			className: "center",
			render: (text, Record) => Record._FormationSuivie && Record._FormationSuivie.anneeExamen || "?"
		},
		/**
		 *
		 */
		{
			dataIndex: "Diplomation.resultat",
			title: "Résultat",
			width: 50,
			className: "center",
			render: (text, Record) => Record.Diplomation.resultat && DIPLOMATION_RESULTAT_VALUES[Record.Diplomation.resultat].tag
		},
		{
			dataIndex: "Diplomation.diplomeEnvoiDate",
			title: "Diplôme envoyé le",
			width: 100,
			className: "center",
			render: (text, Record) => Record.Diplomation.diplomeEnvoiDate && toLocalDate(Record.Diplomation.diplomeEnvoiDate)
		},
		{
			dataIndex: "Diplomation.radiationExamen",
			title: "Radiation",
			width: 50,
			className: "center",
			render: (text, Record) => Record.Diplomation.radiationExamen && <YesNoTag value={Record.Diplomation.radiationExamen}/>
		},
		{
			dataIndex: "Diplomation.choixReinscription",
			title: "Choix réinscription",
			width: 120,
			//	className : "center",
			render: (text, Record) => {
				const choixReinscription = Record.Diplomation.choixReinscription
				return choixReinscription && DIPLOMATION_CHOIX_INSCRIPTION_VALUES[choixReinscription].tag
			}
		},
		{
			dataIndex: 'CreateurModificateur',
			title: '',
			className: "center",
			width: COLUMN_ICON_WIDTH,
			fixed: "right",
			render: (text, Record) => <CreateurModificateurIcon Record={Record}/>
		},
		/*
		{
			dataIndex : "statutApprenantTag",
			title : "Statut",
			width : 200,
		}
		
		 */
	
	]
	return (
		<>
			
			{/* */}
			<Apprenant/>
			{/* */}
			<Table
				size={"small"}
				dataSource={Records}
				columns={Columns}
				rowKey="_id"
				pagination={DEFAULT_PAGINATION}
				scroll={DEFAULT_TABLE_SCROLL}
				showSorterTooltip={false}
				_t={store._t}
			/>
			{/* */}
		</>
	);
}))
/**
 *
 */
