/**
 *
 */
import 'antd/dist/reset.css';
import {createRoot} from 'react-dom/client';
import {initApp} from "./pages/main/initApp";
import {ENV, ENV_DEV} from "./config/Constantes.urls";

/**
 *
 */
const root = createRoot(document.getElementById('root'));
(async () => root.render(await initApp()))()

/**
 *
 */
if (ENV === ENV_DEV) {
	const suppressedWarnings = ['findDOMNode is deprecated'];
	const realConsoleError = console.error;
	console.error = (message, ...args) => {
		console.log(args)
		//realConsoleError(message, ...args);
	};
}

/**
 *
 */

