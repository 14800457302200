/**
 *
 */

import {LoadingOutlined} from "@ant-design/icons";
import {inject, observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Button} from "../../../../../components/antExtendedComponent/Button";
import {SpaceHorizontal, SpaceVertical} from "../../../../../components/antExtendedComponent/Space";
import {MICRO_SERVICE_URLS} from "../../../../../config/Constantes.urls";
import MicroService from "../../../../../helpers/MicroService";

/**
 *
 */
export const PhotoContent = inject("store")(observer(props => {
	const {small, Apprenant} = props
	const [src, setSrc] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const url = MICRO_SERVICE_URLS.APPRENANT_PHOTO + "/" + Apprenant._id
	//
	const width = (small ? 20 : 80)
	//
	const loadData = () => {
		//
		setIsLoading(true)
		//
		MicroService.get(url, (Response) => {
			setIsLoading(false)
			if (Response.Record) {
				const Photo = Response.Record
				const uint8tArray = new Uint8Array(Photo.content.data);
				const blob = new Blob([uint8tArray], {type: 'application/octet-binary'});
				const fileAsObjectUrl = URL.createObjectURL(blob);
				//
				setSrc(fileAsObjectUrl)
				//
			}
			
			//
			
		})
	}
	/**
	 *
	 */
	const download = (src) => {
		const element = document.createElement('a');
		element.setAttribute("href", src);
		element.setAttribute("download", "photo_apprenant_" + Apprenant._id + ".png");
		element.setAttribute("target", "_blank");
		element.style.display = 'none';
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	}
	
	useEffect(() => {
		loadData()
	}, []);
	
	return <SpaceVertical>
		
		<SpaceHorizontal>
			{src && <>
				<img alt={""} width={width} style={{borderRadius: "50%"}} src={src}/>
				<Button type={"dashed"} onClick={() => download(src)}>Télécharger la photo</Button>
			</>
			}
			{
				isLoading && <LoadingOutlined/>
			}
		</SpaceHorizontal>
		{
			
			<Button onClick={() => {
				loadData()
			}}>
				2. Rafraîchir
			</Button>
			
		}
	</SpaceVertical>
}))
