/**
 *
 *
 */
export const Icon = (props) => {
	
	return <i
		{...props}
		style={props.style}
		className={"ms-Icon--" + props.name}
	/>
}

