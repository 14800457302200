import {Tooltip} from "antd";
import React from "react";
import {COLOR_GREEN, COLOR_RED} from "../config/Constantes.ui";

/**
 *
 * ActifNotActifIcon
 *
 */
export const ActifNotActifIcon = (props) => {
	const color = (props.active === true ? COLOR_GREEN : COLOR_RED)
	const title = (props.active === true ? "activé" : "inactivé")
	return <Tooltip title={title} placement="right"><span style={{color: color, opacity: 0.7}}>◯</span></Tooltip>
}
/**
 *
 *
 *
 */
