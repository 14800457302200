import {Tabs} from "antd";
import React from "react";
import {ReferentielCommentaireTags} from "./ReferentielCommentaireTags";
import {ReferentielPieceJointe} from "./ReferentielPieceJointe";
import {ReferentielSourceFinancements} from "./ReferentielSourceFinancements";
import {ReferentielPubliPostageTemplates} from "./referentielPubliPostageTemplates/ReferentielPubliPostageTemplates";

/**
 *
 */

//const DEFAULT_ACTIVE_KEY = "activeKey:Referentiel"
//const defaultActiveKey = sessionStorage[DEFAULT_ACTIVE_KEY]
/**
 *
 */
export const Referentiel = (props) => {
	//
	return <Tabs
		type={"card"}
		//defaultActiveKey={"4"}
		onChange={(value) => {
			//sessionStorage[DEFAULT_ACTIVE_KEY] = value
		}}
		items={
			[
				{
					key: "1",
					label: "Les pièces jointes",
					children: <ReferentielPieceJointe/>
				},
				{
					key: "2",
					label: "Sources de financement",
					children: <ReferentielSourceFinancements/>
				},
				{
					key: "3",
					label: "Tags de commentaire",
					children: <ReferentielCommentaireTags/>
				},
				{
					key: "4",
					label: "Modèles de publipostage ",
					children: <ReferentielPubliPostageTemplates/>
				}
			
			]
		}
	/>
}

/**
 *
 *
 *
 */
