import {DoubleLeftOutlined, DoubleRightOutlined} from "@ant-design/icons";
import {Calendar, Divider, Space, Tag, Tooltip} from "antd";
import days from 'dayjs';
import {inject, observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Select} from "../../../components/antExtendedComponent/Select";
import {SpaceHorizontal, SpaceVertical} from "../../../components/antExtendedComponent/Space";
import {COLOR_PRIMARY, PLANNING_VIEW_CONTEXT_APPRENANT} from "../../../config/Constantes.ui";
import {PLANNING_COMMON_SELECT_OPTIONS} from "../Planning";
import {HourRangeEdition} from "./HourRangeEdition";
import {HourRangeSelection} from "./HourRangeSelection";
import {USER_ROLE_APPRENANT, USER_ROLE_RECRUTEUR, USER_ROLE_RESPONSABLE_FORMATION} from "../../../config/Constantes.roles";

/**
 *
 */

export const PreviewCalendar = inject("store")(observer(props => {
	
	/**
	 *
	 */
	const {context, store, Apprenant} = props
	const {RdvStore, UserStore, ApprenantStore, FormationStore} = store
	/**
	 *
	 */
	const getFormattedDate = (date) => {
		return days(date).format("YYYY-MM-DD")
	}
	/**
	 *
	 */
	const defautSelectedDate = localStorage.selectedDate || getFormattedDate(new Date())
	const [selectedDate, setSelectedDate] = useState(defautSelectedDate)
	//
	//const AllRdvs = RdvStore.getAll()
	//const Apprenants = ApprenantStore.getAll()
	const AuthenticatedUser = UserStore.AuthenticatedUser
	const SelectedOwner = RdvStore.SelectedOwner
	const SelectedTaker = RdvStore.SelectedTaker
	const SelectedFormation = RdvStore.SelectedFormation
	//
	let AuthorizedFormations = FormationStore.getAllByUser(AuthenticatedUser)
	
	//
	//const RdvsPerSelectedDate = RdvStore.getAllByFormationAndDate(SelectedFormation, selectedDate)
	//const rdvDisponibles = RdvsPerSelectedDate.filter(Rdv => !Rdv.hasTaker)
	//
	useEffect(() => {
		//
		if (Apprenant && context === PLANNING_VIEW_CONTEXT_APPRENANT) {
			RdvStore.setSelectedTaker(Apprenant.User.toJSON())
		}
		if (AuthenticatedUser && AuthenticatedUser.hasRoles(USER_ROLE_RESPONSABLE_FORMATION)) {
			RdvStore.setSelectedOwner(AuthenticatedUser.toJSON())
		}
		//
	})
	/**
	 * Responsable
	 * on prend que les ResponsableFormation qui ont des créneaux et enlève les doublons
	 
	const OwnerSelect = () => {
		//
		
		//
		const _ResponsableFormation = {}
		AllRdvs.forEach(Rdv => {
			const Owner = Rdv.Owner
			_ResponsableFormation[Owner._id] = {
				value: Owner._id,
				label: Owner.fullname,
				// servira pour extraire l'objet
				Owner: Owner
			}
		})
		const ResponsableFormation = Object.values(_ResponsableFormation)
		//
		let someProps = PLANNING_COMMON_SELECT_OPTIONS
		someProps.defaultValue = SelectedOwner._id || null
		//
		return <Select
			{...someProps}
			options={ResponsableFormation}
			style={{marginLeft: 4}}
			//disabled={disabled}
			placeholder={"1. Sélectionnez un RF"}
			notFoundContent={"Aucun RF avec un planning"}//
			onChange={value => {
				const SelectedOwner = _ResponsableFormation[value].Owner
				RdvStore.setSelectedOwner(SelectedOwner)
			}}
		/>
	}
	 */
	const FormationSelect = () => {
		const Items = {}
		AuthorizedFormations.forEach(Formation => {
			Items[Formation._id] = {
				value: Formation._id,
				label: Formation.libelleCourt + " (" + Formation.offreFormationCreatedAt + ")",
				Formation: Formation
			}
		})
		
		const options = Object.values(Items)
		//
		let someProps = PLANNING_COMMON_SELECT_OPTIONS
		someProps.defaultValue = (SelectedFormation && SelectedFormation._id) || null
		return <Select
			{...someProps}
			options={options}
			style={{marginLeft: 4}}
			//disabled={disabled}
			placeholder={"Sélectionnez une formation"}
			notFoundContent={"Aucune formation ne vous est affectée"}
			onChange={value => {
				const SelectedFormation = Items[value].Formation
				RdvStore.setSelectedFormation(SelectedFormation)
				
			}}
		/>
	}
	/**
	 *
	
	const TakerSelect = () => {
		//
		let disabled = (
			(
				(AuthenticatedUser && AuthenticatedUser.hasRoles([USER_ROLE_AGENT, USER_ROLE_APPRENANT])))
			|| !SelectedOwner._id
		)
		
		if (context === PLANNING_VIEW_CONTEXT_APPRENANT) {
			disabled = true
		}
		//
		//const ApprenantInscrits = Apprenants.filter(Apprenant => Apprenant.Inscriptions && Apprenant.Inscriptions.length > 0)
		const Users = {}
		Apprenants.forEach(Apprenant => {
			let User = {...Apprenant.User}
			//User.email = Apprenant.emailPrincipal
			Users[User._id] = {
				value: User._id,
				label: User.fullname,
				// servira pour extraire l'objet
				Taker: User
			}
		})
		//
		const options = Object.values(Users)
		//
		let someProps = PLANNING_COMMON_SELECT_OPTIONS
		someProps.defaultValue = SelectedTaker._id || null
		//
		return <Select
			{...someProps}
			options={options}
			allowClear={false}
			//disabled={disabled}
			placeholder={"2. Sélectionnez un apprenant"}
			notFoundContent={"Aucun apprenant avec une inscription"}
			onChange={value => {
				const Taker = Users[value].Taker
				const SelectedTaker = {
					_id: Taker._id,
					fullname: Taker.fullname,
					//email : Taker.email
				}
				RdvStore.setSelectedTaker(SelectedTaker)
				
			}}
		/>
	}
	 */
	/**
	 *
	 * @param date
	 */
	const onSelectDate = (date) => {
		const _selectedDate = getFormattedDate(date)
		setSelectedDate(_selectedDate)
	}
	
	/**
	 *
	 */
	const DatePaginator = (props) => {
		//
		const nextOrPrevMonth = (nextOrPrevMonthValue) => {
			const _selectedDate = days(selectedDate).add(nextOrPrevMonthValue, 'M').format("YYYY-MM-DD");
			setSelectedDate(_selectedDate)
			localStorage.selectedDate = _selectedDate
			
		}
		//
		return <Divider>
			<SpaceHorizontal>
				<DoubleLeftOutlined style={{color: COLOR_PRIMARY}} onClick={() => nextOrPrevMonth(-1)}/>
				<div style={{width: 80}}><b>{days(selectedDate).format("MMM YYYY")}</b></div>
				<DoubleRightOutlined style={{color: COLOR_PRIMARY}} onClick={() => nextOrPrevMonth(1)}/>
			</SpaceHorizontal>
		</Divider>
	}
	/**
	 *
	 */
	const cellRender = (date) => {
		let formationCreneaux = RdvStore.getAllByFormationAndDate(SelectedFormation, date)
		const formationIds = AuthorizedFormations.map(Formation => Formation._id)
		formationCreneaux = formationCreneaux.filter(Rdv => formationIds.includes(Rdv.Formation._id))
		const formationCreneauxTotal = formationCreneaux.length
		if (formationCreneauxTotal > 0) {
			
			const totalPris = formationCreneaux.filter(Rdv => Rdv.Taker && Rdv.Taker._id !== "").length
			return <div>
				<Tooltip title={"Total des créneaux disponibles"}>
					<Tag color={"green"}>{formationCreneauxTotal}</Tag>
				</Tooltip>
				&nbsp;
				<Tooltip title={"Total des créneaux pris"}>
					<Tag color={"red"}>{totalPris}</Tag>
				</Tooltip>
			</div>
		}
	}
	const CreneauxCalendar = () => {
		return selectedDate && <div>
			<DatePaginator/>
			<Calendar
				defaultValue={selectedDate && days(selectedDate)}
				onSelect={onSelectDate}
				cellRender={cellRender}
				className={"planning shadow-2"}
				/*
				   monthCellRender est masqué par la classe "planning"
				 */
			/>
		</div>
	}
	/**
	 * planningMode RF
	
	const ViewTitle = () => {
		let alert = ""
		
			if (!SelectedTaker._id) {
				alert = "Sélectionnez un apprenant pour lui fixer un rdv."
			}
			
		
		if (alert) {
			return <Alert showIcon type={"warning"} message={alert}/>
		}
		return <Alert showIcon message={<>Choisissez un créneau pour le <b>{toLocalDate(selectedDate)}</b> (cliquez sur le calendrier pour modifier la date)</>}/>
	} */
	/**
	 *
	 */
	return <SpaceVertical size={"small"}>
		{/* */}
		<Space>
			<FormationSelect/>
		</Space>
		{/* */}
		<CreneauxCalendar _t={store._t}/>
		<br/>
		{/* */}
		{
			
			AuthenticatedUser
			&& AuthenticatedUser.hasRoles(USER_ROLE_APPRENANT)
			&& <HourRangeSelection {...{selectedDate, SelectedOwner, SelectedTaker, SelectedFormation}}/>
		}
		{
			/*
				ENV === ENV_DEV &&
				<HourRangeSelection {...{selectedDate, SelectedOwner, SelectedTaker}}/>
			 */
		}
		{
			AuthenticatedUser
			&& AuthenticatedUser.hasRoles([USER_ROLE_RESPONSABLE_FORMATION, USER_ROLE_RECRUTEUR])
			&& <HourRangeEdition {...{selectedDate, setSelectedDate, SelectedFormation}}/>
		}
		{/* */}
	
	</SpaceVertical>
	
}))

/**
 *
 *
 *
 */
