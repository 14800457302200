import React from "react";

export const IconButton = (props) => {
	let style = {cursor: "pointer"}
	return <i
		{...props}
		style={style}
		className={"ms-Icon--" + props.name}
	/>
}
