/**
 *
 */
import {FilterOutlined} from "@ant-design/icons";
import {Badge, Button, Row, Select, Space, Tooltip} from "antd";
import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import {BadgeCount} from "../../components/BadgeCount";
import {IconButton} from "../../components/IconButton";
import {InputSearch} from "../../components/InputSearch";
import {Notify} from "../../components/Notify";
import {APPRENANT_STATUTS, FILTER_BY_FAVORITES, FILTER_BY_FORMATION_SUIVIE, FILTER_BY_STATUT, FILTER_BY_WORDS} from "../../config/Constantes.logic";
/**
 *
 */
import {ENV, ENV_DEV} from "../../config/Constantes.urls";
import {key} from "../../helpers/Utils";
import {Apprenant} from "../apprenant/Apprenant";
import {FavoriteSwitch} from "../common/components/FavoriteSwitch";
import {InscriptionGrid} from "./datagrid/InscriptionGrid";
import {RefreshDataButton} from "../refreshButtons/RefreshDataButton";
import {RefreshFromApiFormationButton} from "../refreshButtons/RefreshFromApiFormationButton";

/**
 *
 */

const KEY_IN_USER_SETTINGS = "UserSettings.inscription"
/**
 *
 */
export default inject("store")(observer(class Inscriptions extends Component {
	Filters = {
		FILTER_BY_WORDS: {name: FILTER_BY_WORDS, value: null},
		FILTER_BY_FAVORITES: {name: FILTER_BY_FAVORITES, value: null},
		FILTER_BY_STATUT: {name: FILTER_BY_STATUT, value: null},
		FILTER_BY_FORMATION_SUIVIE: {name: FILTER_BY_FORMATION_SUIVIE, value: null},
	}
	
	constructor(props) {
		super(props);
		const UserStore = this.props.store.UserStore
		this.UserStore = UserStore
		const UserSettings = this.UserStore.getUserSettings(KEY_IN_USER_SETTINGS)
		this.state = {
			Filters: UserSettings.Filters || {},
			//UserColumns : UserColumns,
			Sorter: {order: null, field: null},
			selectedRows: [],
			showSelection: false,
			showColumnsSelection: false,
			showFilters: JSON.parse((localStorage.showFilters || "false")),
			//
			
		}
	}
	
	/**
	 *
	 * @param Setting
	 */
	setUserSettings = (Setting) => {
		this.props.store.UserStore.setUserSettings(Setting, KEY_IN_USER_SETTINGS)
	}
	
	/**
	 * Filters
	 */
	setFilter = (Filter) => {
		const {Filters} = this.state
		const name = Filter.name
		const value = Filter.value
		if (!value) {
			delete Filters[name]
		} else {
			Filters[name] = {name: name, value: value}
		}
		
		this.setState({Filters: Filters}, () => {
			this.setUserSettings({Filters: Filters})
			if (ENV === ENV_DEV) {
				//this.addUserSetting(USER_SETTING_FORMATION_FILTERS_KEY, Filters)
			}
		})
	}
	resetFilters = () => {
		const {Filters} = this.state
		Object.values(Filters).forEach(Filter => {
			Filter.value = null
			this.setFilter(Filter)
		})
		
	}
	
	/**
	 *
	 */
	export = () => {
		Notify(true, this.state.selectedRows.map(item => item.nomUsage).join(", "))
		console.log(this.state.selectedRows)
	}
	
	render() {
		const {Filters, Sorter, showFilters} = this.state;
		
		const {store} = this.props
		const {InscriptionStore} = store
		
		const Records = InscriptionStore.getAllByFilters(Filters, Sorter)
		const RecordsByStatutApprenantTotaux = InscriptionStore.getByStatutApprenantTotaux()
		const RecordsByFormationSuivieTotaux = InscriptionStore.getByFormationSuivieTotaux()
		/**
		 *  filters et buttons
		 */
		
		/**
		 * FilterByWord
		 */
		const FilterByWord = (
			<InputSearch
				style={{width: 150}}
				value={Filters.FILTER_BY_WORDS && Filters.FILTER_BY_WORDS.value}
				placeholder="Nom, N° etc"
				onChange={event => {
					const Filter = this.Filters[FILTER_BY_WORDS]
					Filter.value = event.target.value
					this.setFilter(Filter)
				}}
			/>
		)
		/**
		 * FilterByFavorite
		 */
		const FilterByFavorite = (
			<FavoriteSwitch
				checked={Filters.FILTER_BY_FAVORITES && Filters.FILTER_BY_FAVORITES.value}
				onChange={(value) => {
					const Filter = this.Filters[FILTER_BY_FAVORITES]
					Filter.value = value
					this.setFilter(Filter)
					
				}}
			/>
		)
		/**
		 * defaultSelectFilterProps
		 */
		const defaultSelectFilterProps = {
			style: {width: 170},
			allowClear: true,
			showSearch: true,
			suffixIcon: <FilterOutlined/>,
			optionFilterProp: "label",
			notFoundContent: "Aucun enregistrement trouvé.",
		}
		/**
		 * filterByFormationSuivie
		 */
		
		const filterByFormationSuivie = (
			<Select
				{...defaultSelectFilterProps}
				mode={"multiple"}
				style={{minWidth: 200, width: "100%"}}
				placeholder={"Par formation visée"}
				value={Filters.FILTER_BY_FORMATION_SUIVIE && Filters.FILTER_BY_FORMATION_SUIVIE.value}
				optionFilterProp={"label"}
				onChange={(value) => {
					if (value.length === 0) {
						value = null
					}
					const Filter = this.Filters[FILTER_BY_FORMATION_SUIVIE]
					Filter.value = value
					this.setFilter(Filter)
					
				}}
			>
				{
					RecordsByFormationSuivieTotaux.map(item =>
						<Select.Option key={key()} value={item.value} label={item.libelle}>
							{item.libelle}
						</Select.Option>
					)
				}
			
			</Select>
		
		)
		/**
		 * FilterByAnneeCreation
		
		const FilterByAnneeCreation = (
			<Select
				{...defaultSelectFilterProps}
				showSearch={false}
				placeholder={"Par année création"}
				value={Filters.FILTER_BY_ANNEE_CREATION && Filters.FILTER_BY_ANNEE_CREATION.value}
				onChange={(value) => {
					const Filter = this.Filters[FILTER_BY_ANNEE_CREATION]
					Filter.value = value
					this.setFilter(Filter)
					
				}}
			>
				{
					RecordsByAnneeCreationTotaux.map(item =>
						<Select.Option key={key()} value={item.value}>
							<div className={"flex"}>
								<span>{item.libelle}</span>
								<BadgeCount total={item.total}/>
							</div>
						</Select.Option>
					)
				}
			
			</Select>
		) */
		/**
		 * FilterByStatut
		 */
		const FilterByStatut = (
			<Select
				{...defaultSelectFilterProps}
				style={{width: 150}}
				showSearch={false}
				placeholder={"Par statut"}
				value={Filters.FILTER_BY_STATUT && Filters.FILTER_BY_STATUT.value}
				onChange={(value) => {
					const Filter = this.Filters[FILTER_BY_STATUT]
					Filter.value = value
					this.setFilter(Filter)
					
				}}
			>
				{
					RecordsByStatutApprenantTotaux.map(item =>
						<Select.Option key={key()} value={item.libelle}>
							<div className={"flex"}>
								<span>{APPRENANT_STATUTS[item.libelle] && APPRENANT_STATUTS[item.libelle].tag}</span>
								<BadgeCount total={item.total}/>
							</div>
						</Select.Option>
					)
				}
			
			</Select>
		)
		const isFiltersExists = Boolean(Object.keys(Filters).length)
		const ButtonResetFilters = (
			<Tooltip title="Supprimer tous les filtres" placement="topRight">
				<Badge dot={isFiltersExists}>
					<Button
						shape="circle"
						type="primary"
						ghost
						disabled={!isFiltersExists}
						icon={<IconButton name={"EraseTool"} type="button"/>}
						onClick={this.resetFilters}/>
				</Badge>
			</Tooltip>
		)
		/**
		 *
		 */
		const ButtonShowHideFilters = (
			<Tooltip title="Afficher / masquer les autres filtres" placement="topRight">
				<Badge dot={isFiltersExists}>
					<Button
						shape="circle"
						type={"primary"}
						ghost={!showFilters}
						icon={<IconButton name="Filter"/>}
						onClick={(e) => {
							e.preventDefault();
							this.setState({showFilters: !showFilters})
							localStorage.showFilters = !showFilters
							
						}}/>
				</Badge>
			</Tooltip>
		)
		
		/**
		 *
		
		const ButtonShowHideFilters = (
			<Tooltip title="Afficher / masquer les autres filtres" placement="topRight">
				<Badge dot={isFiltersExists}>
					<Button
						shape="circle"
						type={"primary"}
						ghost={!showFilters}
						icon={<IconButton name="Filter"/>}
						onClick={(e) => {
							e.preventDefault();
							this.setState({showFilters : !showFilters})
							localStorage.showFilters = !showFilters
							
						}}/>
				</Badge>
			</Tooltip>
		)
		/**
		*
		*
		const ButtonExportToXls = (
			<Badge count={selectedRows.length} overflowCount={selectedRows.length + 1} className="default">
				<Tooltip title="Exporter la liste sous format excel (xlsx)" placement="topRight">
					<Button
						shape="circle"
						type="primary"
						disabled={!Boolean(selectedRows.length)}
						ghost
						icon={<IconButton name={"ExcelDocument"} type="button"/>}
						onClick={(e) => this.export()}
					/>
				</Tooltip>
			</Badge>
		)
		const ButtonShowHideRowSelection = (
			<Tooltip title="Afficher l'outil de sélection" placement="topRight">
				<Button
					shape="circle"
					type={"primary"}
					ghost={!showSelection}
					icon={<IconButton name="MultiSelect"/>}
					onClick={(e) => {
						e.preventDefault();
						this.setState({selectedRows : []})
						this.setState({showSelection : !showSelection})
					}}/>
			</Tooltip>
		) */
		
		/**
		 *
		 */
		
		return (
			<>
				{/*  */}
				<Apprenant/>
				{/* */}
				<Row gutter={[16, 16]}>
					<Space style={{flex: 1}} size="middle">
						<BadgeCount total={Records.length}/>
						{FilterByWord}
						{FilterByFavorite}
						{FilterByStatut}
						{filterByFormationSuivie}
						{/*{FilterByDuplication} */}
					</Space>
					{/*  */}
					<Space size="middle">
						<RefreshDataButton/>
						<RefreshFromApiFormationButton/>
						{/*{ButtonExportToXls}
						{ButtonShowHideFilters}
						{ButtonShowHideRowSelection}
						*/}
					</Space>
					{/*  */}
				</Row>
				
				
				{/*  */}
				<br/>
				<InscriptionGrid Records={Records}/>
				{/*  */}
			
			</>
		)
		
	}
}))
