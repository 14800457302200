import {Tooltip} from "antd";
import React from "react";
import {IconButton} from "../../../../components/IconButton";
/**
 *
 */

/**
 *
 * Affichage des  extensions selon le type du documents
 *
 */

export const PieceJointeSpecifiqueExtensionIcon = (props) => {
	let name = ""
	let fichier = "InscriptionPieceJointeSpecifique "
	switch (props.extension.toLowerCase()) {
		
		default:
			name = "TextInscriptionPieceJointeSpecifique"
			fichier += "texte"
			break;
		//
		case "jpeg":
		case "png":
		case "bmp":
			name = "PictureFill"
			fichier += "image"
			break;
		//
		case "ods":
		case "xls":
		case "xlsx":
			name = "ExcelInscriptionPieceJointeSpecifique"
			fichier += "Excel"
			break;
		//
		case "pdf":
			name = "PDF"
			fichier += "Pdf"
			break;
		//
		case "odt":
		case "doc":
		case "docx":
			name = "WordInscriptionPieceJointeSpecifique"
			fichier += "Word"
			break;
		
	}
	return <Tooltip title={fichier} placement="right"><IconButton name={name}/></Tooltip>
	
}
