/**
 *
 */
import {Form} from 'antd';
import {inject, observer} from "mobx-react";
import React, {useEffect} from "react";
import {Button} from "../../../../components/antExtendedComponent/Button";
import {SpaceVertical} from "../../../../components/antExtendedComponent/Space";
import {Notify} from "../../../../components/Notify";
import {SelectYesNo} from "../../../../components/SelectYesNo";
import {FORM_LAYOUT} from "../../../../config/Constantes.ui";
import {PhotoContent} from "./../InfosGenerales/photo/PhotoContent";
import {PhotoForm} from "./../InfosGenerales/photo/PhotoForm";

/**
 *
 */

/**
 *
 */
export const Photo = inject("store")(observer(props => {
	/**
	 *
	 */
	
	const [form] = Form.useForm();
	/**
	 *
	 */
	const {isReadOnly, Apprenant} = props
	/**
	 *
	 */
	
	useEffect(() => {
		form.setFieldsValue(Apprenant);
	})
	
	/**
	 * modifie l'objet dans le store avant enregistrement
	 */
	const onFieldsChange = (changedFields, allFields) => {
		//
		const Obj = {}
		//
		const changedField = changedFields[0]
		if (!changedField) {
			return false
		}
		const field = changedField.name[0]
		// cas d'un sous-objet
		const subField = changedField.name[1]
		//
		
		if (!subField) {
			let value = changedField.value
			switch (field) {
				default:
					break
				
			}
			Obj[field] = value
			//
		} else {
			Obj[field] = {};
			
			const Fields = allFields.filter(item => item.name[0] === field)
			Obj[field] = {}
			Fields.forEach(item => {
				const _subField = item.name[1]
				let _value = item.value
				
				switch (_subField) {
					default:
						break
					case "pays":
						break
				}
				Obj[field][_subField] = _value
			})
		}
		//
		Apprenant.update(Obj)
		//
	};
	/**
	 *
	 */
	const onFinish = () => {
		
		form.validateFields()
			.then((values) => {
				Notify()
				Apprenant.save(succeeded => {
					Notify(succeeded)
				})
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	/**
	 *
	 * @param  form values
	 * @returns {boolean}
	 */
	
	/**
	 *
	 */
	const FORM_PROPS = Object.assign({
		disabled: isReadOnly,
		form: form,
		colon: false,
		onFieldsChange: onFieldsChange,
	}, FORM_LAYOUT)
	
	/**
	 *
	 */
	
	const ButtonSave = <Button onClick={event => {
		event.stopPropagation();
		onFinish()
	}}
	>Enregistrer</Button>
	
	/**
	 *
	 */
	return (
		<div style={{maxWidth: 600, margin: "auto"}}>
			<SpaceVertical>
				<Form {...FORM_PROPS} l>
					<Form.Item name={"autorisationTrombinoscope"} label={<div className={"wrap"}>J'accepte que ma photo apparaisse dans un trombinoscope</div>}>
						<SelectYesNo/>
					</Form.Item>
					{/*  */}
					<Form.Item label={" "}>{ButtonSave}</Form.Item>
					{/*  */}
				
				</Form>
				<div className={"shadow-2 padding"}>
					<SpaceVertical>
						{!isReadOnly && <PhotoForm Apprenant={Apprenant}/>}
						<PhotoContent Apprenant={Apprenant}/>
					</SpaceVertical>
				</div>
			</SpaceVertical>
		</div>
	);
}))

