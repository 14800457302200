import {DownloadOutlined} from '@ant-design/icons';
import {Button, Select, Space, Tag, Tooltip} from "antd";
import {inject, observer} from "mobx-react";
import React from "react";
import {SpaceVertical} from "../../../../components/antExtendedComponent/Space";
import {Notify} from "../../../../components/Notify";
import MicroService from "../../../../helpers/MicroService";
import {key} from "../../../../helpers/Utils";

export const SelectFormation = inject("store")(observer(React.forwardRef((props, refs) => {
	
	const {store} = props
	const {FormationStore} = store
	
	let _Formations = FormationStore.getAll()
	/**
	 *
	 */
	const selectedFormationId = props.value
	const SelectedFormation = _Formations.find(Formation => Formation._id === selectedFormationId)
	
	/**
	 *
	 */
	const FormationSelect = () => <Select
		{...props}
		showSearch
		optionFilterProp={"label"}
		placeholder={"Recherche par libellé ou année d'examen"}
		//popupMatchSelectWidth={false}
		//allowClear
	>
		{
			_Formations.map(Formation => <Select.Option
					key={Formation._id}
					value={Formation._id}
					label={Formation._id + " " + Formation.libelle + " " + " " + Formation.libelleCourt + " " + Formation.anneeExamen}
					disabled={!Formation.isAvailable}
				>
					<div className={"flex"}>
						<Space>
							{Formation.ResponsableFormationView}
							{Formation.RecruteurView}
							<Tag>{Formation.libelleCourt} ({Formation.famille})</Tag>
						
						</Space>
						<Space size={"small"}>
							{Formation.statut}
							{Formation.diplomante && <Tag>Diplômante</Tag>}
							{Formation.anneeExamen && <Tag><b>{Formation.anneeExamen}</b></Tag>}
							{/*Formation.anneeCreation && <Tag>Année création <b>{Formation.anneeCreation}</b></Tag>*/}
							{Formation.sessionDates && <Tag color={(Formation.isAvailable ? "green" : "red")}>Session <b>{Formation.sessionDates}</b></Tag>}
						</Space>
					</div>
				</Select.Option>
			)
		}
	</Select>
	
	/**
	 *sc
	 */
	const download = (Document) => {
		//
		Notify()//
		//
		MicroService.get(Document.url, (Response) => {
			//
			const File = Response.Record
			//
			const uint8tArray = new Uint8Array(File.content.data);
			const blob = new Blob([uint8tArray], {type: File.type});
			const fileAsObjectUrl = URL.createObjectURL(blob);
			//
			const element = document.createElement('a');
			element.setAttribute("href", fileAsObjectUrl);
			//element.setAttribute("download", PieceJointe.name);
			element.setAttribute("target", "_blank");
			element.style.display = 'none';
			document.body.appendChild(element);
			element.click();
			document.body.removeChild(element);
			//
			Notify(Response.OPERATION_SUCCEED)
			//
		})
		
	}
	/**
	 *
	 */
	const FormationInformations = () => SelectedFormation &&
		<Space>
			{/* */}
			{SelectedFormation.copyReferenceView}
			{/* */}
			{
				SelectedFormation.Documents.map((Document, index) =>
					<Tooltip key={key()} title={"Télécharger la fiche N°" + (index + 1) + " de la formation \"" + SelectedFormation.libelle + '"'} placement={"bottom"}>
						<Button
							style={{backgroundColor: "#f5f4f3"}}
							type="link"
							size={"small"}
							onClick={() => {
								download(Document)
							}}
							icon={<DownloadOutlined/>}
						>{Document.name}</Button>
					</Tooltip>
				)
			}
		
		</Space>
	/**
	 *
	 */
	return <SpaceVertical size={"small"}>
		{/* */}
		<FormationSelect/>
		{/* */}
		<FormationInformations/>
		{/* */}
	</SpaceVertical>
})))
