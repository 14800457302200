import axios from 'axios';
import {Notify} from "../components/Notify";
import {MICRO_SERVICE_BASE_URL} from "../config/Constantes.urls";
import {Store} from "../stores/Store";
import appVersion from "./../appVersion.json";
import {getFingerPrint} from "./Utils";

/**
 *
 * @param defaultConfig
 * @returns {*}
 */
axios.interceptors.request.use(config => {
	
	config.headers["dired-annee-creation"] = sessionStorage.anneeCreationSelected || new Date().getFullYear()
	config.headers["dired-version"] = appVersion.version
	config.headers["dired-user-context"] = Store.NavigationStore.userContext
	config.headers["dired-sid"] = Store.NavigationStore.sid
	config.headers["dired-client"] = Store.NavigationStore.client
	//
	return config;
	//
}, error => {
	console.log("axios.interceptors.request", error)
	return Promise.reject(error);
});
/**
 *
 */
axios.interceptors.response.use(async response => {
	//
	if (response.data === "REDIRECT_TO_AUTH") {
		sessionStorage.clear()
		const fingerPrint = await getFingerPrint()
		window.location = MICRO_SERVICE_BASE_URL + "/Auth/client/" + fingerPrint.visitorId
	}
	if (response.data === "USER_NOT_AUTHORIZED") {
		console.log(response.data)
		//window.location = ""
	}
	//
	
	console.log("***********************")
	console.log(response.config.url, response.data)
	console.log("***********************")
	//
	return response;
	//
}, function (error) {
	console.log("axios.interceptors.response", error)
	return Promise.reject(error);
});
/**
 * utilisé uniquement pour tous les appels authentifiés
 * @type {{create: MicroService.create, update: MicroService.update, delete: MicroService.delete}}
 */
const MicroService = {
	//
	get: (url, callback) => {
		axios.get(url).then(response => {
			callback(response.data);
		})
			.catch(error => Notify(false, error.message))
	},
	//
	post: (url, data, callback) => {
		axios.post(url, data)
			.then(response => {
				callback(response.data);
			})
			.catch(error => Notify(false, error.message))
	},
	//
	create: (url, data, callback) => {
		axios.post(url, data)
			.then(response => {
				callback(response.data);
			})
			.catch(error => Notify(false, error.message))
	},
	//
	update: (url, data, callback) => {
		axios.put(url, data)
			.then(response => {
				callback(response.data);
			})
			.catch(error => Notify(false, error.message));
		
	},
	//
	delete: (url, callback) => {
		axios.delete(url)
			.then(response => {
				callback(response.data);
			})
			.catch(error => Notify(false, error.message))
	}
	//
}
/**
 *
 */
export default MicroService
/**
 *
 */
