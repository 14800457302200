import {CloseOutlined, EditOutlined, SaveOutlined} from "@ant-design/icons";
import {Button, Input, Switch, Table, Tooltip} from "antd";
import {inject, observer} from "mobx-react";
import React from "react";
import {ActifNotActifIcon} from "../../components/ActifNotActifIcon";
import {SpaceHorizontal} from "../../components/antExtendedComponent/Space";
import {IconButton} from "../../components/IconButton";
import {Notify} from "../../components/Notify";
import {BUTTON_CIRCLE_DEFAULT_PROPS, COLUMN_BUTTON_WIDTH, COLUMN_ICON_WIDTH} from "../../config/Constantes.ui";
import {ucFirst} from "../../helpers/Utils";
import {CreateurModificateurIcon} from "../common/components/CreateurModificateurIcon";
import {DestroyButton} from "../common/components/DestroyButton";

/**
 *
 */
export const ReferentielSourceFinancements = inject("store")(observer(props => {
	
	const {store} = props
	const {ReferentielSourceFinancementStore} = store
	let Records = ReferentielSourceFinancementStore.getAll()
	/**
	 *
	 */
	let Columns = [
		/**
		 *
		 */
		{
			dataIndex: "actionEdit",
			title: <Tooltip title={"Créer"}>
				<Button
					size={"small"}
					shape="circle"
					type={"primary"}
					icon={<IconButton name="PageAdd"/>}
					onClick={(e) => {
						ReferentielSourceFinancementStore.create()
						
					}}/>
			</Tooltip>,
			width: COLUMN_BUTTON_WIDTH,
			className: "center editable",
			render: (text, Record) => {
				if (Record._isOnEdition === true) {
					return <SpaceHorizontal>
						<Tooltip title="Annuler" placement="top">
							<Button
								{...BUTTON_CIRCLE_DEFAULT_PROPS}
								icon={<CloseOutlined/>}
								onClick={(e) => {
									e.preventDefault();
									Record.cancel()
									
								}}
							/>
						</Tooltip>
						<Tooltip title="Enregistrer" placement="top">
							<Button
								
								{...BUTTON_CIRCLE_DEFAULT_PROPS}
								icon={<SaveOutlined/>}
								onClick={(e) => {
									e.preventDefault();
									Notify()
									Record.save(success => Notify(success))
									
								}}
							/>
						</Tooltip>
					</SpaceHorizontal>
				} else {
					return <Tooltip title="Modifier" placement="right">
						<Button
							{...BUTTON_CIRCLE_DEFAULT_PROPS}
							icon={<EditOutlined/>}
							onClick={(e) => {
								e.preventDefault();
								Record.edit()
								
							}}
						/>
					</Tooltip>
					
				}
			}
		},
		/**
		 *
		 */
		{
			dataIndex: 'CreateurModificateur',
			title: '',
			width: COLUMN_ICON_WIDTH,
			className: "center",
			render: (text, Record) => <CreateurModificateurIcon Record={Record}/>
			
		},
		/**
		 *
		 */
		{
			dataIndex: "active",
			title: "Active",
			width: 50,
			className: "center",
			render: (text, Record) => {
				if (Record._isOnEdition === true) {
					return <Switch
						defaultChecked={Record.active}
						size="small"
						onChange={value => Record.update({active: value})}
					/>
				}
				return <ActifNotActifIcon active={text}/>
				
			}
		},
		/**
		 *
		 */
		{
			dataIndex: "libelle",
			title: "Libellé",
			width: "50%",
			ellipsis: true,
			render: (text, Record) => {
				if (Record._isOnEdition === true) {
					return (<Input
						defaultValue={text}
						onChange={(e) => Record.update({libelle: ucFirst(e.target.value)})}
					/>)
				}
				
				return text
			}
		},
		/**
		 *
		 */
		{
			dataIndex: "_space_",
			title: '',
		},
		/**
		 * actionDestroy
		 */
		{
			dataIndex: 'actionDestroy',
			title: '',
			width: COLUMN_ICON_WIDTH,
			fixed: "right",
			className: "center",
			render: (text, Record) => <DestroyButton record={Record}/>,
		}
		/**
		 *
		 */
	
	]
	
	/**
	 *
	 */
	return <Table
		rowKey="_id"
		dataSource={Records}
		columns={Columns}
		pagination={false}
		scroll={{y: window.innerHeight - 300}}
		showSorterTooltip={false}
		size="small"
		className={"shadow-2"}
		_t={store._t}
	/>
	
}))
