//
import {Col, Divider, Drawer, Row, Space} from 'antd';

import {inject, observer} from "mobx-react";
import React from "react";
import {SpaceVertical} from "../../components/antExtendedComponent/Space";
import {IconClose} from "../../components/IconClose";
/**
 *
 */
import {DRAWERS_NAMES} from "../../stores/NavigationStore";
import {closeDrawer} from "../common/components/drawerManager";

/**
 *
 */

export const PreProspect = inject("store")(observer(props => {
	
	//
	const drawerName = DRAWERS_NAMES.PREPROSPECT_FORM_DRAWER
	//
	const {store} = props
	const {NavigationStore, PreProspectStore} = store
	//
	const PreProspect = PreProspectStore.SelectedRecord
	/**
	 *
	 */
	const ROW_GUTTER = [16, 16]
	const InformationPersonnellesEtProjetView = () => {
		const LineHorizontal = (props) => {
			const label = props.content[0]
			const value = props.content[1]
			return <Row style={{marginBottom: 10}} gutter={ROW_GUTTER}><Col span={8}><b><label>{label}</label></b></Col><Col span={16}>{value}</Col></Row>
		}
		const LineVertical = (props) => {
			const label = props.content[0]
			const value = props.content[1]
			return <div style={{marginBottom: 10}}><b><label>{label}</label></b>
				<div>{value || "?"}</div>
			</div>
		}
		return <div className={"details"}>
			{/*  */}
			<Divider orientation={"left"}><b>Informations Personnelles</b></Divider>
			<LineHorizontal content={["Modifié le", PreProspect._updatedAt]}/>
			<LineHorizontal content={["Civilité", PreProspect._civilite]}/>
			<LineHorizontal content={["Nom", PreProspect.nomUsage]}/>
			<LineHorizontal content={["Prénom", PreProspect.prenom]}/>
			<LineHorizontal content={["Né(e) le", PreProspect._dateNaissance]}/>
			<LineHorizontal content={["Email", PreProspect.email]}/>
			<LineHorizontal content={["Téléphone", PreProspect.telephone]}/>
			<LineHorizontal content={["Nationalité", PreProspect._nationalite]}/>
			{/*  */}
			<Divider orientation={"left"}><b>Projet</b></Divider>
			<LineVertical content={["Diplôme le plus élevé", PreProspect.diplomeLePlusEleve]}/>
			<LineVertical content={["1ère Formation souhaitée", PreProspect._formationSouhaiteePremiere]}/>
			<LineVertical content={["2ème Formation souhaitée", PreProspect._formationSouhaiteeSeconde]}/>
			<LineVertical content={["Profil", PreProspect._profil]}/>
			<LineVertical content={["Objectif", PreProspect._objectif]}/>
			<LineVertical content={["Domaine d'activité", PreProspect.domaineActivite]}/>
			<LineVertical content={["Détails", PreProspect.details]}/>
			{/*  */}
		</div>
	}
	
	/*****************************************************************
			Drawer
	 *****************************************************************/
	return PreProspect && <Drawer
		title={<Space>{PreProspect.fullname}{PreProspect.statutTag}</Space>}
		open={NavigationStore.isDrawerVisible(drawerName)}
		closeIcon={<IconClose/>}
		width={500}
		onClose={() => {
			closeDrawer(drawerName)
		}}
		mask={false}
		_t={store._t}
	>
		<SpaceVertical>
			<InformationPersonnellesEtProjetView/>
		</SpaceVertical>
	</Drawer>
	
	/**
	 *
	 */
	
}))

