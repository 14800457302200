import {FilterOutlined} from "@ant-design/icons";
import alasql from "alasql";
import {Button, List, Popover, Select, Space, Tag, Tooltip} from "antd";
/**
 *
 */
import {inject, observer} from "mobx-react";
import React, {useState} from "react";
import {SpaceHorizontal} from "../../components/antExtendedComponent/Space";
import {BadgeCount} from "../../components/BadgeCount";
import {IconButton} from "../../components/IconButton";
import {Notify} from "../../components/Notify";
import {BUTTON_DEFAULT_PROPS, COLOR_PRIMARY} from "../../config/Constantes.ui";
import {key, normalize, toLocalDateTime} from "../../helpers/Utils";
import {DestroyButton} from "../common/components/DestroyButton";
import {Commentaire} from "./Commentaire";
import {CONTEXT_APPRENANT_DISCUSSION} from "../../config/Constantes.contexts";

/**
 *
 * Commentaires
 *
 */
export const Commentaires = inject("store")(observer(props => {
	/**
	 *
	 */
	const [filteredTag, setFilteredTag] = useState([]);
	const [isFormVisible, setIsFormVisible] = useState(false);
	const {store, belongTo, Apprenant} = props
	const {CommentaireStore, UserStore} = store
	const tags = CommentaireStore.getTagsByBelongTo(belongTo)
	const Commentaires = CommentaireStore.getAllByBelongTo(belongTo, filteredTag)
	const AuthenticatedUser = UserStore.AuthenticatedUser
	/**
	 *
	 */
	
	/**
	 *
	 */
	const exportCommentaires = () => {
		const ColumnsToExport = {
			"Owner": "Crée par",
			"createdAt": "Le",
			"texte": "Contenu",
			"tags": "Tags"
		}
		const Rows = []
		Commentaires.forEach(Commentaire => {
			const Row = {}
			const fields = Object.keys(ColumnsToExport)
			fields.forEach(field => {
				let value = Commentaire[field]
				let title = ColumnsToExport[field]
				// si besoin de formatter certains champs
				switch (field) {
					case "Owner":
						value = value.fullname
						break
					case "createdAt":
						value = toLocalDateTime(value)
						break
					case "tags":
						value = value.join(", ")
						break
				}
				Row[title] = value
				
			})
			//
			Rows.push(Row)
		})
		const fileName = "liste_des_commentaires_" + Apprenant.fullname + ".xlsx"
		const opts = [{sheetid: Apprenant.fullname + " (" + Commentaires.length + ")", header: true}];
		
		Notify()
		alasql.promise('SELECT INTO XLSX("' + fileName + '",?) FROM ?', [opts, [Rows]])
			.then(function (res) {
				Notify(true)
			})
			.catch(function (err) {
				console.log('Error:', err);
			});
	}
	/**
	 *
	 * @param Record
	 */
	const Owner = ({Record}) => {
		const title = "Créé par " + Record.Owner.fullname + " le " + toLocalDateTime(Record.createdAt)
		return <Popover content={title} placement="right">
			<IconButton name="UserOptional" style={{color: COLOR_PRIMARY}}/>
		</Popover>
	}
	/**
	 *
	 */
	const DeleteButton = ({Record}) => {
		if (Record.Owner._id === AuthenticatedUser._id) {
			return <DestroyButton record={Record}/>
			
		} else {
			return <Tooltip title="Vous ne pouvez supprimer que vos commentaires." placement="left">
				<IconButton name={"StatusCircleBlock"}/>
			</Tooltip>
		}
	}
	/**
	 * SearchSelect
	 */
	const SearchSelect = () => {
		return <Select
			mode={"multiple"}
			defaultValue={filteredTag}
			placeholder={"Filtrer par tag"}
			optionFilterProp={"label"}
			showSearch
			allowClear
			style={{minWidth: 300}}
			suffixIcon={<FilterOutlined/>}
			onChange={(value) => {
				setFilteredTag(value)
			}}
		>
			{
				tags.map(tag =>
					<Select.Option key={key()} value={tag} label={normalize(tag)}>{tag}</Select.Option>
				)
			}
		</Select>
	}
	
	/**
	 * DashBoard
	 */
	const Profil = UserStore.getAllowedContext(CONTEXT_APPRENANT_DISCUSSION)
	
	const DashBoard = () => {
		{/* */
		}
		return <SpaceHorizontal>
			{/* */}
			<BadgeCount total={Commentaires.length}/>
			{/* */}
			<SearchSelect/>
			{
				Profil
				&& Profil.isModifier
				&& <Button
					{...BUTTON_DEFAULT_PROPS}
					onClick={() => {
						setIsFormVisible(true)
					}}
				>Ajouter un message</Button>
			}
			{/* */}
			<Tooltip title="Exporter sous format excel (xlsx)" placement="topRight">
				<Button
					shape="circle"
					type="primary"
					disabled={!Boolean(Commentaires.length)}
					ghost
					icon={<IconButton name={"ExcelDocument"} type="button"/>}
					onClick={(e) => exportCommentaires()}
				/>
			</Tooltip>
			{/* */}
		</SpaceHorizontal>
		{/* */
		}
	}
	/**
	 *
	 */
	const tagStyle = {border: "none", backgroundColor: "#f2f2f2", borderRadius: 50, padding: "0px 10px"}
	/**
	 *
	 */
	return <div>
		
		{/* */}
		<Commentaire belongTo={belongTo} isFormVisible={isFormVisible} setIsFormVisible={setIsFormVisible}/>
		{/* */}
		<DashBoard/>
		<List
			rowKey={"_id"}
			dataSource={Commentaires}
			style={{
				marginTop: 20,
				height: window.innerHeight * 0.7,
				overflow: 'auto',
			}}
			//header={<DashBoard/>}
			className={"shadow-2 padding"}
			size={"small"}
			itemLayout="vertical"
			pagination={{size: "small", hideOnSinglePage: true}}
			renderItem={(Commentaire) => (
				<List.Item
					actions={[
						<DeleteButton Record={Commentaire}/>,
						<Space>{Commentaire.tags.map(tag => <Tag key={key()} style={tagStyle}>{tag}</Tag>)}</Space>
					]}
				>
					<List.Item.Meta
						avatar={<Owner Record={Commentaire}/>}
						title={Commentaire.texte}
						//description={Commentaire.texte}
					/>
				</List.Item>
			)}
		/>
		{/* */}
	
	</div>
	
}))
/**
 *
 *
 *
 */
