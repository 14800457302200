/**
 *
 */
import {Col, DatePicker, Form, Input, Row, Select} from 'antd';
import days from 'dayjs';

import React, {useEffect} from "react";
import {Button} from "../../../components/antExtendedComponent/Button";
import {APPRENANT_AGE_MINIMUM, NATIONALITE_VALUES} from "../../../config/Constantes.logic";
import {FORM_DEFAULT_RULE_REQUIRED, FORM_RULE_EMAIL} from "../../../config/Constantes.ui";
import {ENV, ENV_DEV} from "../../../config/Constantes.urls";
import {key, ucFirstAll} from "../../../helpers/Utils";
import {SelectCivilite} from "../../apprenant/common/SelectCivilite";
import {PREPROSPECT_FORM_PROPS, STEP_2} from "../PreProspectRegistration";

/**
 *
 */
const ROW_GUTTER = [16, 16]
/**
 *
 */
export const Step_1 = ((props => {
	/**
	 *
	 */
	
	const {PreProspect, setPreProspect, formDisabled, setCurrentStep, currentStep} = props
	const [form] = Form.useForm();
	/**
	 *
	 */
	const maxDate = days().subtract(APPRENANT_AGE_MINIMUM, 'year');
	const disabledDate = (current) => {
		return current && current.isAfter(maxDate, 'day');
	};
	
	/**
	 *
	 */
	useEffect(() => {
		//
		let _PreProspect = {...PreProspect}
		
		if (ENV === ENV_DEV) {
			
			_PreProspect.civilite = "MONSIEUR"
			_PreProspect.nomUsage = "zetoutou"
			_PreProspect.prenom = "nordine"
			_PreProspect.email = "nordine.zetoutou@gmail.com"
			_PreProspect.dateNaissance = "2001-01-01"
			
		}
		if (_PreProspect.dateNaissance) {
			_PreProspect.dateNaissance = days(_PreProspect.dateNaissance, 'YYYY-MM-DD');
		}
		//
		form.setFieldsValue(_PreProspect);
		//
		
	})
	
	/**
	 *
	 * @param  form values
	 * @returns {boolean}
	 */
	
	const onFinish = () => {
		form.validateFields()
			.then((values) => {
				values.nomUsage = values.nomUsage.trim().toUpperCase()
				values.prenom = ucFirstAll(values.prenom.trim())
				//
				let Record = {...PreProspect, ...values}
				//
				Record.step = currentStep
				Record.dateNaissance = values.dateNaissance.format("YYYY-MM-DD")
				//
				setPreProspect(Record)
				setCurrentStep(STEP_2)
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
	};
	/**
	 *
	 */
	/**
	 * ButtonSave
	 *  disabled={false} car on permet la navigation m^me si formulaire bloqué
	 */
	const ButtonSave = <div className={"right"}>
		<Button disabled={false} onClick={event => {
			event.stopPropagation();
			onFinish()
		}}
		>Suivant</Button>
	</div>
	
	/**
	 *
	 */
	
	const FORM_PROPS = {
		form: form,
		disabled: formDisabled
	}
	return (
		
		<Form {...FORM_PROPS} {...PREPROSPECT_FORM_PROPS}>
			{/*  */}
			<Row gutter={ROW_GUTTER}>
				<Col sm={24} md={6}>
					<Form.Item name={'civilite'} label={'Civilité'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<SelectCivilite/>
					</Form.Item>
				</Col>
				<Col sm={24} md={9}>
					<Form.Item name={'nomUsage'} label={'Nom'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<Input autoComplete='off'/>
					</Form.Item>
				</Col>
				<Col sm={24} md={9}>
					<Form.Item name={'prenom'} label={'Prénom'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<Input autoComplete='off'/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={ROW_GUTTER}>
				
				<Col span={12}>
					<Form.Item name={'nationalite'} label={'Nationalité'} /*rules={FORM_DEFAULT_RULE_REQUIRED}*/>
						<Select>
							{
								Object.values(NATIONALITE_VALUES).map(item =>
									<Select.Option key={key()} value={item.value}>
										{item.label}
									</Select.Option>
								)
							}
						</Select>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item name={"dateNaissance"} label={"Né(e) le"} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<DatePicker
							style={{width: 130}}
							inputReadOnly
							allowClear={false}
							defaultPickerValue={maxDate}
							disabledDate={disabledDate}
							format={'DD/MM/YYYY'}
						/>
					</Form.Item>
				</Col>
			</Row>
			
			<Row gutter={ROW_GUTTER}>
				<Col span={12}>
					<Form.Item name={"email"} label={'Courriel'} rules={FORM_RULE_EMAIL}>
						<Input autoComplete='off'/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item name={"telephone"} label={'Téléphone'} /*rules={FORM_RULE_NUMERIC}*/>
						<Input autoComplete='off' maxLength={10}/>
					</Form.Item>
				</Col>
			</Row>
			
			{/*
			<Form.Item name={["AdressePrincipale", "complement"]} label={'Adresse postale'} rules={FORM_DEFAULT_RULE_REQUIRED}>
				<Input.TextArea autoSize/>
			</Form.Item>*/}
			{/*
			<Row gutter={ROW_GUTTER}>
				<Col span={6}>
					<Form.Item name={["AdressePrincipale", "codePostal"]} label={'Code postal'} rules={FORM_RULE_NUMERIC}>
						<Input autoComplete='off' maxLength={5}/>
					</Form.Item>
				</Col>
				<Col span={9}>
					<Form.Item name={["AdressePrincipale", "commune"]} label={'Ville'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<Input autoComplete='off'/>
					</Form.Item>
				</Col>
				<Col span={9}>
					<Form.Item name={["AdressePrincipale", "pays"]} label={'Pays de résidence'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<SelectCountry valuename={"name"}/>
					</Form.Item>
				</Col>
			</Row>
			
			<Form.Item name={["AdressePrincipale", "pays"]} label={'Pays de résidence'} rules={FORM_DEFAULT_RULE_REQUIRED}>
				<SelectCountry valuename={"name"}/>
			</Form.Item>
			 */}
			{/*  */}
			
			<br/>
			{ButtonSave}
			{/*  */}
		</Form>
	
	);
}))

