import {Alert, Table, Tooltip} from 'antd';
import {inject, observer} from "mobx-react";
import React from "react";
import {Button} from "../../../../components/antExtendedComponent/Button";
import {SpaceVertical} from "../../../../components/antExtendedComponent/Space";
import {IconButton} from "../../../../components/IconButton";
import {Notify} from "../../../../components/Notify";

import {COLUMN_ICON_WIDTH, DEFAULT_PAGINATION} from "../../../../config/Constantes.ui";
import {DRAWERS_NAMES} from "../../../../stores/NavigationStore";
import {CreateurModificateurIcon} from "../../../common/components/CreateurModificateurIcon";
import {DestroyIcon} from "../../../common/components/DestroyIcon";
import {openDrawer} from "../../../common/components/drawerManager";
/**
 *
 */
import {PieceJointeSpecifiqueDownloadButton} from "./PieceJointeSpecifiqueDownloadButton";
import {PieceJointeSpecifiqueExtensionIcon} from "./PieceJointeSpecifiqueExtensionIcon";
import {PieceJointeSpecifiqueForm} from "./PieceJointeSpecifiqueForm";

/**
 *
 */

const drawerName = DRAWERS_NAMES.PIECE_JOINTE_SPECIFIQUE_FORM_DRAWER
/**
 *
 */
export const InscriptionPieceJointeSpecifiques = inject("store")(observer(props => {
	const {store, Inscription, readonly} = props
	//on peut afficher tout les documents si pas  d'id
	const InscriptionPieceJointeSpecifiques = Inscription.PieceJointeSpecifiques
	
	let defaultColumns = [
		{
			dataIndex: "actionDownload",
			title: <Tooltip title={"Créer"}><Button
				size={"small"}
				shape="circle"
				type={"primary"}
				icon={<IconButton name="PageAdd"/>}
				onClick={(e) => {
					openDrawer(drawerName)
					
				}}/></Tooltip>,
			width: COLUMN_ICON_WIDTH,
			className: "center",
			render: (text, Record) => <PieceJointeSpecifiqueDownloadButton Record={Record}/>
		},
		{
			dataIndex: "confidentiel",
			title: "",
			width: 120,
			render: (text, Record) => Record.confidentielTag
		},
		{
			dataIndex: 'extension',
			className: "center",
			width: COLUMN_ICON_WIDTH,
			render: (text) => <PieceJointeSpecifiqueExtensionIcon extension={text}/>
			
		},
		{
			dataIndex: "size",
			title: 'Taille',
			width: 80,
			render: (text) => (text / 1024 / 1024).toFixed(2) + " Mo"
		},
		{
			dataIndex: "name",
			title: 'Nom',
			ellipsis: true
			
		},
		{
			dataIndex: 'Createur',
			title: '',
			width: COLUMN_ICON_WIDTH,
			render: (text, Record) => <CreateurModificateurIcon Record={Record}/>
			
		},
		{
			dataIndex: '_space_',
		},
		/**
		 * actionDelete
		 */
		{
			dataIndex: 'actionDestroy',
			title: '',
			width: COLUMN_ICON_WIDTH,
			className: "center",
			render: (text, Record) => {
				return <DestroyIcon
					onClick={() => {
						Notify()
						Record.destroy((succeeded) => {
							Notify(succeeded)
							//Inscription.update()
						})
						
					}}
				/>
				
			}
		}
	
	];
	/**
	 *
	 */
	const Columns = defaultColumns.filter(Column => {
		if (readonly) {
			return Column.dataIndex !== "actionDestroy"
		}
		return Column
	})
	/**
	 *
	 */
	
	/**
	 *
	 */
	return (
		<SpaceVertical>
			<Alert showIcon message={"Pour mettre à jour un document, supprimez-le puis le renvoyer."}/>
			{/*  */}
			<Table
				className={"shadow-2"}
				rowKey="_id"
				columns={Columns}
				dataSource={InscriptionPieceJointeSpecifiques}
				locale={{emptyText: "Aucun document annexe."}}
				size="small"
				pagination={DEFAULT_PAGINATION}
				_t={store._t}
			/>
			{/*  */}
			<PieceJointeSpecifiqueForm Inscription={Inscription}/>
			{/*  */}
		</SpaceVertical>
	
	);
	
}))

/**
 *
 *
 */
