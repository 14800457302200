/**
 *
 */
import {Drawer, Space, Tabs} from 'antd';
import {inject, observer} from "mobx-react";
import React from "react";
import {IconPageHeaderBack} from "../../components/IconPageHeaderBack";
import {DRAWERS_NAMES} from "../../stores/NavigationStore";
import {closeDrawer} from "../common/components/drawerManager";
import {Maquette} from "./views/Maquette";
import {Sessions} from "./views/Sessions";
import {ClasseVirtuelles} from "./views/ClasseVirtuelles";
import {Evaluations} from "./views/Evaluations";
import {RefreshFromApiFormationButton} from "../refreshButtons/RefreshFromApiFormationButton";
import {TAB_KEY_CLASSE_VIRTUELLE, TAB_KEY_EVALUATION, TAB_KEY_MAQUETTE, TAB_KEY_PUBLIPOSTAGE, TAB_KEY_SESSION, TAB_KEY_STAGE} from "./VieApprenants";
import {PubliPostageDocuments} from "./views/PubliPostageDocuments";

/**
 *
 */
export const VieApprenant = inject("store")(observer(props => {
	/**
	 *
	 */
	const {store} = props
	const
		{
			NavigationStore,
			ApprenantStore,
			InscriptionStore,
		} = store
	/**
	 *
	 */
	const drawerName = DRAWERS_NAMES.MAQUETTE_FORM_DRAWER
	/**
	 *
	 */
	const Inscription = InscriptionStore.SelectedRecord
	
	/**
	 * pageHeaderOne
	 */
	const pageHeader = Inscription && <div className={"flex"}>
		<Space>
			<IconPageHeaderBack onClick={() => {
				closeDrawer(drawerName)
			}}/>
			<span className={"--title"}>{Inscription.Apprenant.fullname}</span>
			<span>{Inscription.numeroTag}</span>
			<span>{Inscription.Apprenant.moodleIdTag}</span>
		</Space>
		<div>
			<RefreshFromApiFormationButton/>
		</div>
	</div>
	/**
	 *
	 */
	let items = [
		
		{
			key: TAB_KEY_MAQUETTE,
			label: "Maquettes",
			children: <Maquette/>
		},
		{
			key: TAB_KEY_SESSION,
			moodleRequired: true,
			label: "Sessions",
			children: <Sessions InscriptionSelected={Inscription}/>
		},
		{
			key: TAB_KEY_CLASSE_VIRTUELLE,
			moodleRequired: true,
			label: "Classes virtuelles",
			children: <ClasseVirtuelles InscriptionSelected={Inscription}/>
		},
		{
			key: TAB_KEY_EVALUATION,
			moodleRequired: true,
			label: "Évaluations",
			children: <Evaluations InscriptionSelected={Inscription}/>
		},
		{
			key: TAB_KEY_PUBLIPOSTAGE,
			moodleRequired: true,
			label: "Annexes",
			children: <PubliPostageDocuments InscriptionSelected={Inscription}/>
		},
		{
			key: TAB_KEY_STAGE,
			moodleRequired: true,
			label: "Stages",
			children: "A venir",
			disabled: true
		},
	]
	/**
	 * doit avoir un compte moodle
	 */
	
	//items.forEach(item => item.disabled = item.moodleRequired && (Inscription && !Inscription.Apprenant.hasCompteMoodle))
	/**
	 *
	 */
	return (
		<Drawer
			title={pageHeader}
			open={NavigationStore.isDrawerVisible(drawerName)}
			closable={false}
			mask={false}
			onClose={() => closeDrawer(drawerName)}
			width={700}
			zIndex={1001}
			className={"--maquette"}
			_t={store._t}
		>
			{/* */}
			
			<Tabs
				//defaultActiveKey={(ENV===ENV_DEV ? TAB_2:TAB_1)}
				activeKey={ApprenantStore.vieApprenantePageTabsActiveKey || TAB_KEY_MAQUETTE}
				onChange={(value) => {
					ApprenantStore.setVieApprenantePageTabsActiveKey(value)
				}}
				items={items}
			/>
			
			{/* */}
		</Drawer>
	
	);
}))
