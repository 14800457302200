/**
 *
 */

export const ENV_DEV = "DEV"
export const ENV_QUALIF = "QUALIF"
export const ENV_PROD = "PROD"
//
let host = window.location.host
let _ENV = ""
if (process.env.NODE_ENV === "development") {
	_ENV = ENV_DEV
} else if (host.includes("qualif")) {
	_ENV = ENV_QUALIF
} else {
	_ENV = ENV_PROD
}
export const ENV = _ENV
/**
 *
 */

export const SUPPORT_URL = "https://ticket.institut-agro-dijon.fr/marketplace/formcreator/front/formdisplay.php?id=21"
export const LOGO_AGROSUP = "https://si-applicatifs-assets.institut-agro.fr/picto_01.svg"

/**
 *
 */
export let MICRO_SERVICE_BASE_URL = "https://172.21.7.113:8888"
export let MOODLE_API_BASE_URL = "https://qualif.moodle.ead.agrosupdijon.fr"
//
switch (ENV) {
	default:
		break
	case ENV_DEV:
		break
	case ENV_QUALIF:
		MICRO_SERVICE_BASE_URL = "https://dired-grc-qualif-back.osc-secnum-fr1.scalingo.io"
		break
	case ENV_PROD:
		MICRO_SERVICE_BASE_URL = "https://dired-grc-prod-back.osc-secnum-fr1.scalingo.io"
		MOODLE_API_BASE_URL = "https://moodle.ead.agrosupdijon.fr"
	
}
/**
 * @type {string}
 */
export const MICRO_SERVICE_URL = MICRO_SERVICE_BASE_URL + "/microservice"
export const LOGOUT_URL = MICRO_SERVICE_BASE_URL + "/logout"
/**
 *
 */
export const MICRO_SERVICE_URLS = {
	ALL: "/All",
	//
	FAVORITE: "/Favorite",
	USER: "/User",
	//
	REFERENTIEL_PIECE_JOINTE: "/ReferentielPieceJointe",
	REFERENTIEL_SOURCE_FINANCEMENT: "/ReferentielSourceFinancement",
	REFERENTIEL_COMMENTAIRE_TAG: "/ReferentielCommentaireTag",
	REFERENTIEL_PUBLIPOSTAGE_TEMPLATE: "/ReferentielPubliPostageTemplate",
	//
	APPRENANT: "/Apprenant",
	APPRENANT_PHOTO: "/ApprenantPhoto",
	APPRENANT_COMPTE_EXTERNES: "/ApprenantCompteExternes",
	APPRENANT_RADIATION: "/ApprenantRadiation",
	
	PRE_PROSPECT: "/PreProspect",
	PRE_PROSPECT_FORMATION: "/PreProspectFormation",
	//
	INSCRIPTION: "/Inscription",
	INSCRIPTION_MOODLE_ENROL_USER: "/InscriptionMoodleEnrolUser",
	INSCRIPTION_ABANDON: "/InscriptionAbandon",
	INSCRIPTION_DUPLICATE: "/InscriptionDuplicate",
	//RE_INSCRIPTION : "/Reinscription",
	INSCRIPTION_PIECE_JOINTE: "/InscriptionPieceJointe",
	INSCRIPTION_PIECE_JOINTE_SPECIFIQUE: "/InscriptionPieceJointeSpecifique",
	INSCRIPTION_PROJET_PROFESSIONNEL: "/InscriptionProjetProfessionnel",
	INSCRIPTION_SESSION: "/InscriptionSession",
	INSCRIPTION_CLASSE_VIRTUELLE: "/InscriptionClasseVirtuelle",
	RDV: "/Rdv",
	DOCUMENT: "/Document",
	LOG: "/Log",
	COMMENTAIRE: "/Commentaire",
	//
	COMMUNE: "/Commune",
	//
	SEND_MAIL: "/SendMail",
	//
	PUBLIPOSTAGE_DOCUMENT: "/PubliPostageDocument",
	//
	FORMATION: "/Formation",
	IMPORT_FORMATION: "/ImportFormation",
	IMPORT_MOODLE: "/ImportMoodle",
	//
	
}
for (const key in MICRO_SERVICE_URLS) {
	const url = MICRO_SERVICE_URLS[key]
	MICRO_SERVICE_URLS[key] = MICRO_SERVICE_URL + url
}
/**
 *
 */

//export let MICRO_SERVICE_BASE_URL_AUTH = MICRO_SERVICE_URLS.u+"/authenticateauthenticate"

