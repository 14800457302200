import {Tag} from "antd";
import {key} from "../helpers/Utils";
import {COLOR_RED} from "./Constantes.ui";

/**
 * USER_ROLES
 */
//

export const USER_ROLES = {};
//

/**
 *
 */
export const USER_ROLE_SUPER_ADMIN = "USER_ROLE_SUPER_ADMIN"
USER_ROLES[USER_ROLE_SUPER_ADMIN] = {
	value: USER_ROLE_SUPER_ADMIN,
	label: "Super admin",
	color: COLOR_RED
}

export const USER_ROLE_ADMIN = "USER_ROLE_ADMIN"
USER_ROLES[USER_ROLE_ADMIN] = {
	value: USER_ROLE_ADMIN,
	label: "Admin",
	color: COLOR_RED
}
//
export const USER_ROLE_AGENT = "USER_ROLE_AGENT"
USER_ROLES[USER_ROLE_AGENT] = {
	value: USER_ROLE_AGENT,
	label: "Agent",
}
//
export const USER_ROLE_APPRENANT = "USER_ROLE_APPRENANT"
USER_ROLES[USER_ROLE_APPRENANT] = {
	value: USER_ROLE_APPRENANT,
	label: "Apprenant",
	color: 'blue'
}

// 1. "Téléaccueil"
export const USER_ROLE_TELEACCUEIL = "USER_ROLE_TELEACCUEIL";
USER_ROLES[USER_ROLE_TELEACCUEIL] = {
	value: USER_ROLE_TELEACCUEIL,
	label: "Téléaccueil"
};
// 2. "Gest. Scol"
export const USER_ROLE_GESTION_SCOL = "USER_ROLE_GESTION_SCOL";
USER_ROLES[USER_ROLE_GESTION_SCOL] = {
	value: USER_ROLE_GESTION_SCOL,
	label: "Gest. Scol"
};
export const USER_ROLE_POLE_FC = "USER_ROLE_POLE_FC";
USER_ROLES[USER_ROLE_POLE_FC] = {
	value: USER_ROLE_POLE_FC,
	label: "Pôle FC"
};

// 3. "Chef / Adj"
export const USER_ROLE_CHEF_ADJOINT = "USER_ROLE_CHEF_ADJOINT";
USER_ROLES[USER_ROLE_CHEF_ADJOINT] = {
	value: USER_ROLE_CHEF_ADJOINT,
	label: "Cheffe / Adj"
};

export const USER_ROLE_RECRUTEUR = "USER_ROLE_RECRUTEUR"
USER_ROLES[USER_ROLE_RECRUTEUR] = {
	value: USER_ROLE_RECRUTEUR,
	label: "Recruteur",
}
// 4. "RF"
export const USER_ROLE_RESPONSABLE_FORMATION = "USER_ROLE_RESPONSABLE_FORMATION";
USER_ROLES[USER_ROLE_RESPONSABLE_FORMATION] = {
	value: USER_ROLE_RESPONSABLE_FORMATION,
	label: "RF"
};
export const USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION = "USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION";
USER_ROLES[USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION] = {
	value: USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION,
	label: "Chef service conduite des formation"
};
// 6. "GASA"
export const USER_ROLE_GASA = "USER_ROLE_GASA";
USER_ROLES[USER_ROLE_GASA] = {
	value: USER_ROLE_GASA,
	label: "GASA"
};

export const USER_ROLE_REFERENT_DISCIPLINAIRE = "USER_ROLE_REFERENT_DISCIPLINAIRE";
USER_ROLES[USER_ROLE_REFERENT_DISCIPLINAIRE] = {
	value: USER_ROLE_REFERENT_DISCIPLINAIRE,
	label: "Référent disciplinaire"
};

export const CHEF_SERVICE_CREATION_DE_RESSOURCES = "CHEF_SERVICE_CREATION_DE_RESSOURCES";
USER_ROLES[CHEF_SERVICE_CREATION_DE_RESSOURCES] = {
	value: CHEF_SERVICE_CREATION_DE_RESSOURCES,
	label: "Chef Service création de ressources"
};
// 9. "Agent Expédition"
export const USER_ROLE_AGENT_EXPEDITION = "USER_ROLE_AGENT_EXPEDITION";
USER_ROLES[USER_ROLE_AGENT_EXPEDITION] = {
	value: USER_ROLE_AGENT_EXPEDITION,
	label: "Agent Expédition"
};
//Agent Gestion du stock
export const USER_ROLE_AGENT_GESTION_STOCK = "USER_ROLE_AGENT_GESTION_STOCK";
USER_ROLES[USER_ROLE_AGENT_GESTION_STOCK] = {
	value: USER_ROLE_AGENT_GESTION_STOCK,
	label: "Agent Gestion du stock"
};
// 11. "Responsable de fabrication"
export const USER_ROLE_CHEF_FABRICATION = "USER_ROLE_CHEF_FABRICATION";
USER_ROLES[USER_ROLE_CHEF_FABRICATION] = {
	value: USER_ROLE_CHEF_FABRICATION,
	label: "Chef de fabrication"
};

// 12. "Chef de service / Directeur adjoint"
export const USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT = "USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT";
USER_ROLES[USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT] = {
	value: USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
	label: "Chef de service / Directeur adjoint"
};
//
export const USER_ROLE_ENCAISSEMENT_INSCRIPTIONS = "USER_ROLE_ENCAISSEMENT_INSCRIPTIONS";
USER_ROLES[USER_ROLE_ENCAISSEMENT_INSCRIPTIONS] = {
	value: USER_ROLE_ENCAISSEMENT_INSCRIPTIONS,
	label: "Encaissement inscriptions"
};
// 13. "Directeur"
export const USER_ROLE_DIRECTEUR = "USER_ROLE_DIRECTEUR";//
USER_ROLES[USER_ROLE_DIRECTEUR] = {
	value: USER_ROLE_DIRECTEUR,
	label: "Directeur"
};
// 14. "Référent Oscar"

export const USER_ROLE_REFERENT_OSCAR = "USER_ROLE_REFERENT_OSCAR";
USER_ROLES[USER_ROLE_REFERENT_OSCAR] = {
	value: USER_ROLE_REFERENT_OSCAR,
	label: "Référent Oscar"
};

/**
 *
 */
export const USER_ROLE_COCONSTRUCTEUR = "USER_ROLE_COCONSTRUCTEUR"
USER_ROLES[USER_ROLE_COCONSTRUCTEUR] = {
	value: USER_ROLE_COCONSTRUCTEUR,
	label: "Co-Constructeur",
}
//
export const USER_ROLE_COORDONNATEUR = "USER_ROLE_COORDONNATEUR"
USER_ROLES[USER_ROLE_COORDONNATEUR] = {
	value: USER_ROLE_COORDONNATEUR,
	label: "Coordonnateur",
}
/*
export const USER_ROLE_REFERENT = "USER_ROLE_REFERENT"
USER_ROLES[USER_ROLE_REFERENT] = {
	value: USER_ROLE_REFERENT,
	label: "Référent",
}
 */
//
export const USER_ROLE_INTERVENANT = "USER_ROLE_INTERVENANT"
USER_ROLES[USER_ROLE_INTERVENANT] = {
	value: USER_ROLE_INTERVENANT,
	label: "Intervenant"
}
Object.values(USER_ROLES).forEach((item) => {
	return item.tag = <Tag key={key()} color={item.color}>{item.label}</Tag>
	
})
/**
 * INTERVENANT_ROLES
 */
//
export const INTERVENANT_ROLES = {};
//
export const INTERVENANT_ROLE_ENSEIGNANT = "INTERVENANT_ROLE_ENSEIGNANT"
INTERVENANT_ROLES[INTERVENANT_ROLE_ENSEIGNANT] = {
	value: INTERVENANT_ROLE_ENSEIGNANT,
	label: "Enseignant"
}

export const INTERVENANT_ROLE_CORRECTEUR = "INTERVENANT_ROLE_CORRECTEUR"
INTERVENANT_ROLES[INTERVENANT_ROLE_CORRECTEUR] = {
	value: INTERVENANT_ROLE_CORRECTEUR,
	label: "Correcteur"
}

export const INTERVENANT_ROLE_FORMATEUR = "INTERVENANT_ROLE_FORMATEUR"
INTERVENANT_ROLES[INTERVENANT_ROLE_FORMATEUR] = {
	value: INTERVENANT_ROLE_FORMATEUR,
	label: "Formateur"
}

export const INTERVENANT_ROLE_CLASSE_VIRTUELLE = "INTERVENANT_ROLE_CLASSE_VIRTUELLE"
INTERVENANT_ROLES[INTERVENANT_ROLE_CLASSE_VIRTUELLE] = {
	value: INTERVENANT_ROLE_CLASSE_VIRTUELLE,
	label: "Intervenant d'une classe virtuelle"
}

export const INTERVENANT_ROLE_SESSION = "INTERVENANT_ROLE_SESSION"
INTERVENANT_ROLES[INTERVENANT_ROLE_SESSION] = {
	value: INTERVENANT_ROLE_SESSION,
	label: "Intervenant d'une session"
}

Object.values(INTERVENANT_ROLES).forEach((item, index) => {
	return item.tag = <Tag key={key()} style={{margin: "3px"}}>{item.name}</Tag>
	
})

//const Roles = Object.assign({}, USER_ROLES, INTERVENANT_ROLES);

/*
"USER_ROLE_RESPONSABLE_FORMATION_INTERNE";
"USER_ROLE_COORDONNATEUR_INTERNE";
"USER_ROLE_REFERENT_INTERNE";
"USER_ROLE_INTERVENANT_INTERNE";
"USER_ROLE_RESPONSABLE_FORMATION_EXTERNE";
"USER_ROLE_CO_CONSTRUCTEUR_INTERNE";
"USER_ROLE_RECRUTEUR"*/
