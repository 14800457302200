/**
 *
 */
import {Space, Upload} from 'antd';
import axios from "axios";
import {inject, observer} from "mobx-react";
import React from "react";
import {Button} from "../../../../../components/antExtendedComponent/Button";
import {Notify} from "../../../../../components/Notify";
import {UPLOAD_PHOTO_ACCEPT, UPLOAD_PHOTO_MAX} from "../../../../../config/Constantes.logic";
import {MICRO_SERVICE_URLS} from "../../../../../config/Constantes.urls";
import {newId} from "../../../../../helpers/Utils";
import {DRAWERS_NAMES} from "../../../../../stores/NavigationStore";
import {closeDrawer} from "../../../../common/components/drawerManager";
/**
 *
 */
/**
 *
 */

/**
 *
 */

/**
 *
 */
export default inject("store")(observer(class PhotoUpload extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store
	}
	
	//modele
	
	state = {
		fileList: [],
		isUploading: false,
		isFileLoaded: false,
		isLoading: false,
		PhotoName: "",
		progress: 0
		
	};
	reinitializeState = () => {
		this.setState({
			fileList: [],
			isUploading: false,
			isFileLoaded: false,
			isLoading: false,
			documentName: "",
			progress: 0
		});
	}
	handleUpload = () => {
		//
		const that = this
		this.setState({isUploading: true})
		//
		const {fileList} = this.state;
		const {Apprenant} = this.props
		let file = fileList[0]
		
		const reader = new FileReader();
		reader.readAsArrayBuffer(file);
		//
		reader.onload = function (e) {
			
			let Photo = {}
			Photo.Apprenant = Apprenant._id
			Photo._id = newId()
			//
			let formData = new FormData();
			formData.append('File', file);
			formData.append('Photo', JSON.stringify(Photo));
			/**
			 *
			 */
			axios.post(MICRO_SERVICE_URLS.APPRENANT_PHOTO, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				}
				
			}).then(Response => {
				const data = Response.data
				const succeeded = data.OPERATION_SUCCEED
				if (succeeded === true) {
					that.reinitializeState()
					closeDrawer(DRAWERS_NAMES.PHOTO_FORM_DRAWER)
					//NavigationStore.setRefreshPhotoContent(true)
					
				}
			}).catch(error => {
				alert(error)
			})
			
		}
		
	};
	
	render() {
		/**
		 *
		 */
		const {isUploading, fileList} = this.state;
		/**
		 *
		 */
		const uploadProps = {
			accept: UPLOAD_PHOTO_ACCEPT.join(","),
			fileList,
			onRemove: file => {
				this.setState(state => {
					return {
						fileList: [],
					};
				});
			},
			beforeUpload: file => {
				this.setState({isUploading: false})
				const sizeMaxMo = (UPLOAD_PHOTO_MAX / 1024 / 1024).toFixed(2) + " Mo"
				const currentSizeMaxMo = (file.size / 1024 / 1024).toFixed(2) + " Mo"
				if (!UPLOAD_PHOTO_ACCEPT.includes(file.type)) {
					Notify(false, "Le format de ce Photo n'est pas accepté")
					return false
				}
				if (file.size > UPLOAD_PHOTO_MAX) {
					alert("La taille maximale d'un Photo est de " + sizeMaxMo + ", votre Photo est de " + currentSizeMaxMo)
					return false
				}
				//on ne veut gèrer qu'un fichier à la fois
				this.setState({isFileLoaded: true})
				this.setState(state => ({
					// fileList: [...state.fileList, file],
					fileList: [file],
				}));
				return false;
			},
			
		};
		return (
			<>
				<Space direction={"vertical"}>
					<Upload {...uploadProps}>
						<Button
							type="primary"
						>1. Sélectionner une photo
						</Button>
					</Upload>
					{
						fileList.length > 0 && <Button
							type="primary"
							onClick={this.handleUpload}
							//disabled={fileList.length === 0}
							loading={isUploading}
						>
							{isUploading ? 'Ajout du Photo en cours..' : '2. Envoyer la Photo'}
						</Button>
					}
				</Space>
			</>
		
		);
	}
	
}))

