import {Button, Select, Space, Tag, Tooltip} from "antd";

import {inject, observer} from "mobx-react";
import React from "react";
import {SpaceHorizontal} from "../../components/antExtendedComponent/Space";
import {key, normalize} from "../../helpers/Utils";
import {USER_ROLES} from "../../config/Constantes.roles";
import {InfoCircleOutlined} from "@ant-design/icons";
import {ENV, ENV_DEV, ENV_QUALIF} from "../../config/Constantes.urls";

/**
 *
 * SelectPays
 *
 */
export const SESSION_STORAGE_PROFIL_SWITCHER_KEY = "__PROFIL_SWITCHER__"
export const SESSION_STORAGE_ROLE_SWITCHER_KEY = "__ROLE__SWITCHER__"
export const ProfilSwitcher = inject("store")(observer(props => {
	
	const {UserStore} = props.store
	const AuthenticatedUser = UserStore.AuthenticatedUser
	const Users = UserStore.getAllForProfilSwitcher()
	/**
	 *
	 */
	const storedRoles = sessionStorage[SESSION_STORAGE_ROLE_SWITCHER_KEY] || ""
	const AuthenticatedUserRoles = AuthenticatedUser && AuthenticatedUser.roles
	let rolesDefaultValues = storedRoles && JSON.parse(storedRoles) || AuthenticatedUserRoles
	
	let Roles = Object.values(USER_ROLES).sort((a, b) => a.label.localeCompare(b.label));
	/**
	 *
	 */
	return <Space style={{position: "fixed", bottom: 20, left: 20, zIndex: 10000, fontSize: "0.5em"}}>
		<Select
			_t={props.store._t}
			//open
			showSearch
			optionFilterProp={"label"}
			popupMatchSelectWidth={false}
			//allowClear={true}
			placeholder={"Profil Switcher"}
			value={sessionStorage[SESSION_STORAGE_PROFIL_SWITCHER_KEY] || (AuthenticatedUser && AuthenticatedUser._id)}
			onChange={(value) => {
				//sessionStorage.clear()
				if (value) {
					sessionStorage[SESSION_STORAGE_PROFIL_SWITCHER_KEY] = value
				} else {
					delete sessionStorage[SESSION_STORAGE_PROFIL_SWITCHER_KEY]
				}
				UserStore.setAuthenticatedUser(value)
			}}
		>
			{
				Users.map(User =>
					<Select.Option key={key()} value={User._id} label={User.fullname}>
						<SpaceHorizontal>{User.roleTags}{User.fullname} {/*<Tag>id : {User._id}</Tag>*/}</SpaceHorizontal>
					</Select.Option>
				)
			}
		
		</Select>
		<Select
			_t={props.store._t}
			//open
			mode={"multiple"}
			showSearch
			optionFilterProp={"label"}
			style={{minWidth: 200}}
			popupMatchSelectWidth={false}
			//allowClear={true}
			placeholder={"Role Switcher"}
			value={UserStore.roles || rolesDefaultValues}
			onChange={(value) => {
				//sessionStorage.clear()
				if (value) {
					sessionStorage[SESSION_STORAGE_ROLE_SWITCHER_KEY] = JSON.stringify(value)
				} else {
					delete sessionStorage[SESSION_STORAGE_ROLE_SWITCHER_KEY]
				}
				AuthenticatedUser.setRoles(value)
				
			}}
		>
			{
				Roles.map((ROLE, index) =>
					<Select.Option key={key()} value={ROLE.value} label={normalize(ROLE.label)}>
						<Space>
							<Tooltip key={key()} title={(() => {
								const data = UserStore.getContextsByRole(ROLE.value)
								const content = data && [
									...data.readers.map(role => <div key={key()} style={{margin: 3}}><Tag color={"red"}>{`R => ${role}`}</Tag></div>),
									...data.modifiers.map(role => <div key={key()} style={{margin: 3}}><Tag color={"green"}>{`M => ${role}`}</Tag></div>)
								];
								return <div style={{height: window.innerHeight * 0.9}} className={"overflow"}>{content}</div>
							})}>
								<InfoCircleOutlined/>
							</Tooltip>
							<span>{index++} - {ROLE.label}</span>
						</Space>
					</Select.Option>
				)
			}
		
		</Select>
		<Button type="dashed" onClick={() => {
			sessionStorage[SESSION_STORAGE_ROLE_SWITCHER_KEY] = ""
			window.location = ""
		}}>
			Reset
		</Button>
		{
			ENV === ENV_DEV && window.Constantes &&
			<Space>
				<Button type="dashed"><a target={"_blank"} href={window.Constantes.URLS.QUALIF}>aller en QUALIF</a></Button>
				<Button type="dashed"><a target={"_blank"} href={window.Constantes.URLS.PROD}>aller en PROD</a></Button>
			</Space>
		}
		{
			ENV === ENV_QUALIF && window.Constantes &&
			<Space>
				<Button type="dashed"><a target={"_blank"} href={window.Constantes.URLS.PROD}>PROD</a></Button>
			</Space>
		}
	</Space>
}))

/**
 *
 *
 *
 */
