import {Modal} from "antd";
import {inject, observer} from "mobx-react";
import React from "react";
import {DRAWERS_NAMES} from "../../../../stores/NavigationStore";
import {closeDrawer} from "../../../common/components/drawerManager";
import PieceJointeSpecifique from "./PieceJointeSpecifique";

/**
 *
 */
export const PieceJointeSpecifiqueForm = inject("store")(observer(props => {
	
	const {store, Inscription} = props
	const {NavigationStore} = store;
	const drawerName = DRAWERS_NAMES.PIECE_JOINTE_SPECIFIQUE_FORM_DRAWER
	return <Modal
		_t={store._t}
		title={"Ajout d'une Pièce Jointe"}
		centered
		closable={true}
		width={700}
		open={NavigationStore.isDrawerVisible(drawerName)}
		footer={false}
		/*
		footer={[
			<Button key={key()} {...BUTTON_DEFAULT_PROPS} onClick={() => closeDrawer(drawerName)}>
				Fermer
			</Button>,
		
		]}
		
		
		 */
		onCancel={() => closeDrawer(drawerName)}
	>
		<PieceJointeSpecifique Inscription={Inscription}/>
	</Modal>
	
}))
