/**
 *
 */
import {Col, DatePicker, Form, Input, Row, Select, Space} from 'antd';
import days from 'dayjs';
import {inject, observer} from "mobx-react";

import React, {useEffect, useState} from "react";
import {Button} from "../../../../components/antExtendedComponent/Button";
import {Notify} from "../../../../components/Notify";
import {APPRENANT_AGE_MINIMUM, NATIONALITE_VALUES} from "../../../../config/Constantes.logic";
import {FORM_DEFAULT_RULE_REQUIRED} from "../../../../config/Constantes.ui";
import {key, ucFirstAll} from "../../../../helpers/Utils";
import {AutoCompleteCity} from "../../common/AutoCompleteCity";
import {SelectCivilite} from "../../common/SelectCivilite";
import {SelectCountry} from "../../common/SelectCountry";

/**
 *
 */

/**
 *
 */

export const EtatCivil = inject("store")(observer(props => {
	/**
	 *
	 */
	const [form] = Form.useForm();
	const [autoCompleteVisible, setAutoCompleteVisible] = useState(true);
	const {isReadOnly, Apprenant} = props
	/**
	 * devient un objet non mobx
	 * car on a besoin des id dans le formulaire si champ est un objet
	 */
	const ApprenantEdited = {...Apprenant}
	/**
	 *
	 */
	
	/**
	 * populate form
	 */
	const dateNaissanceDefaultPickerValue = days().add(-APPRENANT_AGE_MINIMUM, 'years')
	
	/**
	 *
	 */
	
	useEffect(() => {
		if (ApprenantEdited.dateNaissance) {
			ApprenantEdited.dateNaissance = days(ApprenantEdited.dateNaissance, 'YYYY-MM-DD');//
		}
		if (ApprenantEdited.LocaliteNaissance && ApprenantEdited.LocaliteNaissance.pays === "") {
			ApprenantEdited.LocaliteNaissance = {...ApprenantEdited.LocaliteNaissance}
			ApprenantEdited.LocaliteNaissance.pays = "France"
		}
		if (ApprenantEdited.AdressePrincipale && ApprenantEdited.AdressePrincipale.pays === "") {
			ApprenantEdited.AdressePrincipale = {...ApprenantEdited.AdressePrincipale}
			ApprenantEdited.AdressePrincipale.pays = "France"
		}
		
		//
		form.setFieldsValue(ApprenantEdited);
	})
	/**
	 *
	 */
	const disabledDate = (d) => d => d && d.isAfter(days().add(-APPRENANT_AGE_MINIMUM, 'years'))
	/**
	 * modifie l'objet dans le store avant enregistrement
	 */
	const onFieldsChange = (changedFields, allFields) => {
		//
		const Obj = {}
		//
		const changedField = changedFields[0]
		if (!changedField) {
			return false
		}
		const field = changedField.name[0]
		// cas d'un sous-objet
		const subField = changedField.name[1]
		//
		
		if (!subField) {
			let value = changedField.value
			switch (field) {
				default:
					break
				case "nomUsage":
				case "nomNaissance":
					value = value.toUpperCase()
					break
				case "prenom":
				case "prenomAutres":
					value = ucFirstAll(value)
					break;
				case "dateNaissance":
					value = value.format("YYYY-MM-DD")
					break;
			}
			Obj[field] = value
			//
		} else {
			Obj[field] = {};
			
			const Fields = allFields.filter(item => item.name[0] === field)
			Obj[field] = {}
			Fields.forEach(item => {
				const _subField = item.name[1]
				let _value = item.value
				
				switch (_subField) {
					default:
						break
				}
				Obj[field][_subField] = _value
			})
		}
		//
		Apprenant.update(Obj)
		//
	};
	/**
	 *
	 * @param  form values
	 * @returns {boolean}
	 */
	const onFinish = () => {
		
		form.validateFields()
			.then((values) => {
				
				Notify()
				Apprenant.save(succeeded => {
					Notify(succeeded)
				})
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	
	/**
	 * ButtonSave
	 */
	const ButtonSave = <div className={"center"}>
		<Button block onClick={onFinish}>Enregistrer</Button>
	</div>
	/**
	 *
	 */
	/**
	 *
	 */
	const FORM_PROPS = {
		form: form,
		disabled: isReadOnly,
		layout: "vertical",
		className: "fadein shadow-2 padding",
		style: {width: 700, margin: "auto"},
		onFieldsChange: onFieldsChange,
	}
	
	/**
	 *
	 */
	return (
		<Form {...FORM_PROPS} >
			{/*  */}
			
			{/*  */}
			<Row>
				<Col span={5}>
					<Form.Item name={'numeroINE'} label={'Numéro INA ou INE'}>
						<Input autoComplete='off' maxLength={13}/>
					</Form.Item>
				</Col>
				<Col span={5}>
					<Form.Item name={'civilite'} label={'Civilité'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<SelectCivilite/>
					</Form.Item>
				</Col>
				<Col span={7}>
					{/*  */}
					<Form.Item name={'nomUsage'} label={'Nom d\'usage'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<Input autoComplete='off'/>
					</Form.Item>
				</Col>
				<Col span={7}>
					<Form.Item name={'prenom'} label={'Prénom'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<Input autoComplete='off'/>
					</Form.Item>
				</Col>
			</Row>
			
			{/*  */}
			<Row>
				<Col span={6}>
					<Form.Item name={'nomNaissance'} label={'Nom de naissance'}>
						<Input autoComplete='off'/>
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item name={'prenomAutres'} label={'Autre(s) prénom(s)'}>
						<Input autoComplete='off'/>
					</Form.Item>
				</Col>
			</Row>
			{/*  */}
			<Form.Item
				name={"dateNaissance"}
				label={<Space><span>Né le</span>{Apprenant.ageTag}</Space>}
				rules={FORM_DEFAULT_RULE_REQUIRED}
				//help={<Tag color={"blue"} style={{marginTop : 10, marginBottom : 10}}>Cliquez sur l'année pour la modifier.</Tag>}
			>
				<DatePicker
					style={{marginTop: 3}}
					placeholder={""}
					defaultPickerValue={dateNaissanceDefaultPickerValue}
					//inputReadOnly
					allowClear={false}
					disabledDate={disabledDate()}
					format={'DD/MM/YYYY'}
				/>
			</Form.Item>
			{/*  */}
			<Row>
				<Col span={8}>
					<Form.Item name={'nationalite'} label={'Nationalité'}>
						<Select>
							{
								Object.values(NATIONALITE_VALUES).map(item =>
									<Select.Option key={key()} value={item.value}>
										{item.label}
									</Select.Option>
								)
							}
						</Select>
					</Form.Item>
				</Col>
				<Col span={16}>
					<Form.Item name={["LocaliteNaissance", "pays"]} label={'Pays de naissance'}>
						<SelectCountry
							valuename={"name"}
							onSelect={(value) => {
								setAutoCompleteVisible(value === "France")
								
							}}/>
					</Form.Item>
				</Col>
			
			</Row>
			{
				autoCompleteVisible && <Form.Item label={"Saisir le nom ou le code postal du lieu de naissance"}>
					<AutoCompleteCity
						onChange={(Place) => {
							Apprenant.update({
								LocaliteNaissance: {
									commune: Place.communeNom,
									departement: Place.departementNom,
									pays: "France"
								}
							})
						}}
					/>
				</Form.Item>
			}
			
			{/*  */}
			<Form.Item name={["LocaliteNaissance", "commune"]} label={'Ville de naissance'}>
				<Input autoComplete='off'/>
			</Form.Item>
			{/*  */}
			<Form.Item name={["LocaliteNaissance", "departement"]} label={'Département de naissance'}>
				<Input autoComplete='off'/>
			</Form.Item>
			{/*  */}{/*  */}
			{ButtonSave}
			{/*  */}
			{/*  */}
		</Form>
	);
}))

