/**
 *
 */

export const FILTER_BY_INSCRIPTION = "FILTER_BY_INSCRIPTION"
export const FILTER_BY_ANNEE_EXAMEN = "FILTER_BY_ANNEE_EXAMEN"
export const FILTER_BY_INTERVENANT = "FILTER_BY_INTERVENANT"
export const FILTER_BY_FORMATION = "FILTER_BY_FORMATION"
export const FILTER_BY_APPRENANT = "FILTER_BY_APPRENANT"
export const FILTER_BY_CORRECTEUR = "FILTER_BY_CORRECTEUR"
export const FILTER_BY_GRADED = "FILTER_BY_GRADED"
export const FILTER_BY_FAVORITES = "FILTER_BY_FAVORITES"
