import {Tooltip} from "antd";
import React from "react";
import {SpaceHorizontal} from "../../../../components/antExtendedComponent/Space";
import {IconButton} from "../../../../components/IconButton";
/**
 *
 */

/**
 *
 * Affichage des  extensions selon le type du documents
 *
 */

export const PieceJointeIcon = ({metaInfos}) => {
	
	let name = ""
	let fichier = " "
	switch (metaInfos.extension.toLowerCase()) {
		
		default:
			name = "TextDocument"
			fichier = "texte"
			break;
		//
		case "jpeg":
		case "png":
		case "bmp":
			name = "PictureFill"
			fichier = "image"
			break;
		//
		case "ods":
		case "xls":
		case "xlsx":
			name = "ExcelDocument"
			fichier = "Excel"
			break;
		//
		case "pdf":
			name = "PDF"
			fichier = "Pdf"
			break;
		//
		case "odt":
		case "doc":
		case "docx":
			name = "WordDocument"
			fichier = "Word"
			break;
		
	}
	const size = (metaInfos.size / 1024 / 1024).toFixed(2) + " Mo"
	const title = "Document " + fichier + " (" + size + ")"
	return <Tooltip title={title} placement="right">
		<SpaceHorizontal>
			<IconButton name={name}/>
		</SpaceHorizontal>
	</Tooltip>
	
}
