/**
 *
 */
import {LockOutlined} from "@ant-design/icons";
import {Alert, Form, Input, Select, Space, Tag} from 'antd';
import days from "dayjs";
import {inject, observer} from "mobx-react";
import React, {useEffect, useState} from "react";
/**
 *
 */
import {Button, ButtonSmall} from "../../../components/antExtendedComponent/Button";
import {SpaceHorizontal, SpaceVertical} from "../../../components/antExtendedComponent/Space";
import {BadgeCount} from "../../../components/BadgeCount";
import {Notify} from "../../../components/Notify";
import {SelectYesNo} from "../../../components/SelectYesNo";
import {
	BAC_PRO,
	BAC_TECHNO,
	BTSA,
	INSCRIPTION_PROJET_STATUT_ACCEPTE,
	INSCRIPTION_PROJET_STATUT_EN_ATTENTE,
	INSCRIPTION_STATUT_VALIDATION_PEDAGOGIQUE_EN_ATTENTE,
	INSCRIPTION_STATUT_VALIDATION_PEDAGOGIQUE_REFUSE,
	LANGUE_EN_ETABLISSEMENT,
	LANGUES_VIVANTES_EXPERIENCE_VALUES,
	LANGUES_VIVANTES_PARCOURS_ANTERIEUR_VALUES,
	LANGUES_VIVANTES_VALUES,
	OBJECTIF_TRAVAILLER_A_MON_COMPTE,
	OBJECTIF_TROUVER_UN_EMPLOI_DE_SALARIE,
	POSSIBILITES_AIDE_VALUES,
	PROJET_OBJECTIFS
} from "../../../config/Constantes.logic";
import {key, toLocalDateTime} from "../../../helpers/Utils";
import {DRAWERS_NAMES} from "../../../stores/NavigationStore";
import {NON_CONCERNE, SelectYesNoPasConcerne} from "../../apprenant/common/SelectYesNoPasConcerne";
import {CreateurModificateurIcon} from "../../common/components/CreateurModificateurIcon";
import {closeDrawer} from "../../common/components/drawerManager";
import {SectionTitle} from "../../common/components/SectionTitle";
import {SelectFormation} from "./common/SelectFormation";
import {ProjetProfessionnelValidation} from "./ProjetProfessionnelValidation";

;

/**
 *
 */
const displayValidationProjetProfessionnelFormKey = "displayValidationProjetProfessionnelFormKey"
/**
 * ""].map(item => <Select.Option key={key()}
 * projetObjectif
 */

/**
 *
 */
export const ProjetProfessionnel = inject("store")(observer(props => {
	/**
	 *
	 */
		//
	const displayValidationProjetProfessionnelForm = (JSON.parse(localStorage[displayValidationProjetProfessionnelFormKey] || true) ? true : false)
	const [isValidationProjetProfessionnelFormVisible, setValidationProjetProfessionnelFormVisible] = useState(displayValidationProjetProfessionnelForm);
	/**
	 *
	 */
	const {isReadOnly, store} = props
	/**
	 *
	 */
	const {
		UserStore,
		InscriptionProjetProfessionnelStore,
		InscriptionStore,
	} = store
	
	/**
	 *
	 */
	const [form] = Form.useForm();
	
	/**
	 *
	 */
	const AuthenticatedUser = UserStore.AuthenticatedUser
	/**
	 *
	 */
	const Inscription = InscriptionStore.SelectedRecord || {}
	const ProjetProfessionnels = Inscription.ProjetProfessionnels || []
	let ProjetProfessionnelSelected = InscriptionProjetProfessionnelStore.SelectedRecord
	if (!ProjetProfessionnelSelected && ProjetProfessionnels.length > 0) {
		const ProjetProfessionnelSelected = ProjetProfessionnels[0]
		setTimeout(() => {
			InscriptionProjetProfessionnelStore.setSelectedRecord(ProjetProfessionnelSelected)
		}, 10)
	}
	const ProjetProfessionnelEdited = {...ProjetProfessionnelSelected}
	/**
	 * populate form
	 */
	let isFormationChoisieApresEntretienEmpty = false
	useEffect(() => {
		const langueVivantes = (ProjetProfessionnelEdited && ProjetProfessionnelEdited.langueVivantes) || []
		if (langueVivantes.length > 0) {
			ProjetProfessionnelEdited.langueVivantes = langueVivantesValidate(langueVivantes)
		}
		//
		if (ProjetProfessionnelEdited.dateEntretien) {
			ProjetProfessionnelEdited.dateEntretien = days(ProjetProfessionnelEdited.dateEntretien, 'YYYY-MM-DD');//
		}
		//
		if (ProjetProfessionnelEdited.Responsable) {
			ProjetProfessionnelEdited.Responsable = ProjetProfessionnelEdited.Responsable._id
		}
		//
		if (ProjetProfessionnelEdited.FormationChoisieApresEntretien) {
			ProjetProfessionnelEdited.FormationChoisieApresEntretien = ProjetProfessionnelEdited.FormationChoisieApresEntretien._id
			
		} else {
			
			/**
			 * on met par défaut la formation choisie avant entretien
			 */
			isFormationChoisieApresEntretienEmpty = true
			if (ProjetProfessionnelSelected) {
				//on met à jour le champ du projet
				ProjetProfessionnelSelected.update({FormationChoisieApresEntretien: Inscription.FormationSouhaitee.FormationChoisieAvantEntretien})
				
			}
			ProjetProfessionnelEdited.FormationChoisieApresEntretien = Inscription.FormationSouhaitee.FormationChoisieAvantEntretien
		}
		form.setFieldsValue(ProjetProfessionnelEdited);
	})
	/**
	 *
	 */
	
	/**
	 *
	 */
	const langueVivantesValidate = (langueVivantes) => {
		let map = {}
		langueVivantes.forEach(value => {
			const arr = value.split("-")
			const lv = arr[0]
			const langue = arr[1]
			map[lv] = langue
		})
		let newValues = Object.keys(map).map(lv => {
			const langue = map[lv]
			return lv + "-" + langue
		})
		return newValues
	}
	
	/**
	 * modifie l'objet dans le store avant enregistrement
	 */
	
	const onFieldsChange = (changedFields, allFields) => {
		const Obj = {}
		const changedField = changedFields[0]
		if (!changedField) {
			return false
		}
		const field = changedField.name[0]
		Obj[field] = changedField.value;
		/**
		 * on met par défaut la formation choisie avant entretien
		 */
		if (isFormationChoisieApresEntretienEmpty) {
			Obj.FormationChoisieApresEntretien = Inscription.FormationSouhaitee.FormationChoisieAvantEntretien
			
		}
		switch (field) {
			default:
				break;
			case "langueVivantes":
				//on enlève les choix en double selon la règle métier : si lv1 espagnol pas lv1 allemand
				Obj[field] = langueVivantesValidate(Obj[field])
				
				break;
			case "FormationChoisieApresEntretien":
				//on enregistre également pour l'inscption car on en a besoin lors de la réinscption
				const FormationSuivie = {...Inscription.FormationSuivie}
				FormationSuivie.Formation = Obj[field]
				Inscription.update({FormationSuivie: FormationSuivie})
				Inscription.save(succeeded => {
				})
				break;
			case "objectif":
				//on vide le domaineActivite
				if (
					[
						OBJECTIF_TROUVER_UN_EMPLOI_DE_SALARIE,
						OBJECTIF_TRAVAILLER_A_MON_COMPTE
					]
						.includes(Obj.objectif) === false) {
					
					Obj["domaineActivite"] = ""
				}
			
		}
		/**
		 *
		 */
		console.log(Obj)
		ProjetProfessionnelSelected.update(Obj)
		/**
		 *
		 */
	};
	/**
	 *
	 * @param  form values
	 * @returns {boolean}
	 */
	const onFinish = () => {
		
		form.validateFields()
			.then((values) => {
				Notify()
				ProjetProfessionnelSelected.save(succeeded => {
					Notify(succeeded)
				})
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	/**
	 *
	 */
	const ProjetProfessionneSelectAndCreateView = (props) => {
		//
		
		/**
		 *
		 */
		const AddProjetProfessionnelButton = () => {
			const ValidationStatuts = (ProjetProfessionnelSelected && ProjetProfessionnelSelected.ValidationStatuts) || []
			//
			let disabled = true
			
			//
			let ValidationStatut = null
			//
			/**
			 * Avis En attente , Avis Sous Réserve
			 */
			if (Inscription.statutInscription === INSCRIPTION_STATUT_VALIDATION_PEDAGOGIQUE_EN_ATTENTE) {
				ValidationStatut = ValidationStatuts.find(
					ValidationStatut => ValidationStatut.statut === INSCRIPTION_PROJET_STATUT_EN_ATTENTE
				)
				if (ValidationStatut && ValidationStatut.Responsable._id !== AuthenticatedUser._id) {
					disabled = false
				}
			}
			/**
			 * Avis Sous Réserve
			 */
			
			/**
			 * Avis Défavorable
			 */
			if (Inscription.statutInscription === INSCRIPTION_STATUT_VALIDATION_PEDAGOGIQUE_REFUSE) {
				disabled = false
			}
			//
			if (ValidationStatuts.length === 0) {
				disabled = true
			}
			
			if (ProjetProfessionnels.length === 0) {
				disabled = false
			}
			
			if (Inscription._isNew) {
				disabled = true
			}
			//
			
			/*
			const projectRefusee=ValidationStatuts.find(ValidationStatut=>ValidationStatut.statut===INSCRIPTION_PROJET_STATUT_REFUSE)
			console.log(projectRefusee)
			if(projectRefusee || ValidationStatuts.length===0)
			{
				//disabled=false
			}
			if(ValidationStatuts.length===0)
			{
				//disabled=false
			}
			
			 */
			/*
			console.log(ProjetProfessionnelSelected && ProjetProfessionnelSelected.ValidationStatuts.toJSON())
			console.log(Inscription.toJSON())
			console.log(Inscription.statutInscription)
			
			 */
			
			return <ButtonSmall
				disabled={disabled}
				onClick={() => {
					closeDrawer(DRAWERS_NAMES.PROJET_VALIDATION_DRAWER)
					Notify()
					const NewInscriptionProjetProfessionnel = InscriptionProjetProfessionnelStore.create(Inscription)
					NewInscriptionProjetProfessionnel.save((successOrFailed) => {
						Notify(successOrFailed)
						InscriptionProjetProfessionnelStore.setSelectedRecord(NewInscriptionProjetProfessionnel._id)
					})
					
				}}
			>Créer un entretien
			</ButtonSmall>
		}
		/**
		 *
		 */
		const selectOptions = ProjetProfessionnels.map(ProjetProfessionnel => {
				
				let label = "Projet crée le " + toLocalDateTime(ProjetProfessionnel.createdAt)
				if (ProjetProfessionnel.readOnly) {
					label = <Space><LockOutlined/>{label}</Space>
				}
				return {
					value: ProjetProfessionnel._id,
					label: label
				}
			}
		)
		//
		return <Space className={"flex shadow-2 padding"}>
			<SpaceHorizontal>
				<BadgeCount total={ProjetProfessionnels.length}/>
				<Select
					options={selectOptions}
					placeholder={"Sélectionnez un entretien"}
					defaultValue={ProjetProfessionnelSelected && ProjetProfessionnelSelected._id}
					style={{width: "100%"}}
					popupMatchSelectWidth={false}
					onChange={(value) => {
						InscriptionProjetProfessionnelStore.setSelectedRecord(value)
					}}
				/>
			</SpaceHorizontal>
			<SpaceHorizontal>
				{ProjetProfessionnelSelected && <ButtonSmall
					ghost={isValidationProjetProfessionnelFormVisible}
					onClick={() => {
						let value = !isValidationProjetProfessionnelFormVisible
						localStorage[displayValidationProjetProfessionnelFormKey] = value
						setValidationProjetProfessionnelFormVisible(value)
						
					}}
				>{isValidationProjetProfessionnelFormVisible ? "Masquer les avis RF" : "Afficher les avis RF"}</ButtonSmall>}
				{/*  */}
				<AddProjetProfessionnelButton/>
				{/*  */}
				<CreateurModificateurIcon Record={ProjetProfessionnelSelected} placement={"right"}/>
				{/*
				{
					(ProjetProfessionnelSelected )
					&& <DestroyButton Record={ProjetProfessionnelSelected} titleIfDestroyNotAvailable={"Vous ne pouvez pas supprimer cet entretien"}/>
				}*/}
				{/*  */}
			</SpaceHorizontal>
		</Space>
	}
	/**
	 * ButtonSave
	 */
	const ButtonSave = <div className={"center"}>
		<Button block onClick={onFinish}>Enregistrer</Button>
	</div>
	
	/**
	 *
	 */
	
	/**
	 *
	 */
	const LangueVivanteSelect = (props) => {
		//fonction qui cherche une portion du libellé
		const funct = ProjetProfessionnelSelected.ifFormationChoisieApresEntretienContains
		if (!funct) {
			return <Tag>Aucune formation choisie</Tag>
		}
		let _key = ""
		if (funct(BTSA)) {
			_key = BTSA
		}
		if (funct(BAC_PRO) || funct(BAC_TECHNO)) {
			_key = BAC_TECHNO
		}
		
		if (funct(LANGUE_EN_ETABLISSEMENT)) {
			_key = LANGUE_EN_ETABLISSEMENT
		}
		if (_key === "") {
			return <Tag>Aucune liste de langues n'est définie pour cette formation</Tag>
		}
		//
		const LVs = LANGUES_VIVANTES_VALUES[_key]
		console.log(_key, LVs)
		/*
		const ProjetProfessionnelLvs=ProjetProfessionnelEdited.langueVivantes.map(langueVivante=>langueVivante.split("-")[0])
		console.log(ProjetProfessionnelLvs)
		const availableLvs=LVs.map(LV=>{
			LV.disabled=ProjetProfessionnelLvs.includes(LV.value)
			return LV
		})
		console.log(availableLvs)
		
		 */
		//
		const tagRender = (props) => {
			
			try {
				const {label, value, closable, onClose} = props;
				const LVValue = value.split("-")[0]
				const LV = LVs.find(LV => LV.value === LVValue)
				return LV && <Tag style={{marginTop: 3, marginBottom: 3, padding: 3}} closable={closable} onClose={onClose}>
					<Tag color={LV.color}>{LV.label}</Tag> {label}
				</Tag>
			} catch (e) {
			
			}
			
		}
		//
		
		//
		return <Select
			{...props}
			showSearch={false}
			mode={"multiple"}
			tagRender={tagRender}
		>
			{
				LVs.map(LV =>
					<Select.OptGroup key={key()} label={LV.tag}>
						{
							//on concatène pour enregistrer le lv et la langue : LV.value + "-" + item.value
							LV.items.map(item => <Select.Option key={key()} value={LV.value + "-" + item.value}>
								{item.label}
							</Select.Option>)
						}
					</Select.OptGroup>
				)
				
			}
		</Select>
	}
	/**
	 *
	 */
	const disabled = (() => {
		if (isReadOnly) {
			return true
		}
		
		if (
			Inscription._isNew
			|| (ProjetProfessionnelSelected && ProjetProfessionnelSelected.readOnly)
			|| (ProjetProfessionnelSelected && ProjetProfessionnelSelected.statut === INSCRIPTION_PROJET_STATUT_ACCEPTE)
		) {
			return true
		}
		
		return false
		
	})()
	const FORM_PROPS = {
		disabled: disabled,
		form: form,
		onFieldsChange: onFieldsChange,
		className: "shadow-2 padding",
		layout: "vertical"
		
	}
	
	/**
	 *
	 */
	return <SpaceVertical>
		{/*  */}
		<ProjetProfessionneSelectAndCreateView/>
		{/*  */}
		{ProjetProfessionnelSelected && <>
			<SpaceVertical>
				{/*  */}
				{
					isValidationProjetProfessionnelFormVisible
					&& <ProjetProfessionnelValidation/>
				}
				
				{/* */}
				<Form {...FORM_PROPS}>
					<SectionTitle title={"Voeux défini lors de l'entretien"}/>
					<Alert showIcon message={"Par défaut, celle choisie dans la formation souhaitée."}/>
					<br/>
					{/*  */}
					{
						ProjetProfessionnelSelected.readOnly &&
						<Form.Item name={"FormationChoisieApresEntretien"} label={"Formation"}>
							<SelectFormation/>
						</Form.Item>
					}
					{/*  */}
					{
						!ProjetProfessionnelSelected.readOnly &&
						<Form.Item name={"FormationChoisieApresEntretien"} label={"Formation"}>
							<SelectFormation/>
						</Form.Item>
					}
					{/*  */}
					<Form.Item name={"langueVivantes"} label={'Langues  vivantes'}>
						<LangueVivanteSelect/>
					</Form.Item>
					{ButtonSave}
				</Form>
				{/*  */}
				<Form {...FORM_PROPS}>
					<SectionTitle title={"Projet de formation"}/>
					{/* projetObjectif */}
					<Form.Item name={'objectif'} label={'Quel est votre projet professionnel ?'}>
						<Select options={Object.values(PROJET_OBJECTIFS)}></Select>
					</Form.Item>
					{/*  */}
					{
						(
							ProjetProfessionnelSelected.objectif === OBJECTIF_TROUVER_UN_EMPLOI_DE_SALARIE
							|| ProjetProfessionnelSelected.objectif === OBJECTIF_TRAVAILLER_A_MON_COMPTE
						) && <Form.Item name={"domaineActivite"} label={'Précisez le domaine, l’activité...'}>
							<Input.TextArea autoSize/>
						</Form.Item>
					}
					{/*  */}
					<Form.Item name={"details"} label={'Pouvez-vous préciser en quelques lignes votre projet ?'}>
						<Input.TextArea autoSize/>
					</Form.Item>
					{/*  */}
					<Form.Item name={"capaciteProfessionnelleAgricole"} label={'Avez-vous besoin de la Capacité Professionnelle Agricole (CPA) ?'}>
						<SelectYesNo/>
					</Form.Item>
					{/*  */}
					<SectionTitle title={"Prérequis"}/>
					{/*  */}
					<Form.Item name={"connaissanceMilieuProfessionnel"} label={'Quelle est votre expérience et/ou votre connaissance du milieu professionnel en lien avec la formation visée ?'}>
						<Input.TextArea autoSize/>
					</Form.Item>
					{/*  */}
					<Form.Item name={"niveauDisciplinesPrincipales"} label={'Quel est votre niveau dans les disciplines principales ?'}>
						<Input.TextArea autoSize/>
					</Form.Item>
					{/*  */}
					<Form.Item name={"langueVivanteParcoursAnterieur"} label={'Quel est votre parcours antérieur en langue vivante  ?'}>
						<Select>
							{
								Object.values(LANGUES_VIVANTES_PARCOURS_ANTERIEUR_VALUES).map(item => <Select.Option key={key()} value={item.value}>
									{item.label}
								</Select.Option>)
							}
						
						</Select>
					</Form.Item>
					{/*  */}
					<Form.Item name={"langueVivanteParcoursAnterieurAutre"} label={'si autre'}>
						<Input.TextArea autoSize/>
					</Form.Item>
					{/*  */}
					<Form.Item name={"langueVivanteExperienceProfessionnelle"} label={'Quelle est votre expérience personnelle en langue vivante   ?'}>
						<Select mode={"multiple"}>
							{
								Object.values(LANGUES_VIVANTES_EXPERIENCE_VALUES).map(item => <Select.Option key={key()} value={item.value}>
									{item.label}
								</Select.Option>)
							}
						
						</Select>
					</Form.Item>
					{/*  */}
					<Form.Item name={"langueVivanteExperienceProfessionnelleAutre"} label={'si autre'}>
						<Input.TextArea autoSize/>
					</Form.Item>
					{/*  */}
					<SectionTitle title={"Positionnement vis-à-vis de la formation à distance"}/>
					{/*  */}
					<Form.Item name={"foadSuivieCapacite"} label={'Quelles sont les raisons qui vous font choisir une formation à distance ?'}>
						<Input.TextArea autoSize/>
					</Form.Item>
					{/*  */}
					<Form.Item name={"foadMotivationsEtAptitudes"} label={'Selon vous, quelles sont vos facilités et/ou difficultés à suivre une formation à distance ?'}>
						<Input.TextArea autoSize/>
					</Form.Item>
					{/*  */}
					<Form.Item name={"foadDejaSuivie"} label={'Avez-vous déjà suivi une formation à distance ?'}>
						<SelectYesNo/>
					</Form.Item>
					{/*  */}
					<Form.Item name={"foadDejaSuivieDetails"} label={'Si oui, laquelle ? '}>
						<Input.TextArea autoSize/>
					</Form.Item>
					{/*  */}
					<Form.Item name={"possibiliteAideProximites"} label={'Quelles sont vos possibilités d’aide de proximité ?'}>
						<Select mode={"multiple"}>
							{
								Object.values(POSSIBILITES_AIDE_VALUES).map(item => <Select.Option key={key()} value={item.value}>
									{item.label}
								</Select.Option>)
							}
						
						</Select>
					</Form.Item>
					<Form.Item name={"possibiliteAideProximitesAutre"} label={'Si autre'}>
						<Input.TextArea autoSize/>
					</Form.Item>
					{/*  */}
					{/* Organisation */}
					<SectionTitle title={"Organisation"}/>
					<Alert showIcon
					       message={"Afin de travailler dans les meilleures conditions, vous devrez avoir une bonne connexion internet, un ordinateur, un endroit calme. Pour les personnes qui souhaitent suivre une formation en langue un micro-casque est préconisé pour la pratique de l’oral."}/>
					<br/>
					{/*  */}
					<Form.Item name={"disponibiliteEtContraintes"} label={"Quels sont vos disponibilités et contraintes (pour l'étude des cours, le rendu des travaux demandés etc) ?"}>
						<Input.TextArea autoSize/>
					</Form.Item>
					{/*  */}
					<Form.Item name={"projetTempsEstime"} label={"Quel temps souhaitez-vous consacrer à votre formation (nombre d'heures par semaine) ?"}>
						<Input.TextArea autoSize/>
					</Form.Item>
					
					{/*  */}
					<Form.Item name={"environnementInformatique"} label={'De quel équipement informatique disposez-vous ?'}>
						<Select
							mode={"multiple"}
						>
							{
								[
									"Ordinateur", "Téléphone", "Tablette", "Connexion haut début", "Espace de travail au calme", "Bureau", "Autre situation",
								
								].map(item => <Select.Option key={key()} value={item}>
									{item}
								</Select.Option>)}
						
						</Select>
					</Form.Item>
					<SectionTitle title={"Après avoir lu attentivement la (ou les) fiche(s) formation, avez-vous des questions sur les points suivants qui seront abordés au cours de l’entretien ?"}/>
					<Form.Item name={"roleReferentEnLangueVivanteEnEtablissement"} label={'- Le rôle du référent en langue vivante en établissement'}>
						<SelectYesNoPasConcerne/>
					</Form.Item>
					<Form.Item name={"modaliteInscriptionEnLangueVivanteEnEtablissement"} label={'- Les modalités d’inscription en langue vivante en établissement '}>
						<SelectYesNoPasConcerne/>
					</Form.Item>
					{/*  */}
					<Form.Item name={"informationRecueSurLesModalitesExamen"} label={'- Les modalités d\'examen'}>
						<SelectYesNoPasConcerne/>
					</Form.Item>
					{
						ProjetProfessionnelSelected.informationRecueSurLesModalitesExamen !== NON_CONCERNE && <>
							{/*  */}
							<Form.Item name={"informationRecueSurLesEpreuves"} label={'- Les épreuves'}>
								<SelectYesNo/>
							</Form.Item>
							{/*  */}
							<Form.Item name={"informationRecueSurLesPeriodesEtLieuxPassageExamen"} label={'- Les périodes et lieux de passage de l’examen '}>
								<SelectYesNo/>
							</Form.Item>
							{/*  */}
							<Form.Item name={"informationRecueSurLesDispenses"} label={'- Les dispenses'}>
								<SelectYesNoPasConcerne/>
							</Form.Item>
							{/*  */}
							<Form.Item name={"informationRecueSurLesStages"} label={'- Les stages (où, quand, objectifs de stage, déroulement etc) '}>
								<SelectYesNoPasConcerne/>
							</Form.Item>
							{/*  */}
							<Form.Item name={"informationRecueSurLesSessionsDeRegroupement"} label={'- Les sessions de regroupement (quand, où, contenus, etc.) '}>
								<SelectYesNoPasConcerne/>
							</Form.Item>
							{/*  */}
							<Form.Item name={"informationRecueSurLesClassesVirtuelles"} label={'- Les classes virtuelles '}>
								<SelectYesNoPasConcerne/>
							</Form.Item>
							{/*  */}
							<Form.Item name={"informationRecueSurLaccompagnement"} label={"- Les tarifs"}>
								<SelectYesNo/>
							</Form.Item>
						</>
					}
					
					{/*  */}
					<Form.Item name={"informationRecueSurLesTarifs"} label={"- L'accompagnement "}>
						<SelectYesNo/>
					</Form.Item>
					
					{/*  */}
					<Form.Item name={"informationRecueCommentaireParticulier"} label={'Commentaire particulier '}>
						<Input.TextArea autoSize/>
					</Form.Item>
					{ButtonSave}
					{/*  */}
				</Form>
			
			
			</SpaceVertical>
		</>
		}
	</SpaceVertical>
	
}))

