/**
 *
 */
import {Switch} from "antd";
import React from "react";

/**
 *
 */
export const FavoriteSwitch = (props =>
		<Switch
			{...props}
			style={{width: 90}}
			checkedChildren="Favoris"
			unCheckedChildren="Favoris"
		/>
)
