import {InfoCircleOutlined} from '@ant-design/icons';
import alasql from "alasql";
import {Badge, Space, Tag, Tooltip} from "antd";
import days from "dayjs";
import {values} from "mobx";
import {applySnapshot, getParent, types} from "mobx-state-tree";
import React from "react";
import {Icon} from "../components/Icon";
import {YesNoTag} from "../components/YesNoTag";
/**
 *
 */
import {
	AGE_LEGAL,
	APPRENANT_STATUT_INSCRIT,
	APPRENANT_STATUT_PREINSCRIT,
	APPRENANT_STATUT_PROSPECT,
	APPRENANT_STATUTS,
	DIPLOMATION_RESULTAT_VALUES,
	FILTER_BY_FAVORITES,
	FILTER_BY_FORMATION_SUIVIE,
	FILTER_BY_STATUT,
	FILTER_BY_VALIDATION_STATUT,
	FILTER_BY_WORDS,
	INSCRIPTION_PROJET_STATUT_ACCEPTE,
	INSCRIPTION_PROJET_STATUTS,
	INSCRIPTION_STATUT_VALIDATION_EN_ATTENTE,
	INSCRIPTION_STATUTS,
	ORDER_DESCEND,
	PIECE_JOINTE_CATEGORIE_AGE_1,
	PIECE_JOINTE_CATEGORIE_AGE_2,
	PIECE_JOINTE_CATEGORIE_AGE_3,
	PIECE_JOINTE_CATEGORIE_AGES,
	REINSCRIPTION_MOTIFS,
	VALIDATION_ADMINISTRATIVE_STATUTS,
} from "../config/Constantes.logic";
import {MICRO_SERVICE_URLS} from "../config/Constantes.urls";
import MicroService from "../helpers/MicroService";
import {newId, normalize, toLocalDateTime} from "../helpers/Utils";
import {CopyReference} from "../pages/common/components/CopyReference";
import {ApprenantModel} from "./ApprenantStore";
import {FormationModel} from "./FormationStore";

/**
 *
 */
const CURRENT_MICRO_SERVICE_URL = MICRO_SERVICE_URLS.INSCRIPTION
/**
 * model
 */
export const InscriptionModel = types
	.model({
		_id: types.identifier,
		anneeCreation: types.maybeNull(types.integer),
		OldInscription: types.frozen({
			_id: "",
			numero: 0,
			anneeCreation: 0
		}),
		duplique: types.optional(types.boolean, false),
		reInscriptionType: types.maybeNull(types.string, ""),
		numero: types.optional(types.number, 0),
		Apprenant: types.maybeNull(types.reference(ApprenantModel)),
		//
		apprenantAgeCourant: types.maybeNull(types.number, 0),
		//
		statutApprenant: types.maybeNull(types.string, ""),
		//
		statutInscription: types.maybeNull(types.string, INSCRIPTION_STATUT_VALIDATION_EN_ATTENTE),
		profil: types.maybeNull(types.string, ""),
		//
		Abandon: types.frozen({
			Agent: {
				_id: "",
				fullname: ""
			},
			confirme: null,
			date: "",
			createdAt: ""
		}),
		//
		Paiement: types.frozen({
			Agent: {},
			statut: null,
			modalitePaiement: "",
			nombreEcheance: 0,
			formationCout: 0,
			surtaxeAerienne: 0,
			montantRestant: 0,
			suiviPoleEmploi: "",
			pix: 0,
			date: "",
			commentaire: "",
		}),
		
		//
		ValidationAdministrative: types.frozen({
			boursier: null,
			statut: null,
			Agent: {},
			date: "",
			commentaire: "",
		}),
		//
		
		//
		Diplomation: types.frozen({
			resultat: "",
			diplomeEnvoiDate: "",
			choixReinscription: "",
			radiationExamen: null,
			//formationTerminee : null
		}),
		
		//
		formationType: types.maybeNull(types.string, ""),
		//
		FormationSouhaiteePremiere: types.maybeNull(types.reference(FormationModel)),
		FormationSouhaiteeSeconde: types.maybeNull(types.reference(FormationModel)),
		//
		Parcours: types.frozen({
			dateArretEtudes: "",
			titreAccesFormation: "",
			titreAccesFormationAnneeObtention: "",
			//titreAccesFormationResultatAdmission : null,
			//
			titrePlusEleveFormation: "",
			titrePlusEleveFormationAnneeObtention: "",
			//titrePlusEleveFormationResultatAdmission : null,
			//
			FormationDispense: "",
			FormationDerogation: "",
			
		}),
		//
		FormationSouhaitee: types.frozen({
			FormationChoisieAvantEntretien: "",
			parcourSupValidee: null,
			parcourSupNumero: "",
			statutPourExamen: "",
			modalites: "",
			profil: "",
			souhaitDemandeBourse: null,
			souhaitFinancementFormation: null,
		}),
		//
		FormationSouhaiteeLangueEtablissement: types.frozen({
			periodicite: "",
			Etablissement: {
				nom: "",
				adressePostale: "",
				telephone: "",
			},
			Referent: {
				civilite: "",
				nom: "",
				prenom: "",
				fonction: "",
				telephone: "",
				email: "",
			}
		}),
		//
		FormationSuivie: types.frozen({
			Formation: "",
			statutPourExamen: "",
			modalites: "",
		}),
		//
		FormationContinueFinancee: types.frozen({
			FinancementSource: "",
			financementAccorde: null,
			financementAccordeDate: "",
			
		}),
		//
		Maquette: types.frozen({
			Composants: [],
			date: null,
			Agent: {}
		}),
		CoursMoodles: types.frozen({
			Courss: [],
			date: null,
			Agent: {}
		}),
		//
		Createur: types.frozen({_id: "", fullname: ""}),
		Modificateur: types.frozen({_id: "", fullname: ""}),
		createdAt: types.maybeNull(types.string, ""),
		updatedAt: types.maybeNull(types.string, ""),
		_isOnEdition: types.optional(types.boolean, false),
		_isNew: types.optional(types.boolean, false),
		_index: types.optional(types.number, 0),
	})
	.views((self) => ({
		/**
		 *
		 */
		get isDuplicable() {
			return self.statutApprenant === APPRENANT_STATUT_INSCRIT
		},
		/**
		 *
		 */
		get apprenantFullname() {
			return self.Apprenant.nomUsage + " " + self.Apprenant.prenom
		},
		get categorieAge() {
			const age = self.apprenantAgeCourant
			if (age < 18) {
				return PIECE_JOINTE_CATEGORIE_AGE_1
			}
			if (age > 18 && age < 25) {
				return PIECE_JOINTE_CATEGORIE_AGE_2
			}
			if (age > 25) {
				return PIECE_JOINTE_CATEGORIE_AGE_3
			}
			return ""
			
		},
		get _etatDossier() {
			const Validation = self.ValidationAdministrative
			let content = ""
			let title = "le " + toLocalDateTime(Validation.date) + " par " + (Validation.Agent && Validation.Agent.fullname)
			if (Validation.statut) {
				const Statut = VALIDATION_ADMINISTRATIVE_STATUTS[Validation.statut]
				content =
					Statut && <Tooltip title={title}>
						<Tag color={Statut.color}>
							<Icon name={"UserOptional"}/>&nbsp;&nbsp;{Statut.label}
						</Tag>
					</Tooltip>
			}
			return content
			
		},
		/**
		 *
		 */
		get reInscription() {
			return self.OldInscription && self.OldInscription._id && true
		},
		/**
		 *
		 */
		get _createdAt() {
			return days(self.createdAt).format('ddd DD/MM/YYYY à HH:mm:ss')
		},
		/**
		 *
		 */
		get _updatedAt() {
			return days(self.updatedAt).format('ddd DD/MM/YYYY à HH:mm:ss')
		},
		/**
		 *
		 */
		get ProjetProfessionnels() {
			const InscriptionProjetProfessionnelStore = getParent(self, 3).InscriptionProjetProfessionnelStore
			const ProjetProfessionnels = values(InscriptionProjetProfessionnelStore.InscriptionProjetProfessionnels)
			const ProjetProfessionnelFounds = ProjetProfessionnels.filter(item => item.Inscription._id === self._id)
			return ProjetProfessionnelFounds
		},
		/**
		 *
		 */
		get ProjetProfessionnelChoisi() {
			return self.ProjetProfessionnels.find(ProjetProfessionnel => ProjetProfessionnel.statut === INSCRIPTION_PROJET_STATUT_ACCEPTE)
			
		},
		/**
		 *
		 */
		get _projetProfessionnelLastStatutTag() {
			let ProjetProfessionnels = self.ProjetProfessionnels
			//
			let allValidationsStatuts = []
			//on concatène toutes les validations de tous les projets
			for (let i in ProjetProfessionnels) {
				allValidationsStatuts = allValidationsStatuts.concat(ProjetProfessionnels[i].ValidationStatuts)
			}
			allValidationsStatuts = allValidationsStatuts.sort((a, b) => {
				if (a.date < b.date) {
					return 1;
				}
				return -1;
			});
			//
			
			let tag = <Tag color={"red"}>Aucune validation de projet en cours</Tag>
			
			if (allValidationsStatuts.length > 0) {
				const ValidationsStatutLast = allValidationsStatuts[0]
				if (ValidationsStatutLast.statut) {
					const title = "Par " + ValidationsStatutLast.Responsable.fullname + " le " + toLocalDateTime(ValidationsStatutLast.date)
					const Statut = INSCRIPTION_PROJET_STATUTS[ValidationsStatutLast.statut]
					tag = <Tooltip title={title}>
						<Tag color={Statut.color} icon={<InfoCircleOutlined/>}>
							{Statut.label}
						</Tag>
					</Tooltip>
				}
			}
			return !self._isNew && tag
		},
		/**
		 *
		
		get projetProfessionnelChoisiLastValidation() {
			const _ProjetProfessionnelChoisi = self.ProjetProfessionnelChoisi.toJSON()
			const Validations = _ProjetProfessionnelChoisi.ValidationStatuts
			//on doit prendre le dernier
			let LastOne = null
			if (Validations.length > 0) {
				//console.log(Validations.reverse())
				//LastOne=Validations.reverse()[0]
			}
			return LastOne
		},
		 */
		/**
		 * Si on 2 formations non diplômante => l'onglet "Parcours" ne doit pas être actif
		 * Si on a au moins une formation Diplômante parmi les 2 vœux, l’onglet "Parcours" doit être actif
		 * Si à l’entretien ou Formation Souhaitée, la formation choisie est finalement diplômante => l’onglet "Parcours" devient actif
		 * Si à l’entretien ou Formation Souhaitée, la formation choisie est finalement  diplômante => l’onglet "Parcours" devient actif
		 * @returns {*|boolean}
		 */
		get isParcoursActif() {
			//
			let actif = (
				(self.FormationSouhaiteePremiere && self.FormationSouhaiteePremiere.diplomante)
				|| (self.FormationSouhaiteeSeconde && self.FormationSouhaiteeSeconde.diplomante)
			)
			//
			if (self.FormationSouhaitee && self.FormationSouhaitee.FormationChoisieAvantEntretien) {
				const FormationChoisieAvantEntretienId = self.FormationSouhaitee.FormationChoisieAvantEntretien
				const FormationChoisieAvantEntretien = self.getFormationById(FormationChoisieAvantEntretienId)
				actif = FormationChoisieAvantEntretien.diplomante
			}
			//
			if (self.FormationChoisieApresEntretien) {
				actif = self.FormationChoisieApresEntretien.diplomante
			}
			//
			return actif
			
		},
		getFormationById(_id) {
			const Formations = getParent(self, 3).FormationStore.getAll()
			return Formations.find(Formation => Formation._id === _id)
		},
		/**
		 *
		 */
		get Formation() {
			return self.FormationSuivie._id
		},
		get isLangueEtablissement() {
			//
			const Formation = self.FormationSouhaiteePremiere
			let actif = false
			
			if (Formation) {
				const str = normalize(Formation.famille.toLowerCase())
				actif = (str.includes("langue") && str.includes("etablissement"))
			}
			
			return actif
			
		},
		/**
		 *
		 */
		get _FormationSuivie() {
			const Formations = getParent(self, 3).FormationStore.getAll()
			const FormationSuivie = Formations.find(Formation => Formation._id === self.FormationSuivie.Formation)
			return FormationSuivie
		},
		
		/**
		 *
		 */
		get _FormationSuivieAnneeExamen() {
			return self._FormationSuivie && self._FormationSuivie.anneeExamen
		},
		get _FormationSuivieAnneeExamenTag() {
			const anneeExamen = self._FormationSuivieAnneeExamen
			if (!anneeExamen) {
				return "-"
			}
			return <Tag>{anneeExamen}</Tag>
		},
		get _FormationSuivielibelleCourt() {
			return self._FormationSuivie && self._FormationSuivie.libelleCourt
		},
		/**
		 *
		 */
		get _FormationSuivieLibelleTag() {
			if (self._FormationSuivie) {
				return <Space>
					<CopyReference Record={self._FormationSuivie}/>
					<Tag>{self._FormationSuivie.libelleCourt} <b> {self._FormationSuivie.anneeExamen}</b></Tag>
				</Space>
				
			} else {
				return <Tag color={"red"}>Aucune formation choisie</Tag>
			}
		},
		/**
		 *
		 */
		get categorieAgeTag() {
			return self.categorieAge && PIECE_JOINTE_CATEGORIE_AGES[self.categorieAge] && PIECE_JOINTE_CATEGORIE_AGES[self.categorieAge].tag
		},
		get diplomationResultatTag() {
			const resultat = self.Diplomation && self.Diplomation.resultat
			return resultat && <Tag>{DIPLOMATION_RESULTAT_VALUES[resultat].label}</Tag>
		},
		get isDiplomante() {
			return self._FormationSuivie && self._FormationSuivie.diplomante
		},
		
		/**
		 *
		 */
		get _formationContinueFinancee() {
			if (self.FormationContinueFinancee) {
				const ReferentielSourceFinancements = getParent(self, 3).ReferentielSourceFinancementStore.getAll()
				const SourceFinancement = ReferentielSourceFinancements
					.find(item => item._id === self.FormationContinueFinancee.FinancementSource)
				//
				if (SourceFinancement) {
					const financementAccorde = self.FormationContinueFinancee.financementAccorde
					let title = "Refusé"
					if (financementAccorde) {
						title = "Accordé"
					}
					title += " (" + SourceFinancement.libelle + ")"
					return SourceFinancement && <Tooltip title={title}>
						<YesNoTag icon={<InfoCircleOutlined/>} value={self.FormationContinueFinancee.financementAccorde}/>
					</Tooltip>
				}
				
			}
		},
		/**
		 *
		 */
		get reInscriptionTag() {
			return self.OldInscription && self.OldInscription._id && <YesNoTag value={true}/>
		},
		/**
		 *
		 */
		get _reInscriptionTypeTag() {
			return self.reInscriptionType && self.OldInscription._id && <Tag>{REINSCRIPTION_MOTIFS[self.reInscriptionType].label}</Tag>
		},
		/**
		 *
		 */
		get apprenantAgeCourantTag() {
			const color = (parseInt(self.apprenantAgeCourant) < AGE_LEGAL ? "pink" : "")
			return <Tag color={color}>{self.apprenantAgeCourant && self.apprenantAgeCourant + " ans" || "?"} </Tag>
		},
		/**
		 *
		 */
		get anneeCreationTag() {
			return <Tag>Année création <b>{self.anneeCreation}</b></Tag>
		},
		
		/**
		 *
		 */
		get numeroTag() {
			if (self.OldInscription && self.OldInscription._id) {
				const title = "Dupliquée à partir de l'inscription N°" + self.OldInscription.numero + " de l'année " + self.OldInscription.anneeCreation
				return <Badge dot><Tooltip title={title} placement={"right"}><Tag>N°{self.numero}</Tag></Tooltip></Badge>
			}
			return <Tag>N°{self.numero}</Tag>
		},
		/**
		 *
		 */
		
		/**
		 *
		 */
		get statutApprenantTag() {
			return self.statutApprenant && APPRENANT_STATUTS[self.statutApprenant].tag
		},
		/**
		 *
		 */
		get statutInscriptionTag() {
			return self.statutInscription && INSCRIPTION_STATUTS[self.statutInscription].tag
		},
		/**
		 *
		 */
		get PieceJointeEnvoyees() {
			const InscriptionPieceJointeStore = getParent(self, 3).InscriptionPieceJointeStore
			const InscriptionPieceJointes = InscriptionPieceJointeStore.getAllByInscription(self._id)
			
			return InscriptionPieceJointes || []
		},
		/**
		 *
		 */
		get PieceJointesToDisplay() {
			// on cherche la formation coisie lors du dernier entretien
			const _FormationSuivie = self._FormationSuivie
			if (!_FormationSuivie) {
				return false
			}
			let InscriptionPieceJointesToDisplay = []
			//on récupère les pièces jointes du référentiel associées à  la formation choisie
			const ReferentielPieceJointes = _FormationSuivie.ReferentielPieceJointes
			/**
			 * on récupère celle qui sont obligatoires et de la catégorie d'âge
			 */
			
			const ReferentielPieceJointeObligatoires = ReferentielPieceJointes.filter(ReferentielPieceJointe => {
				const obligatoire = ReferentielPieceJointe.obligatoire
				const categorieAge = ReferentielPieceJointe.categorieAge
				if (categorieAge) {
					return obligatoire === true && categorieAge === self.categorieAge
				}
				return obligatoire === true
			})
			InscriptionPieceJointesToDisplay = InscriptionPieceJointesToDisplay.concat(ReferentielPieceJointeObligatoires)
			/**
			 * on récupère celle qui sont en fonction de la catégorie d'âge
			 */
			const ReferentielPieceJointeNonObligatoires = ReferentielPieceJointes.filter(ReferentielPieceJointe => {
				const obligatoire = ReferentielPieceJointe.obligatoire
				const categorieAge = ReferentielPieceJointe.categorieAge
				if (categorieAge) {
					return !obligatoire && categorieAge === self.categorieAge
				}
				return !obligatoire
			})
			
			InscriptionPieceJointesToDisplay = InscriptionPieceJointesToDisplay.concat(ReferentielPieceJointeNonObligatoires)
			
			/**
			 *
			 
			console.log("ReferentielPieceJointeNonObligatoires", ReferentielPieceJointeNonObligatoires.length)
			console.log("ReferentielPieceJointeObligatoires", ReferentielPieceJointeObligatoires.length)
			console.log("InscriptionPieceJointesToDisplay.length", InscriptionPieceJointesToDisplay.length)
			 */
			return InscriptionPieceJointesToDisplay
			//
		},
		/**
		 *
		 */
		get isReadyForValidationAdministrative() {
			const PieceJointesToDisplay = self.PieceJointesToDisplay || []
			const PieceJointeObligatoires = PieceJointesToDisplay.filter(PieceJointe => PieceJointe.obligatoire === true)
			//
			const PieceJointeEnvoyees = self.PieceJointeEnvoyees
			const PieceJointeEnvoyeeObligatoires = PieceJointeEnvoyees.filter(PieceJointeEnvoyee => PieceJointeEnvoyee.ReferentielPieceJointe && PieceJointeEnvoyee.ReferentielPieceJointe.obligatoire === true)
			//
			/*
			console.log("PieceJointeEnvoyees", PieceJointeEnvoyees.length)
			console.log("PieceJointeObligatoires", PieceJointeObligatoires.length)
			console.log("PieceJointeEnvoyeeObligatoires", PieceJointeEnvoyeeObligatoires.length)
			 */
			//
			//si une pj a été désactivée entre temps , il se pourrait que le nombre de pj envoyées obligatoires soit superieur
			return PieceJointeEnvoyeeObligatoires.length === PieceJointeObligatoires.length
		},
		get mustSendMail() {
			const PieceJointesToDisplay = self.PieceJointesToDisplay || []
			const PieceJointeObligatoires = PieceJointesToDisplay.filter(PieceJointe => PieceJointe.obligatoire === true)
			const PieceJointeEnvoyees = self.PieceJointeEnvoyees
			const PieceJointeEnvoyeeObligatoires = PieceJointeEnvoyees.filter(PieceJointeEnvoyee => PieceJointeEnvoyee.ReferentielPieceJointe && PieceJointeEnvoyee.ReferentielPieceJointe.obligatoire === true)
			//&& PieceJointeEnvoyeeObligatoires.length<=PieceJointeEnvoyees.length
			/*
			console.log("PieceJointeEnvoyees", PieceJointeEnvoyees.length)
			console.log("PieceJointeObligatoires", PieceJointeObligatoires.length)
			console.log("PieceJointeEnvoyeeObligatoires", PieceJointeEnvoyeeObligatoires.length)
			
			 */
			//
			return PieceJointeEnvoyeeObligatoires.length === PieceJointeObligatoires.length
		},
		/**
		 *
		 */
		get PieceJointeSpecifiques() {
			const InscriptionPieceJointeSpecifiqueStore = getParent(self, 3).InscriptionPieceJointeSpecifiqueStore
			const InscriptionPieceJointeSpecifiques = InscriptionPieceJointeSpecifiqueStore.getAllByInscription(self._id)
			return InscriptionPieceJointeSpecifiques
		},
		/**
		 *
		 */
		
	}))
	.actions((self) => ({
		
		/**
		 *
		 */
		update(EditedRecord) {
			applySnapshot(self, Object.assign(self, EditedRecord));
			getParent(self, 3).notifyUIChanges()
		},
		/**
		 *
		 * @param callback
		 */
		save(callback) {
			//
			// on ajoute l'age courant de l'apprenant
			self.apprenantAgeCourant = parseInt(self.Apprenant.age)
			//
			let url = CURRENT_MICRO_SERVICE_URL
			let service = MicroService.create
			let data = {Record: self}
			//
			if (self._isNew === false) {
				url = url + "/" + self._id
				service = MicroService.update
			}
			
			//
			service(url, data, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					let Record = Response.Record
					getParent(self, 2).addToStore(Record)
					
				}
				callback(succeeded)
			})
			
		},
		/**
		 *
		 */
		destroy(callback) {
			
			MicroService.delete(CURRENT_MICRO_SERVICE_URL + "/" + self._id, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					//on supprime ses InscriptionPieceJointes du store local
					const InscriptionPieceJointeStore = getParent(self, 3).InscriptionPieceJointeStore
					InscriptionPieceJointeStore.InscriptionPieceJointes.forEach(InscriptionPieceJointe => {
						InscriptionPieceJointeStore.removeFromStore(InscriptionPieceJointe._id)
					})
					//on supprime ses projets du store local
					const InscriptionProjetProfessionnelStore = getParent(self, 3).InscriptionProjetProfessionnelStore
					InscriptionProjetProfessionnelStore.InscriptionProjetProfessionnels.forEach(InscriptionProjetProfessionnel => {
						InscriptionProjetProfessionnelStore.removeFromStore(InscriptionProjetProfessionnel._id)
					})
					
					getParent(self, 2).removeFromStore(self._id)
					
				}
				callback(succeeded)
				
			})
			
		},
		/**
		 * réinscription
		 */
		duplicate({nextAnneeCreation}, callback) {
			
			//on récupère l'id de l'ancien record de la forme YYYY-[az]
			const _id = self._id.split("-").reverse()[0]
			const _newId = nextAnneeCreation + "-" + _id
			
			//
			const InscriptionToDuplicate = {
				_id: _newId,
				duplique: false,
				OldInscription: {
					_id: self._id,
					numero: self.numero,
					anneeCreation: self.anneeCreation,
				},
				anneeCreation: nextAnneeCreation,
				statutApprenant: APPRENANT_STATUT_PREINSCRIT,
				statutInscription: INSCRIPTION_STATUT_VALIDATION_EN_ATTENTE,
				FormationSouhaiteePremiere: null,
				FormationSouhaiteeSeconde: null,
				Paiement: {
					statut: null,
					Agent: {},
					montantRestant: 0,
				},
				ValidationAdministrative: types.frozen({
					statut: null,
					Agent: {},
					date: "",
				}),
			}
			if (self.FormationSouhaitee) {
				let FormationSouhaitee = {...self.FormationSouhaitee}
				FormationSouhaitee.FormationChoisieAvantEntretien = null
				InscriptionToDuplicate.FormationSouhaitee = FormationSouhaitee;
			}
			//
			const params = {
				InscriptionToDuplicate: InscriptionToDuplicate
				
			}
			MicroService.post(MICRO_SERVICE_URLS.INSCRIPTION_DUPLICATE, {params: params}, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					self.update({duplique: true})
					self.save(() => {
					});
				}
				callback(succeeded)
				
			})
			
			//pour éviter d'utiliser des setter
			//let Model = {...self.initModel(ApprenantId)}
			//
			/*
			Model.OldInscription = Inscription._id
			Model.FormationSouhaiteePremiere = Inscription.FormationSouhaiteePremiere
			Model.FormationSouhaiteeSeconde = Inscription.FormationSouhaiteeSeconde
			//
			const InscriptionDuplicated = InscriptionModel.create(Model)
			
			self.Inscriptions.set(InscriptionDuplicated._id, InscriptionDuplicated)
			self.setSelectedRecord(InscriptionDuplicated)
			console.log(InscriptionDuplicated)
			return InscriptionDuplicated
			
			 */
		},
		
	}));
/**
 * store
 */
export const InscriptionStore = types
	.model({
		Inscriptions: types.map(InscriptionModel),
		SelectedRecord: types.maybeNull(types.reference(InscriptionModel, {})),
		//
		tabActiveKey: types.optional(types.string, ""),
		
	})
	
	.actions((self) => ({
		/**
		 *
		 *permet d'activer le premier onglet par défaut
		 */
		setTabActiveKey(key) {
			self.tabActiveKey = key
		},
		/**
		 *
		 *
		 
		initModel(ApprenantId) {
			const _id = newId()
			//on récupèrela partie de l'id qui est de la forme YYYY-[az] pour créer un numéro sinon il sera trop grand
			const numero = generateUniqueNumberFromString(_id.split("-").reverse()[0])
			let Model = {
				_id : _id,
				_isNew : true,
				numero : numero,
				Apprenant : ApprenantId,
				anneeCreation : parseInt(sessionStorage.anneeCreationSelected || new Date().getFullYear()),
				statutApprenant : APPRENANT_STATUT_PROSPECT,
				statutInscription : INSCRIPTION_STATUT_VALIDATION_EN_ATTENTE,
				createdAt : new Date().toISOString()
				
			}
			return Model
		},*/
		create(Apprenant) {
			const _id = newId()
			//on récupèrela partie de l'id qui est de la forme YYYY-[az] pour créer un numéro sinon il sera trop grand
			//const numero = generateUniqueNumberFromString(_id.split("-").reverse()[0])
			let Model = {
				_id: _id,
				_isNew: true,
				Apprenant: Apprenant._id,
				anneeCreation: parseInt(sessionStorage.anneeCreationSelected || new Date().getFullYear()),
				statutApprenant: APPRENANT_STATUT_PROSPECT,
				statutInscription: INSCRIPTION_STATUT_VALIDATION_EN_ATTENTE,
				createdAt: new Date().toISOString()
				
			}
			const Record = InscriptionModel.create(Model)
			self.addToStore(Record)
			return Record
		},
		
		/**
		 *
		 *
		 */
		setSelectedRecord(SelectedRecord) {
			self.SelectedRecord = SelectedRecord
			//
			getParent(self, 1).InscriptionProjetProfessionnelStore.setSelectedRecord(null)
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 * @param Record
		 */
		addToStore(Record) {
			self.Inscriptions.set(Record._id, Record)
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 * @param _id
		 */
		removeFromStore(_id) {
			self.Inscriptions.delete(_id)
			self.SelectedRecord = null
			getParent(self, 1).notifyUIChanges()
		},
	}))
	
	.views((self) => ({
		
		getAll() {
			return values(self.Inscriptions)
			
		},
		
		getAllInscrits() {
			return values(self.Inscriptions).filter(Inscription => Inscription.statutApprenant === APPRENANT_STATUT_INSCRIT)
		},
		/**
		 *
		 * @param Filters
		 * @param Sorter
		 * @returns {*}
		 */
		getAllByFilters(Filters, Sorter) {
			let Records = values(self.Inscriptions);
			//
			const FavoriteStore = getParent(self, 1).FavoriteStore;
			const favoriteIds = FavoriteStore.getAll().map(Favorite => Favorite.belongTo);
			/**
			 *
			 */
			/**
			 *
			 */
			const wheres = [];
			wheres.push('_isNew=false');
			//wheres.push("YEAR(createdAt)="+localStorage.selectedAnneeCreation+"")
			
			for (let key in Filters) {
				const Filter = Filters[key];
				let value = Filter.value;
				if (value === null) {
					continue;
				}
				
				switch (Filter.name) {
					
					default:
						break
					case FILTER_BY_WORDS:
						value = value.replace("'", " ").replace('"', " ")
						//value = value.replace(/[^a-zA-Z0-9À-ÖØ-öø-ÿ\s]+/g, "")
						const fields = [
							'_id',
							'normalize(Apprenant->nomUsage)',
							'normalize(Apprenant->prenom)',
							'Apprenant->numeroINE',
							'numero',
						];
						const words = value.split(' ');
						let _wheres = [];
						words.forEach(word => {
							_wheres = _wheres.concat(fields.map(field => field + ' LIKE "%' + word + '%"'));
						});
						wheres.push('(' + _wheres.join(' OR ') + ')');
						break;
					//
					case FILTER_BY_FAVORITES:
						wheres.push('_id IN (\'' + favoriteIds.join('\',\'') + '\')');
						break;
					//
					
					//
					case FILTER_BY_STATUT:
						wheres.push('statutApprenant = "' + value + '"');//
						break;
					//
					case FILTER_BY_FORMATION_SUIVIE:
						wheres.push('FormationSuivie->Formation IN (\'' + value.join('\',\'') + '\')');
						break;
					//
					case FILTER_BY_VALIDATION_STATUT:
						wheres.push('ValidationStatut->value = "' + value + '"');//
						break;
					
				}
			}
			
			let query = 'SELECT _id FROM ? ';
			//
			if (wheres.length > 0) {
				query += ' WHERE ' + wheres.join('\n AND \n');
			}
			/**
			 * order
			 */
			let field = Sorter.field || 'createdAt';
			let order = Sorter.order || ORDER_DESCEND;
			let collate = 'COLLATE NOCASE';
			//
			switch (Sorter.field) {
				default:
					break;
				
			}
			query += ' ORDER BY   ' + field + '  ' + collate + '  ' + order;
			const Rows = alasql(query, [Records]);
			/**
			 *  on récupère la collection de records associés
			 *  car alasql détruit les objects et lesrenvoie en pure json or mob state tree (mst) a besoin d'object avec ses classes
			 *
			 */
			return Rows.map(Row => self.Inscriptions.get(Row._id));
			
		},
		/**
		 *
		
		getByAnneeCreationTotaux() {
			let Records = values(self.Inscriptions);
			let query = 'SELECT  count(anneeCreation) AS total, anneeCreation AS libelle FROM ?  GROUP BY  anneeCreation ORDER BY libelle';
			const Rows = alasql(query, [Records]);
			Rows.forEach(Row => {
				if (!Row.libelle) {
					Row.libelle = 'Sans année de création';
					Row.value = 'WITHOUT_VALUE';
				} else {
					Row.value = Row.libelle;
				}
				
			});
			return Rows;
		}, */
		/**
		 *
		
		getByValidationStatutTotaux() {
			let Records = values(self.Inscriptions);
			let map = {}
			Records.forEach(Record => {
				const key = Record.ValidationStatut.value
				if (!map[key]) {
					map[key] = 0
				}
				map[key]++
			})
			const Rows = Object.entries(map).map(([key, value]) => ({value : key, total : value}));
			return Rows;
		},
		 */
		/**
		 *
		 */
		getByStatutApprenantTotaux() {
			const Records = values(self.Inscriptions);
			const query = "SELECT COUNT(statutApprenant) AS [total], statutApprenant AS libelle FROM ?  GROUP BY statutApprenant ";
			const Rows = alasql(query, [Records]);
			return Rows;
		},
		getByFormationSuivieTotaux() {
			let Inscriptions = values(self.Inscriptions);
			//
			
			const FormationStore = getParent(self, 1).FormationStore;
			let map = {}
			for (const Inscription of Inscriptions) {
				if (Inscription.FormationSuivie && Inscription.FormationSuivie.Formation) {
					const Formation = FormationStore.getById(Inscription.FormationSuivie.Formation)
					if (Formation) {
						map[Formation._id] = Formation
					}
					
				}
			}
			const query = "SELECT *, COUNT(_id) AS [total], _id AS [value], libelleCourt AS libelle FROM ?  GROUP BY libelleCourt ORDER BY libelle ";
			
			const Records = Object.values(map)
			const Rows = alasql(query, [Records]);
			return Rows;
		},
		
		exportDiplomation() {
			const Records = values(self.Inscriptions);
			const query = "SELECT  FROM ?  WHERE statutApprenant='" + APPRENANT_STATUT_INSCRIT + "'";
			const Rows = alasql(query, [Records]);
			return Rows;
		},
	}));

