import {Select} from "antd";
import React from "react";
import {CONTACT_CATEGORIE_VALUES} from "../../../config/Constantes.logic";
import {key} from "../../../helpers/Utils";

/**
 *
 * SelectPays
 *
 */
export const SelectContactCategorie = props =>
	<Select {...props} key={key()}>
		{
			Object.values(CONTACT_CATEGORIE_VALUES).map(item =>
				<Select.Option key={key()} name={props.name} value={item.value}>
					{item.label}
				</Select.Option>
			)
		}
	
	</Select>

/**
 *
 *
 *
 */
