import {Alert, Tag} from "antd";
import React from "react";
import {Button} from "../../../components/antExtendedComponent/Button";
import {SpaceHorizontal} from "../../../components/antExtendedComponent/Space";
import {Notify} from "../../../components/Notify";
import {MESSAGE_1_2} from "../../../config/Constantes.logic";
import {MICRO_SERVICE_URLS} from "../../../config/Constantes.urls";
import microService from "../../../helpers/MicroService";

export const Communication = (({Apprenant}) => {
	
	/**
	 *
	 */
	const EnvoiLienConnectionButton = () => {
		const email = Apprenant.emailPrincipal
		if (!email) {
			return <Alert type={"error"} showIcon message={"Cet apprenant n'a pas d'email principal renseigné."}/>
		}
		return <SpaceHorizontal>
			<Button size={"small"} onClick={() => {
				//
				Notify()
				//
				const data = {}
				data.action = MESSAGE_1_2
				data.lienConnection = Apprenant.lienConnection
				data.To = [{
					Email: email,
					Name: Apprenant.fullname
				}]
				//
				microService.post(MICRO_SERVICE_URLS.SEND_MAIL, data, (Response => {
					Notify(Response.OPERATION_SUCCEED)
					console.log(Response)
				}))
				//
			}}>Envoyer le lien de connection</Button>
			<Tag>Email principal <b>{email}</b></Tag>
		</SpaceHorizontal>
	}
	/**
	 *
	 */
	return <SpaceHorizontal className={"shadow-1 padding"} style={{display: "flex"}}>
		<EnvoiLienConnectionButton/>
	</SpaceHorizontal>
})
