import {inject, observer} from "mobx-react";
import React from "react";
/**
 *
 */
import {Table, Tooltip} from "antd";
import {COLUMN_DATE_TIME, COLUMN_ICON_WIDTH, DEFAULT_PAGINATION, DISPLAY_BOTH, DISPLAY_IN_DATAGRID, DISPLAY_IN_DRAWER} from "../../../config/Constantes.ui";
import {PubliPostageDocumentGenerateButton} from "./publiPostageDocument/PubliPostageDocumentGenerateButton";
import {PubliPostageDocumentDownloadButton} from "./publiPostageDocument/PubliPostageDocumentDownloadButton";
import {DestroyButton} from "../../common/components/DestroyButton";
import {DRAWERS_NAMES} from "../../../stores/NavigationStore";
import {TAB_KEY_PUBLIPOSTAGE} from "../VieApprenants";
import {openDrawer} from "../../common/components/drawerManager";
import {simpleSorter} from "../../../helpers/Utils";
import {CreateurModificateurIcon} from "../../common/components/CreateurModificateurIcon";
import {IconButton} from "../../../components/IconButton";
import {USER_ROLE_APPRENANT} from "../../../config/Constantes.roles";

/**
 *
 */

export const PubliPostageDocuments = inject("store")(observer(props => {
	//
	const {InscriptionSelected, store} = props
	const {
		InscriptionStore,
		PubliPostageDocumentStore,
		ApprenantStore,
		UserStore
	} = store
	//
	const AuthenticatedUser = UserStore.AuthenticatedUser
	//
	let Records = []
	if (InscriptionSelected) {
		Records = PubliPostageDocumentStore.getByInscription(InscriptionSelected._id)
	} else {
		Records = PubliPostageDocumentStore.getAll()
	}
	/**
	 *
	 */
	const openVieApprenanteDrawer = (Inscription) => {
		InscriptionStore.setSelectedRecord(Inscription)
		const maquetteDrawerName = DRAWERS_NAMES.MAQUETTE_FORM_DRAWER
		ApprenantStore.setVieApprenantePageTabsActiveKey(TAB_KEY_PUBLIPOSTAGE)
		openDrawer(maquetteDrawerName)
	};
	/**
	 *
	 */
		//
	let Columns = [
			{
				//
				display: DISPLAY_BOTH,
				//
				dataIndex: "actionDownload",
				title: <Tooltip title={"Télécharger le document"}><IconButton name={"Download"}/></Tooltip>,
				className: "center",
				width: COLUMN_ICON_WIDTH,
				fixed: "left",
				render: (text, Record) => <PubliPostageDocumentDownloadButton Record={Record}/>
			},
			
			{
				//
				display: DISPLAY_IN_DATAGRID,
				//
				dataIndex: 'Inscription.apprenantFullname',
				title: 'Apprenant',
				width: 150,
				ellipsis: true,
				fixed: "left",
				sorter: simpleSorter("Inscription.apprenantFullname"),
				render: (text, Record) =>
					<a href="/" onClick={(e) => {
						e.preventDefault();
						openVieApprenanteDrawer(Record.Inscription)
					}}>{Record.Inscription.apprenantFullname}
					</a>
			},
			{
				
				//
				display: DISPLAY_IN_DATAGRID,
				//
				dataIndex: 'Inscription.numeroTag',
				title: 'Insc.',
				width: 70,
				className: "center",
				fixed: "left",
				sorter: simpleSorter("Inscription.numero"),
				render: (text, Record) => Record.Inscription.numeroTag
			},
			{
				//
				display: DISPLAY_BOTH,
				//
				dataIndex: 'Inscription._FormationSuivie.libelleCourt',
				title: 'Formation suivie',
				width: 150,
				ellipsis: true,
				render: (text, Record) => Record.Inscription._FormationSuivielibelleCourt
			},
			{
				//
				display: DISPLAY_BOTH,
				//
				dataIndex: 'Inscription._FormationSuivie.anneeExamen',
				title: 'Examen',
				width: 90,
				className: "center",
				sorter: simpleSorter("Inscription._FormationSuivieAnneeExamen"),
				render: (text, Record) => Record.Inscription._FormationSuivieAnneeExamenTag
			},
			{
				//
				display: DISPLAY_BOTH,
				//
				dataIndex: 'name',
				title: 'Document',
				ellipsis: true,
				width: 200,
				sorter: simpleSorter("name"),
			},
			{
				dataIndex: "_space_",
				title: '',
			},
			{
				//
				display: DISPLAY_BOTH,
				//
				dataIndex: '_updatedAt',
				title: 'Crée le',
				className: "center nowrap",
				width: COLUMN_DATE_TIME,
				sorter: simpleSorter("updatedAt"),
			},
			
			{	//
				display: DISPLAY_BOTH,
				//
				dataIndex: "CreateurModificateur",
				title: "",
				width: COLUMN_ICON_WIDTH,
				className: "center",
				fixed: "right",
				render: (text, Record) => <CreateurModificateurIcon Record={Record}/>
			},
			{
				//
				display: DISPLAY_IN_DRAWER,
				//
				dataIndex: 'actionDestroy',
				title: '',
				width: COLUMN_ICON_WIDTH,
				fixed: "right",
				className: "center",
				render: (text, Record) => AuthenticatedUser && AuthenticatedUser.hasRoles(USER_ROLE_APPRENANT) === false && <DestroyButton record={Record}/>,
			}
		
		]
	
	if (InscriptionSelected) {
		Columns = Columns.filter(Column => Column.display === DISPLAY_IN_DRAWER || Column.display === DISPLAY_BOTH)
	} else {
		Columns = Columns.filter(Column => Column.display === DISPLAY_BOTH || Column.display === DISPLAY_IN_DATAGRID)
	}
	
	/**
	 *
	 */
	return (
		<div>
			{/* */}
			{
				AuthenticatedUser
				&& AuthenticatedUser.hasRoles(USER_ROLE_APPRENANT) === false
				&& InscriptionSelected
				&& <PubliPostageDocumentGenerateButton InscriptionSelected={InscriptionSelected}/>
			}
			{/* */}
			<Table
				rowKey="_id"
				dataSource={Records}
				columns={Columns}
				size={"small"}
				className={"shadow-2"}
				pagination={DEFAULT_PAGINATION}
				scroll={{y: window.innerHeight - 300}}
				showSorterTooltip={false}
				_t={store._t}
			/>
			{/* */}
		</div>
	
	)
	
	/**
	 *
	 */
	
}))
