/**
 *
 */
import {EditOutlined} from "@ant-design/icons";
import {Alert, Form, Input, Modal, Select, Table, Tag, Tooltip} from 'antd';
import {inject, observer} from "mobx-react";
import React, {useState} from "react";

import {Button, ButtonIcon} from "../../../../components/antExtendedComponent/Button";
import {IconButton} from "../../../../components/IconButton";
import {Notify} from "../../../../components/Notify";
import {CONTACT_CATEGORIE_VALUES, PHONES_TYPES} from "../../../../config/Constantes.logic";
import {COLUMN_ICON_WIDTH, FORM_DEFAULT_RULE_REQUIRED, FORM_RULE_NUMERIC} from "../../../../config/Constantes.ui";
import {formatPhoneNumber, key, newId} from "../../../../helpers/Utils";
import {CreateurModificateurIcon} from "../../../common/components/CreateurModificateurIcon";
import {DestroyIcon} from "../../../common/components/DestroyIcon";
import {SelectContactCategorie} from "../../common/SelectContactCategorie";
import {SelectCountry} from "../../common/SelectCountry";
import {SelectYesNo} from "../../../../components/SelectYesNo";
import {SpaceVertical} from "../../../../components/antExtendedComponent/Space";

/**
 *
 */
export const Telephonies = inject("store")(observer(props => {
	/**
	 *
	 */
	const {isReadOnly, store} = props
	/**
	 *
	 */
	const
		{
			UserStore,
		} = store
	/**
	 *
	 */
	
	const AuthenticatedUser = UserStore.AuthenticatedUser
	/**
	 *
	 */
	const [form] = Form.useForm();
	const [isFormVisible, setFormVisible] = useState(false);
	/**
	 *
	 */
	const Apprenant = props.Apprenant
	let Telephones = Apprenant.Telephones && Apprenant.Telephones.toJSON()
	/**
	 *
	 */
	const onRecordDelete = (Record) => {
		const _Telephones = Telephones.filter(item => item._id !== Record._id)
		Apprenant.update({Telephones: _Telephones})
		Apprenant.save(succeeded => {
			Notify(succeeded)
		})
		/*
		if (_Telephones.length === 0) {
			Notify(false, "Vous devez avoir au moins un Telephone.")
		} else {
		
		}
		
		 */
	};
	/**
	 *
	 */
	const edit = (_Record) => {
		let Record = {..._Record}
		Record._isNew = false
		Record.indicatif_et_pays = Record.indicatif + "|" + Record.pays
		form.setFieldsValue(Record)
		setFormVisible(true)
	};
	/**
	 *
	 */
	const addNew = () => {
		const Record = {
			_id: newId(),
			_isNew: true,
			principal: false,
			indicatif_et_pays: "+33|France"
		}
		form.resetFields()
		form.setFieldsValue(Record)
		setFormVisible(true)
	};
	/**
	 *
	 */
	const onFormFinish = () => {
		
		form.validateFields()
			.then((Telephone) => {
				//
				
				let _Telephones = [...Telephones]
				const OldTelephone = Telephones.find(_Telephone => _Telephone._id === Telephone._id)
				const date = new Date().toISOString()
				if (Telephone._isNew) {
					Telephone.createdAt = date
					Telephone.Createur = AuthenticatedUser.toJSON()
				} else {
					Telephone.updatedAt = date
					Telephone.Createur = OldTelephone && OldTelephone.Createur
					Telephone.Modificateur = AuthenticatedUser.toJSON()
					
					//on ne l'inclut pas dans le tableau
					_Telephones = Telephones.filter(_Telephone => _Telephone._id !== Telephone._id)
				}
				
				//
				Telephone.numero = formatPhoneNumber(Telephone.numero)
				
				console.log(Telephone.numero)
				//
				const str = Telephone.indicatif_et_pays.split("|")
				Telephone.indicatif = str[0]
				Telephone.pays = str[1]
				//
				/**
				 *
				 * constrôle pour n'avoir q"un fixe et un portable
				 */
				const FIXE_PERSONNEL = PHONES_TYPES.FIXE_PERSONNEL.value
				if (Telephone.principal && Telephone.type === FIXE_PERSONNEL) {
					_Telephones = _Telephones.map(_Telephone => {
						_Telephone = {..._Telephone}
						if (_Telephone.type === FIXE_PERSONNEL) {
							_Telephone.principal = false
						}
						return _Telephone
					})
					Telephone.principal = true
				}
				//
				const PORTABLE_PERSONNEL = PHONES_TYPES.PORTABLE_PERSONNEL.value
				if (Telephone.principal && Telephone.type === PORTABLE_PERSONNEL) {
					_Telephones = _Telephones.map(_Telephone => {
						_Telephone = {..._Telephone}
						if (_Telephone.type === PORTABLE_PERSONNEL) {
							_Telephone.principal = false
						}
						return _Telephone
					})
					Telephone.principal = true
				}
				/**
				 *
				 */
				//
				_Telephones.push(Telephone)
				// on trie pour afficher en premier le Telephone principal
				_Telephones = _Telephones.sort((a, b) => {
					if (a.principal === false) {
						return 1;
					}
					return -1;
				});
				console.log(_Telephones)
				/**
				 *
				 */
				Apprenant.update({Telephones: _Telephones})
				
				Apprenant.save(succeeded => {
					Notify(succeeded)
					form.resetFields()
					setFormVisible(false)
				})
				/*
				 */
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	
	/**
	 *
	 */
	let Columns = [
		{
			dataIndex: "actionEdit",
			width: COLUMN_ICON_WIDTH,
			className: "center",
			title: () => !isReadOnly && <Tooltip key={key()} title={"Créer"}>
				<Button
					key={key()}
					shape="circle"
					type={"primary"}
					icon={<IconButton name="PageAdd"/>}
					onClick={addNew}/>
			</Tooltip>,
			render: (text, Record) => !isReadOnly && <ButtonIcon
				key={key()}
				icon={<EditOutlined/>}
				onClick={(e) => {
					edit(Record)
				}}
			/>
		},
		{
			dataIndex: "CreateurModificateur",
			title: "",
			width: COLUMN_ICON_WIDTH,
			className: "center",
			render: (text, Record) => <CreateurModificateurIcon Record={Record}/>
		},
		{
			dataIndex: "principal",
			title: "",
			width: 80,
			render: (text, Record) => Record.principal && <Tag>principal</Tag>
		},
		{
			dataIndex: "categorie",
			title: "Catégorie",
			width: 150,
			render: (text, Record) => Record.categorie && CONTACT_CATEGORIE_VALUES[Record.categorie].label
		},
		
		{
			dataIndex: "type",
			title: "Type",
			width: 250,
			render: (text, Record) => Record.type && PHONES_TYPES[Record.type].label
		},
		{
			dataIndex: "numero",
			title: "Numéro",
			width: 200,
			render: (text, Record) => {
				let content = Record.numero
				if (Record.indicatif) {
					content = Record.indicatif + " " + content
				}
				return content
			}
		},
		{
			dataIndex: "pays",
			title: "Pays",
			render: (text, Record) => Record.pays
		},
		{
			dataIndex: 'actionDestroy',
			title: '',
			width: COLUMN_ICON_WIDTH,
			fixed: "right",
			className: "center",
			
			render: (text, Record) => !isReadOnly && !Record.principal && <DestroyIcon onClick={() => {
				onRecordDelete(Record)
			}}/>
		}
	]
	/**
	 *
	 */
	const ButtonSave = <Button key={key()} onClick={onFormFinish}>Enregistrer</Button>
	
	const FORM_PROPS = {
		form: form,
		disabled: isReadOnly,
		className: "padding",
		labelCol: {span: 8},
		wrapperCol: {span: 10},
		colon: false//
	}
	/**
	 *
	 */
	return (
		<>
			<SpaceVertical>
				
				{
					!Apprenant.telephonePrincipalPortable && <Alert type={"error"} showIcon message={"Cet apprenant n'a pas de téléphone portable principal renseigné."}/>
				}
			</SpaceVertical>
			
			<Table
				_t={store._t}
				rowKey="_id"
				dataSource={Telephones}
				columns={Columns}
				size="small"
				className={"shadow-2"}
				pagination={false}
				showSorterTooltip={false}
			
			/>
			{/*  */}
			<Modal
				title={"Numéro de téléphone"}
				centered
				width={700}
				mask={false}
				open={isFormVisible}
				footer={[ButtonSave]}
				onCancel={() => {
					setFormVisible(false)
				}}
			>
				<Form {...FORM_PROPS}>
					
					<Form.Item name={"_isNew"} hidden>
						<Input/>
					</Form.Item>
					<Form.Item name={"_id"} hidden>
						<Input/>
					</Form.Item>
					{/*  */}
					<Form.Item name={"categorie"} label={'Catégorie'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<SelectContactCategorie/>
					</Form.Item>
					{/*  */}
					<Form.Item name={"type"} label={'Type'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<Select>
							{
								Object.values(PHONES_TYPES).map(item =>
									<Select.Option key={key()} value={item.value}>
										{item.label}
									</Select.Option>
								)
							}
						</Select>
					</Form.Item>
					
					{/*  */}
					<Form.Item name={"principal"} label={'Principal'}>
						<SelectYesNo/>
					</Form.Item>
					{/*  */}
					<Form.Item name={"indicatif_et_pays"} label={'Indicatif'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<SelectCountry valuename={"dialCode"}/>
					</Form.Item>
					{/*  */}
					<Form.Item name={"numero"} label={"Numéro"} rules={FORM_RULE_NUMERIC}>
						<Input autoComplete='off'/>
					</Form.Item>
				</Form>
			</Modal>
		
		</>
	);
}))
