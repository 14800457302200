import {
	CHEF_SERVICE_CREATION_DE_RESSOURCES,
	USER_ROLE_ADMIN,
	USER_ROLE_AGENT,
	USER_ROLE_AGENT_EXPEDITION,
	USER_ROLE_AGENT_GESTION_STOCK,
	USER_ROLE_APPRENANT,
	USER_ROLE_CHEF_ADJOINT,
	USER_ROLE_CHEF_FABRICATION,
	USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION,
	USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
	USER_ROLE_DIRECTEUR,
	USER_ROLE_ENCAISSEMENT_INSCRIPTIONS,
	USER_ROLE_GASA,
	USER_ROLE_GESTION_SCOL,
	USER_ROLE_POLE_FC,
	USER_ROLE_RECRUTEUR,
	USER_ROLE_REFERENT_DISCIPLINAIRE,
	USER_ROLE_REFERENT_OSCAR,
	USER_ROLE_RESPONSABLE_FORMATION,
	USER_ROLE_SUPER_ADMIN,
	USER_ROLE_TELEACCUEIL,
	USER_ROLES,
} from "./Constantes.roles";

/**********************************************************************
 *
 * les contextes des rôles
 *
 **********************************************************************/
export const CONTEXT_ROLES = {}
// USER_ROLE_SUPER_ADMIN est inclus d'office ( voir fin du script
const ALL_ROLES = Object.keys(USER_ROLES).filter(role => role !== USER_ROLE_SUPER_ADMIN)
/****************************************************
 *
 * APPRENANTS
 *
 ****************************************************/
export const CONTEXT_ACCES_DASHBOARD_APPRENANTS = "Accès dashboard des apprenants";
CONTEXT_ROLES[CONTEXT_ACCES_DASHBOARD_APPRENANTS] = {
	modifiers: [
		USER_ROLE_APPRENANT,
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [],
};
export const CONTEXT_APPRENANT_CREER_BUTTON = "Bouton créer un apprenant";
CONTEXT_ROLES[CONTEXT_APPRENANT_CREER_BUTTON] = {
	modifiers: [
		
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [],
};

export const CONTEXT_APPRENANT_INFORMATIONS_PRINCIPALES = "apprenant : Informations principales:  Editer et modifier la fiche d'un apprenant";
CONTEXT_ROLES[CONTEXT_APPRENANT_INFORMATIONS_PRINCIPALES] = {
	modifiers: [
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [
		USER_ROLE_APPRENANT,
		USER_ROLE_POLE_FC,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_RESPONSABLE_FORMATION,
		USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION,
		USER_ROLE_GASA,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		USER_ROLE_AGENT_EXPEDITION,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR
	],
};
//
export const CONTEXT_APPRENANT_EXPERIENCE_PROFESSIONNELLE = "apprenant : Expérience professionnelle :  Editer et modifier la fiche d'un apprenant";
CONTEXT_ROLES[CONTEXT_APPRENANT_EXPERIENCE_PROFESSIONNELLE] = {
	modifiers: [
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [
		USER_ROLE_APPRENANT,
		USER_ROLE_POLE_FC,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_RESPONSABLE_FORMATION,
		USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION,
		USER_ROLE_GASA,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR
	],
};
/****************************************************
 *
 *  DETAILS  INSCRIPTIONS D'UN  APPRENANT
 *
 ****************************************************/
export const CONTEXT_APPRENANT_INSCRIPTION_DETAILS = "apprenant : inscriptions :  accès à une inscription";
CONTEXT_ROLES[CONTEXT_APPRENANT_INSCRIPTION_DETAILS] = {
	modifiers: [
		USER_ROLE_APPRENANT,
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_GASA,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_AGENT_GESTION_STOCK,
		USER_ROLE_AGENT_EXPEDITION,
		CHEF_SERVICE_CREATION_DE_RESSOURCES,
		USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [
		USER_ROLE_POLE_FC,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_CHEF_FABRICATION,
		USER_ROLE_ENCAISSEMENT_INSCRIPTIONS,
		USER_ROLE_RESPONSABLE_FORMATION,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_GASA,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		USER_ROLE_DIRECTEUR
	],
};
//
export const CONTEXT_INSCRIPTION_CREER_BUTTON = "Bouton créer une inscription";
CONTEXT_ROLES[CONTEXT_INSCRIPTION_CREER_BUTTON] = {
	modifiers: [
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_POLE_FC,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [],
};
//
export const CONTEXT_APPRENANT_INSCRIPTION_VOEUX = "apprenant : inscriptions : onglet voeux";
CONTEXT_ROLES[CONTEXT_APPRENANT_INSCRIPTION_VOEUX] = {
	modifiers: [
		USER_ROLE_APPRENANT,
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_RESPONSABLE_FORMATION,
		USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [
		USER_ROLE_GASA,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		CHEF_SERVICE_CREATION_DE_RESSOURCES,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR
	],
};
//
export const CONTEXT_APPRENANT_INSCRIPTION_PARCOURS = "apprenant : inscriptions :  onglet parcours";
CONTEXT_ROLES[CONTEXT_APPRENANT_INSCRIPTION_PARCOURS] = {
	modifiers: [
		USER_ROLE_APPRENANT,
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [
		USER_ROLE_GASA,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_RESPONSABLE_FORMATION,
		USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION,
		CHEF_SERVICE_CREATION_DE_RESSOURCES,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR
	],
};
export const CONTEXT_APPRENANT_INSCRIPTION_FORMATION_SOUHAITEE = "apprenant : inscriptions :  onglet formation souhaitée";
CONTEXT_ROLES[CONTEXT_APPRENANT_INSCRIPTION_FORMATION_SOUHAITEE] = {
	modifiers: [
		USER_ROLE_APPRENANT,
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_POLE_FC,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_RESPONSABLE_FORMATION,
		USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [
		USER_ROLE_GASA,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		CHEF_SERVICE_CREATION_DE_RESSOURCES,
		USER_ROLE_AGENT_EXPEDITION,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR
	],
};
export const CONTEXT_APPRENANT_INSCRIPTION_PROJET_PROFESSIONNEL = "apprenant : inscriptions :  onglet entretien";
CONTEXT_ROLES[CONTEXT_APPRENANT_INSCRIPTION_PROJET_PROFESSIONNEL] = {
	modifiers: [
		USER_ROLE_APPRENANT,
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_RESPONSABLE_FORMATION,
		USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [
		USER_ROLE_GASA,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR
	],
};
//
export const CONTEXT_APPRENANT_INSCRIPTION_PIECES_JOINTES = "apprenant : inscriptions :  onglet pieces_jointes";
CONTEXT_ROLES[CONTEXT_APPRENANT_INSCRIPTION_PIECES_JOINTES] = {
	modifiers: [
		USER_ROLE_APPRENANT,
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_POLE_FC,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [
		USER_ROLE_GASA,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR
	],
};
//
export const CONTEXT_APPRENANT_INSCRIPTION_FORMATION_CONTINUE_FINANCEE = "apprenant : inscriptions :  onglet formation_continue_financee";
CONTEXT_ROLES[CONTEXT_APPRENANT_INSCRIPTION_FORMATION_CONTINUE_FINANCEE] = {
	modifiers: [
		USER_ROLE_APPRENANT,
		USER_ROLE_POLE_FC,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR
	],
};
//
export const CONTEXT_APPRENANT_INSCRIPTION_FORMATION_VISEE = "apprenant : inscriptions :  onglet formation_visee";
CONTEXT_ROLES[CONTEXT_APPRENANT_INSCRIPTION_FORMATION_VISEE] = {
	modifiers: [
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_RECRUTEUR,
		CHEF_SERVICE_CREATION_DE_RESSOURCES,
		USER_ROLE_RESPONSABLE_FORMATION,
		USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [
		USER_ROLE_APPRENANT,
		USER_ROLE_GASA,
		USER_ROLE_POLE_FC,
		USER_ROLE_AGENT_EXPEDITION,
		USER_ROLE_AGENT_GESTION_STOCK,
		USER_ROLE_CHEF_FABRICATION,
		USER_ROLE_RESPONSABLE_FORMATION,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR
	],
};

export const CONTEXT_APPRENANT_INSCRIPTION_PAIEMENT = "apprenant : inscriptions :  onglet paiement";
CONTEXT_ROLES[CONTEXT_APPRENANT_INSCRIPTION_PAIEMENT] = {
	modifiers: [
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_POLE_FC,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_ENCAISSEMENT_INSCRIPTIONS,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [
		USER_ROLE_APPRENANT,
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GASA,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_AGENT_EXPEDITION,
		USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION,
		USER_ROLE_RESPONSABLE_FORMATION,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR
	],
};
//
export const CONTEXT_APPRENANT_INSCRIPTION_VALIDATION_ADMIN = "apprenant : inscriptions :  onglet validation_admin";
CONTEXT_ROLES[CONTEXT_APPRENANT_INSCRIPTION_VALIDATION_ADMIN] = {
	modifiers: [
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_ENCAISSEMENT_INSCRIPTIONS,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [
		USER_ROLE_APPRENANT,
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GASA,
		USER_ROLE_POLE_FC,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_RESPONSABLE_FORMATION,
		USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR
	],
};
//
export const CONTEXT_APPRENANT_INSCRIPTION_DUPLICATION = "apprenant : inscriptions :  onglet inscription_duplication";
CONTEXT_ROLES[CONTEXT_APPRENANT_INSCRIPTION_DUPLICATION] = {
	modifiers: [
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_ENCAISSEMENT_INSCRIPTIONS,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [
		USER_ROLE_APPRENANT,
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GASA,
		USER_ROLE_POLE_FC,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_RESPONSABLE_FORMATION,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		USER_ROLE_AGENT_EXPEDITION,
		USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR
	],
};
export const CONTEXT_APPRENANT_INSCRIPTION_ABANDON = "apprenant : inscriptions :  onglet abandon";
CONTEXT_ROLES[CONTEXT_APPRENANT_INSCRIPTION_ABANDON] = {
	modifiers: [
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_ENCAISSEMENT_INSCRIPTIONS,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [
		USER_ROLE_APPRENANT,
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GASA,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_POLE_FC,
		USER_ROLE_RESPONSABLE_FORMATION,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		USER_ROLE_AGENT_EXPEDITION,
		USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR
	],
};
export const CONTEXT_APPRENANT_INSCRIPTION_RESULTAT_ET_DIPLOMATION = "apprenant : inscriptions :  onglet resultat_et_diplomation";
CONTEXT_ROLES[CONTEXT_APPRENANT_INSCRIPTION_RESULTAT_ET_DIPLOMATION] = {
	modifiers: [
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_ENCAISSEMENT_INSCRIPTIONS,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [
		USER_ROLE_APPRENANT,
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_RESPONSABLE_FORMATION,
		USER_ROLE_GASA,
		USER_ROLE_POLE_FC,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		USER_ROLE_AGENT_EXPEDITION,
		USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR
	],
};

/****************************************************
 *
 * DISCUSSION
 *
 ****************************************************/
export const CONTEXT_APPRENANT_DISCUSSION = "apprenant : discussion";
CONTEXT_ROLES[CONTEXT_APPRENANT_DISCUSSION] = {
	modifiers: [
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_POLE_FC,
		USER_ROLE_GASA,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_ENCAISSEMENT_INSCRIPTIONS,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [
		USER_ROLE_APPRENANT,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_RESPONSABLE_FORMATION,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		CHEF_SERVICE_CREATION_DE_RESSOURCES,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		USER_ROLE_DIRECTEUR
	],
};
//
export const CONTEXT_APPRENANT_RDV = "apprenant : prise de rdv";
CONTEXT_ROLES[CONTEXT_APPRENANT_RDV] = {
	modifiers: [
		USER_ROLE_APPRENANT,
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [
		USER_ROLE_POLE_FC,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_RESPONSABLE_FORMATION,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_GASA,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		USER_ROLE_DIRECTEUR
	],
};
////
export const CONTEXT_APPRENANT_COMMUNICATION = "apprenant : communication";
CONTEXT_ROLES[CONTEXT_APPRENANT_COMMUNICATION] = {
	modifiers: [
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [
		USER_ROLE_POLE_FC,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
	],
};
//
export const CONTEXT_APPRENANT_COMPTES_EXTERNES = "apprenant : comptes externes";
CONTEXT_ROLES[CONTEXT_APPRENANT_COMPTES_EXTERNES] = {
	modifiers: [
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [
		USER_ROLE_POLE_FC,
	],
};
/****************************************************
 *
 * INSCRIPTIONS
 *
 ****************************************************/
export const CONTEXT_ACCES_INSCRIPTIONS = "Accès : dashboard des  inscriptions ";
CONTEXT_ROLES[CONTEXT_ACCES_INSCRIPTIONS] = {
	modifiers: ALL_ROLES,
	readers: [],
};
//
//
export const CONTEXT_ACTION_SYNCHRONISER_FORMATIONS = "Action synchroniser les formations";
CONTEXT_ROLES[CONTEXT_ACTION_SYNCHRONISER_FORMATIONS] = {
	modifiers: [
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [],
};
/**
 * VIE APPRENANT
 */
export const CONTEXT_ACCES_VIE_APPRENANTE = "Accès : vie apprenante";
CONTEXT_ROLES[CONTEXT_ACCES_VIE_APPRENANTE] = {
	modifiers: ALL_ROLES,
	readers: [],
};

/*
export const CONTEXT_ACTION_EDITER_MAQUETTE = "Action éditer la maquette";
CONTEXT_ROLES[CONTEXT_ACTION_EDITER_MAQUETTE] = {
	modifiers: [
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [],
};

 */
/**
 * PROSPECTS
 */
export const CONTEXT_ACCES_PREPROSPECTS = "Accès : dashboard  des preprospects";
CONTEXT_ROLES[CONTEXT_ACCES_PREPROSPECTS] = {
	modifiers: [
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_POLE_FC,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [],
};

export const CONTEXT_PREPROSPECT_GERER = "preprospect : créer, supprimer, lien de connection";
CONTEXT_ROLES[CONTEXT_PREPROSPECT_GERER] = {
	modifiers: [
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_POLE_FC,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_REFERENT_OSCAR
	],
	readers: [],
};
//
/**
 * PLANNING
 */
export const CONTEXT_ACCES_PLANNING = "Accès : planning";
CONTEXT_ROLES[CONTEXT_ACCES_PLANNING] = {
	modifiers: [
		USER_ROLE_APPRENANT,
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_POLE_FC,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_RESPONSABLE_FORMATION,
		USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION,
		USER_ROLE_GASA,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [],
};

export const CONTEXT_PLANNING_RDV_ANNULER_SUPPRIMER = "Planning : Rdvs annuler ou supprimer un rdv";
CONTEXT_ROLES[CONTEXT_PLANNING_RDV_ANNULER_SUPPRIMER] = {
	modifiers: [
		USER_ROLE_APPRENANT,
		USER_ROLE_TELEACCUEIL,
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_RECRUTEUR,
		USER_ROLE_RESPONSABLE_FORMATION,
		USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION,
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [
		USER_ROLE_GESTION_SCOL,
		USER_ROLE_POLE_FC,
		USER_ROLE_GASA,
		USER_ROLE_REFERENT_DISCIPLINAIRE,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR,
	],
};
/**
 * REFERENTIELS
 */
export const CONTEXT_ACCES_REFERENTIEL = "Accès : référentiel";
CONTEXT_ROLES[CONTEXT_ACCES_REFERENTIEL] = {
	modifiers: [
		USER_ROLE_REFERENT_OSCAR,
	],
	readers: [],
};
/*
export const CONTEXT_REFERENTIEL_GERER= "Créer, modifier, supprimer,référentiel";
CONTEXT_ROLES[CONTEXT_REFERENTIEL_GERER] = {
	modifiers: [
	
	],
	readers: [],
};
 */
/**
 * EXPORT
 */
export const CONTEXT_ACCES_EXPORT = "Accès : export";
CONTEXT_ROLES[CONTEXT_ACCES_EXPORT] = {
	modifiers: [
		USER_ROLE_REFERENT_OSCAR
	],
	readers: [],
};
/**
 * USERS
 */
export const CONTEXT_ACCES_USERS = "Accès : gestion des users";
CONTEXT_ROLES[CONTEXT_ACCES_USERS] = {
	modifiers: [
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR,
		USER_ROLE_REFERENT_OSCAR
	],
	readers: [],
};
//
export const CONTEXT_USER_GERER = "gérer un utilisateur";
CONTEXT_ROLES[CONTEXT_USER_GERER] = {
	modifiers: [
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR,
		USER_ROLE_REFERENT_OSCAR
	],
	readers: [],
};
//
/**
 * SUPPORT
 */
export const CONTEXT_ACCES_SUPPORT = "support";
CONTEXT_ROLES[CONTEXT_ACCES_SUPPORT] = {
	modifiers: [
		USER_ROLE_CHEF_ADJOINT,
		USER_ROLE_CHEF_SERVICE_CONDUITE_FORMATION,
		CHEF_SERVICE_CREATION_DE_RESSOURCES,
		USER_ROLE_CHEF_SERVICE_DIRECTEUR_ADJOINT,
		USER_ROLE_DIRECTEUR,
		USER_ROLE_REFERENT_OSCAR
	],
	readers: [],
};
/**
 * on accorde le max de droits aux   super admin et admins
 */
Object.values(CONTEXT_ROLES).forEach((item) => {
	//
	let roles = [USER_ROLE_AGENT]
	//
	item.readers = item.readers.concat(roles)
	roles = [
		USER_ROLE_ADMIN,
		USER_ROLE_SUPER_ADMIN
	]
	item.modifiers = item.modifiers.concat(roles)
	return item
});

