import {Space, Table, Tag} from "antd";
import {inject, observer} from "mobx-react";
import React, {useState} from "react";
import {Button} from "../../../components/antExtendedComponent/Button";
import {SpaceHorizontal, SpaceVertical} from "../../../components/antExtendedComponent/Space";
import {BadgeCount} from "../../../components/BadgeCount";
import {Notify} from "../../../components/Notify";
import {COLOR_RED} from "../../../config/Constantes.ui";
import {key} from "../../../helpers/Utils";
import {SelectFormation} from "../../inscription/forms/common/SelectFormation";

/**
 *
 */

export const ReferentielPieceJointeAssociation = inject("store")(observer(props => {
	
	const {store} = props
	const {ReferentielPieceJointeStore, FormationStore} = store
	let ReferentielPieceJointes = ReferentielPieceJointeStore.getAll().filter(item => item._isNew === false)// && item.active === true
	//
	const [FormationSelected, setFormationSelected] = useState()
	const [SelectedRowKeys, setSelectedRowKeys] = useState([])
	//
	const FormationSelect = (props) => <SelectFormation
		allowClear
		style={{width: "100%"}}
		placeholder={"Sélectionner  une formation à associer"}
		defaultValue={FormationSelected && FormationSelected._id}
		onChange={(value) => {
			if (!value) {
				setFormationSelected(null)
				setSelectedRowKeys([])
			} else {
				const Formations = FormationStore.getAll()
				const _FormationSelected = Formations.find(Formation => Formation._id === value)
				setFormationSelected(_FormationSelected)
				setPieceJointes(_FormationSelected.ReferentielPieceJointes.map(item => item._id))
			}
			
		}}
	/>
	const setPieceJointes = (PieceJointeIds) => {
		setSelectedRowKeys(PieceJointeIds)
		
	}
	
	/**
	 *
	 */
	const ButtonSave = (props) => <Button
		disabled={!Boolean(FormationSelected) || SelectedRowKeys.length === 0}
		onClick={() => {
			FormationSelected.update({ReferentielPieceJointes: SelectedRowKeys})
			Notify()
			FormationSelected.save(succeeded => {
				Notify(succeeded)
			})
			
		}}
	>Enregistrer
	</Button>
	/**
	 *
	 */
	let Columns = [
		{
			dataIndex: "_active_obligatoire_publicAge",
			title: <Space>{SelectedRowKeys.length > 0 && <BadgeCount total={SelectedRowKeys.length}/>} Pièce jointe (catégorie etc) </Space>,
			width: 250,
			render: (text, Record) => <SpaceHorizontal>
				{!Record.active && <Tag key={key()} color={"red"}>Désactivée</Tag>}
				{Record.obligatoire && <Tag key={key()} color={COLOR_RED}>Obligatoire</Tag>}
				{Record.categorieAgeTag && Record.categorieAgeTag}
			</SpaceHorizontal>
		},
		{
			dataIndex: "libelle",
			title: "Libellé",
			render: (text, Record) => text
		}
	];
	
	/**
	 * affichage des checkbox de sélection pour l'export
	 */
	const RowSelection = {
		hideSelectAll: true,
		selectedRowKeys: SelectedRowKeys,
		//
		getCheckboxProps: (Record) => {
			let disabled = true
			if (FormationSelected) {
				//disabled= Record.obligatoire ||  !Record.active
				disabled = !Record.active
			}
			return {disabled: disabled}//Record.obligatoire || true
		},
		//
		onChange: (selectedRowKeys, selectedRows) => setPieceJointes(selectedRowKeys)
		//
	};
	/**
	 *
	 */
	return <SpaceVertical>
		{/* */}
		<SpaceHorizontal><Tag>1</Tag>Sélectionner une formation à associer</SpaceHorizontal>
		{/* */}
		<FormationSelect/>
		{/* */}
		<SpaceHorizontal><Tag>2</Tag>Sélectionnez un ou plusieurs type de pièces jointes</SpaceHorizontal>
		{/* */}
		<Table
			rowKey="_id"
			rowSelection={RowSelection}
			dataSource={ReferentielPieceJointes}
			columns={Columns}
			pagination={false}
			scroll={{y: window.innerHeight * 0.25}}
			showSorterTooltip={false}
			size="small"
			className={"shadow-2"}
			_t={store._t}
		/>
		{/* */}
		<SpaceHorizontal><Tag>3</Tag>Enregistrer la sélection</SpaceHorizontal>
		{/* */}
		<ButtonSave/>
		{/* */}
	</SpaceVertical>
	
}))
