import {Alert, Space, Tag} from "antd";
import {inject, observer} from "mobx-react";
import React from "react";
import {ButtonSmall} from "../../../components/antExtendedComponent/Button";
import {Notify} from "../../../components/Notify";
import {MESSAGE_2, MESSAGE_2_1, RDV_STATUT_DISPONIBLE, RDV_STATUT_EN_ATTENTE, TAKER_TOTAL_RDV_AUTORISE} from "../../../config/Constantes.logic";
import {MICRO_SERVICE_URLS} from "../../../config/Constantes.urls";
import microService from "../../../helpers/MicroService";
import {key} from "../../../helpers/Utils";
import {USER_ROLE_AGENT, USER_ROLE_APPRENANT} from "../../../config/Constantes.roles";

/**
 * view pour Apprenant
 */

export const HourRangeSelection = inject("store")(observer(props => {
	const {
		//
		selectedDate,
		SelectedOwner,
		SelectedFormation,
		SelectedTaker,
		//
	} = props
	//
	
	const {store} = props
	const {
		RdvStore,
		UserStore,
		FormationStore,
		InscriptionStore,
	} = store
	//
	const AuthenticatedUser = UserStore.AuthenticatedUser
	
	//
	//
	const AllRdvs = RdvStore.getAll()
	//
	let RdvsPerSelectedDate = RdvStore.getAllByFormationAndDate(SelectedFormation, selectedDate)
	//
	const formationIds = FormationStore.getAllByUser(SelectedTaker).map(Formation => Formation._id)
	//
	RdvsPerSelectedDate = RdvsPerSelectedDate
		.filter(Rdv => formationIds.includes(Rdv.Formation._id))
		.filter(Rdv => {
			if (AuthenticatedUser.hasRoles(USER_ROLE_APPRENANT)) {
				return (!(Rdv.Taker && Rdv.Taker._id)) || Rdv.Taker._id === AuthenticatedUser._id
			}
			
		})
	/**
	 *
	 * @param rdvId
	 */
	
	/**
	 *
	 */
	const setNotificationAfterOnChange = (Rdv) => {
		const data = {}
		/**
		 * NOTIFICATION_RF_RDV_CHOISI
		 */
		data.action = MESSAGE_2
		const Owner = Rdv.Owner
		data.To = [{
			Email: Owner.email,
			Name: Owner.fullname
		}]
		//
		microService.post(MICRO_SERVICE_URLS.SEND_MAIL, data, (Response => {
		}))
		/**
		 * NOTIFICATION_RF_AUCUN_CRENEAU_DISPONIBLE
		 */
		const creaneauxSansRdv = RdvsPerSelectedDate.filter(Rdv => Rdv.Taker === null)
		
		if (creaneauxSansRdv && creaneauxSansRdv.length === 0) {
			
			data.action = MESSAGE_2_1
			data.To = [{
				Email: Owner.email,
				Name: Owner.fullname
			}]
			//
			microService.post(MICRO_SERVICE_URLS.SEND_MAIL, data, (Response => {
			}))
		}
		
	}
	/**
	 *
	 * @param SelectedRdv
	 */
	const onRdvSelect = (SelectedRdv) => {
		//on annule les rdv de l'apprenant s'il en existe un
		const OldRdvs = AllRdvs.filter(Rdv => {
			return ((Rdv.Taker && Rdv.Taker._id === SelectedTaker._id) && Rdv.Formation._id === SelectedFormation._id)
		})
		
		if (OldRdvs.length === TAKER_TOTAL_RDV_AUTORISE) {
			//
			console.log(OldRdvs.length)
			OldRdvs.forEach(Rdv => {
				Rdv.update({Taker: null, Statut: {value: RDV_STATUT_DISPONIBLE}})
				Rdv.save((succeedOrFail) => {
				})
			})
			//
		}
		//
		let Taker = {...SelectedTaker}
		Taker.date = new Date()
		if (AuthenticatedUser.hasRoles(USER_ROLE_AGENT)) {
			Taker.ByAgent = AuthenticatedUser.toJSON()
		}
		//
		SelectedRdv.update({Taker: Taker, Statut: {value: RDV_STATUT_EN_ATTENTE}})
		
		Notify()
		SelectedRdv.save((succeedOrFail) => {
			Notify(succeedOrFail)
			if (succeedOrFail === true) {
				setNotificationAfterOnChange(SelectedRdv)
			}
		})
		//
	}
	/**
	 *
	 * @returns {JSX.Element}
	 * @constructor
	 */
	const style = {padding: 6, border: "1px solid #f0f0f0"}
	const RdvSelectionButtons = (props) => <Space size={"small"} style={{flexWrap: "wrap"}}>
		{
			RdvsPerSelectedDate.map(Rdv => {
				//
				const disabled = Rdv.hasTaker || SelectedTaker._id === "" || !SelectedFormation
				//
				return <div key={key()} style={style}>
					<Space>
						{Rdv.statutIcon}
						{Rdv.Taker && Rdv.Taker._id && <Tag>{Rdv.Taker.fullname}</Tag>}
						{!SelectedFormation && <Tag>{Rdv.Formation.libelleCourt}</Tag>}
						<ButtonSmall
							disabled={disabled}
							onClick={() => onRdvSelect(Rdv)}
						>
							{Rdv.hourRange}
						</ButtonSmall>
					</Space>
				</div>
				
				//
			})
		}
	</Space>
	/**
	 *
	 */
	
	/**
	 *
	 */
	return <>
		{
			!SelectedFormation &&
			<>
				<Alert showIcon type={"warning"} message={"Vous devez sélectionnez une formation pour afficher les rdvs."}/>
				<br/>
			</>
		}
		
		<RdvSelectionButtons/>
	</>
}))
