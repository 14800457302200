import React from "react";
import {COLOR_PRIMARY} from "../config/Constantes.ui";

/**
 *
 */
export const IconInfo = (props) => {
	let defaultStyle = {color: COLOR_PRIMARY}
	let style = Object.assign(defaultStyle, props.style || {})
	return <i
		{...props}
		style={style}
		className={"ms-Icon--" + props.name}
	/>
}
