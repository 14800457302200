import {values} from "mobx";
import {getParent, types} from "mobx-state-tree";
import {InscriptionModel} from "./InscriptionStore";
import alasql from "alasql";
import {FILTER_BY_ANNEE_EXAMEN, FILTER_BY_APPRENANT, FILTER_BY_CORRECTEUR, FILTER_BY_FAVORITES, FILTER_BY_FORMATION, FILTER_BY_GRADED, FILTER_BY_INSCRIPTION} from "./Constantes.filters";

/**
 *
 */
export const InscriptionEvaluationModel = types
	.model({
		_id: types.identifier,
		anneeCreation: types.maybeNull(types.integer),
		Inscription: types.maybeNull(types.reference(InscriptionModel)),
		Cours: types.frozen({
			_id: "",
			libelleCourt: ""
		}),
		Evaluation: types.frozen({
			_id: "",
			libelle: ""
		}),
		Correcteur: types.frozen({
			_id: "",
			fullname: ""
		}),
		date: types.string,
		graded: types.boolean,
		grade: types.maybeNull(types.number)
		
	})
	.views((self) => ({}))
	.actions((self) => ({}));
/**
 * store
 */
export const InscriptionEvaluationStore = types
	.model({
		InscriptionEvaluations: types.map(InscriptionEvaluationModel),
	}).actions((self) => ({
		addBulkToStore(Records) {
			//self.InscriptionEvaluations.clear()
			Records.forEach(Record => {
				self.InscriptionEvaluations.set(Record._id, Record)
			})
			getParent(self, 1).notifyUIChanges()
		},
		
	}))
	
	.views((self) => ({
		getAll() {
			const Records = values(self.InscriptionEvaluations)
			const query = "SELECT * FROM ?"
			const Rows = alasql(query, [Records]);
			return Rows.map(Row => self.InscriptionEvaluations.get(Row._id))
		},
		
		getByInscription(_id) {
			const Records = values(self.InscriptionEvaluations)
			return Records.filter(Record => Record.Inscription._id === _id)
		},
		getAllByFilters(Filters) {
			
			const Records = values(self.InscriptionEvaluations)
			
			const wheres = [];
			
			for (let key in Filters) {
				const Filter = Filters[key];
				if (!("value" in Filter)) {
					continue
				}
				let value = Filter.value;
				
				switch (Filter.name) {
					case FILTER_BY_INSCRIPTION:
						wheres.push("Inscription->_id = '" + value + "'")
						
						break;
					case FILTER_BY_ANNEE_EXAMEN:
						if (value === 0) {
							wheres.push("Inscription->_FormationSuivie->anneeExamen IS Null");
						} else {
							wheres.push("Inscription->_FormationSuivie->anneeExamen = " + value);
						}
						
						break;
					case FILTER_BY_FORMATION:
						wheres.push("Inscription->_FormationSuivie->_id = '" + value + "'");
						break;
					case FILTER_BY_APPRENANT  :
						wheres.push("Inscription->Apprenant->_id = '" + value + "'");
						break;
					case FILTER_BY_CORRECTEUR  :
						wheres.push("Correcteur->_id = '" + value + "'");
						break;
					case FILTER_BY_GRADED  :
						wheres.push("graded = " + value + "");
						break;
					case FILTER_BY_FAVORITES:
						const FavoriteStore = getParent(self, 1).FavoriteStore;
						const favoriteIds = FavoriteStore.getAll().map(Favorite => Favorite.belongTo);
						wheres.push('_id IN (\'' + favoriteIds.join('\',\'') + '\')');
						break;
				}
			}
			let query = 'SELECT _id FROM ? ';
			//
			if (wheres.length > 0) {
				query += ' WHERE ' + wheres.join('\n AND \n');
			}
			//
			const orderBy = [
				"Inscription->apprenantFullname",
				"Inscription->_FormationSuivie->libelleCourt",
				"Correcteur->fullname",
			].join(",")
			query += " ORDER BY " + orderBy
			//
			//console.log(query)
			//
			const Rows = alasql(query, [Records]);
			return Rows.map(Row => self.InscriptionEvaluations.get(Row._id));
		},
	}));



