/**
 *
 */
import {DeleteOutlined} from "@ant-design/icons";
import {Popconfirm, Tooltip} from "antd";
import React from "react";
/**
 *
 */
import {IconInfo} from "../../../components/IconInfo";
import {Notify} from "../../../components/Notify";
import {COLOR_RED} from "../../../config/Constantes.ui";
import {closeDrawer} from "./drawerManager";

/**
 *
 */

let style = {color: COLOR_RED, cursor: "pointer"}

export const DestroyButton = (props => {
	//
	const {record, drawerName, titleIfDestroyNotAvailable} = props
	//
	const onConfirm = () => {
		Notify()
		record.destroy((succeeded) => {
			Notify(succeeded)
			if (drawerName) {
				closeDrawer(drawerName)
			}
			
		})
	}
	//
	if (record && record.isDeletable === false) {
		return <Tooltip title={titleIfDestroyNotAvailable} placement={"left"}>
			<IconInfo name={"StatusCircleBlock"}/>
		</Tooltip>
	} else {
		return <Tooltip title={"Supprimer"} placement="left">
			<Popconfirm
				title="Attention"
				description="Voulez-vous supprimer cet enregistrement ?"
				onConfirm={onConfirm}
				//	onCancel={cancel}
				okText="Oui"
				cancelText="Non"
			>
				<DeleteOutlined
					{...props}
					style={style}
				/>
			</Popconfirm>
		</Tooltip>
	}
	
})
