/**
 *
 */
import {Alert, List, Modal, Space, Table, Tag, Tooltip} from 'antd';

import {inject, observer} from "mobx-react";
import React, {useState} from "react";
import {SpaceVertical} from "../../../../components/antExtendedComponent/Space";
import {Icon} from "../../../../components/Icon";
import {IconButton} from "../../../../components/IconButton";
import {COLOR_GRAY, COLOR_GREEN, COLOR_ORANGE, COLOR_RED, COLUMN_ICON_WIDTH} from "../../../../config/Constantes.ui";
import {DRAWERS_NAMES} from "../../../../stores/NavigationStore";
import {CreateurModificateurIcon} from "../../../common/components/CreateurModificateurIcon";
import {DestroyButton} from "../../../common/components/DestroyButton";
import {closeDrawer, openDrawer} from "../../../common/components/drawerManager";
import PieceJointe from "./PieceJointe";
import {PieceJointeDownloadButton} from "./PieceJointeDownloadButton";
import {PieceJointeIcon} from "./PieceJointeIcon";
import {CONTEXT_APPRENANT_INSCRIPTION_PIECES_JOINTES} from "../../../../config/Constantes.contexts";

/**
 *
 */
export const PieceJointes = inject("store")(observer(props => {
	
	/**
	 *
	 */
	const {store} = props
	const {InscriptionStore, NavigationStore, UserStore} = store
	const drawerName = DRAWERS_NAMES.PIECE_JOINTE_FORM_DRAWER
	//
	const [ReferentielPieceJointeToUpload, setReferentielPieceJointeToUpload] = useState()
	const [OldPieceJointe, setOldPieceJointe] = useState()
	
	//
	const Inscription = InscriptionStore.SelectedRecord
	
	//
	const uploadParams = ReferentielPieceJointeToUpload && {
		Inscription: Inscription,
		ReferentielPieceJointe: ReferentielPieceJointeToUpload,
		OldPieceJointe: OldPieceJointe,
	}
	//
	const PieceJointeEnvoyees = Inscription && Inscription.PieceJointeEnvoyees || [];
	const PieceJointesToDisplay = Inscription && Inscription.PieceJointesToDisplay || []
	/**
	 * on vérifie si un _id de la pj existe dans la liste des pj référentiel
	 * il se peut qu'un agent modifie la catégorie d'âge ou obligatoire d'une pj référentiel
	 */
	const PieceJointeObsoleteToDisplay = []
	
	const InscriptionPieceJointesToDisplayIds = PieceJointesToDisplay.map(item => item._id)
	PieceJointeEnvoyees.forEach(PieceJointeEnvoyee => {
		if (!InscriptionPieceJointesToDisplayIds.includes(PieceJointeEnvoyee.ReferentielPieceJointe._id)) {
			PieceJointeObsoleteToDisplay.push(PieceJointeEnvoyee)
		}
		
	})
	
	/**
	 * PieceJointeEnvoyees.reverse() car quand on ajoute pour remplacer on a en fait 4 fichier
	 */
	const getInscriptionPieceJointe = (ReferentielPieceJointeId) => PieceJointeEnvoyees.find(item => item.ReferentielPieceJointe._id === ReferentielPieceJointeId)
	/**
	 *
	 */
	const PieceJointeObsoleteToDisplayView = () => {
		return PieceJointeObsoleteToDisplay.length > 0 && <SpaceVertical className={"shadow-2 padding"}>
			<Alert type="error" showIcon message={"Pièces jointes obsolètes à supprimer."}/>
			<List
				dataSource={PieceJointeObsoleteToDisplay}
				bordered
				renderItem={PieceJointeObsolete => (
					<List.Item>
						<Space>
							<DestroyButton Record={PieceJointeObsolete}/>
							<span>{PieceJointeObsolete.ReferentielPieceJointe.libelle}</span>
						</Space>
					</List.Item>
				)}
			/>
		</SpaceVertical>
		
	}
	/**
	 * s
	 */
	const MessagePieceJointesEnvoyeeManquantesAlert = () => {
		if (!Inscription.isReadyForValidationAdministrative) {
			return <Alert
				type="error"
				showIcon
				message={"Une ou plusieurs pièces obligatoires sont manquantes."}
			/>
			
		}
		
	}
	/**
	 *
	 */
	const MessageReferentielPieceJointesManquantesAlert = () => {
		if ((!PieceJointesToDisplay || PieceJointesToDisplay.length === 0) && Inscription.FormationSuivie) {
			return <Alert
				type="error"
				showIcon
				message={"Aucune pièce jointe du référentiel n'est associée à la formation visée."}
			/>
			
		}
		
	}
	/**
	 *
	 */
	const ColumnIconHelp = ({title}) => <Tooltip title={title}><Icon name={"Info"}/></Tooltip>
	//
	const Profil = UserStore.getAllowedContext(CONTEXT_APPRENANT_INSCRIPTION_PIECES_JOINTES)
	//
	let Columns = [
		/**
		 *
		 */
		{
			dataIndex: "actionDownload",
			title: <ColumnIconHelp title={"Télécharger un document"}/>,
			className: "center",
			width: COLUMN_ICON_WIDTH,
			render: (text, Record) => {
				const InscriptionPieceJointe = getInscriptionPieceJointe(Record._id)
				return InscriptionPieceJointe && <PieceJointeDownloadButton Record={InscriptionPieceJointe}/>
			}
		},
		{
			dataIndex: "actionUpload",
			title: <ColumnIconHelp title={"Déposer ou remplacer un document"}/>,
			width: 80,
			className: "center",
			render: (text, Record) => {
				if (Record.active === false) {
					return <Tag color={COLOR_ORANGE}>Obsolète</Tag>
				}
				
				return Profil && Profil.isModifier &&
					<Tooltip title={"Déposer ou remplacer un document"}>
						<IconButton
							name={"CloudUpload"}
							onClick={() => {
								setReferentielPieceJointeToUpload(Record)
								//
								const InscriptionPieceJointe = getInscriptionPieceJointe(Record._id)
								if (InscriptionPieceJointe) {
									setOldPieceJointe(InscriptionPieceJointe)
									
								}
								
								openDrawer(drawerName)
							}}
						/>
					</Tooltip>
				
			}
		},
		/**
		 *
		 */
		{
			dataIndex: "_isOk",
			title: <ColumnIconHelp title={"Document déposé / Document non déposé"}/>,
			width: COLUMN_ICON_WIDTH,
			className: "center",
			render: (text, Record) => {
				if (Profil
					&& Profil.isModifier
					&& Record.active
				) {
					const InscriptionPieceJointe = getInscriptionPieceJointe(Record._id)
					let color = (InscriptionPieceJointe) ? COLOR_GREEN : COLOR_GRAY
					return <Icon name={"InboxCheck"} style={{color: color}}/>
				}
				return ""
				
			}
		},
		/**
		 *
		 */
		{
			dataIndex: "obligatoire",
			title: <ColumnIconHelp title={"Document obligatoire"}/>,
			className: "center",
			width: 100,
			render: (text, Record) => Record.obligatoire && <Tag color={COLOR_RED}>obligatoire</Tag>
		},
		/**
		 *
		 */
		{
			dataIndex: "categorieAgeTag",
			title: <ColumnIconHelp title={"Catégorie d'âge"}/>,
			className: "center",
			width: 100,
			render: (text, Record) => Record.categorieAgeTag
		},
		/**
		 *
		 */
		{
			dataIndex: "libelle",
			title: "Libellé",
			width: 400
		},
		/**
		 *
		 */
		{
			dataIndex: "originalName",
			title: "Nom du fichier",
			width: 400,
			render: (text, Record) => {
				const InscriptionPieceJointe = getInscriptionPieceJointe(Record._id)
				if (InscriptionPieceJointe) {
					return InscriptionPieceJointe.originalName
					
				}
				
			}
		},
		/**
		 *
		 */
		{
			dataIndex: "_file",
			title: <ColumnIconHelp title={"Type de document"}/>,
			width: COLUMN_ICON_WIDTH,
			className: "center",
			render: (text, Record) => {
				const InscriptionPieceJointe = getInscriptionPieceJointe(Record._id)
				if (InscriptionPieceJointe) {
					return <PieceJointeIcon metaInfos={InscriptionPieceJointe}/>
					
				}
				
			}
		},
		/**
		 *
		 */
		
		
		{
			dataIndex: "InscriptionPieceJointeCreateur",
			width: COLUMN_ICON_WIDTH,
			className: "center",
			fixed: "right",
			title: "",
			render: (text, Record) => {
				const InscriptionPieceJointe = getInscriptionPieceJointe(Record._id)
				return InscriptionPieceJointe && <CreateurModificateurIcon Record={InscriptionPieceJointe}/>
			}
		},
		/**
		 * actionDestroy
		 */
		{
			dataIndex: 'actionDestroy',
			title: <ColumnIconHelp title={"Suppression"}/>,
			width: COLUMN_ICON_WIDTH,
			fixed: "right",
			className: "center",
			render: (text, Record) => {
				if (Profil && Profil.isModifier) {
					const InscriptionPieceJointe = getInscriptionPieceJointe(Record._id)
					return InscriptionPieceJointe && <DestroyButton record={InscriptionPieceJointe}/>
				}
				
			}
		}
	];
	/**
	 *
	 */
	return (
		
		Inscription && <SpaceVertical size={"small"}>
			{/* */}
			<Space>
				<span>{Inscription._FormationSuivieLibelleTag}</span>
				{Inscription.categorieAgeTag}
				<Tag>Nombre documents restants à déposer ({PieceJointesToDisplay.length - PieceJointeEnvoyees.length})</Tag>
			</Space>
			{/* */}
			<PieceJointeObsoleteToDisplayView/>
			{/* */}
			<MessageReferentielPieceJointesManquantesAlert/>
			{/* */}
			<MessagePieceJointesEnvoyeeManquantesAlert/>
			{/* */}
			<Table
				rowKey="_id"
				dataSource={PieceJointesToDisplay}
				columns={Columns}
				scroll={{y: window.innerHeight - 500}}
				showSorterTooltip={false}
				size="small"
				pagination={false}
				className={"shadow-2"}
				_t={store._t}
			
			/>
			{/* */}
			<Modal
				_t={store._t}
				title={"Ajout d'une Pièce Jointe annexe"}
				centered
				closable={true}
				width={700}
				//open={ReferentielPieceJointeToUpload && true}//
				open={NavigationStore.isDrawerVisible(drawerName)}
				onCancel={() => {
					setReferentielPieceJointeToUpload(null)
					closeDrawer(drawerName)
				}
				}
				footer={false}
			>
				{/* */}
				<PieceJointe {...uploadParams}/>
				{/* */}
			</Modal>
			
			{/* */}
		</SpaceVertical>
	
	);
}))

