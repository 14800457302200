import {LinkOutlined} from "@ant-design/icons";
import alasql from "alasql";
import {Badge, Tag, Tooltip} from "antd";
import days from "dayjs";
import {values} from "mobx";
import {applySnapshot, getParent, types} from "mobx-state-tree";
import React from "react";
import {IconInfo} from "../components/IconInfo";
import {
	ACCESS_PLATEFORME_BASE_URL,
	AGE_LEGAL,
	APPRENANT_STATUT_INSCRIT,
	FILTER_BY_FAVORITES,
	FILTER_BY_PRISE_EN_COMPTE,
	FILTER_BY_WITH_INSCRIPTION,
	FILTER_BY_WORDS,
	INSCRIPTION_STATUT_VALIDATION_ADMINISTRATIVE_ACCEPTE,
	NATIONALITE_VALUES,
	ORDER_DESCEND,
	PHONES_TYPES
} from "../config/Constantes.logic";
import {MICRO_SERVICE_URLS} from "../config/Constantes.urls";
//import {MICRO_SERVICE_URLS} from "../config/Constantes.urls";
import MicroService from "../helpers/MicroService";
import {key, newId, toLocalDateTime} from "../helpers/Utils";
import {UserModel} from "./UserStore";
import {USER_ROLE_APPRENANT} from "../config/Constantes.roles";
import {COLOR_GREEN} from "../config/Constantes.ui";

;
/**
 *
 */

/**
 *
 */

const CURRENT_MICRO_SERVICE_URL = MICRO_SERVICE_URLS.APPRENANT
/**
 * model
 */
export const ApprenantModel = types
	.model({
		_id: types.identifier,
		//
		User: types.maybeNull(types.reference(UserModel)),
		//
		CompteExternes: types.frozen({
			moodle: "",
			ldap: "",
			partage: null,
			date: ""
		}),
		Radiation: types.frozen({
			Agent: {
				_id: "",
				fullname: ""
			},
			confirme: null,
			date: "",
			createdAt: ""
		}),
		//
		priseEnCompte: types.optional(types.boolean, false),
		numeroINE: types.optional(types.string, ""),
		civilite: types.optional(types.string, ""),
		nomNaissance: types.optional(types.string, ""),
		nomUsage: types.optional(types.string, ""),
		prenom: types.optional(types.string, ""),
		prenomAutres: types.optional(types.string, ""),
		dateNaissance: types.optional(types.string, ""),
		nationalite: types.optional(types.string, ""),
		//
		amenagementEpreuve: types.maybeNull(types.boolean, null),
		autorisationTrombinoscope: types.maybeNull(types.boolean, null),
		permisDeConduire: types.maybeNull(types.boolean, null),
		sportHautNiveau: types.maybeNull(types.boolean, null),
		commentaire: types.optional(types.string, ""),
		certificatPix: types.maybeNull(types.boolean, null),
		certificatPixDateValidite: types.optional(types.string, ""),
		
		//
		LocaliteNaissance: types.frozen({
			_id: "",
			commune: "",
			departement: "",
			pays: "",
		}),
		//
		RepresentantLegaux: types.array(types.frozen({
			_id: "",
			principal: null,
			civilite: "",
			nomUsage: "",
			nomNaissance: "",
			prenom: "",
			telephoneFixe: "",
			telephonePortable: "",
			email: "",
			lienParente: ""
		})),
		//
		AdressePrincipale: types.frozen({
			_id: "",
			voieNumero: "",
			voieType: "",
			voieNom: "",
			complement: "",
			codePostal: "",
			commune: "",
			departement: "",
			region: "",
			pays: "",
			chez: "",
		}),
		//
		AdresseExpedition: types.frozen({
			_id: "",
			voieNumero: "",
			voieType: "",
			voieNom: "",
			complement: "",
			codePostal: "",
			commune: "",
			departement: "",
			region: "",
			pays: "",
			chez: "",
		}),
		Telephones: types.array(types.frozen({
			_id: "",
			categorie: "",
			type: "",
			numero: "",
			pays: "",
			indicatif: "",
			principal: null,
			Createur: {_id: "", fullname: ""},
			Modificateur: {_id: "", fullname: ""},
			createdAt: "",
			updatedAt: "",
		})),
		Emails: types.array(types.frozen({
			_id: "",
			categorie: "",
			email: "",
			principal: null,
			Createur: {_id: "", fullname: ""},
			Modificateur: {_id: "", fullname: ""},
			createdAt: "",
			updatedAt: "",
		})),
		//
		SituationProfessionnelle: types.frozen({
			_id: "",
			type: "",
			employeur: "",
			dateDebut: null,
			dateFin: null,
			pleinTemps: null,
			quotite: "",
			duree: ""
			
		}),
		//
		ExperienceProfessionnelles: types.array(types.frozen({
			_id: "",
			profession: "",
			employeur: "",
			//dateDebutFin : [],
			duree: "",
			createdAt: "",
		})),
		
		//
		Createur: types.frozen({_id: "", fullname: ""}),
		Modificateur: types.frozen({_id: "", fullname: ""}),
		createdAt: types.maybeNull(types.string, ""),
		updatedAt: types.maybeNull(types.string, ""),
		_isOnEdition: types.optional(types.boolean, false),
		_isNew: types.optional(types.boolean, false),
		_index: types.optional(types.number, 0),
	})
	.views((self) => ({
		get isDeletable() {
			//const Records = getParent(self, 3).ProduitStore.getAll()
			//const found = Records.find(Record => Record.Apprenant && Record.Apprenant._id === self._id)
			return true;//!Boolean(found)
		},
		get warninIfEdited() {
			//ApprenantEditeds est de type Set
			const ApprenantEditeds = values(getParent(self, 2).ApprenantEditeds)
			const Founds = ApprenantEditeds.filter(ApprenantEdited => ApprenantEdited.objectId === self._id)
			let Users = Founds.map(Found => Found.User.fullname)
			//on enlève les doublons
			Users = [...new Set(Users)].join(", ");
			return Founds.length > 0 && <Tooltip title={Users}><Badge status="processing" style={{marginLeft: 4}}/></Tooltip>
			
		},
		
		get fullname() {
			return self.nomUsage + " " + self.prenom
		},
		get moodleIdTag() {
			const moodle = self.CompteExternes.moodle
			if (moodle > 0) {
				return <Tooltip title={"Identifiant de l'utilisateur moodle"}>
					<Tag><IconInfo name="ReminderPerson"/> moodle <b>{moodle}</b></Tag>
				</Tooltip>
			}
			return <Tag color={"red"}>Aucun compte moodle</Tag>
		},
		get priseEnCompteTag() {
			let color = "red"
			let title = "Prise en charge en attente"
			if (self.priseEnCompte) {
				color = COLOR_GREEN
				title = "Pris en charge"
			}
			return <Tooltip title={title}><IconInfo name={"Clock"} style={{color: color}}/></Tooltip>
			
		},
		get telephonePrincipalFixe() {
			const Telephone = self.Telephones && self.Telephones.find(Telephone => Telephone.principal === true && Telephone.type === PHONES_TYPES.FIXE_PERSONNEL.value)
			return Telephone && Telephone.numero && Telephone.indicatif + " " + Telephone.numero;
		},
		get telephonePrincipalPortable() {
			const Telephone = self.Telephones && self.Telephones.find(Telephone => Telephone.principal === true && Telephone.type === PHONES_TYPES.PORTABLE_PERSONNEL.value)
			return Telephone && Telephone.numero && Telephone.indicatif + " " + Telephone.numero;
		},
		get emailPrincipal() {
			const Email = self.Emails.find(Email => Email.principal === true)
			return (Email && Email.email) || null;
		},
		
		get _nationalite() {
			return self.nationalite && NATIONALITE_VALUES[self.nationalite].label
		},
		get dateNaissanceFormated() {
			return self.dateNaissance && days(self.dateNaissance).format("DD/MM/YYYY");
		}
		,
		get age() {
			if (self.dateNaissance) {
				return days().diff(self.dateNaissance, 'years') + " ans";
			}
			return null
		},
		get isMineur() {
			return parseInt(self.age) < AGE_LEGAL
		},
		get ageTag() {
			if (self.age) {
				const content = [<Tag key={key()} color={"blue"}>{self.age}</Tag>]
				if (self.isMineur) {
					content.push(<Tag key={key()} color={"red"}>Mineur</Tag>)
				}
				return content
			}
			return null
		},
		
		get totalExperiencesDurees() {
			let total = self.SituationProfessionnelle.duree || 0
			self.ExperienceProfessionnelles.forEach(ExperienceProfessionnelle => {
				total += ExperienceProfessionnelle.duree
			})
			return total
		},
		get _createdAt() {
			return toLocalDateTime(self.createdAt)
		},
		get _updatedAt() {
			return toLocalDateTime(self.updatedAt)
		},
		get totalInscription() {
			return self.Inscriptions.filter(Inscription => !Inscription._isNew).length
		},
		get Inscriptions() {
			const InscriptionStore = getParent(self, 3).InscriptionStore
			const Inscriptions = values(InscriptionStore.Inscriptions)
			return Inscriptions.filter(Inscription => Inscription.Apprenant._id === self._id && Inscription._isNew === false).reverse()
		},
		get lienConnection() {
			const sid = self.User && self.User.sid
			return sid && ACCESS_PLATEFORME_BASE_URL + "&sid=" + sid
		},
		get lienConnectionIcon() {
			
			const lienConnection = self.lienConnection
			return self.totalInscription > 0 && lienConnection && <Tooltip title={"Lien de connection temporaire à la plateforme"}>
				<a target={"_blank"} href={lienConnection}><LinkOutlined/></a>
			</Tooltip>
			
		},
		get comptesReadyToCreate() {
			const JustOneInscription = self.Inscriptions.find(Inscription =>
				Inscription.statutApprenant === APPRENANT_STATUT_INSCRIT
				&& Inscription.statutInscription === INSCRIPTION_STATUT_VALIDATION_ADMINISTRATIVE_ACCEPTE
			)
			return Boolean(JustOneInscription)
			
		},
		/*
		get comptesReadyToCreateTag() {
			//
			if(!self.comptesReadyToCreate)
			{
				return ""
			}
			//
			let title="Comptes ext."
			let color=""
			if(self.CompteExternes.ldap)
			{
				color="green"
				title+= " créés le "+toLocalDateTime(self.CompteExternes.date)
			}
			else
			{
				title+= " non créés"
			}
			return <Tooltip title={title}>
				<Tag color={color}>Comptes ext.</Tag>
			</Tooltip>
			
		},
		*/
		get hasCompteMoodle() {
			return Boolean(self.CompteExternes.moodle)
		}
	}))
	
	.actions((self) => ({
		
		/**
		 * fonction sans body
		 * sert pour le log des fiches ouvertes
		 * le nom de cette fonction est appele par le drawer
		 * fonction capturée par le store car relié à l'objet apprenant
		 *
		 */
		cancel() {
		},
		/**
		 *
		 */
		edit() {
			sessionStorage.currentApprenant = self._id
			getParent(self, 2).setSelectedRecord(null)
			getParent(self, 2).setRecordOnEdition(self);
			getParent(self, 3).InscriptionStore.setSelectedRecord(null)
		},
		/**
		 *
		 * @param Field {fieldname:value}
		 */
		update(EditedRecord) {
			applySnapshot(self, Object.assign(self, EditedRecord));
			getParent(self, 3).notifyUIChanges()
			
		},
		
		/**
		 * on crée ou modifie le user ( collection Users)  pour l'apprenant
		 *
		 */
		upsertItsUser(callback) {
			
			//
			const UserToUpdate = {
				nom: self.nomUsage,
				prenom: self.prenom,
				fullname: self.nomUsage + " " + self.prenom,
				login: "login-" + newId(),
				email: "email-" + newId(),
				roles: [USER_ROLE_APPRENANT]
			}
			/**
			 * création du user de l'apperenant
			 */
				//
			const UserStore = getParent(self, 3).UserStore
			let User = null
			if (self.User && self.User._id) {
				User = UserStore.findById(self.User._id)
				UserToUpdate._isNew = false
				User.update(UserToUpdate)
			} else {
				const _id = newId()
				UserToUpdate._isNew = true
				UserToUpdate._id = _id
				UserToUpdate.login = _id
				UserToUpdate.sid = _id
				console.log(UserToUpdate)
				User = UserStore.create(UserToUpdate)
			}
			//
			User.save(callback)
			
		},
		/**
		 *
		 * @param callback
		 */
		save(callback) {
			//
			/**
			 * create
			 */
			let url = CURRENT_MICRO_SERVICE_URL
			let data = {Record: self}
			//
			self.upsertItsUser((successOrFailed, User) => {
				if (successOrFailed === true) {
					//
					self.update({User: User._id})
					//
					if (self._isNew === false) {
						url = url + "/" + self._id
						MicroService.update(url, data, setResult)
					} else {
						MicroService.create(url, data, setResult)
					}
				}
				
			})
			//
			const setResult = (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					let Record = Response.Record
					getParent(self, 2).addToStore(Record)
				}
				callback(succeeded)
			}
			
			//
			
		},
		/**
		 *
		 */
		
		/**
		 *
		 * @param callback
		 */
		destroy(callback) {
			MicroService.delete(CURRENT_MICRO_SERVICE_URL + "/" + self._id, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					
					const InscriptionStore = getParent(self, 3).InscriptionStore
					const UserStore = getParent(self, 3).UserStore
					
					//on supprime ses Inscriptions du store local
					self.Inscriptions.forEach(Inscription => {
						InscriptionStore.removeFromStore(Inscription._id)
					})
					//on supprime ses preprospect du store local
					const PreProspectStore = getParent(self, 3).PreProspectStore
					PreProspectStore.PreProspects.forEach(PreProspect => {
						if (PreProspect.Apprenant === self._id) {
							PreProspectStore.removeFromStore(PreProspect._id)
						}
						
					})
					//on supprime son User du store local
					if (self.User) {
						UserStore.removeFromStore(self.User._id);
					}
					
					//on supprime l'apprennant du store local
					getParent(self, 2).removeFromStore(self._id)
					
				}
				callback(succeeded)
				
			})
			
		}
		
	}));
/**
 * store
 */
export const ApprenantStore = types
	.model({
		Apprenants: types.map(ApprenantModel),
		SelectedRecord: types.maybeNull(types.reference(ApprenantModel, {})),
		
		ApprenantEditeds: types.map(types.frozen({})),
		//
		apprenantPageTabsActiveKey: types.optional(types.string, ""),
		inscriptionPageTabsActiveKey: types.optional(types.string, ""),
		vieApprenantePageTabsActiveKey: types.optional(types.string, ""),
		
	})
	
	.actions((self) => ({
		/**
		 *
		 * @param SocketObjectEdited
		 */
		addOrRemoveToApprenantEditeds(SocketObjectEdited) {
			//_id est de la forme Record.action+"-"+Record.objectId+"-"+AuthenticatedUser._id
			let id = SocketObjectEdited._id
			
			if (SocketObjectEdited.action === "edit") {
				const data = {
					User: SocketObjectEdited.User,
					objectId: SocketObjectEdited.objectId
				}
				self.ApprenantEditeds.set(id, data)
			} else {
				
				id = id.replace("cancel", "edit")
				self.ApprenantEditeds.delete(id)
			}
			//
			getParent(self, 1).notifyUIChanges()
			//
		},
		/**
		 *
		 * @param SelectedRecord
		 */
		setSelectedRecord(SelectedRecord) {
			self.SelectedRecord = SelectedRecord
		},
		/**
		 *
		 * @param SelectedRecord
		 */
		setOpenedRecord(SelectedRecord) {
			self.OpenedRecord = SelectedRecord
		},
		/**
		 *
		 * @param key
		 */
		setApprenantPageTabsActiveKey(key) {
			self.apprenantPageTabsActiveKey = key
		},
		/**
		 *
		 * @param key
		 */
		setInscriptionPageTabsActiveKey(key) {
			self.inscriptionPageTabsActiveKey = key
		},
		setVieApprenantePageTabsActiveKey(key) {
			self.vieApprenantePageTabsActiveKey = key
		},
		/**
		 *
		 */
		create() {
			self.removeSetOnEditionForAll()
			let size = self.Apprenants.size + 1
			let obj = {
				_id: newId(),
				_isOnEdition: true,
				_isNew: true,
				_index: -Date.now()
				
			}
			const newRecord = ApprenantModel.create(obj)
			self.Apprenants.set(newRecord._id, newRecord)
			self.SelectedRecord = newRecord._id
		},
		/**
		 *
		 * @param SelectedRecord
		 */
		setRecordOnEdition(SelectedRecord) {
			self.removeSetOnEditionForAll()
			SelectedRecord._isOnEdition = true
			self.SelectedRecord = SelectedRecord
			getParent(self, 1).notifyUIChanges()
		},
		
		/**
		 *
		 * @param Record
		 */
		addToStore(Record) {
			self.Apprenants.set(Record._id, Record)
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 * @param _id
		 */
		removeFromStore(_id) {
			self.Apprenants.delete(_id)
			self.SelectedRecord = null
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 */
		removeSetOnEditionForAll() {
			getParent(self, 1).notifyUIChanges()
			//self.SelectedRecord = null
			values(self.Apprenants).forEach((Record) => {
				Record._isOnEdition = false
				if (Record._isNew === true) {
					self.Apprenants.delete(Record._id)
				}
			})
		}
	}))
	
	.views((self) => ({
		getById(_id) {
			const Records = values(self.Apprenants)
			const Apprenant = Records.find(Record => Record._id === _id)
			return Apprenant
		},
		getByUserId(userId) {
			const Records = values(self.Apprenants)
			return Records.find(Record => Record.User && Record.User._id === userId)
		},
		getAll() {
			const Records = values(self.Apprenants)			//
			const query = "SELECT * FROM ? ORDER BY nom"//  COLLATE NOCASE
			const Rows = alasql(query, [Records]);
			return Rows.map(Row => self.Apprenants.get(Row._id))
			
		},
		getByInscriptionTotaux() {
			
			let Records = values(self.Apprenants);
			let query = 'SELECT  count(anneeCreation) AS total, anneeCreation AS libelle FROM ?  GROUP BY  anneeCreation ORDER BY libelle';
			const Rows = alasql(query, [Records]);
			Rows.forEach(Row => {
				if (!Row.libelle) {
					Row.libelle = 'Sans année de création';
					Row.value = 'WITHOUT_VALUE';
				} else {
					Row.value = Row.libelle;
				}
				
			});
			return Rows;
		},
		/**
		 *
		 * @param searchText
		 * @param byFullSearch
		 * @param byFavorites
		 * @returns {*}
		 */
		getAllByFilters(Filters, Sorter) {
			
			let Records = values(self.Apprenants);
			//
			const FavoriteStore = getParent(self, 1).FavoriteStore;
			const favoriteIds = FavoriteStore.getAll().map(Favorite => Favorite.belongTo);
			//
			
			const InscriptionStore = getParent(self, 1).InscriptionStore;
			const apprenantWidthInscriptionIds = InscriptionStore.getAll().map(Inscription => Inscription.Apprenant._id);
			
			/**
			 *
			 */
			/**
			 *
			 */
			const wheres = [];
			wheres.push('_isNew=false');
			//
			for (let key in Filters) {
				const Filter = Filters[key];
				let value = Filter.value;
				if (value === null) {
					continue;
				}
				switch (Filter.name) {
					default:
						
						break
					//
					case FILTER_BY_WORDS:
						const fields = [
							'_id',
							'nomUsage',
							'normalize(nomUsage)',
							'prenom',
							'normalize(prenom)',
							'nomNaissance',
							'normalize(nomNaissance)',
						];
						const words = value.split(' ');
						let _wheres = [];
						words.forEach(word => {
							_wheres = _wheres.concat(fields.map(field => field + ' LIKE \'%' + word + '%\''));
						});
						wheres.push('(' + _wheres.join(' OR ') + ')');
						break;
					//
					case FILTER_BY_FAVORITES:
						wheres.push('_id IN (\'' + favoriteIds.join('\',\'') + '\')');
						break;
					//
					case FILTER_BY_PRISE_EN_COMPTE:
						wheres.push('priseEnCompte = ' + value)
						break;
					//
					case FILTER_BY_WITH_INSCRIPTION:
						if (value === true) {
							
							wheres.push('_id  IN (\'' + apprenantWidthInscriptionIds.join('\',\'') + '\')');
						} else {
							
							wheres.push('_id NOT  IN (\'' + apprenantWidthInscriptionIds.join('\',\'') + '\')');
						}
						
						break;
					
				}
			}
			let query = 'SELECT _id FROM ? ';
			//
			if (wheres.length > 0) {
				query += ' WHERE ' + wheres.join('\n AND \n');
			}
			/**
			 * order
			 */
			let field = Sorter.field;
			let order = Sorter.order || ORDER_DESCEND;
			let collate = 'COLLATE NOCASE';
			//
			switch (Sorter.field) {
				default:
					field = Sorter.field || 'createdAt';
					collate = '';
					break;
				
			}
			
			query += ' ORDER BY   ' + field + '  ' + collate + '  ' + order;
			const Rows = alasql(query, [Records]);
			/**
			 *  on récupère la collection de records associés
			 *  car alasql détruit les objects et lesrenvoie en pure json or mob state tree (mst) a besoin d'object avec ses classes
			 *
			 */
			return Rows.map(Row => self.Apprenants.get(Row._id));
			
		},
		
	}));

