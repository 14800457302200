/**
 *
 */
import {Alert, Form, Input, Select} from 'antd';
import {inject, observer} from "mobx-react";
import React, {useEffect} from "react";
import {Button} from "../../../components/antExtendedComponent/Button";
import {Notify} from "../../../components/Notify";
import {SelectYesNo} from "../../../components/SelectYesNo";
import {
	FORMATION_MODALITES,
	FORMATION_PROFIL_ACTIVITE_PRO_OU_DEMANDEUR_EMPLOI,
	FORMATION_PROFIL_ELEVE_OU_ETUDIANT,
	FORMATION_PROFILS_VALUES,
	FORMATION_TYPES_VALUES,
	INSCRIPTION_PROJET_STATUT_ACCEPTE,
	REINSCRIPTION_MOTIFS,
	STATUTS_EXAMEN
} from "../../../config/Constantes.logic";
import {FORM_DEFAULT_RULE_REQUIRED} from "../../../config/Constantes.ui";
import {key} from "../../../helpers/Utils";
import {BigTitle} from "../../common/components/BigTitle";
import {SectionTitle} from "../../common/components/SectionTitle";
import {SelectFormation} from "./common/SelectFormation";

/**
 *
 */

/**
 *
 */
export const FormationSouhaitee = inject("store")(observer(props => {
	/**
	 *
	 */
	const {isReadOnly, store} = props
	
	/**
	 *
	 */
	const
		{
			InscriptionStore,
			InscriptionProjetProfessionnelStore,
			FormationStore
			
		} = store
	
	/**
	 *
	 */
	const [form] = Form.useForm();
	/**
	 *
	 */
	const InscriptionSelected = InscriptionStore.SelectedRecord
	const InscriptionEdited = InscriptionSelected && InscriptionSelected.toJSON()
	//
	const isFormationChoisieAvantEntretienDiplomante = (() => {
		if (InscriptionSelected && InscriptionSelected.FormationSouhaitee) {
			const FormationChoisieAvantEntretien = InscriptionSelected.FormationSouhaitee && InscriptionSelected.FormationSouhaitee.FormationChoisieAvantEntretien
			const Formation = FormationStore.getById(FormationChoisieAvantEntretien)
			return Formation && Formation.diplomante
		}
		return false
	})()
	//
	const ifFormationChoisieAvantEntretienContains = (libelle) => {
		const FormationChoisieAvantEntretien = InscriptionSelected.FormationSouhaitee && InscriptionSelected.FormationSouhaitee.FormationChoisieAvantEntretien
		if (FormationChoisieAvantEntretien) {
			const Formation = FormationStore.getById(FormationChoisieAvantEntretien)
			const found = Formation.libelle.toLowerCase().includes(libelle.toLowerCase())
			return found
		}
		return false
	}
	/**
	 *
	 */
	const InfoFormationModalite = () => {
		const modalites = InscriptionSelected.FormationSouhaitee && InscriptionSelected.FormationSouhaitee.modalites
		const Modalite = FORMATION_MODALITES[modalites] || {}
		let info = Modalite.info
		// cas pour redoublant ext
		if (modalites === FORMATION_MODALITES.MODALITE_REDOUBLANT_EXTERIEUR.value
			&& ifFormationChoisieAvantEntretienContains("stav")) {
			info = Modalite.infoParDiplome["stav"]
		}
		
		return info && <Alert showIcon message={info}></Alert>
	}
	/**
	 * populate form
	 */
	useEffect(() => {
		form.resetFields()
		form.setFieldsValue(InscriptionEdited);
	})
	/**
	 *
	 */
	
	/**
	 * modifie l'objet dans le store avant enregistrement
	 */
	const onFieldsChange = (changedFields, allFields) => {
		//
		const Obj = {}
		//
		const changedField = changedFields[0]
		if (!changedField) {
			return false
		}
		const field = changedField.name[0]
		// cas d'un sous-objet
		const subField = changedField.name[1]
		//
		if (!subField) {
			let value = changedField.value
			
			Obj[field] = value;
		} else {
			Obj[field] = {};
			const Fields = allFields.filter(item => item.name[0] === field)
			Obj[field] = {}
			if (!Obj["FormationSuivie"]) {
				Obj["FormationSuivie"] = {}
			}
			Fields.forEach(item => {
				const _subField = item.name[1]
				let _value = item.value
				
				switch (_subField) {
					default:
						break
					//
					case "FormationChoisieAvantEntretien":
						if (InscriptionSelected.reInscription) {
							Obj["FormationSuivie"]["Formation"] = _value
						}
						break
					//
					case "modalites":
						// on doit affecter la formation suivie
						Obj["FormationSuivie"]["modalites"] = _value
						break
					case "statutPourExamen":
						// on doit affecter la formation suivie
						Obj["FormationSuivie"]["statutPourExamen"] = _value
						break
					//
					case "profil":
						if (_value === FORMATION_PROFIL_ELEVE_OU_ETUDIANT) {
							Obj["formationType"] = FORMATION_TYPES_VALUES.FORMATION_INITIALE.value
						}
						if (_value === FORMATION_PROFIL_ACTIVITE_PRO_OU_DEMANDEUR_EMPLOI) {
							Obj["formationType"] = FORMATION_TYPES_VALUES.FORMATION_CONTINUE.value
							Obj["FormationSouhaitee"]["souhaitDemandeBourse"] = null
						}
						break
					//
					case "souhaitDemandeBourse":
						Obj[field]["souhaitFinancementFormation"] = null
						break
					//
					case "souhaitFinancementFormation":
						//on remplit aussi ce champs
						Obj[field]["souhaitDemandeBourse"] = null
						
						break
					//
				}
				Obj[field][_subField] = _value
			})
			//
		}
		InscriptionSelected.update(Obj)
	};
	/**
	 *
	 * @param  form values
	 * @returns {boolean}
	 */
	const onFinish = () => {
		
		form.validateFields()
			.then((values) => {
				Notify()
				//
				//
				InscriptionSelected.save(succeeded => {
					Notify(succeeded)
					if (succeeded === true) {
						// si aucun entretien  on crée un entretien par défaut
						if (InscriptionSelected.ProjetProfessionnels.length === 0) {
							const NewProjetProfessionnel = InscriptionProjetProfessionnelStore.create(InscriptionSelected)
							NewProjetProfessionnel.update({FormationChoisieApresEntretien: InscriptionSelected.FormationSouhaitee.FormationChoisieAvantEntretien})
							NewProjetProfessionnel.save(succeeded => {
							
							})
							
						}
						//
						
					}
				})
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	/**
	 *
	 */
	const ButtonSave = <div className={"center"}>
		<Button block onClick={onFinish}>Enregistrer</Button>
	</div>
	/**
	 *
	 */
	let ProjetProfessionnelChoisiStatut = ""
	
	if (InscriptionEdited) {
		ProjetProfessionnelChoisiStatut = (InscriptionSelected.ProjetProfessionnelChoisi && InscriptionSelected.ProjetProfessionnelChoisi.statut) || null
		
	}
	
	const disabled = isReadOnly || (ProjetProfessionnelChoisiStatut === INSCRIPTION_PROJET_STATUT_ACCEPTE)
	const FORM_PROPS = {
		
		form: form,
		disabled: disabled,
		onFieldsChange: onFieldsChange,
		layout: "vertical",
		className: "fadein shadow-2 padding",
		
	}
	return (
		
		<Form  {...FORM_PROPS} >
			<BigTitle title={"Conditions d'accès à la formation"}/>
			{/*  */}
			<SectionTitle title={"Général"}/>
			{/*  champ de l'InscriptionSelected  et non de l'objet FormationSouhaitee */}
			{
				InscriptionSelected && InscriptionSelected.reInscription && <>
					<Form.Item name={"reInscriptionType"} label={'Type de réinscription'}>
						<Select>
							{
								Object.values(REINSCRIPTION_MOTIFS).map(item =>
									<Select.Option key={key()} value={item.value}>
										{item.label}
									</Select.Option>
								)
							}
						</Select>
					</Form.Item>
				</>
			}
			{/*  */}
			<Form.Item name={["FormationSouhaitee", "FormationChoisieAvantEntretien"]} label={'Formation choisie  pour l\'entretien'} rules={FORM_DEFAULT_RULE_REQUIRED}>
				<SelectFormation/>
			</Form.Item>
			{/*  */}
			<Form.Item name={["FormationSouhaitee", "profil"]} label={'Statut ou profil'}>
				<Select>
					{
						Object.values(FORMATION_PROFILS_VALUES).map(item =>
							<Select.Option key={key()} value={item.value}>
								{item.label}
							</Select.Option>
						)
					}
				</Select>
			</Form.Item>
			{/*  */}
			{
				InscriptionSelected && InscriptionSelected.FormationSouhaitee.profil === FORMATION_PROFIL_ELEVE_OU_ETUDIANT
				&& <Form.Item name={["FormationSouhaitee", "souhaitDemandeBourse"]} label={'Souhaitez-vous faire une demande de bourse ?'}>
					<SelectYesNo/>
				</Form.Item>
				
			}
			{/*  */}
			{
				InscriptionSelected && InscriptionSelected.FormationSouhaitee.souhaitDemandeBourse
				&& InscriptionSelected.FormationSouhaitee.profil === FORMATION_PROFIL_ELEVE_OU_ETUDIANT
				&& <Alert showIcon message={"Transmettre votre notification d'attribution provisoire ou définitive de bourse"}/>
			}
			{/*  */}
			{
				InscriptionSelected && InscriptionSelected.FormationSouhaitee.profil === FORMATION_PROFIL_ACTIVITE_PRO_OU_DEMANDEUR_EMPLOI
				&& <Form.Item name={["FormationSouhaitee", "souhaitFinancementFormation"]} label={'Souhaitez-vous faire financer votre formation ?'}>
					<SelectYesNo/>
				</Form.Item>
			}
			{
				isFormationChoisieAvantEntretienDiplomante &&
				<>
					{/*  */}
					<SectionTitle title={"Statut et modalité de programmation de votre formation"}/>
					{/*  */}
					<Form.Item name={["FormationSouhaitee", "statutPourExamen"]} label={'Statut pour l\'examen'}>
						<Select>
							{
								Object.values(STATUTS_EXAMEN).map(item =>
									<Select.Option key={key()} value={item.value}>
										{item.label}
									</Select.Option>
								)
							}
						</Select>
					</Form.Item>
					{/*  */}
					<Form.Item name={["FormationSouhaitee", "modalites"]} label={'Modalité de la Formation'}>
						<Select>
							{
								Object.values(FORMATION_MODALITES).map(item =>
									<Select.Option key={key()} value={item.value}>
										{item.label}
									</Select.Option>
								)
							}
						</Select>
					</Form.Item>
					{/*  */}
					<InfoFormationModalite/>
					{/*  */}
					{/* Si la formation choisie est un BTSA uniquement, faire afficher cette question   */}
					{
						ifFormationChoisieAvantEntretienContains("BTSA") &&
						<>
							<Form.Item name={["FormationSouhaitee", "parcourSupValidee"]} label={'Votre demande de formation a-t-elle été validée sur ParcourSup ?'}>
								<SelectYesNo/>
							</Form.Item>
							{
								InscriptionSelected.FormationSouhaitee.parcourSupValidee && <Form.Item name={["FormationSouhaitee", "parcourSupNumero"]} label={'N° ParcourSup'}>
									<Input/>
								</Form.Item>
							}
						
						</>
					}
				</>
			}
			
			{/*  */}
			<Form.Item></Form.Item>
			{ButtonSave}
			{/*  */}
		
		</Form>
	
	);
}))

