import {Select as AntSelect} from "antd";
import React from "react";

/**
 *
 */

export const Select = (props) => {
	/**
	 *
	 */
	let filterOption = null
	if (props.showSearch) {
		filterOption = (input, option) => {
			if (option.label) {
				// pour la gestion des accents
				return normalize(option.label).includes(normalize(input))
			} else {
				return false;
			}
		}
	}
	/**
	 *
	 */
	return <AntSelect
		style={{minWidth: 200, width: "100%"}}
		{...props}
		popupMatchSelectWidth={false}
		filterOption={filterOption}
	></AntSelect>
	/**
	 *
	 */
}

/**
 *
 */
const normalize = str => {
	return str ? str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : str;
};
