/**
 *
 */
import {EditOutlined} from "@ant-design/icons";
import {Form, Input, InputNumber, Modal, Table, Tooltip} from 'antd';
import {inject, observer} from "mobx-react";
import React, {useState} from "react";
import {Button, ButtonIcon} from "../../../components/antExtendedComponent/Button";
import {IconButton} from "../../../components/IconButton";
import {Notify} from "../../../components/Notify";
import {COLUMN_ICON_WIDTH, FORM_DEFAULT_RULE_REQUIRED} from "../../../config/Constantes.ui";
import {key, newId} from "../../../helpers/Utils";
import {CreateurModificateurIcon} from "../../common/components/CreateurModificateurIcon";
import {DestroyIcon} from "../../common/components/DestroyIcon";
import {CONTEXT_APPRENANT_EXPERIENCE_PROFESSIONNELLE} from "../../../config/Constantes.contexts";

/**
 *
 */
export const ExperienceProfessionnelles = inject("store")(observer(props => {
	/**
	 *
	 */
	const {store} = props
	/**
	 *
	 */
	const
		{
			ApprenantStore,
			UserStore
		} = store
	/**
	 *
	 *
	 */
	
	const AuthenticatedUser = UserStore.AuthenticatedUser
	/**
	 *
	 */
	const [form] = Form.useForm();
	const [isFormVisible, setFormVisible] = useState(false);
	const Apprenant = ApprenantStore.SelectedRecord || {}
	let ExperienceProfessionnelles = Apprenant.ExperienceProfessionnelles.toJSON()
	
	//
	
	/**
	 *
	 */
	const onRecordDelete = (Record) => {
		ExperienceProfessionnelles = ExperienceProfessionnelles.filter(item => item._id !== Record._id)
		Apprenant.update({ExperienceProfessionnelles: ExperienceProfessionnelles})
		Apprenant.save(succeeded => {
			Notify(succeeded)
		})
	};
	/**
	 *
	 */
	const edit = (_Record) => {
		let Record = {..._Record}
		Record._isNew = false
		Record.indicatif_et_pays = Record.indicatif + "|" + Record.pays
		form.setFieldsValue(Record)
		setFormVisible(true)
	};
	/**
	 *
	 */
	const addNew = () => {
		const Record = {
			_id: newId(),
			_isNew: true
		}
		form.resetFields()
		form.setFieldsValue(Record)
		setFormVisible(true)
	};
	/**
	 *
	 */
	
	const onFormFinish = () => {
		form.validateFields()
			.then((ExperienceProfessionnelle) => {
				const OldExperienceProfessionnelle = ExperienceProfessionnelles.find(_ExperienceProfessionnelle => _ExperienceProfessionnelle._id === ExperienceProfessionnelle._id)
				const date = new Date()
				if (ExperienceProfessionnelle._isNew) {
					ExperienceProfessionnelle.createdAt = date
					ExperienceProfessionnelle.Createur = AuthenticatedUser.toJSON()
				} else {
					ExperienceProfessionnelle.updatedAt = date
					ExperienceProfessionnelle.Createur = OldExperienceProfessionnelle && OldExperienceProfessionnelle.Createur
					ExperienceProfessionnelle.Modificateur = AuthenticatedUser.toJSON()
					
					//on ne l'inclut pas dans le tableau
					ExperienceProfessionnelles = ExperienceProfessionnelles.filter(_ExperienceProfessionnelle => _ExperienceProfessionnelle._id !== ExperienceProfessionnelle._id)
				}
				
				ExperienceProfessionnelles.push(ExperienceProfessionnelle)
				Apprenant.update({ExperienceProfessionnelles: ExperienceProfessionnelles})
				Apprenant.save(succeeded => {
					Notify(succeeded)
					form.resetFields()
					setFormVisible(false)
				})
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	
	/**
	 *
	 */
	const Profil = UserStore.getAllowedContext(CONTEXT_APPRENANT_EXPERIENCE_PROFESSIONNELLE)
	
	let Columns = [
		
		{
			dataIndex: "actionEdit",
			width: COLUMN_ICON_WIDTH,
			className: "center",
			title: Profil.isModifier && <Tooltip title={"Créer"}>
				<Button
					shape="circle"
					type={"primary"}
					icon={<IconButton name="PageAdd"/>}
					onClick={addNew}/>
			</Tooltip>,
			render: (text, Record) => Profil.isModifier && <ButtonIcon
				icon={<EditOutlined/>}
				onClick={(e) => {
					edit(Record)
				}}
			/>
		},
		{
			dataIndex: "CreateurModificateur",
			title: "",
			width: COLUMN_ICON_WIDTH,
			className: "center",
			render: (text, Record) => <CreateurModificateurIcon Record={Record}/>
		},
		{
			dataIndex: "duree",
			title: "Durée (mois)",
			width: 80
		},
		{
			dataIndex: "employeur",
			title: "Employeur",
			width: 300
		},
		{
			dataIndex: "profession",
			title: "Profession"
		},
		
		{
			dataIndex: 'actionDestroy',
			title: '',
			width: COLUMN_ICON_WIDTH,
			fixed: "right",
			className: "center",
			render: (text, Record) => Profil.isModifier && <DestroyIcon onClick={() => {
				onRecordDelete(Record)
			}}/>
		}
	]
	/**
	 *
	 */
	const ButtonSave = <Button key={key()} onClick={onFormFinish}>Enregistrer</Button>
	
	const FORM_PROPS = {
		form: form,
		className: "padding",
		labelCol: {span: 8},
		wrapperCol: {span: 10},
		colon: false
	}
	/**
	 *
	 */
	/**
	 *
	 */
	return (
		<div>
			<Table
				rowKey="_id"
				dataSource={ExperienceProfessionnelles}
				columns={Columns}
				size="small"
				className={"shadow-2 padding"}
				pagination={false}
				showSorterTooltip={false}
			
			/>
			{/* */}
			<Modal
				
				title={"Expérience professionnelle"}
				centered
				width={700}
				mask={false}
				open={isFormVisible}
				footer={[ButtonSave]}
				onCancel={() => {
					setFormVisible(false)
				}}
			>
				<Form  {...FORM_PROPS}>
					<Form.Item name={"_isNew"} hidden>
						<Input/>
					</Form.Item>
					<Form.Item name={"_id"} hidden>
						<Input/>
					</Form.Item>
					{/* */}
					<Form.Item name={"profession"} label={'Profession'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<Input/>
					</Form.Item>
					{/* */}
					<Form.Item name={"employeur"} label={'Employeur'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<Input/>
					</Form.Item>
					{/*
					<Form.Item name={"dateDebutFin"} label={'Début - fin'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<DatePicker.RangePicker />
					</Form.Item>*/}
					{/* */}
					<Form.Item name={"duree"} label={'Durée (mois)'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<InputNumber step={0.25}/>
					</Form.Item>
					{/* */}
				
				</Form>
			</Modal>
		
		</div>
	);
}))
