import {inject, observer} from "mobx-react";
import React, {useState} from "react";
/**
 *
 */
import {COLUMN_ICON_WIDTH, DEFAULT_PAGINATION, DEFAULT_TABLE_SCROLL, DISPLAY_BOTH, DISPLAY_IN_DATAGRID, DISPLAY_IN_DRAWER} from "../../../config/Constantes.ui";
import {Badge, Button, Select, Space, Table, Tag, Tooltip} from "antd";
import {DRAWERS_NAMES} from "../../../stores/NavigationStore";
import {openDrawer} from "../../common/components/drawerManager";
import {key, simpleSorter, toLocalDateTime} from "../../../helpers/Utils";
import {IconButton} from "../../../components/IconButton";
import {Notify} from "../../../components/Notify";
import alasql from "alasql";
import {MOODLE_API_BASE_URL} from "../../../config/Constantes.urls";
import {BadgeCount} from "../../../components/BadgeCount";
import {FavoriteSwitch} from "../../common/components/FavoriteSwitch";
import {FavoriteButton} from "../../common/components/FavoriteButton";
import {FILTER_BY_ANNEE_EXAMEN, FILTER_BY_APPRENANT, FILTER_BY_CORRECTEUR, FILTER_BY_FAVORITES, FILTER_BY_FORMATION, FILTER_BY_GRADED, FILTER_BY_INSCRIPTION} from "../../../stores/Constantes.filters";
import {SpaceVertical} from "../../../components/antExtendedComponent/Space";

/**
 *

function getAnneeCreation(date) {
	const dateActuelle = new Date(date);
	const mois = dateActuelle.getMonth() + 1; // Mois actuel (janvier = 1, février = 2, ..., décembre = 12)
	const jour = dateActuelle.getDate(); // Jour actuel
	
	if (mois>= 9 ) {
		// Si nous sommes le 1er septembre ou après
		return dateActuelle.getFullYear(); // Extraire l'année actuelle (année "n")
	} else {
		// Sinon, nous sommes avant le 1er septembre
		return dateActuelle.getFullYear() - 1; // Extraire l'année précédente (année "n-1")
	}
}

// Exemple d'utilisation de la fonction
const annee = getAnneeCreation("2024-02-01");

console.log("Année extraite :", annee);
 */
const localStorageFiltersName = "FILTERS_EVALUATIONS"

export const Evaluations = inject("store")(observer(props => {
	//
	
	//
	const data = localStorage[localStorageFiltersName]
	const _Filters = (data && JSON.parse(data)) || {}
	const [Filters, setFilters] = useState(_Filters)
	//
	const {InscriptionSelected, store} = props
	const {InscriptionStore, InscriptionEvaluationStore} = store
	
	/**
	 *
	 */
	const AllInscriptionEvaluations = InscriptionEvaluationStore.getAll()
	/**
	 *
	 */
	let Records = []
	if (InscriptionSelected) {
		let Filter = {name: FILTER_BY_INSCRIPTION, value: InscriptionSelected._id}
		Filters[FILTER_BY_INSCRIPTION] = Filter
	}
	Records = InscriptionEvaluationStore.getAllByFilters(Filters)
	/**
	 *
	 */
	const openVieApprenanteDrawer = (Inscription) => {
		InscriptionStore.setSelectedRecord(Inscription)
		const maquetteDrawerName = DRAWERS_NAMES.MAQUETTE_FORM_DRAWER
		openDrawer(maquetteDrawerName)
	};
	const exportExcel = () => {
		const ColumnsToExport = {
			"Inscription.numero": "Inscription N°",
			"Inscription.apprenantFullname": "Apprenant",
			"Inscription._FormationSuivie.anneeExamen": "Année examen",
			"Inscription._FormationSuivie.libelleCourt": "Formation",
			"Evaluation.libelle": "Évaluation",
			"Cours.libelleCourt": "Cours",
			"grade": "Note",
			"Correcteur.fullname": "Correcteur",
			"date": "Date",
			
		}
		const Rows = []//
		Records.forEach(Record => {
			const Row = {}
			const fields = Object.keys(ColumnsToExport)
			fields.forEach((field, index) => {
				let value = Record[field]
				let title = ColumnsToExport[field]
				Row[title] = value
				// si besoin de formatter certains champs
				switch (field) {
					case "Inscription.numero":
						value = Record.Inscription.numero
						Row[title] = value
						break
					case "Inscription.apprenantFullname":
						value = Record.Inscription.apprenantFullname
						Row[title] = value
						break
					case "Inscription._FormationSuivie.anneeExamen":
						value = Record.Inscription._FormationSuivie && Record.Inscription._FormationSuivie.anneeExamen
						Row[title] = value
						break
					case "Inscription._FormationSuivie.libelleCourt":
						value = Record.Inscription._FormationSuivie && Record.Inscription._FormationSuivie.libelleCourt
						Row[title] = value
						break
					case "Evaluation.libelle":
						value = Record.Evaluation.libelle
						Row[title] = value
						break
					case "Cours.libelleCourt":
						value = Record.Cours.libelleCourt
						Row[title] = value
						break
					case "grade":
						value = Record.grade || "-"
						Row[title] = value
						break
					case "Correcteur.fullname":
						value = (Record.Correcteur && Record.Correcteur.fullname) || ""
						Row[title] = value
						break
					case "date":
						value = toLocalDateTime(Record.date)
						Row[title] = value
						break
				}
				
			})
			Rows.push(Row)
		})
		//
		const fileName = "notes.xlsx"
		const opts = [{sheetid: "notes", header: true}];
		
		Notify()
		alasql.promise('SELECT INTO XLSX("' + fileName + '",?) FROM ?', [opts, [Rows]])
			.then(function (res) {
				Notify(true)
			})
			.catch(function (err) {
				console.log('Error:', err);
			});
	}
	/**
	 *
	 */
	const setFilter = (Filter) => {
		const _Filters = {...Filters}
		if (!("value" in Filter)) {
			delete _Filters[Filter.name];
		} else {
			_Filters[Filter.name] = Filter;
		}
		
		setFilters(_Filters)
		localStorage[localStorageFiltersName] = JSON.stringify(_Filters)
		
	};
	
	const resetFilters = () => {
		Object.values(Filters).forEach(Filter => {
			delete Filter.value
			setFilter(Filter)
		})
		
	}
	/**
	 *
	 */
	
	const ButtonExportToXls = (
		<Tooltip title="Exporter les notes sous format excel (xlsx)">
			<BadgeCount total={Records.length}>
				<Button
					disabled={Records.length === 0}
					shape="circle"
					type="primary"
					icon={<IconButton name={"ExcelDocument"} type="button"/>}
					onClick={exportExcel}
				/>
			</BadgeCount>
		
		</Tooltip>
	)
	/**
	 *
	 */
	const availablesStoredFilters = Object.values(Filters).filter(Filter => ("value" in Filter))
	const isFiltersExists = Boolean(availablesStoredFilters.length)
	const ButtonResetFilters = (
		<Tooltip title="Supprimer tous les filtres" placement="topRight">
			<Badge dot={isFiltersExists}>
				<Button
					shape="circle"
					type="primary"
					ghost
					disabled={!isFiltersExists}
					icon={<IconButton name={"EraseTool"} type="button"/>}
					onClick={resetFilters}/>
			</Badge>
		</Tooltip>
	)
	/**
	 * Filters
	 */
	/**
	 * FilterByFavorite
	 */
	const FilterByFavorite = () =>
		<FavoriteSwitch
			checked={Filters.FILTER_BY_FAVORITES && Filters.FILTER_BY_FAVORITES.value}
			onChange={(value) => {
				//
				let Filter = {}
				Filter = {name: FILTER_BY_FAVORITES, value: value}
				if (!value) {
					delete Filter.value
				}
				setFilter(Filter)
				//
				
			}}
		/>
	/**
	 *
	 */
	const selectWidth = 150
	const selectDropdownWidth = 300
	/**
	 * Année examen
	 */
	const FilterByAnneeExamen = () => {
		//
		let Items = {}
		AllInscriptionEvaluations.forEach(Record => {
			let value = Record.Inscription._FormationSuivieAnneeExamen
			let libelle = value
			if (!value) {
				libelle = "Sans"
			}
			Items[value] = {value: value, libelle: libelle}
		})
		Items = Object.values(Items)
		//
		const Filter = Filters[FILTER_BY_ANNEE_EXAMEN]
		const value = Filter && Filter.value
		//
		return <Select
			value={value}
			allowClear
			placeholder={"Examen"}
			style={{width: 90}}
			onChange={(value) => {
				//
				let Filter = {}
				Filter = {name: FILTER_BY_ANNEE_EXAMEN, value: value}
				if (!value) {
					delete Filter.value
				}
				setFilter(Filter)
				//
			}}
		>
			{
				Items.map(item =>
					<Select.Option key={key()} value={item.value} label={item.libelle}>
						{item.libelle}
					</Select.Option>
				)
			}
		
		</Select>
	}
	/**
	 * formation suivie
	 */
	const FilterByFormationSuivie = () => {
		//
		let Items = {}
		AllInscriptionEvaluations.forEach(Record => {
			let Formation = Record.Inscription._FormationSuivie
			let value = Formation && Formation._id
			if (value) {
				let libelle = Formation.libelleCourt
				Items[value] = {value: value, libelle: libelle}
			}
			
		})
		Items = Object.values(Items)
		//
		const Filter = Filters[FILTER_BY_FORMATION]
		const value = Filter && Filter.value
		//
		return <Select
			value={value}
			allowClear
			showSearch
			placeholder={"Formation suivie"}
			style={{minWidth: selectWidth}}
			dropdownStyle={{width: selectDropdownWidth}}
			optionFilterProp={"label"}
			onChange={(value) => {
				//
				let Filter = {}
				Filter = {name: FILTER_BY_FORMATION, value: value}
				if (!value) {
					delete Filter.value
				}
				setFilter(Filter)
				//
			}}
		>
			{
				Items.map(item =>
					<Select.Option key={key()} value={item.value} label={item.libelle}>
						{item.libelle}
					</Select.Option>
				)
			}
		
		</Select>
	}
	/**
	 * Apprenant
	 */
	const FilterByApprenant = () => {
		//
		let Items = {}
		AllInscriptionEvaluations.forEach(Record => {
			let Apprenant = Record.Inscription.Apprenant
			let value = Apprenant._id
			let libelle = Apprenant.fullname
			Items[value] = {value: value, libelle: libelle}
			
		})
		Items = Object.values(Items)
		//
		const Filter = Filters[FILTER_BY_APPRENANT]
		const value = Filter && Filter.value
		//
		return <Select
			value={value}
			allowClear
			showSearch
			placeholder={"Apprenant"}
			style={{minWidth: selectWidth}}
			dropdownStyle={selectDropdownWidth}
			optionFilterProp={"label"}
			onChange={(value) => {
				//
				let Filter = {}
				Filter = {name: FILTER_BY_APPRENANT, value: value}
				if (!value) {
					delete Filter.value
				}
				setFilter(Filter)
				//
			}}
		>
			{
				Items.map(item =>
					<Select.Option key={key()} value={item.value} label={item.libelle}>
						{item.libelle}
					</Select.Option>
				)
			}
		
		</Select>
	}
	/**
	 * Apprenant
	 */
	const FilterByCorrecteur = () => {
		//
		let Items = {}
		AllInscriptionEvaluations.forEach(Record => {
			let Correcteur = Record.Correcteur
			let value = Correcteur._id
			if (value) {
				let libelle = Correcteur.fullname
				Items[value] = {value: value, libelle: libelle}
			}
			
		})
		Items = Object.values(Items)
		//
		const Filter = Filters[FILTER_BY_CORRECTEUR]
		const value = Filter && Filter.value
		//
		return <Select
			value={value}
			allowClear
			showSearch
			placeholder={"Correcteur"}
			style={{minWidth: selectWidth}}
			dropdownStyle={{width: selectDropdownWidth}}
			optionFilterProp={"label"}
			onChange={(value) => {
				//
				let Filter = {}
				Filter = {name: FILTER_BY_CORRECTEUR, value: value}
				if (!value) {
					delete Filter.value
				}
				setFilter(Filter)
				//
			}}
		>
			{
				Items.map(item =>
					<Select.Option key={key()} value={item.value} label={item.libelle}>
						{item.libelle}
					</Select.Option>
				)
			}
		
		</Select>
	}
	/**
	 * FilterByGraded
	 */
	const FilterByGraded = () => {
		//
		let Items = {}
		AllInscriptionEvaluations.forEach(Record => {
			const value = Record.graded
			let libelle = (value === true ? "Noté" : "Non noté")
			Items[value] = {value: value, libelle: libelle}
			
		})
		Items = Object.values(Items)
		//
		const Filter = Filters[FILTER_BY_GRADED]
		const value = Filter && Filter.value
		return <Select
			value={value}
			placeholder={"Noté / Non noté"}
			allowClear
			style={{minWidth: 80}}
			onChange={(value) => {
				//
				let Filter = {}
				Filter = {name: FILTER_BY_GRADED, value: value}
				if (!value && value !== false) {
					delete Filter.value
				}
				setFilter(Filter)
				//
			}}
		>
			{
				Items.map(item =>
					<Select.Option key={key()} value={item.value}>
						{item.libelle}
					</Select.Option>
				)
			}
		
		</Select>
	}
	/**
	 *
	 */
	let Columns = [
		{
			//
			display: DISPLAY_IN_DATAGRID,
			//
			dataIndex: '_isFavorite',
			title: '',
			width: COLUMN_ICON_WIDTH,
			fixed: "left",
			className: "center",
			render: (text, Record) => <FavoriteButton Record={Record}/>
		},
		{
			//
			display: DISPLAY_IN_DATAGRID,
			//
			dataIndex: 'Inscription.Apprenant',
			title: 'Apprenant',
			width: 150,
			ellipsis: true,
			fixed: "left",
			sorter: simpleSorter("Inscription.apprenantFullname"),
			render: (text, Record) =>
				<a href="/" onClick={(e) => {
					e.preventDefault();
					openVieApprenanteDrawer(Record.Inscription)
				}}>{Record.Inscription.apprenantFullname}
				</a>
		},
		{
			
			//
			display: DISPLAY_IN_DATAGRID,
			//
			dataIndex: 'Inscription.numeroTag',
			title: 'Insc.',
			width: 70,
			className: "center",
			fixed: "left",
			sorter: simpleSorter("Inscription.numero"),
			render: (text, Record) => Record.Inscription.numeroTag
		},
		{
			//
			display: DISPLAY_IN_DATAGRID,
			//
			dataIndex: 'Inscription._FormationSuivie.libelleCourt',
			title: 'Formation suivie',
			width: 150,
			ellipsis: true,
			sorter: simpleSorter("Inscription._FormationSuivielibelleCourt"),
			render: (text, Record) => Record.Inscription._FormationSuivielibelleCourt
		},
		{
			//
			display: DISPLAY_IN_DATAGRID,
			//
			dataIndex: 'Inscription._FormationSuivie.anneeExamen',
			title: 'Examen',
			width: 90,
			className: "center",
			sorter: simpleSorter("Inscription._FormationSuivieAnneeExamen"),
			render: (text, Record) => Record.Inscription._FormationSuivieAnneeExamenTag
		},
		{
			//
			display: DISPLAY_BOTH,
			//
			dataIndex: 'Evaluation.libelle',
			title: 'Devoir',
			width: 200,
			ellipsis: true,
			sorter: simpleSorter("Evaluation.libelle"),
			render: (text, Record) => {
				let url = MOODLE_API_BASE_URL + "/mod/assign/view.php?id="
				url += Record.Evaluation._id.split('-')[1]
				return <a target={"_blank"} href={url}>{Record.Evaluation.libelle}</a>
			}
		},
		{
			//
			display: DISPLAY_BOTH,
			//
			dataIndex: 'Cours.libelleCourt',
			title: 'Cours',
			width: 200,
			ellipsis: true,
			sorter: simpleSorter("Evaluation.libelleCourt"),
			render: (text, Record) => {
				let url = MOODLE_API_BASE_URL + "/course/view.php?id="
				url += Record.Cours._id.split('-')[1]
				return <a target={"_blank"} href={url}>{Record.Cours.libelleCourt}</a>
			}
		},
		{   //
			display: DISPLAY_BOTH,
			//
			dataIndex: 'grade',
			title: 'Note',
			className: "center",
			width: 70,
			sorter: simpleSorter("grade"),
			render: (text, Record) => {
				//
				if (Record.graded) {
					//
					if (Record.grade) {
						return <Tag>{Record.grade}</Tag>
					}
					//
					return <Tag color={"red"}>?</Tag>
					//
				}
				//
				return "-"
				//
			}
		},
		{
			//
			display: DISPLAY_BOTH,
			//
			dataIndex: 'Correcteur',
			title: 'Correcteur',
			width: 150,
			ellipsis: true,
			sorter: simpleSorter("Correcteur.fullname"),
			render: (text, Record) => (Record.Correcteur.fullname ? Record.Correcteur.fullname : "-")
		},
		{
			//
			display: DISPLAY_BOTH,
			//
			dataIndex: 'date',
			title: 'Date',
			className: "center nowrap",
			width: 150,
			sorter: simpleSorter("date"),
			render: (text, Record) => toLocalDateTime(Record.date)
		},
	
	]
	if (InscriptionSelected) {
		Columns = Columns.filter(Column => Column.display === DISPLAY_IN_DRAWER || Column.display === DISPLAY_BOTH)
	} else {
		Columns = Columns.filter(Column => Column.display === DISPLAY_BOTH || Column.display === DISPLAY_IN_DATAGRID)
	}
	/**
	 *
	 */
	return (
		<SpaceVertical>
			{/* */}
			<div className={"flex"}>
				<Space size={"middle"}>
					{
						!InscriptionSelected && <>
							<FilterByApprenant/>
							<FilterByFormationSuivie/>
							<FilterByAnneeExamen/>
						</>
					}
					<FilterByCorrecteur/>
					<FilterByGraded/>
					<FilterByFavorite/>
				</Space>
				<Space size={"middle"}>
					{ButtonResetFilters}
					{ButtonExportToXls}
				</Space>
			</div>
			{/* */}
			<Table
				dataSource={Records}
				columns={Columns}
				rowKey="_id"
				size="small"
				className={"shadow-2"}
				pagination={DEFAULT_PAGINATION}
				scroll={DEFAULT_TABLE_SCROLL}
				showSorterTooltip={false}
				_t={store._t}
			/>
			{/* */
			}
		</SpaceVertical>
	)
	
	/**
	 *
	 */
	
}))

