/**
 * crée le 17/06/2024 par  par nordine.zetoutou@agrosupdijon.fr
 */
import {ACCESS_FORMULAIRE_CONTACT_BASE_URL} from "../../config/Constantes.logic";
import {Button} from "antd";
import {BUTTON_DEFAULT_PROPS} from "../../config/Constantes.ui";
import {CONTEXT_PREPROSPECT_GERER} from "../../config/Constantes.contexts";
import {inject, observer} from "mobx-react";

export const PreProspectButtonFormulaire = inject("store")(observer(props => {
	
	const {store} = props
	const {UserStore} = store
	const Profil = UserStore.getAllowedContext(CONTEXT_PREPROSPECT_GERER)
	return Profil && Profil.isModifier && <a
		href={ACCESS_FORMULAIRE_CONTACT_BASE_URL}
		target={"_blank"}
	>
		<Button
			size={"small"}
			{...BUTTON_DEFAULT_PROPS}
			type={"dashed"}
		> Créer un contact
		</Button>
	</a>
}))
