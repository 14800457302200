/**
 *
 */
import {Alert, DatePicker, Form, Input} from 'antd';
import days from "dayjs";
import {inject, observer} from "mobx-react";
import React, {useEffect} from "react";
import {Button} from "../../../../components/antExtendedComponent/Button";
import {Notify} from "../../../../components/Notify";
import {SelectYesNo} from "../../../../components/SelectYesNo";
/**
 *
 */

/**
 *
 */
export const RenseignementsDivers = inject("store")(observer(props => {
	/**
	 *
	 */
	
	const [form] = Form.useForm();
	/**
	 *
	 */
	const {isReadOnly, Apprenant} = props
	/**
	 *
	 */
	/**
	 * devient un objet non mobx
	 * car on a besoin des id dans le formulaire si champ est un objet
	 */
	const ApprenantEdited = {...Apprenant}
	/**
	 *
	 */
	
	/**
	 * populate form
	 */
	const onFill = () => {
		if (ApprenantEdited.certificatPixDateValidite) {
			ApprenantEdited.certificatPixDateValidite = days(ApprenantEdited.certificatPixDateValidite, 'YYYY-MM-DD');//
		}
		form.setFieldsValue(ApprenantEdited);
	};
	/**
	 *
	 */
	
	useEffect(() => {
		onFill()
	})
	
	/**
	 * modifie l'objet dans le store avant enregistrement
	 */
	const onFieldsChange = (changedFields, allFields) => {
		//
		const Obj = {}
		//
		const changedField = changedFields[0]
		if (!changedField) {
			return false
		}
		const field = changedField.name[0]
		// cas d'un sous-objet
		const subField = changedField.name[1]
		//
		
		if (!subField) {
			let value = changedField.value
			switch (field) {
				case "certificatPix":
					Obj.certificatPixDateValidite = ""
					break;
				case "certificatPixDateValidite":
					value = value.format("YYYY-MM-DD")
					break;
				
			}
			Obj[field] = value
			//
		} else {
			Obj[field] = {};
			
			const Fields = allFields.filter(item => item.name[0] === field)
			Obj[field] = {}
			Fields.forEach(item => {
				const _subField = item.name[1]
				let _value = item.value
				
				switch (_subField) {
					default:
						break
					
				}
				Obj[field][_subField] = _value
			})
		}
		//
		Apprenant.update(Obj)
		//
	};
	/**
	 *
	 * @param  form values
	 * @returns {boolean}
	 */
	const onFinish = () => {
		
		form.validateFields()
			.then((values) => {
				console.log(values)
				Notify()
				Apprenant.save(succeeded => {
					Notify(succeeded)
				})
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	
	/**
	 * ButtonSave
	 */
	const ButtonSave = <div className={"center"}>
		<Button block onClick={onFinish}>Enregistrer</Button>
	</div>
	/**
	 *
	 */
	const FORM_PROPS = {
		form: form,
		disabled: isReadOnly,
		layout: "vertical",
		className: "fadein shadow-2 padding",
		style: {width: 600, margin: "auto"},
		onFieldsChange: onFieldsChange,
	}
	
	/**
	 *
	 */
	
	/**
	 *
	 */
	return (
		<>
			<Form {...FORM_PROPS} >
				
				{/*  */}
				
				<Form.Item name={"amenagementEpreuve"} label={"Demande d'aménagement d'épreuve pour l'examen"}>
					<SelectYesNo/>
				</Form.Item>
				
				{
					Apprenant.amenagementEpreuve === true && <><Alert type="warning" showIcon message={"La demande d'aménagement doit être effectuée, par vos soins, auprès du ministère de l'agriculture en téléchargeant le dossier en ligne."}/>
						{/* separateur */}
						<Form.Item/>
					</>
				}
				
				{/*  */}
				<Form.Item name={"permisDeConduire"} label={'Permis de conduire'}>
					<SelectYesNo/>
				</Form.Item>
				{/*  */}
				<Form.Item name={"sportHautNiveau"} label={'Sport de haut niveau'}>
					<SelectYesNo/>
				</Form.Item>
				{/*  */}
				<Form.Item name={"certificatPix"} label={'Avez-vous obtenu votre certificat PIX ?'}>
					<SelectYesNo/>
				</Form.Item>
				{/*  */}
				{
					Apprenant.certificatPix
					&& <Form.Item name={"certificatPixDateValidite"} label={'Quelle est sa date de fin de validité ?'}>
						<DatePicker
							inputReadOnly
							allowClear={false}
							format={'DD/MM/YYYY'}
						/>
					</Form.Item>
				}
				{/*  */}
				<Form.Item name={"commentaire"} label={'Commentaire'}>
					<Input.TextArea autoSize placeholder={'Pour saisir une nouvelle ligne, faîtes "entrée" ↩'}/>
				</Form.Item>
				<Form.Item>{ButtonSave}</Form.Item>
			</Form>
		</>
	);
}))

