/**
 *
 */
import {Col, Form, Input, Radio, Row} from 'antd';
import {inject, observer} from "mobx-react";
import React, {useEffect} from "react";
import {Button} from "../../../components/antExtendedComponent/Button";
import {Notify} from "../../../components/Notify";
import {PERIODICITE_ENVOIS} from "../../../config/Constantes.logic";
import {FORM_DEFAULT_RULE_REQUIRED, FORM_RULE_EMAIL, FORM_RULE_NUMERIC} from "../../../config/Constantes.ui";
import {SelectCivilite} from "../../apprenant/common/SelectCivilite";
import {SectionTitle} from "../../common/components/SectionTitle";

/**
 *
 */

/**
 *
 */
export const FormationSouhaiteeLangueEtablissement = inject("store")(observer(props => {
	/**
	 *
	 */
	const [form] = Form.useForm();
	const {store} = props
	const {InscriptionStore} = store
	/**
	 *
	 */
	const Inscription = InscriptionStore.SelectedRecord
	/**
	 * populate form
	 */
	useEffect(() => {
		//form.resetFields()
		const FormationSouhaiteeLangueEtablissement = Inscription.FormationSouhaiteeLangueEtablissement
		form.setFieldsValue(FormationSouhaiteeLangueEtablissement)
		
	})
	/**
	 *
	 */
	const onValuesChange = (changedValues, allValues) => {
		/*
		const field = Object.keys(changedValues)[0]
		let value = Object.values(changedValues)[0]
		 */
		Inscription.update({FormationSouhaiteeLangueEtablissement: allValues})
	}
	
	/**
	 *
	 * @param  form values
	 * @returns {boolean}
	 */
	const onFinish = () => {
		
		form.validateFields()
			.then((values) => {
				Notify()
				Inscription.save(succeeded => {
					Notify(succeeded)
				})
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	/**
	 *
	 */
	const ButtonSave = <div className={"center"}>
		<Button block onClick={onFinish}>Enregistrer</Button>
	</div>
	/**
	 *
	 */
	const ROW_GUTTER = [16, 16]
	const FORM_PROPS = {
		form: form,
		onValuesChange: onValuesChange,
		className: "fadein shadow-2 padding",
		layout: "vertical",
		//style : {width : 700, margin : "auto"},
	}
	
	return (
		<Form  {...FORM_PROPS} >
			{/* Périodicité */}
			<SectionTitle title={"Périodicité d'envoi des bulletins"}/>
			<Form.Item name={"periodicite"} label={''}>
				<Radio.Group>
					{
						Object.values(PERIODICITE_ENVOIS).map(item => <Radio key={item.value} value={item.value}>{item.label}</Radio>)
					}
				</Radio.Group>
			</Form.Item>
			{/* Établissement */}
			<SectionTitle title={"Établissement"}/>
			<Row>
				<Col span={6}>
					<Form.Item name={["Etablissement", "nom"]} label={'Nom'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<Input/>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item name={["Etablissement", "telephone"]} label={'Téléphone'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<Input/>
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<Col span={12}>
					<Form.Item name={["Etablissement", "adressePostale"]} label={'Adresse postale'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<Input/>
					</Form.Item>
				</Col>
			</Row>
			{/* Référent */}
			<SectionTitle title={"Référent"}/>
			<Row>
				<Col>
					<Form.Item name={["Referent", "civilite"]} label={'Civilité'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<SelectCivilite/>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item name={["Referent", "nom"]} label={'Nom'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<Input/>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item name={["Referent", "prenom"]} label={'Prénom'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<Input/>
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Item name={["Referent", "fonction"]} label={'Fonction'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<Input/>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item name={["Referent", "telephone"]} label={'Téléphone'} rules={FORM_RULE_NUMERIC}>
						<Input/>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item name={["Referent", "email"]} label={'Email'} rules={FORM_RULE_EMAIL}>
						<Input/>
					</Form.Item>
				</Col>
			
			</Row>
			{/*  */}
			<Form.Item></Form.Item>
			{ButtonSave}
			{/*  */}
		
		</Form>
	
	);
}))

