import {CaretRightOutlined} from "@ant-design/icons";
import {Collapse as AntCollapse} from "antd";
import React from "react";

/**
 *
 */
export const Collapse = (props) => <AntCollapse
	{...props}
	accordion={true}
	ghost
	expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
>{props.children}</AntCollapse>
/**
 *
 */
export const CollapsePanel = (props) => <AntCollapse.Panel {...props}>{props.children}</AntCollapse.Panel>
