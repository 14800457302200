/**
 *
 */
import {EditOutlined} from "@ant-design/icons";
import {Alert, Form, Input, Modal, Table, Tag, Tooltip} from 'antd';
import {inject, observer} from "mobx-react";
import React, {useState} from "react";
import {Button, ButtonIcon} from "../../../../components/antExtendedComponent/Button";
import {IconButton} from "../../../../components/IconButton";
import {Notify} from "../../../../components/Notify";
import {SelectYesNo} from "../../../../components/SelectYesNo";
import {CONTACT_CATEGORIE_VALUES} from "../../../../config/Constantes.logic";
import {COLUMN_ICON_WIDTH, FORM_DEFAULT_RULE_REQUIRED} from "../../../../config/Constantes.ui";
import {key, newId} from "../../../../helpers/Utils";
import {CreateurModificateurIcon} from "../../../common/components/CreateurModificateurIcon";
import {DestroyIcon} from "../../../common/components/DestroyIcon";
import {SelectContactCategorie} from "../../common/SelectContactCategorie";

/**
 *
 */
export const Emails = inject("store")(observer(props => {
	/**
	 *
	 */
	const {isReadOnly, store} = props
	/**
	 *
	 */
	const
		{
			UserStore,
		} = store
	/**
	 *
	 */
	
	const AuthenticatedUser = UserStore.AuthenticatedUser
	/**
	 *
	 */
	const [form] = Form.useForm();
	const [isFormVisible, setFormVisible] = useState(false);
	const Apprenant = props.Apprenant
	let Emails = Apprenant.Emails && Apprenant.Emails.toJSON()
	/**
	 *
	 */
	
	/**
	 *
	 * @param Record
	 */
	const onRecordDelete = (Record) => {
		let _Emails = Emails.filter(item => item._id !== Record._id)
		
		if (Emails.length === 0) {
			Notify(false, "Vous devez avoir au moins un email.")
		} else {
			Apprenant.update({Emails: _Emails})
			Apprenant.save(succeeded => {
				Notify(succeeded)
			})
		}
		
	};
	/**
	 *
	 */
	const edit = (Record) => {
		form.setFieldsValue(Record)
		setFormVisible(true)
	};
	/**
	 *
	 */
	const addNew = () => {
		const Record = {
			_id: newId(),
			_isNew: true,
			principal: false
		}
		form.resetFields()
		form.setFieldsValue(Record)
		setFormVisible(true)
	};
	/**
	 *
	 */
	const onFormFinish = () => {
		
		form.validateFields()
			.then((Email) => {
				//
				let _Emails = [...Emails]
				const OldEmail = _Emails.find(_Email => _Email._id === Email._id)
				const date = new Date().toISOString()
				//
				
				if (Email._isNew) {
					Email.createdAt = date
					Email.Createur = AuthenticatedUser.toJSON();
				} else {
					Email.updatedAt = date
					
					Email.Createur = OldEmail && OldEmail.Createur
					Email.Modificateur = AuthenticatedUser.toJSON()
					
					//on ne l'inclut pas dans le tableau
					_Emails = _Emails.filter(_Email => _Email._id !== Email._id)
				}
				//
				
				if (Email.principal) {
					_Emails = _Emails.map(_Email => {
						_Email = {..._Email}
						_Email.principal = false
						return _Email
					})
				}
				//
				if (_Emails.length === 0) {
					Email.principal = true
				}
				_Emails.push(Email)
				// on trie pour afficher en premier l'email principal
				_Emails = _Emails.sort((a, b) => {
					if (a.principal === false) {
						return 1;
					}
					return -1;
				});
				//
				Apprenant.update({Emails: _Emails})
				Apprenant.save(succeeded => {
					Notify(succeeded)
					form.resetFields()
					setFormVisible(false)
					
				})
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	
	/**
	 *
	 */
	let Columns = [
		{
			dataIndex: "actionEdit",
			width: COLUMN_ICON_WIDTH,
			className: "center",
			title: () => !isReadOnly && <Tooltip key={key()} title={"Créer"}>
				<Button
					shape="circle"
					type={"primary"}
					icon={<IconButton name="PageAdd"/>}
					onClick={addNew}/>
			</Tooltip>,
			render: (text, Record) => !isReadOnly && <ButtonIcon
				key={key()}
				icon={<EditOutlined/>}
				onClick={(e) => {
					edit(Record)
				}}
			/>
		},
		
		{
			dataIndex: "CreateurModificateur",
			title: "",
			width: COLUMN_ICON_WIDTH,
			className: "center",
			render: (text, Record) => <CreateurModificateurIcon Record={Record}/>
		},
		{
			dataIndex: "principal",
			title: "",
			width: 80,
			render: (text, Record) => Record.principal && <Tag>principal</Tag>
		},
		{
			dataIndex: "categorie",
			title: "Catégorie",
			width: 150,
			render: (text, Record) => (Record.categorie && CONTACT_CATEGORIE_VALUES[Record.categorie].label) || "Aucune catégorie"
		},
		
		{
			dataIndex: "email",
			title: "",
			width: 150,
			render: (text, Record) => Record.email
		},
		{
			dataIndex: '_space_',
			title: '',
		},
		{
			dataIndex: 'actionDestroy',
			title: '',
			width: COLUMN_ICON_WIDTH,
			fixed: "right",
			className: "center",
			render: (text, Record) => !isReadOnly && !Record.principal && <DestroyIcon onClick={() => {
				onRecordDelete(Record)
			}}/>
		}
	]
	/**
	 *
	 */
	const FORM_PROPS = {
		form: form,
		className: "padding",
		labelCol: {span: 8},
		wrapperCol: {span: 10},
		colon: false
		
	}
	
	const ButtonSave = <Button key={key()} onClick={onFormFinish}>Enregistrer</Button>
	const rules = [
		{
			type: "email",
			required: true,
			message: "Le format n'est pas valide",
		},
	]
	/**
	 *
	 */
	return (
		<>
			{
				!Apprenant.emailPrincipal && <Alert type={"error"} showIcon message={"Cet apprenant n'a pas d'email principal renseigné."}/>
			}
			{/*  */}
			<Table
				key={key()}
				_t={store._t}
				rowKey="_id"
				dataSource={Emails}
				columns={Columns}
				size="small"
				className={"shadow-2"}
				pagination={false}
				showSorterTooltip={false}
			
			/>
			{/*  */}
			<Modal
				key={key()}
				title={"Email"}
				centered
				width={700}
				mask={false}
				open={isFormVisible}
				footer={[ButtonSave]}
				onCancel={() => {
					setFormVisible(false)
				}}
			>
				<Form {...FORM_PROPS} >
					{/**/}
					<Form.Item name={"_isNew"} hidden>
						<Input/>
					</Form.Item>
					<Form.Item name={"_id"} hidden>
						<Input/>
					</Form.Item>
					<Form.Item name={"categorie"} label={'Catégorie'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<SelectContactCategorie/>
					</Form.Item>
					<Form.Item name={"email"} label={'Email'} rules={rules}>
						<Input autoComplete='off'/>
					</Form.Item>
					<Form.Item name={"principal"} label={'Principal'}>
						<SelectYesNo/>
					</Form.Item>
					{/*  */}
				
				</Form>
			</Modal>
			{/*  */}
		</>
	);
}))
