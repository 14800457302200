import {DeleteOutlined} from "@ant-design/icons";
import {Popconfirm, Tooltip} from "antd";
import React from "react";
/**
 *
 */
import {COLOR_RED} from "../../../config/Constantes.ui";

/**
 * IconTrash s
 */
export const DestroyIcon = (props) => {
	//
	//
	let style = {color: COLOR_RED, cursor: "pointer"}
	return <Tooltip title={"Supprimer"} placement="left">
		<Popconfirm
			title="Attention"
			description="Voulez-vous supprimer cet enregistrement ?"
			onConfirm={props.onClick}
			okText="Oui"
			cancelText="Non"
		>
			<DeleteOutlined
				{...props}
				style={style}
				onClick={(e) => {
					e.stopPropagation();
				}}
			/></Popconfirm>
	</Tooltip>
}

