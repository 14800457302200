import {Modal} from "antd";
import {inject, observer} from "mobx-react";
import React from "react";
import {Button} from "../../../../../components/antExtendedComponent/Button";
import {key} from "../../../../../helpers/Utils";
import {DRAWERS_NAMES} from "../../../../../stores/NavigationStore";
import {closeDrawer, openDrawer} from "../../../../common/components/drawerManager";
import Photo from "./PhotoUpload";

/**
 *
 */
export const PhotoForm = inject("store")(observer(props => {
	
	const {store, Apprenant} = props
	const {NavigationStore} = store;
	const drawerName = DRAWERS_NAMES.PHOTO_FORM_DRAWER
	return <>
		<Button
			type={"primary"}
			onClick={(e) => {
				openDrawer(drawerName)
				
			}}>1. Choisir une photo</Button>
		<Modal
			_t={store._t}
			title={"Ajout d'une Photo"}
			centered
			closable={false}
			width={700}
			open={NavigationStore.isDrawerVisible(drawerName)}
			footer={[
				<Button key={key()} onClick={() => closeDrawer(drawerName)}>
					Fermer
				</Button>,
			
			]}
			onCancel={() => closeDrawer(drawerName)}
		>
			<Photo Apprenant={Apprenant}/>
		</Modal>
	</>
	
}))
