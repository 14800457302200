import {Row, Space, Switch, Table, Tooltip} from "antd";
import days from "dayjs";
import {inject, observer} from "mobx-react";
import React from "react";

import {SpaceVertical} from "../../components/antExtendedComponent/Space";
import {BadgeCount} from "../../components/BadgeCount";
import {InputSearch} from "../../components/InputSearch";
import {FILTER_BY_FAVORITES, FILTER_BY_ROLE, FILTER_BY_WORDS} from "../../config/Constantes.logic";
import {DEFAULT_PAGINATION, DEFAULT_TABLE_SCROLL} from "../../config/Constantes.ui";

import {simpleSorter} from "../../helpers/Utils";
import {Notify} from "../../components/Notify";
import {CONTEXT_USER_GERER} from "../../config/Constantes.contexts";

;
/**
 *
 */

/**
 *
 */

export default inject("store")(observer(class Users extends React.Component {
	/**
	 *
	 */
	/**
	 * constantes de filtres des formations ( se trouve dans le store formation )
	 */
	
	Filters = {
		FILTER_BY_WORDS: {name: FILTER_BY_WORDS, value: ""},
		FILTER_BY_TYPE: {name: FILTER_BY_ROLE, value: null},
		FILTER_BY_FAVORITES: {name: FILTER_BY_FAVORITES, value: null},
		//FILTER_BY_FAMILLE : {name : FILTER_BY_FAMILLE, value : null},
		//FILTER_BY_INTITULE : {name : FILTER_BY_INTITULE, value : null},
		//FILTER_BY_EXAMEN_ANNEE : {name : FILTER_BY_EXAMEN_ANNEE, value : null}
	}
	
	/**
	 *
	 */
	constructor(props) {
		super(props);
		
		//const {Filters} = this.initUserSettings()
		this.state = {
			Filters: this.Filters,
			//	EditedRecord : {}
		}
		
	}
	
	initUserSettings = () => {
		let UserSettings = (localStorage.UserSettings ? JSON.parse(localStorage.UserSettings) : {})
		let Filters = UserSettings.Filters || {}
		//let UserColumns = UserSettings.UserColumns || []
		return {Filters}
		
	}
	/**
	 *
	 * @param Setting
	 */
	storeUserSetting = (Setting) => {
		this.props.store.UserStore.storeUserSetting(Setting)
	}
	addUserSetting = (key, value) => {
		this.props.store.UserStore.addUserSetting(key, value)
	}
	/**
	 *
	 * @param Filter
	 */
	setFilter = (Filter) => {
		let {Filters} = this.state
		const name = Filter.name
		const value = Filter.value
		if (!value) {
			delete Filters[name]
		} else {
			Filters[name] = {name: name, value: value}
		}
		this.setState({Filters: Filters}, () => {
			//this.storeUserSetting({Filters : Filters})
		})
	}
	
	/**
	 *
	 */
	render() {
		
		const {Filters} = this.state
		const {store} = this.props
		const {UserStore} = store
		const Users = UserStore.getAllByFilters(this.state.Filters)
		/**
		 *
		 */
		
		/**
		 * FilterByWords
		 */
		const FilterByWords = (
			<InputSearch
				value={Filters.FILTER_BY_WORDS && Filters.FILTER_BY_WORDS.value}
				placeholder="Nom ou prénom"
				onChange={event => {
					const Filter = this.Filters[FILTER_BY_WORDS]
					Filter.value = event.target.value
					this.setFilter(Filter)
				}}
			/>
		)
		/**
		 *
		 */
		const Profil = UserStore.getAllowedContext(CONTEXT_USER_GERER)
		let Columns = [
			{
				
				dataIndex: "active",
				title: "",
				width: 60,
				fixed: "left",
				className: "center",
				render: (text, Record) => <Switch
					defaultChecked={Record.active}
					size="small"
					disabled={!(Profil && Profil.isModifier === true)}
					onChange={value => {
						Record.edit()
						Record.update({active: value})
						Notify()
						Record.save(successOrFailed => Notify(successOrFailed))
						
					}}
				
				/>
			},
			{
				dataIndex: "fullname",
				title: "Nom et prénom",
				sorter: simpleSorter("fullname"),
				ellipsis: true,
				width: 200,
				fixed: "left",
				render: (text, Record) => <Tooltip title={Record.email}>{Record.fullname}</Tooltip>
			},
			
			{
				dataIndex: "roleTags",
				title: "Rôles ",
				className: "overflow",
				width: 200,
				sorter: simpleSorter("roles"),
			},
			{
				dataIndex: "service",
				title: "Service",
				ellipsis: true,
				width: 200,
				
			},
			{
				dataIndex: "lastUse",
				title: "Dernière utilisation",
				className: "center",
				fixed: "right",
				width: 200,
				sorter: simpleSorter("lastUse"),
				render: (text) => (text && days(text).format('ddd DD/MM/YYYY à HH:mm'))
				
			},
		]
		/**
		 *
		 */
		return (
			<SpaceVertical>
				{/* */}
				<Row>
					<Space style={{flex: 1}}>
						<BadgeCount total={Users.length}/>
						{FilterByWords}
						{/*FilterByFavorites*/}
					
					</Space>
					<Space>
					</Space>
				</Row>
				{/* */}
				<Table
					showSorterTooltip={false}
					rowKey="_id"
					size="small"
					pagination={DEFAULT_PAGINATION}
					scroll={DEFAULT_TABLE_SCROLL}
					dataSource={Users}
					columns={Columns}
					t={store._t}
				
				/>
				{/* */}
			</SpaceVertical>
		
		);
	}
}))
