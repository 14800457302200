import {inject, observer} from "mobx-react";
import React from "react";
/**
 *
 */
import {Checkbox, Table} from "antd";
import {COLUMN_ICON_WIDTH, DEFAULT_PAGINATION, DEFAULT_TABLE_SCROLL, DISPLAY_BOTH, DISPLAY_IN_DATAGRID, DISPLAY_IN_DRAWER} from "../../../config/Constantes.ui";
import {DestroyButton} from "../../common/components/DestroyButton";
import {Notify} from "../../../components/Notify";
import {DRAWERS_NAMES} from "../../../stores/NavigationStore";
import {openDrawer} from "../../common/components/drawerManager";
import {TAB_KEY_CLASSE_VIRTUELLE} from "../VieApprenants";
import {simpleSorter} from "../../../helpers/Utils";

/**
 *
 */

export const ClasseVirtuelles = inject("store")(observer(props => {
	
	//
	const {InscriptionSelected, store} = props
	const {ApprenantStore, InscriptionStore, InscriptionClasseVirtuelleStore} = store
	let Records = []
	if (InscriptionSelected) {
		Records = InscriptionClasseVirtuelleStore.getByInscription(InscriptionSelected._id)
	} else {
		Records = InscriptionClasseVirtuelleStore.getAll()
	}
	//
	const onChange = (value, Record) => {
		
		Notify()
		Record.update({participation: value})
		Record.save((succeed) => {
			Notify(succeed)
		})
	}
	const openVieApprenanteDrawer = (Inscription) => {
		InscriptionStore.setSelectedRecord(Inscription)
		const maquetteDrawerName = DRAWERS_NAMES.MAQUETTE_FORM_DRAWER
		ApprenantStore.setVieApprenantePageTabsActiveKey(TAB_KEY_CLASSE_VIRTUELLE)
		openDrawer(maquetteDrawerName)
	};
	//
	let Columns = [
		{
			//
			display: DISPLAY_IN_DATAGRID,
			//
			dataIndex: 'Inscription.apprenantFullname',
			title: 'Apprenant',
			width: 150,
			ellipsis: true,
			fixed: "left",
			sorter: simpleSorter("Inscription.apprenantFullname"),
			render: (text, Record) =>
				<a href="/" onClick={(e) => {
					e.preventDefault();
					openVieApprenanteDrawer(Record.Inscription)
				}}>{Record.Inscription.apprenantFullname}
				</a>
		},
		{
			
			//
			display: DISPLAY_IN_DATAGRID,
			//
			dataIndex: 'Inscription.numeroTag',
			title: 'Insc.',
			width: 70,
			className: "center",
			fixed: "left",
			sorter: simpleSorter("Inscription.numero"),
			render: (text, Record) => Record.Inscription.numeroTag
		},
		{
			//
			display: DISPLAY_BOTH,
			//
			dataIndex: 'Inscription._FormationSuivie.libelleCourt',
			title: 'Formation suivie',
			width: 150,
			ellipsis: true,
			sorter: simpleSorter("Inscription._FormationSuivielibelleCourt"),
			render: (text, Record) => Record.Inscription._FormationSuivielibelleCourt
		},
		{
			//
			display: DISPLAY_BOTH,
			//
			dataIndex: 'Inscription._FormationSuivie.anneeExamen',
			title: 'Examen',
			width: 90,
			className: "center",
			sorter: simpleSorter("Inscription._FormationSuivieAnneeExamen"),
			render: (text, Record) => Record.Inscription._FormationSuivieAnneeExamenTag
		},
		{
			//
			display: DISPLAY_BOTH,
			//
			dataIndex: 'ClasseVirtuelle.libelle',
			title: 'Classe virtuelle',
			ellipsis: true,
			width: 150,
			sorter: simpleSorter("ClasseVirtuelle.libelle"),
			render: (text, Record) => Record.ClasseVirtuelle.libelle
		},
		{
			//
			display: DISPLAY_BOTH,
			//
			dataIndex: 'participation',
			title: 'A participé',
			width: 110,
			className: "center",
			sorter: simpleSorter("participation"),
			render: (text, Record) => {
				
				if (InscriptionSelected) {
					return <Checkbox
						checked={Record.participation}
						onChange={(e) => onChange(e.target.checked, Record)}
					/>
				}
				return Record.participationTag
			}
			
		},
		{
			//
			display: DISPLAY_IN_DRAWER,
			//
			dataIndex: 'actionDestroy',
			title: '',
			width: COLUMN_ICON_WIDTH,
			fixed: "right",
			className: "center",
			render: (text, Record) => <DestroyButton record={Record}/>
			
		}
	]
	if (InscriptionSelected) {
		Columns = Columns.filter(Column => Column.display === DISPLAY_IN_DRAWER || Column.display === DISPLAY_BOTH)
	} else {
		Columns = Columns.filter(Column => Column.display === DISPLAY_BOTH || Column.display === DISPLAY_IN_DATAGRID)
	}
	/**
	 *
	 */
	return (
		<Table
			rowKey="_id"
			dataSource={Records}
			columns={Columns}
			size="small"
			className={"shadow-2"}
			pagination={DEFAULT_PAGINATION}
			scroll={DEFAULT_TABLE_SCROLL}
			showSorterTooltip={false}
			_t={store._t}
		/>
	
	)
	
	/**
	 *
	 */
	
}))

