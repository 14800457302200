/**
 *
 */
import {Alert, Checkbox, Form, Input, Select, Space, Tag} from 'antd';
import days from "dayjs";

import {inject, observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Button} from "../../../components/antExtendedComponent/Button";
import {Notify} from "../../../components/Notify";
import {
	APPRENANT_STATUT_INSCRIT,
	APPRENANT_STATUT_PREINSCRIT,
	INSCRIPTION_STATUT_VALIDATION_ADMINISTRATIVE_ACCEPTE,
	INSCRIPTION_STATUT_VALIDATION_ADMINISTRATIVE_REFUSE,
	MESSAGE_5_1,
	MESSAGE_5_2,
	VALIDATION_ADMINISTRATIVE_STATUT_ACCEPTE,
	VALIDATION_ADMINISTRATIVE_STATUT_REFUSE,
	VALIDATION_ADMINISTRATIVE_STATUTS
} from "../../../config/Constantes.logic";
import {MICRO_SERVICE_URLS} from "../../../config/Constantes.urls";
import microService from "../../../helpers/MicroService";
import {key, toLocalDateTime} from "../../../helpers/Utils";

;
/**
 *
 */

/**
 *
 */
export const ValidationAdministrative = inject("store")(observer(props => {
	/**
	 *
	 */
	const {isReadOnly, store} = props
	const [form] = Form.useForm();
	const [formationContinueFinanceeLibelle, setFormationContinueFinanceeLibelle] = useState("")
	/**
	 *
	 */
	
	/**
	 *
	 */
	const
		{
			UserStore,
			InscriptionStore,
			ReferentielSourceFinancementStore
		} = store
	
	/**
	 *
	 */
	
	const ReferentielSourceFinancements = ReferentielSourceFinancementStore.getAll()
	const AuthenticatedUser = UserStore.AuthenticatedUser
	const Inscription = InscriptionStore.SelectedRecord
	
	//
	/**
	 *
	 */
	
	/**
	 * populate form
	 */
	
	/**
	 *
	 */
	useEffect(() => {
		
		if (Inscription.FormationContinueFinancee) {
			const FinancementSource = ReferentielSourceFinancements.find(item => item._id === Inscription.FormationContinueFinancee.FinancementSource)
			if (FinancementSource) {
				setFormationContinueFinanceeLibelle(FinancementSource.libelle)//
			}
		}
		//form.resetFields()
		form.setFieldsValue(Inscription);
	})
	
	/**
	 * modifie l'objet dans le store avant enregistrement
	 */
	const onFieldsChange = (changedFields, allFields) => {
		//
		
		const Obj = {}
		//
		const changedField = changedFields[0]
		if (!changedField) {
			return false
		}
		const field = changedField.name[0]
		// cas d'un sous-objet
		const subField = changedField.name[1]
		//
		if (!subField) {
			Obj[field] = changedField.value;
		} else {
			Obj[field] = {};
			const Fields = allFields.filter(item => item.name[0] === field)
			Obj[field] = {}
			Fields.forEach(item => {
				const _subField = item.name[1]
				let _value = item.value
				Obj[field][_subField] = _value
				switch (_subField) {
					default:
						break
					case "statut":
						//
						if (_value === VALIDATION_ADMINISTRATIVE_STATUT_ACCEPTE) {
							Obj["statutApprenant"] = APPRENANT_STATUT_INSCRIT
							Obj["statutInscription"] = INSCRIPTION_STATUT_VALIDATION_ADMINISTRATIVE_ACCEPTE
							
						}
						//
						if (_value === VALIDATION_ADMINISTRATIVE_STATUT_REFUSE) {
							Obj["statutApprenant"] = APPRENANT_STATUT_PREINSCRIT
							Obj["statutInscription"] = INSCRIPTION_STATUT_VALIDATION_ADMINISTRATIVE_REFUSE
							
						}
						break
				}
				
				Obj[field]["Agent"] = AuthenticatedUser.toJSON()
				Obj[field]["date"] = days().format("YYYY-MM-DD HH:mm:ss")
				
			})
			//
		}
		console.log(Obj)
		Inscription.update(Obj)
	};
	/**
	 *
	 * @param  form values
	 * @returns {boolean}
	 */
	const onFinish = () => {
		
		form.validateFields()
			.then((values) => {
				
				Notify()
				Inscription.save(succeeded => {
					Notify(succeeded)
					if (succeeded === true) {
						const ValidationAdministrative = Inscription.ValidationAdministrative
						if (ValidationAdministrative) {
							let data = {}
							switch (ValidationAdministrative.statut) {
								case VALIDATION_ADMINISTRATIVE_STATUT_ACCEPTE:
									data.action = MESSAGE_5_1
									break
								case VALIDATION_ADMINISTRATIVE_STATUT_REFUSE:
									data.action = MESSAGE_5_2
									break
							}
							//
							data.To = [{
								Email: Inscription.Apprenant.emailPrincipal,
								Name: Inscription.Apprenant.fullname
							}]
							microService.post(MICRO_SERVICE_URLS.SEND_MAIL, data, (Response => {
							}))
						}
						
					}
				})
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	/**
	 *
	 */
	const ButtonSave = <div className={"center"}>
		<Button block onClick={onFinish}>Enregistrer</Button>
	</div>
	/**
	 *
	 */
	const SourceFinancementView = () => {
		const FormationContinueFinancee = Inscription.FormationContinueFinancee
		if (!FormationContinueFinancee.financementAccordeDate) {
			return <Tag>Aucune</Tag>
		}
		const FinancementSource = ReferentielSourceFinancements.find(item => item._id === FormationContinueFinancee.FinancementSource)
		const date = days(FormationContinueFinancee.financementAccordeDate).format("DD/MM/YYYY")
		return <Space>
			{FormationContinueFinancee.financementAccorde && <Tag color={"green"}>{"Accordé le " + date}</Tag>}
			{!FormationContinueFinancee.financementAccorde && <Tag color={"red"}>{"Refusé le " + date}</Tag>}
			{FinancementSource && <Tag>{FinancementSource.libelle}</Tag>}
		</Space>
		
	}
	/**
	 *
	 */
	
	let MessageView = ""
	let message = ""
	const ValidationAdministrative = Inscription.ValidationAdministrative
	let agent = ValidationAdministrative && ValidationAdministrative.Agent && ValidationAdministrative.Agent.fullname
	const date = toLocalDateTime(ValidationAdministrative.date)
	if (ValidationAdministrative.statut === VALIDATION_ADMINISTRATIVE_STATUT_ACCEPTE) {
		message = "Dossier accepté le " + date + " par " + agent
		MessageView = <Alert showIcon type={"success"} message={message}/>
		
	}
	if (ValidationAdministrative.statut === VALIDATION_ADMINISTRATIVE_STATUT_REFUSE) {
		message = "Dossier refusé le " + date + " par " + agent
		MessageView = <Alert showIcon type={"error"} message={message}/>
	}
	//
	const FORM_PROPS = {
		form: form,
		disabled: isReadOnly,
		onFieldsChange: onFieldsChange,
		colon: false,
		layout: "vertical",
		className: "fadein shadow-2 padding",
		style: {width: 600, margin: "auto"},
	}
	
	return (
		
		<Form  {...FORM_PROPS}>
			{MessageView}
			<br/>
			<Form.Item name={["ValidationAdministrative", "statut"]} label={"Statut du dossier"}>
				<Select style={{width: 200}}>
					{
						Object.values(VALIDATION_ADMINISTRATIVE_STATUTS).map(item =>
							<Select.Option key={key()} value={item.value}>
								{item.tag}
							</Select.Option>
						)
					}
				</Select>
			</Form.Item>
			{/*  */}
			<Form.Item label={'Source de financement'}>
				<SourceFinancementView/>
			</Form.Item>
			{/*  */}
			<Form.Item name={["ValidationAdministrative", "boursier"]} valuePropName="checked" label={""}>
				<Checkbox>Boursier</Checkbox>
			</Form.Item>
			{/*  */}
			<Form.Item name={["ValidationAdministrative", "commentaire"]} label={'Commentaire'}>
				<Input.TextArea/>
			</Form.Item>
			{/*  */}
			<Form.Item></Form.Item>
			{ButtonSave}
			{/*  */}
		
		</Form>
	
	);
}))

