import {Badge} from "antd";
import React from "react";

/**
 *
 * CloseIcon
 *
 */
export const BadgeCount = (props) => {
	const {total} = props
	
	return <Badge
		{...props}
		showZero
		overflowCount={total + 1}
		count={total}
		className="ant-badge-default"
		style={{minWidth: 30}}
	>
		{props.children}
	</Badge>
	
}
/**
 *
 *
 *
 */
