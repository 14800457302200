import axios from "axios";
import {values} from "mobx";
import {applySnapshot, getParent, types} from "mobx-state-tree";
import {MICRO_SERVICE_URLS} from "../config/Constantes.urls";
/**
 *
 */
import MicroService from "../helpers/MicroService";
import alasql from "alasql";
import days from "dayjs";

/**
 * model
 */

const CURRENT_MICRO_SERVICE_URL = MICRO_SERVICE_URLS.REFERENTIEL_PUBLIPOSTAGE_TEMPLATE
/**
 *
 */
export const ReferentielPubliPostageTemplateModel = types
	.model({
		_id: types.identifier,
		anneeCreation: types.maybeNull(types.integer),
		originalName: types.optional(types.string, ""),
		name: types.optional(types.string, ""),
		type: types.optional(types.string, ""),
		extension: types.optional(types.string, ""),
		size: 0,
		/**
		 * content : existe dans la base on ne l'alimente pas côté client car trop lourd et non utile
		 * on le récupère lors d'un téléchargement du fichier
		 */
		Createur: types.frozen({
			_id: "",
			fullname: "",
		}),
		updatedAt: types.maybeNull(types.string, ""),
	})
	.views((self) => ({
		get isDeletable() {
			return true
		},
		get _updatedAt() {
			return days(self.updatedAt).format('ddd DD/MM/YYYY à HH:mm:ss')
		},
	}))
	.actions((self) => ({
		update(EditedRecord) {
			applySnapshot(self, Object.assign(self, EditedRecord));
		},
		/**
		 *
		 * @param callback
		 */
		destroy(callback) {
			MicroService.delete(CURRENT_MICRO_SERVICE_URL + "/" + self._id, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					getParent(self, 2).removeFromStore(self._id)
				}
				callback(succeeded)
				
			})
			
		}
	}));
/**
 * store
 */
export const ReferentielPubliPostageTemplateStore = types
	.model({
		ReferentielPubliPostageTemplates: types.map(ReferentielPubliPostageTemplateModel),
	})
	.actions((self) => ({
		
		/**
		 *
		 * @param newRecord
		 * @param callback
		 * @returns {boolean}
		 */
		create(formData, callback) {
			axios.post(CURRENT_MICRO_SERVICE_URL, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				}
				
			}).then(Response => {
				const data = Response.data
				const succeeded = data.OPERATION_SUCCEED
				if (succeeded === true) {
					let Record = data.Record
					self.removeFromStore(Record)
					self.addToStore(Record)
					
				}
				callback(succeeded)
			}).catch(error => {
				console.log(error)
			})
			
		},
		/**
		 *
		 */
		removeFromStore(_id) {
			self.ReferentielPubliPostageTemplates.delete(_id)
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 */
		addToStore(Record) {
			self.ReferentielPubliPostageTemplates.set(Record._id, Record)
			getParent(self, 1).notifyUIChanges()
		}
		
	}))
	.views((self) => ({
		getAll() {
			const Records = values(self.ReferentielPubliPostageTemplates)
			const query = "SELECT * FROM ? ORDER BY name"
			const Rows = alasql(query, [Records]);
			return Rows.map(Row => self.ReferentielPubliPostageTemplates.get(Row._id))
		},
		
	}));

