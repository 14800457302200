/**
 *
 */
import {LinkOutlined, SolutionOutlined} from "@ant-design/icons";
import {Table, Tooltip} from "antd";
import {inject, observer} from "mobx-react";
import React from "react";
/**
 *
 */
import {ACCESS_FORMULAIRE_CONTACT_BASE_URL, ACCESS_PLATEFORME_BASE_URL, PREPROSPECT_DEMANDE_ENVOYEE, PREPROSPECT_DEMANDE_NON_ENVOYEE} from "../../config/Constantes.logic";
import {COLUMN_ICON_WIDTH, DEFAULT_PAGINATION, DEFAULT_TABLE_SCROLL} from "../../config/Constantes.ui";
import {simpleSorter} from "../../helpers/Utils";
import {DestroyButton} from "../common/components/DestroyButton";
import {openDrawer} from "../common/components/drawerManager";
import {DRAWERS_NAMES} from "./../../stores/NavigationStore";
import {PreProspect} from "./PreProspect";
import {PreProspectButtonFormulaire} from "./PreProspectButtonFormulaire";
import {CONTEXT_PREPROSPECT_GERER} from "../../config/Constantes.contexts";

/**
 *
 */

export const PreProspects = inject("store")(observer(props => {
	
	const {store} = props
	const {PreProspectStore, UserStore} = store
	const Records = PreProspectStore.getAll()
	/**
	 *
	 */
	const drawerName = DRAWERS_NAMES.PREPROSPECT_FORM_DRAWER
	const edit = (PreProspect) => {
		PreProspect.edit()
		openDrawer(drawerName)
	}
	/**
	 *
	 */
	const Profil = UserStore.getAllowedContext(CONTEXT_PREPROSPECT_GERER)
	const Columns = [
		/**
		 * fullname
		 */
		{
			dataIndex: "fullname",
			title: <PreProspectButtonFormulaire _t={store._t}/>,
			width: 220,
			fixed: "left",
			ellipsis: true,
			sorter: simpleSorter("fullname"),
			render: (text, Record) => <a href={"/"} onClick={(e) => {
				e.preventDefault();
				edit(Record)
			}}>{text}</a>
		},
		/**
		 * statut
		 */
		{
			dataIndex: "statutTag",
			title: "Statut",
			width: 150,
			className: "center",
			sorter: simpleSorter("statut"),
			render: (text, Record) => Record.statutTag
		},
		/**
		 * dateNaissanceFormated
		 */
		{
			dataIndex: "_dateNaissance",
			title: 'Né le',
			className: "center",
			width: 100,
			ellipsis: true,
			sorter: simpleSorter("dateNaissance"),
		},
		/**
		 *
		 */
		{
			dataIndex: "telephone",
			title: 'Téléphone',
			width: 100,
			ellipsis: true
		},
		/**
		 *
		 */
		{
			dataIndex: "email",
			title: 'Email',
			width: 200,
			ellipsis: true,
		},
		/**
		 *
		 */
		{
			dataIndex: "_nationalite",
			title: 'Nationalité',
			width: 180,
			ellipsis: true,
			sorter: simpleSorter("_nationalite"),
		},
		/**
		 *
		 */
		{
			dataIndex: "_createdAt",
			title: 'Crée le',
			className: "center",
			width: 170,
			sorter: simpleSorter("createdAt"),
			render: (text, Record) => Record._createdAt
		},
		/**
		 *
		 */
		{
			dataIndex: "_updatedAt",
			title: 'Modifié le',
			fixed: "right",
			className: "center",
			width: 170,
			sorter: simpleSorter("updatedAt"),
			render: (text, Record) => Record._updatedAt
		},
		/**
		 * fullname
		 */
		{
			dataIndex: "lienConnection",
			title: <Tooltip title={"Lien de connection à la plateforme"}><LinkOutlined/></Tooltip>,
			width: COLUMN_ICON_WIDTH,
			fixed: "right",
			className: "center",
			ellipsis: true,
			render: (text, Record) => Record.statut === PREPROSPECT_DEMANDE_ENVOYEE
				&& <Tooltip title={"Lien de connexion temporaire à la plateforme de " + Record.fullname}>
					<a target={"_blank"} href={ACCESS_PLATEFORME_BASE_URL + "&sid=" + Record.sid}>
						<LinkOutlined/>
					</a>
				</Tooltip>
		},
		/**
		 *
		 */
		{
			dataIndex: "lienFormulaire",
			title: <Tooltip title={"Formulaire de contact"}><SolutionOutlined/></Tooltip>,
			width: COLUMN_ICON_WIDTH,
			fixed: "right",
			className: "center",
			ellipsis: true,
			render: (text, Record) => {
				if (Record.statut === PREPROSPECT_DEMANDE_ENVOYEE) {
					return
				}
				if (Profil && Profil.isModifier) {
					const href = ACCESS_FORMULAIRE_CONTACT_BASE_URL + "&PreProspect=" + Record._id
					return <Tooltip title={"Voir le formulaire de " + Record.fullname}>
						<a target={"_blank"} href={href}>
							<SolutionOutlined/>
						</a>
					</Tooltip>
				}
				
			}
		},
		/**
		 *
		 */
		{
			dataIndex: 'actionDestroy',
			title: '',
			width: COLUMN_ICON_WIDTH,
			fixed: "right",
			className: "center",
			sorter: simpleSorter("statut"),
			render: (text, Record) => {
				if (Profil && Profil.isModifier && Record.statut === PREPROSPECT_DEMANDE_NON_ENVOYEE) {
					return <DestroyButton record={Record}/>
				}
				
			}
			
		}
		/**
		 *
		 */
	
	]
	return (
		<>
			{/* */}
			<PreProspect/>
			{/* */}
			{/*
				<Row gutter={[16, 16]}>
					<Space style={{flex : 1}} size="middle">
						<BadgeCount total={Records.length}/>
						{FilterByWords}
						{FilterByFavorites}
					</Space>
					<Space size="middle">
					
					</Space>
				</Row>
			*/}
			{/*  */}
			
			
			<br/>
			<Table
				id={"pre-prospect-table"}
				size={"small"}
				className={"shadow-2"}
				dataSource={Records}
				columns={Columns}
				rowKey="_id"
				pagination={DEFAULT_PAGINATION}
				scroll={DEFAULT_TABLE_SCROLL}
				showSorterTooltip={false}
				_t={store._t}
			/>
		
		</>
	);
}))
/**
 *
 */
