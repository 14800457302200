/**
 *
 */
import {Alert, Checkbox, Space} from 'antd';
import axios from "axios";

import React, {useState} from "react";
import {Button} from "../../../components/antExtendedComponent/Button";
import {SpaceVertical} from "../../../components/antExtendedComponent/Space";
import {Notify} from "../../../components/Notify";
import {ACCESS_FORMULAIRE_CONTACT_BASE_URL, PREPROSPECT_DEMANDE_ENVOYEE, PREPROSPECT_DEMANDE_STATUTS} from "../../../config/Constantes.logic";
import {MICRO_SERVICE_URLS} from "../../../config/Constantes.urls";
import {STEP_2} from "../PreProspectRegistration";

/**
 *
 */
export const Step_3 = (props => {
	/**
	 *
	 */
	const {PreProspect, setPreProspect, setCurrentStep, currentStep, setFormDisabled} = props
	const [disabledRgpdCheckbox, setDisabledRgpdCheckbox] = useState(true)
	/**
	 *
	 */
	const onFinish = () => {
		//
		let Record = {...PreProspect}
		Record.statut = PREPROSPECT_DEMANDE_ENVOYEE
		Record.step = currentStep
		Record.rgpdAccepte = true
		//
		Notify()
		//
		const url = MICRO_SERVICE_URLS.PRE_PROSPECT + "/" + PreProspect._id
		//
		axios
			.put(url, {Record: Record})
			.then(response => {
				//
				const Response = response.data
				const Record = Response.Record
				setPreProspect(Record)
				setDisabledRgpdCheckbox(true)
				setFormDisabled(Record.rgpdAccepte)
				//
				Notify(true)
				//
			})
			.catch(error => Notify(false, error.message))
		
	}
	/**
	 * ButtonSave et ButtonPrev
	 */
	const Buttons = <Space className={"flex"}>
		{/*  */}
		<Button disabled={false} onClick={event => {
			event.stopPropagation();
			setCurrentStep(STEP_2)
		}}
		>Retour
		</Button>
		{/*  */}
		
		{/*  */}
		{
			<Button disabled={disabledRgpdCheckbox} danger onClick={event => {
				onFinish()
			}}
			>J'envoie ma demande
			</Button>
			
		}
	
	</Space>
	/**
	 *
	 */
	const MessageProps = PREPROSPECT_DEMANDE_STATUTS[PreProspect.statut]
	const Message = MessageProps && <Alert showIcon {...MessageProps} />
	/**
	 *
	 */
	const RgpdCheckbox = () => {
		
		const message = "En cochant cette case et en soumettant ce formulaire, j'accepte que mes données personnelles soient utilisées pour me recontacter dans le cadre de ma demande indiquée dans ce formulaire. Aucun autre traitement ne sera effectué avec mes informations."
		
		let props = {}
		if (PreProspect.rgpdAccepte) {
			props.disabled = PreProspect.rgpdAccepte
			props.checked = PreProspect.rgpdAccepte
		} else {
			props.disabled = !disabledRgpdCheckbox
			props.checked = !disabledRgpdCheckbox
		}
		
		props.onChange = (value) => {
			setDisabledRgpdCheckbox(!disabledRgpdCheckbox)
		}
		return <Checkbox  {...props}>{message}</Checkbox>
		
	}
	/**
	 *
	 */
	const accessPlateformeUrl = PreProspect.accessPlateformeBaseUrl + "&sid=" + PreProspect.sid
	return (
		<SpaceVertical className={"shadow-1 padding"}>
			
			{/* */}
			{
				PreProspect.inscriptionExists &&
				<Alert
					showIcon
					type="warning"
					message={<Space>
						<span>Cette demande a déjà été envoyée.</span>
						<a href={ACCESS_FORMULAIRE_CONTACT_BASE_URL}>
							<Button size={"small"}>Nouvelle demande</Button>
						</a>
						<a href={accessPlateformeUrl}>
							<Button size={"small"}>Accès à la plateforme</Button>
						</a>
					</Space>}>
				</Alert>
				
			}
			
			{
				!PreProspect.inscriptionExists && Message
			}
			
			{/*  */}
			<RgpdCheckbox/>
			{/*  */}
			{Buttons}
			{/*  */}
		
		</SpaceVertical>
	
	);
})

