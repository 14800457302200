import {Radio, Tag} from "antd";
import React from "react";

/**
 *
 * SelectPays
 *
 */
export const YES_NO_LABEL = {
	true: "Oui",
	false: "Non"
}
export const YES_NO_LABEL_TAG = {
	true: <Tag>Oui</Tag>,
	false: <Tag color={"red"}>Non</Tag>
}
// direction="vertical"
export const SelectYesNo = props => {
	return <Radio.Group {...props} >
		{
			Object.keys(YES_NO_LABEL).map(key =>
				<Radio key={key} value={(key === "true" ? true : false)}>{YES_NO_LABEL[key]}</Radio>
			)
		}
	</Radio.Group>
}

/**
 *
 *
 *
 */
