/**
 *
 */
import {Popover} from "antd";
import React from "react";
import {IconInfo} from "../../../components/IconInfo";
import {COLOR_PRIMARY} from "../../../config/Constantes.ui";
/**
 *
 */
import {toLocalDateTime} from "../../../helpers/Utils";

/**
 *
 */

export const CreateurModificateurIcon = props => {
	//
	const {Record, placement} = props
	const Createur = Record && Record.Createur
	const Modificateur = Record && Record.Modificateur
	const Content = () => {
		//
		let contentCreateur = ""
		if (Record.createdAt) {
			contentCreateur = "Créé par " + Record.Createur.fullname + " le " + toLocalDateTime(Record.createdAt)
		}
		
		//
		let contentModificateur = ""
		if (Record.updatedAt) {
			contentModificateur = "Modifié par " + Record.Modificateur.fullname + " le " + toLocalDateTime(Record.updatedAt)
		}
		return <div>
			<div>{contentCreateur}</div>
			<div>{contentModificateur}</div>
		</div>
	}
	//
	return (Createur || Modificateur) && <Popover content={<Content/>} placement={placement || "right"}>
		<IconInfo name={"UserOptional"} style={{color: COLOR_PRIMARY}}/>
	</Popover>
	
}
