/**
 *
 */
import React from "react";
import {SectionTitle} from "../../../common/components/SectionTitle";
import {Emails} from "./Emails";
import {Telephonies} from "./Telephonies";

/**
 *
 */

/**
 *
 */
export const TelephonesEmails = (props => {
	
	/**
	 *
	 */
	const {isReadOnly, Apprenant} = props
	/**
	 *
	 */
	return (
		<div style={{maxWidth: 1024, margin: "auto"}}>
			<SectionTitle title={"Téléphonie"}/>
			<Telephonies isReadOnly={isReadOnly} Apprenant={Apprenant}/>
			<br/>
			<SectionTitle title={"Emails"}/>
			<Emails isReadOnly={isReadOnly} Apprenant={Apprenant}/>
		</div>
	);
})

