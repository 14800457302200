import {values} from "mobx";
import {applySnapshot, getParent, types} from "mobx-state-tree";
import {APPRENANT_STATUT_PROSPECT} from "../config/Constantes.logic";
import {MICRO_SERVICE_URLS} from "../config/Constantes.urls";
import MicroService from "../helpers/MicroService";
import {newId, normalize} from "../helpers/Utils";
import {FormationModel} from "./FormationStore";
import {InscriptionModel} from "./InscriptionStore";

/**
 * model
 */
const CURRENT_MICRO_SERVICE_URL = MICRO_SERVICE_URLS.INSCRIPTION_PROJET_PROFESSIONNEL
/**
 *
 */
export const InscriptionProjetProfessionnelModel = types
	.model({
		_id: types.identifier,
		readOnly: types.optional(types.boolean, false),
		Inscription: types.maybeNull(types.reference(InscriptionModel)),
		//
		statut: types.optional(types.string, ""),
		ValidationStatuts: types.array(types.frozen({
			_id: "",
			Responsable: {},
			statut: null,
			date: "",
			commentaire: "",
		})),
		//
		FormationChoisieApresEntretien: types.maybeNull(types.reference(FormationModel)),
		langueVivantes: types.array(types.string, ""),
		//Prérequis
		connaissanceMilieuProfessionnel: types.maybeNull(types.string, ""),
		//representationSecteurEtMetiers : types.maybeNull(types.boolean, null),
		niveauDisciplinesPrincipales: types.optional(types.string, ""),
		//Positionnement vis-à-vis de la formation à distance
		foadMotivationsEtAptitudes: types.optional(types.string, ""),
		foadDejaSuivie: types.maybeNull(types.boolean, null),
		foadDejaSuivieDetails: types.optional(types.string, ""),
		//	perceptionEtAutonomie : types.optional(types.string, ""),
		foadSuivieCapacite: types.optional(types.string, ""),
		possibiliteAideProximites: types.array(types.string, ""),
		possibiliteAideProximitesAutre: types.optional(types.string, ""),
		//
		
		objectif: types.optional(types.string, ""),
		domaineActivite: types.optional(types.string, ""),
		details: types.optional(types.string, ""),
		capaciteProfessionnelleAgricole: types.maybeNull(types.boolean, null),
		/*
		monProjetProfessionnel : types.optional(types.string, ""),
		passageParcoursSup : types.maybeNull(types.boolean, null),
		projetProfessionnelDetails : types.optional(types.string, ""),
		experiencesPersonnelles : types.maybeNull(types.boolean, null),
		passageExamenSouhaite : types.maybeNull(types.boolean, null),
		
		 */
		
		roleReferentEnLangueVivanteEnEtablissement: types.maybeNull(types.string, ""),
		modaliteInscriptionEnLangueVivanteEnEtablissement: types.maybeNull(types.string, ""),
		langueVivanteParcoursAnterieur: types.maybeNull(types.string, ""),
		langueVivanteParcoursAnterieurAutre: types.maybeNull(types.string, ""),
		langueVivanteExperienceProfessionnelle: types.array(types.string, ""),
		langueVivanteExperienceProfessionnelleAutre: types.maybeNull(types.string, ""),
//Organisation
		projetTempsEstime: types.maybeNull(types.string, ""),
		disponibiliteEtContraintes: types.maybeNull(types.string, ""),
		environnementInformatique: types.array(types.string, ""),
		//Avez-vous eu les informations suivantes
		informationRecueSurLesModalitesExamen: types.maybeNull(types.string, ""),
		informationRecueSurLesEpreuves: types.maybeNull(types.boolean, null),
		informationRecueSurLesPeriodesEtLieuxPassageExamen: types.maybeNull(types.boolean, null),
		informationRecueSurLesDispenses: types.maybeNull(types.string, ""),
		informationRecueSurLesStages: types.maybeNull(types.string, ""),
		informationRecueSurLesSessionsDeRegroupement: types.maybeNull(types.string, ""),
		informationRecueSurLesClassesVirtuelles: types.maybeNull(types.string, ""),
		informationRecueSurLaccompagnement: types.maybeNull(types.boolean, null),
		informationRecueSurLesTarifs: types.maybeNull(types.boolean, null),
		informationRecueCommentaireParticulier: types.maybeNull(types.string, ""),
		//
		//souhaitFinancementFormation: types.maybeNull(types.boolean, null),
		//souhaitDemandeBourse: types.maybeNull(types.boolean, null),
		//avisPedagogique:types.array(types.string, ""),
		
		//
		Createur: types.frozen({_id: "", fullname: ""}),
		Modificateur: types.frozen({_id: "", fullname: ""}),
		createdAt: types.maybeNull(types.string, ""),
		updatedAt: types.maybeNull(types.string, ""),
		//
		_isNew: types.optional(types.boolean, false),
		_index: types.optional(types.number, 0),
	})
	.views((self) => ({
		
		get isDeletable() {
			return self.Inscription.statutApprenant === APPRENANT_STATUT_PROSPECT;
		},
		
	}))
	
	.actions((self) => ({
		
		/**
		 *
		 * @param Field {fieldname:value}
		 */
		update(EditedRecord) {
			applySnapshot(self, Object.assign(self, EditedRecord));
			getParent(self, 3).notifyUIChanges()
			
		},
		/**
		 *
		 * @param callback
		 */
		save(callback) {
			let url = CURRENT_MICRO_SERVICE_URL
			let service = MicroService.create
			let data = {Record: self}
			//
			if (self._isNew === false) {
				url = url + "/" + self._id
				service = MicroService.update
			}
			//
			service(url, data, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					let Record = Response.Record
					getParent(self, 2).addToStore(Record)
				}
				callback(succeeded)
			})
		},
		
		/**
		 *
		 * @param callback
		 */
		destroy(callback) {
			MicroService.delete(CURRENT_MICRO_SERVICE_URL + "/" + self._id, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					getParent(self, 2).setSelectedRecord(null)
					getParent(self, 2).removeFromStore(self._id)
				}
				callback(succeeded)
				
			})
			
		},
		ifFormationChoisieApresEntretienContains(partOfText) {
			const FormationChoisieApresEntretien = self.FormationChoisieApresEntretien
			
			const ReferentielIntitule = FormationChoisieApresEntretien && FormationChoisieApresEntretien.ReferentielIntitule
			if (ReferentielIntitule) {
				const strToSearch = normalize(partOfText.toLowerCase())
				const searchIn = normalize(ReferentielIntitule.libelleCourt.toLowerCase())
				return searchIn.includes(strToSearch)
			}
			/*
			if (FormationChoisieApresEntretien && FormationChoisieApresEntretien.famille) {
				const strToSearch=normalize(partOfText.toLowerCase())
				const searchIn= normalize(FormationChoisieApresEntretien.famille.toLowerCase())
				return searchIn.includes(strToSearch)
			}
			
			 */
			return false
			
		},
		
	}));
/**
 * store
 */
export const InscriptionProjetProfessionnelStore = types
	.model({
		InscriptionProjetProfessionnels: types.map(InscriptionProjetProfessionnelModel),
		SelectedRecord: types.maybeNull(types.reference(InscriptionProjetProfessionnelModel, {})),
	})
	
	.actions((self) => ({
		
		/**
		 *
		 */
		create(Inscription) {
			
			const newRecord = InscriptionProjetProfessionnelModel.create({
				_id: newId(),
				Inscription: Inscription._id,
				_isNew: true
			})
			
			self.InscriptionProjetProfessionnels.set(newRecord._id, newRecord)
			return newRecord
		},
		/**
		 *
		 * @param SelectedRecord
		 */
		setSelectedRecord(SelectedRecord) {
			self.SelectedRecord = SelectedRecord
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 * @param Record
		 */
		addToStore(Record) {
			self.InscriptionProjetProfessionnels.set(Record._id, Record)
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 * @param _id
		 */
		removeFromStore(_id) {
			self.InscriptionProjetProfessionnels.delete(_id)
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 */
		
	}))
	
	.views((self) => ({
		getAll() {
			return values(self.InscriptionProjetProfessionnels)
		},
		
	}));

