/**
 *
 */
import {Checkbox, DatePicker, Form, Select} from 'antd';

import days from 'dayjs';
import {inject, observer} from "mobx-react";
import React, {useEffect} from "react";
import {Button} from "../../../components/antExtendedComponent/Button";
import {Notify} from "../../../components/Notify";
import {APPRENANT_STATUT_FORMATION_TERMINEE, DIPLOMATION_AJOURNE, DIPLOMATION_CHOIX_INSCRIPTION_VALUES, DIPLOMATION_RESULTAT_VALUES} from "../../../config/Constantes.logic";
import {key} from "../../../helpers/Utils";
import {SectionTitle} from "../../common/components/SectionTitle";
/**
 * Extraction dédiée à l'onglet "Diplôme" pour tout apprenant et toutes formations diplômantes sur une même année :
 *
 * Tous les champs de "Diplôme" : Résultat, Redoublement, Date d'envoi du diplôme, Radiation à l'examen
 * Nom de l'apprenant
 * Prénom de l'apprenant
 * Intitulé court de la formation
 * Année d'examen
 */
/**
 *
 */

export const ResultatEtDiplomation = inject("store")(observer(props => {
	/**
	 *
	 */
	const {isReadOnly, store} = props
	/**
	 *
	 */
	const
		{
			InscriptionStore,
		} = store
	/**
	 *
	 */
	const [form] = Form.useForm();
	
	/**
	 *
	 */
		
		//
	const Inscription = InscriptionStore.SelectedRecord
	const InscriptionEdited = {...Inscription}
	let Diplomation = {...InscriptionEdited.Diplomation}
	
	//
	
	/**
	 *
	 */
	
	useEffect(() => {
		if (Diplomation.diplomeEnvoiDate) {
			Diplomation.diplomeEnvoiDate = days(Diplomation.diplomeEnvoiDate, 'YYYY-MM-DD');
		}
		
		form.setFieldsValue(Diplomation);
	})
	/**
	 *
	 */
	const onValuesChange = (changedValues) => {
		const field = Object.keys(changedValues)[0]
		let value = Object.values(changedValues)[0]
		switch (field) {
			case "diplomeEnvoiDate":
				value = value.format("YYYY-MM-DD")
				break
			case "resultat":
				if (value !== DIPLOMATION_AJOURNE) {
					Diplomation.redoublement = ""
				}
				
				break
			
		}
		Diplomation[field] = value
		Inscription.update({Diplomation: Diplomation})
		//
		
	}
	/**
	 *
	 * @param  form values
	 * @returns {boolean}
	 */
	const onFinish = () => {
		
		form.validateFields()
			.then((values) => {
				Notify()
				Inscription.save(succeeded => {
					Notify(succeeded)
				})
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	/**
	 *
	 */
	const ButtonDiplomanteSave = <div className={"center"}>
		<Button block onClick={onFinish}>Enregistrer</Button>
	</div>
	/**
	 *
	 */
	const ButtonNonDiplomanteSave = <div className={"center"}>
		<Button
			disabled={isReadOnly || Inscription.statutApprenant === APPRENANT_STATUT_FORMATION_TERMINEE}
			block
			onClick={() => {
				Inscription.update({statutApprenant: APPRENANT_STATUT_FORMATION_TERMINEE})
				Inscription.save(succeeded => {
					Notify(succeeded)
				})
			}}>Cette formation est terminée</Button>
	</div>
	/**
	 *
	 */
	const FORM_PROPS = {
		form: form,
		disabled: isReadOnly,
		onValuesChange: onValuesChange,
		layout: "vertical",
		colon: false,
		className: "fadein shadow-2 padding",
		style: {width: 700, margin: "auto"},
	}
	/**
	 *
	 */
	
	return (
		
		<Form  {...FORM_PROPS} >
			{/*  */}
			{
				!Inscription.isDiplomante &&
				<>
					<SectionTitle title={"Formation non diplômante"}/>
					{ButtonNonDiplomanteSave}
				</>
			}
			{
				Inscription.isDiplomante &&
				<>
					
					<SectionTitle title={"Formation  diplômante"}/>
					<Form.Item name={"radiationExamen"} valuePropName="checked" label={""}>
						<Checkbox>Radiation à l'examen</Checkbox>
					</Form.Item>
					{/*  */}
					<Form.Item name={"resultat"} label={"Résultat"}>
						<Select style={{width: 200}}>
							{
								Object.values(DIPLOMATION_RESULTAT_VALUES).map(item =>
									<Select.Option key={key()} value={item.value}>
										{item.label}
									</Select.Option>
								)
							}
						</Select>
					</Form.Item>
					{/*  */}
					{
						Diplomation.resultat === DIPLOMATION_AJOURNE
						&& <Form.Item name={"choixReinscription"} label={"Choix de réinscription"}>
							<Select style={{width: 200}}>
								{
									Object.values(DIPLOMATION_CHOIX_INSCRIPTION_VALUES).map(item =>
										<Select.Option key={key()} value={item.value}>
											{item.label}
										</Select.Option>
									)
								}
							</Select>
						</Form.Item>
					}
					
					{/*  */}
					<Form.Item name={"diplomeEnvoiDate"} label={"Date d'envoi du diplôme"}>
						<DatePicker
							placeholder={""}
							inputReadOnly
							allowClear={false}
							format={'DD/MM/YYYY'}
							disabledDate={d => d && d.isBefore(days().add(-1, 'day'))}
						/>
					
					</Form.Item>
					{ButtonDiplomanteSave}
				</>
			}
			{/*  */}
		</Form>
	
	);
}))

