import {Table, Tooltip} from "antd";
import {inject, observer} from "mobx-react";
import React from "react";
import {COLUMN_DATE_TIME, COLUMN_ICON_WIDTH, DEFAULT_PAGINATION, DISPLAY_BOTH} from "../../../config/Constantes.ui";
import {DestroyButton} from "../../common/components/DestroyButton";
import {CreateurModificateurIcon} from "../../common/components/CreateurModificateurIcon";
import {Button} from "../../../components/antExtendedComponent/Button";
import {IconButton} from "../../../components/IconButton";
import {openDrawer} from "../../common/components/drawerManager";
import {DRAWERS_NAMES} from "../../../stores/NavigationStore";
import {ReferentielPubliPostageTemplateForm} from "./ReferentielPubliPostageTemplateForm";
import {SpaceVertical} from "../../../components/antExtendedComponent/Space";
import {ReferentielPubliPostageTemplateDownloadButton} from "./ReferentielPubliPostageTemplateDownloadButton";
import {simpleSorter} from "../../../helpers/Utils";

/**
 *
 */

const drawerName = DRAWERS_NAMES.PUBLIPOSTAGE_TEMPLATE_FORM_DRAWER
/**
 *
 */
export const ReferentielPubliPostageTemplates = inject("store")(observer(props => {
	
	const {store} = props
	const {ReferentielPubliPostageTemplateStore} = store
	let Records = ReferentielPubliPostageTemplateStore.getAll()
	/**
	 *
	 */
	let Columns = [
		/**
		 *
		 */
		{
			dataIndex: "actionDownload",
			title: <Tooltip title={"Ajouter un document modèle pour publipostage"}>
				<Button size={"small"}
				        shape="circle"
				        type={"primary"}
				        icon={<IconButton name="PageAdd"/>}
				        onClick={(e) => {
					        openDrawer(drawerName)
					        
				        }}/></Tooltip>,
			width: COLUMN_ICON_WIDTH,
			className: "center",
			render: (text, Record) => <ReferentielPubliPostageTemplateDownloadButton Record={Record}/>
		},
		{
			dataIndex: 'CreateurModificateur',
			width: COLUMN_ICON_WIDTH,
			className: "center",
			render: (text, Record) => <CreateurModificateurIcon Record={Record}/>
		},
		{
			dataIndex: "name",
			title: "Libellé",
			ellipsis: true,
			width: 200,
			sorter: simpleSorter("name"),
		},
		{
			dataIndex: "originalName",
			title: "Nom",
			ellipsis: true,
			width: 200,
			sorter: simpleSorter("originalName"),
		},
		
		{
			//
			display: DISPLAY_BOTH,
			//
			dataIndex: '_updatedAt',
			title: 'Modifié le',
			className: "center nowrap",
			width: COLUMN_DATE_TIME,
			sorter: simpleSorter("updatedAt"),
		},
		
		/**
		 * actionDestroy
		 */
		{
			dataIndex: 'actionDestroy',
			title: '',
			width: COLUMN_ICON_WIDTH,
			fixed: "right",
			className: "center",
			render: (text, Record) => <DestroyButton record={Record}/>,
		}
		/**
		 *
		 */
	
	]
	
	/**
	 *
	 */
	return <SpaceVertical>
		<Table
			rowKey="_id"
			dataSource={Records}
			columns={Columns}
			pagination={DEFAULT_PAGINATION}
			scroll={{y: window.innerHeight - 300}}
			showSorterTooltip={false}
			size="small"
			className={"shadow-2"}
			_t={store._t}
		/>
		{/*  */}
		<ReferentielPubliPostageTemplateForm/>
		{/*  */}
	</SpaceVertical>
	
}))
