import {EditOutlined} from "@ant-design/icons";
import {Alert, DatePicker, Empty, Form, Modal, Segmented, Space, Tag, TimePicker, Tooltip} from "antd";
import days from "dayjs";
import {inject, observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Button, ButtonIcon, ButtonSmall} from "../../../components/antExtendedComponent/Button";
import {SpaceVertical} from "../../../components/antExtendedComponent/Space";
import {BadgeCount} from "../../../components/BadgeCount";
import {Icon} from "../../../components/Icon";
import {IconInfo} from "../../../components/IconInfo";
import {Notify} from "../../../components/Notify";
import {RDV_STATUT_ACCEPTE, RDV_STATUT_DISPONIBLE, RDV_STATUT_REFUSE, RDV_STATUT_VALUES} from "../../../config/Constantes.logic";
import {COLOR_GRAY, COLOR_PRIMARY, FORM_DEFAULT_RULE_REQUIRED} from "../../../config/Constantes.ui";
import {key, toLocalDate} from "../../../helpers/Utils";
import {DestroyButton} from "../../common/components/DestroyButton";
import {disabledDate} from "../Planning";
import {CreateurModificateurIcon} from "../../common/components/CreateurModificateurIcon";

/**
 *
 */

export const HourRangeEdition = inject("store")(observer(props => {
	
	//
	const {
		store,
		//
		selectedDate,
		setSelectedDate,
		SelectedFormation,
		//
		//selectedFormation
		//
	} = props
	//
	const {UserStore} = store
	const [isFormVisible, setFormVisible] = useState(false)
	const [SelectedRdv, setSelectedRdv] = useState()
	//
	const Owner = UserStore.AuthenticatedUser
	//
	let someProps = {...props}
	someProps._t = store._t
	someProps.store = store
	someProps.Owner = Owner
	someProps.state = {
		SelectedRdv,
		SelectedFormation,
		isFormVisible,
		setFormVisible,
		setSelectedRdv,
		selectedDate,
		setSelectedDate
	}
	//
	//
	return <SpaceVertical>
		<Dashboard {...someProps}/>
		<HourRangeViews  {...someProps} />
		<RdvForm {...someProps}/>
	</SpaceVertical>
}))
/**
 *
 * @type {function(*): *}
 */
export const Dashboard = (props => {
	const {
		store,
		Owner,
		state
	} = props
	//
	const {RdvStore, FormationStore} = store
	//
	const {
		SelectedFormation,
		//
		selectedDate,
		setFormVisible,
		setSelectedDate,
		setSelectedRdv,
		//
	} = state
	//
	const [oldSelectedDate, setOldSelectedDate] = useState()
	//
	
	let Creneaux = []
	if (selectedDate) {
		Creneaux = RdvStore.getAllByFormationAndDate(SelectedFormation, selectedDate)
	} else {
		Creneaux = RdvStore.getAllByFormation(SelectedFormation)
	}
	/**
	 *
	 */
	
	/**
	 *
	 */
	const ButtonAdd = <ButtonSmall
		disabled={!SelectedFormation}
		onClick={(e) => {
			const Record = RdvStore.create({
				_isNew: true,
				Owner: Owner.toJSON(),
				Taker: null,
				Formation: SelectedFormation,
				Statut: {value: RDV_STATUT_DISPONIBLE},
				hourRange: "",
				date: days(selectedDate).format("YYYY-MM-DD"),
			})
			setSelectedRdv(Record)
			setFormVisible(true)
		}}
	>Ajouter un créneau</ButtonSmall>
	/**
	 *
	
	const ButtonFilter = <Switch
		checkedChildren="Tous les créneaux"
		unCheckedChildren="Tous les créneaux"
		defaultChecked={false}
		onChange={(value) => {
			if (value === true) {
				setOldSelectedDate(selectedDate)
				setSelectedDate(null)
				setSelectedDate(null)
			} else {
				setSelectedDate(oldSelectedDate)
			}
			
		}}
	
	/> */
	/**
	 *
	 */
	return <div className={"flex"} style={{borderBottom: "1px solid #f0f0f0", paddingBottom: 6}}>
		<Space>
			<Icon name={"DateTime"}/>
			<b>Gestion des créneaux horaires {selectedDate && <>du <Tag>{toLocalDate(selectedDate)}</Tag></>}</b>
			<BadgeCount total={Creneaux.length}/>
		</Space>
		<Space>
			{!SelectedFormation && <Alert showIcon type={"warning"} message={"Vous devez sélectionnez une formation pour ajouter un créneau."}/>}
			{ButtonAdd}
			<span>&nbsp;</span>
		
		</Space>
	</div>
})
/**
 *
 * @param Rdvs
 * @returns {*}
 * @constructor
 */
export const HourRangeViews = (props) => {
	const {
		store,
		Owner,
		state
	} = props
	//
	const {
		SelectedRdv,
		SelectedFormation,
		isFormVisible,
		setFormVisible,
		setSelectedRdv,
		selectedDate,
		setSelectedDate
	} = state
	//
	const {RdvStore, FormationStore} = store
	const AllRdvs = RdvStore.getAll()
	let Creneaux = []
	if (selectedDate) {
		Creneaux = RdvStore.getAllByFormationAndDate(SelectedFormation, selectedDate)
	} else {
		Creneaux = RdvStore.getAllByFormation(SelectedFormation)
	}
	//
	/**
	 * if (Creneaux.length === 0) {
	 * 		//return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<>Aucun créneau pour le <b>{toLocalDate(selectedDate)}</b></>}/>
	 * 	}
	 */
	
	const onOwnerRdvAccept = (rdvStatut, SelectedRdv) => {
		Notify()
		
		let data = {
			Statut: {
				value: rdvStatut,
				date: new Date()
			}
		}
		if (rdvStatut === RDV_STATUT_DISPONIBLE) {
			data.Taker = null
			data.Statut.date = null
		}
		const formationIds = FormationStore.getAllByUser(SelectedRdv.Taker).map(Formation => Formation._id)
		
		SelectedRdv.update(data)
		SelectedRdv.save((succeedOrFail) => {
			Notify(succeedOrFail)
			if (succeedOrFail === true) {
				//setNotificationAfterOnChange()
				//
				if (rdvStatut === RDV_STATUT_ACCEPTE) {
					//on rend les autres rdv avec le même RF disponibles
					const RdvToCancels = AllRdvs.filter(Rdv => (
							SelectedRdv._id !== Rdv._id
							&& (Rdv.Taker && Rdv.Taker._id === SelectedRdv.Taker._id)
							&& formationIds.includes(Rdv.Formation._id)
						)
					)
					
					RdvToCancels.forEach(Rdv => {
						Rdv.update({
							Taker: null,
							Statut: {value: RDV_STATUT_DISPONIBLE}
						})
						Rdv.save((succeedOrFail) => {
						})
					})
				}
				
			}
		})
		
	}
	
	/**
	 *
	 */
	
	/**
	 *
	 */
	
	const RdvStatutChangeButton = ({Rdv}) => {
		//const defaultValue = (Rdv.Statut && Rdv.Statut.value) || RDV_STATUT_EN_ATTENTE
		let segmentedItems = []
		Object.values(RDV_STATUT_VALUES).forEach(Statut => {
			const item = {...Statut}
			let color = Statut.color
			if (!Rdv.hasTaker) {
				color = COLOR_GRAY
			}
			
			if (Rdv.Statut.value === RDV_STATUT_ACCEPTE) {
				if (item.iconNameAccepte) {
					item.iconName = item.iconNameAccepte
				}
			}
			if (Rdv.Statut.value === RDV_STATUT_REFUSE) {
				if (item.iconNameRefuse) {
					item.iconName = item.iconNameRefuse
				}
				
			}
			
			item.label = <Tooltip title={Statut.title}><IconInfo name={item.iconName} style={{color: color}}/></Tooltip>
			segmentedItems.push(item)
		})
		
		return <Segmented
			options={segmentedItems}
			defaultValue={Rdv.Statut && Rdv.Statut.value}
			size={"small"}
			//disabled={!Rdv.hasTaker}
			onChange={(value) => onOwnerRdvAccept(value, Rdv)}
		/>
	}
	/**
	 *
	 */
	
	const edit = (Rdv) => {
		setSelectedRdv(Rdv)
		setFormVisible(true)
	};
	const RdvEditButton = ({Rdv}) => {
		return <ButtonIcon
			icon={<EditOutlined/>}
			onClick={() => edit(Rdv)}
		/>
	}
	/**
	 *
	 */
	
	if (Creneaux.length === 0) {
		return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<>Aucun créneau pour le <b>{toLocalDate(selectedDate)}</b></>}/>
	}
	let itemProps = {
		size: "middle",
		style: {border: "1px solid #f0f0f0", borderRadius: 4, padding: "3px 12px 3px 8px"}
	}
	//
	return <Space style={{flexWrap: "wrap"}}>
		{
			
			Creneaux.map(Rdv => {
				
				//
				itemProps.key = key()
				//
				let title = "Rdv encore disponible"
				if (Rdv.hasTaker) {
					title = "Rdv avec " + Rdv.Taker.fullname
				}
				
				return <Space {...itemProps}>
					{/* */}
					<RdvEditButton Rdv={Rdv}/>
					{/* */}
					{
						!SelectedFormation &&
						<Tag
							className={"ellipsis"}
							style={{width: 110}}>
							{Rdv.Formation.libelleCourt}
						</Tag>}
					{/* */}
					<Tooltip title={title}>
						<IconInfo name={"ReminderPerson"} style={{color: (Rdv.hasTaker ? COLOR_PRIMARY : COLOR_GRAY)}}/>
					</Tooltip>
					{/* */}
					<RdvStatutChangeButton Rdv={Rdv}/>
					{/* */}
					<Tag style={{width: 90}}>
						{!selectedDate && <span>Le {toLocalDate(Rdv.date)} à </span>}
						{Rdv.hourRange}
					</Tag>
					{/* */}
					<CreateurModificateurIcon Record={Rdv}></CreateurModificateurIcon>
					{/* */}
					&nbsp;
					<DestroyButton record={Rdv}/>
					{/* */}
				</Space>
				
			})
		}
	</Space>
}

/**
 *
 * @type {function(*): *}
 */
export const RdvForm = (props => {
	//
	const [form] = Form.useForm();
	//
	const {
		store,
		Owner,
		state
	} = props
	//
	const {RdvStore, FormationStore} = store
	//
	const {
		isFormVisible,
		setFormVisible,
		//
		SelectedRdv,
		SelectFormation,
		//
		setSelectedRdv,
		selectedDate,
		setSelectedDate
	} = state
	//
	
	//
	const getHourFormatted = (value) => days(value).format("HH:mm")
	//
	let Creneaux = RdvStore.getAllByFormation(SelectFormation)
	
	//
	useEffect(() => {
		
		let Record = {}
		if (SelectedRdv) {
			Record = {...SelectedRdv}
			delete Record.Formation
			//
			if (Record.hourRange) {
				const hourRange = Record.hourRange.split("-")
				Record.hourRange = [days(hourRange[0], "HH:mm"), days(hourRange[1], "HH:mm")]
			}
			Record.date = days(Record.date, "YYYY-MM-DD")
		}
		//
		form.resetFields()
		form.setFieldsValue(Record)
		//
	})
	
	// on interdit la saise d'un horaire si date passée mais on autorise la visualisation des créneaux
	//const disabled = days() < days().startOf('day')
	/**
	 *
	 */
	const onFinish = () => {
		
		form.validateFields()
			.then((Record) => {
				const hourRange = Record.hourRange
				Record.hourRange = getHourFormatted(hourRange[0]) + "-" + getHourFormatted(hourRange[1])
				//
				Record.date = days(Record.date).format("YYYY-MM-DD")
				
				//on cherche si le creaneau exist
				const found = Creneaux.find(item =>
						item.date === Record.date
						&& item.hourRange === Record.hourRange
					//&& item.Formation._id === Record.Formation._id
				)
				if (found) {
					Notify(false, "Ce créneau existe déjà pour la date sélectionnée.")
				} else {
					
					Notify()
					SelectedRdv.update(Record)
					SelectedRdv.save((succeedOrFail) => {
						Notify(succeedOrFail)
						setFormVisible(false)
					})
				}
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	
	/**
	 *
	 */
	const FORM_PROPS = {
		form: form,
		className: "padding",
		labelCol: {span: 8},
		wrapperCol: {span: 10},
		colon: false
		
	}
	const ButtonSave = <div key={key()} className={"center"}>
		<Button
			//disabled={ButtonSaveDisabled}
			onClick={onFinish}
		>Enregistrer
		</Button>
	</div>
	//
	const MyForm = (props) => {
		
		return <Form {...FORM_PROPS}>
			{/* */}
			<Form.Item name={"date"} label={"Date"} rules={FORM_DEFAULT_RULE_REQUIRED}>
				<DatePicker
					allowClear={false}
					inputReadOnly
					disabledDate={disabledDate}
					format={'DD/MM/YYYY'}
					placeholder={"Date"}
				/>
			</Form.Item>
			
			{/* */}
			<Form.Item name={"hourRange"} label={"Créneau"} rules={FORM_DEFAULT_RULE_REQUIRED}>
				<TimePicker.RangePicker
					inputReadOnly
					style={{width: 200}}
					placeholder={["De", "À"]}
					size={"middle"}
					minuteStep={15}
					format={"HH:mm"}
				/>
			</Form.Item>
			{ButtonSave}
			{/* */}
		</Form>
	}
	
	//
	return <div>
		{/* */}
		<Modal
			open={isFormVisible}
			centered
			mask={false}
			maskClosable={false}
			closable
			footer={false}
			onCancel={() => {
				setFormVisible(false)
			}}
		>
			{/* */}
			<MyForm/>
			{/* */}
		</Modal>
		
		{/* */}
	</div>
})
