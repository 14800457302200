/**
 *
 */
import {Form} from 'antd';
import {inject, observer} from "mobx-react";
import React, {useEffect} from "react";
import {Button} from "../../../components/antExtendedComponent/Button";
import {Notify} from "../../../components/Notify";
import {FORM_DEFAULT_RULE_REQUIRED} from "../../../config/Constantes.ui";
import {SelectFormation} from "./common/SelectFormation";

/**
 *
 */

/**
 *
 */
export const Voeux = inject("store")(observer(props => {
	/**
	 *
	 */
	const {isReadOnly, store} = props
	
	/**
	 *
	 */
	const
		{
			InscriptionStore,
		} = store
	
	/**
	 *
	 */
	
	const [form] = Form.useForm();
	/**
	 *
	 */
	const Inscription = InscriptionStore.SelectedRecord
	const InscriptionEdited = {...Inscription}
	/**
	 * populate form
	 */
	
	useEffect(() => {
		//
		form.resetFields()
		//
		if (InscriptionEdited.FormationSouhaiteePremiere) {
			InscriptionEdited.FormationSouhaiteePremiere = InscriptionEdited.FormationSouhaiteePremiere._id
		}
		if (InscriptionEdited.FormationSouhaiteeSeconde) {
			InscriptionEdited.FormationSouhaiteeSeconde = InscriptionEdited.FormationSouhaiteeSeconde._id
		}
		const values = {
			FormationSouhaiteePremiere: InscriptionEdited.FormationSouhaiteePremiere,
			FormationSouhaiteeSeconde: InscriptionEdited.FormationSouhaiteeSeconde,
		}
		form.setFieldsValue(values);
	})
	
	/**
	 * modifie l'objet dans le store avant enregistrement
	 */
	const onFieldsChange = (changedFields, allFields) => {
		//
		const Obj = {}
		//
		const changedField = changedFields[0]
		if (!changedField) {
			return false
		}
		const field = changedField.name[0]
		Obj[field] = changedField.value;
		Inscription.update(Obj)
		//
	};
	/**
	 *
	 * @param  form values
	 * @returns {boolean}
	 */
	const onFinish = () => {
		
		form.validateFields()
			.then((values) => {
				//Inscription.update(values)
				
				Notify()
				Inscription.save(succeeded => {
					Notify(succeeded)
				})
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	/**
	 *
	 */
	const ButtonSave = <div className={"center"}>
		<Button block onClick={onFinish}>Enregistrer</Button>
	</div>
	/**
	 *
	 */
	const disabled = isReadOnly || (Inscription && Inscription.reInscription)
	const FORM_PROPS = {
		form: form,
		disabled: disabled,
		onFieldsChange: onFieldsChange,
		layout: "vertical",
		className: "fadein shadow-2 padding",
		
	}
	/**
	 *
	 */
	return (
		
		<Form   {...FORM_PROPS}>
			{/*  */}
			<Form.Item name={"FormationSouhaiteePremiere"} label={"1ère formation souhaitée"} rules={FORM_DEFAULT_RULE_REQUIRED}>
				<SelectFormation/>
			</Form.Item>
			{/*  */}
			<Form.Item name={"FormationSouhaiteeSeconde"} label={"2ème formation souhaitée"}>
				<SelectFormation allowClear/>
			</Form.Item>
			{/*  */}
			<Form.Item>
			</Form.Item>
			{ButtonSave}
		</Form>
	
	);
}))

