import alasql from "alasql";
import {Tooltip} from "antd";
import days from "dayjs";
import {values} from "mobx";
import {applySnapshot, getParent, types} from "mobx-state-tree";
import React from "react";
import {IconInfo} from "../components/IconInfo";
import {RDV_STATUT_ACCEPTE, RDV_STATUT_REFUSE, RDV_STATUT_VALUES} from "../config/Constantes.logic";
import {MICRO_SERVICE_URLS} from "../config/Constantes.urls";
import MicroService from "../helpers/MicroService";
import {key, ObjectId, toLocalDateWidthDayName, toLocalTime} from "../helpers/Utils";
import {FormationModel} from "./FormationStore";
import {USER_ROLE_APPRENANT, USER_ROLE_RECRUTEUR, USER_ROLE_RESPONSABLE_FORMATION} from "../config/Constantes.roles";

/**
 * model
 */
const CURRENT_MICRO_SERVICE_URL = MICRO_SERVICE_URLS.RDV
/**
 *
 */
export const RdvModel = types
	.model({
		_id: types.identifier,
		date: types.optional(types.string, ""),
		hourRange: types.optional(types.string, ""),
		//
		Statut: types.frozen({
			value: "",
			date: ""
		}),
		Owner: types.frozen({
			_id: "",
			fullname: "",
			email: ""
		}),
		//
		Taker: types.frozen({
			_id: "",
			fullname: "",
			email: "",
			date: "",
			ByAgent: {
				_id: "",
				fullname: "",
			}
		}),
		Formation: types.maybeNull(types.reference(FormationModel)),
		//
		Createur: types.frozen({_id: "", fullname: ""}),
		Modificateur: types.frozen({_id: "", fullname: ""}),
		//
		createdAt: types.maybeNull(types.string, ""),
		updatedAt: types.maybeNull(types.string, ""),
		_isOnEdition: types.optional(types.boolean, false),
		_isNew: types.optional(types.boolean, false),
		_index: types.optional(types.number, 0),
	})
	.views((self) => ({
		get isDeletable() {
			/*
			const AuthenticatedUser = getParent(self, 3).UserStore.AuthenticatedUser
			const FormationStore = getParent(self, 3).FormationStore
			const AuthorizedFormations = FormationStore.getAllByUser(AuthenticatedUser)
			const formationIds = AuthorizedFormations.map(Formation => Formation._id)
			return formationIds.includes(self.Formation._id);
			 */
			return true
		},
		get hasTaker() {
			return Boolean(self.Taker && self.Taker._id !== "")
		},
		get statutIcon() {
			const Statut = Object.values(RDV_STATUT_VALUES).find(Statut => Statut.value === (self.Statut && self.Statut.value))
			if (self.Statut.value === RDV_STATUT_ACCEPTE) {
				if (Statut.iconNameAccepte) {
					Statut.iconName = Statut.iconNameAccepte
				}
			}
			if (self.Statut.value === RDV_STATUT_REFUSE) {
				if (Statut.iconNameRefuse) {
					Statut.iconName = Statut.iconNameRefuse
				}
				
			}
			const title = Statut.label
				+ " le " + toLocalDateWidthDayName(self.Statut.date)
				+ " à " + toLocalTime(self.Statut.date)
				+ " par " + self.Owner.fullname
			
			return <Tooltip key={key()} title={title}>
				<IconInfo name={Statut.iconName} style={{color: Statut.color}}/>
			</Tooltip>
			
		},
		
	}))
	
	.actions((self) => ({
		/**
		 *
		 * @param Field {fieldname:value}
		 */
		update(EditedRecord) {
			applySnapshot(self, Object.assign(self, EditedRecord));
			
		},
		/**
		 *
		 * @param callback
		 */
		save(callback) {
			let url = CURRENT_MICRO_SERVICE_URL
			let service = MicroService.create
			let data = {Record: self}
			//
			if (self._isNew === false) {
				url = url + "/" + self._id
				service = MicroService.update
			}
			//
			service(url, data, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					let Record = Response.Record
					getParent(self, 2).addToStore(Record)
				}
				callback(succeeded)
			})
			/**
			 * create
			
			if (self._isNew === true) {
				MicroService.create(CURRENT_MICRO_SERVICE_URL, {Record : self}, (Response) => {
					const succeeded = Response.OPERATION_SUCCEED
					if (succeeded === true) {
						let Record = Response.Record
						getParent(self, 2).addToStore(Record)
					}
					callback(succeeded)
					
				})
			}
		
			else {
				MicroService.update(CURRENT_MICRO_SERVICE_URL + "/" + self._id, {Record : self}, (Response) => {
					const succeeded = Response.OPERATION_SUCCEED
					if (succeeded === true) {
						getParent(self, 2).addToStore(Response.Record)
					}
					callback(succeeded)
				})
			} */
		},
		
		/**
		 *
		 * @param callback
		 */
		destroy(callback) {
			MicroService.delete(CURRENT_MICRO_SERVICE_URL + "/" + self._id, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					getParent(self, 2).removeFromStore(self._id)
				}
				callback(succeeded)
				
			})
			
		}
		
	}));
/**
 * store
 */
export const RdvStore = types
	.model({
		Rdvs: types.map(RdvModel),
		//
		SelectedOwner: types.frozen({
			_id: "",
			fullname: "",
			email: ""
		}),
		//
		SelectedTaker: types.frozen({
			_id: "",
			fullname: "",
			email: ""
		}),
		//
		SelectedFormation: types.maybeNull(types.reference(FormationModel)),
		//
		
	})
	.views((self) => ({
		get totalRdvs() {
			return values(self.Rdvs).filter(Rdv => Rdv.hasTaker).length
		},
		get totalCreneaux() {
			return values(self.Rdvs).filter(Rdv => !Rdv._isNew).length
		}
	}))
	.actions((self) => ({
		setSelectedOwner(SelectedOwner) {
			self.SelectedOwner = SelectedOwner
		},
		setSelectedTaker(SelectedTaker) {
			self.SelectedTaker = SelectedTaker || {}
		},
		setSelectedFormation(SelectedFormation) {
			self.SelectedFormation = SelectedFormation
		},
		
		/**
		 *
		 */
		create(Record) {
			Record._id = ObjectId()
			const newRecord = RdvModel.create(Record)
			self.addToStore(newRecord)
			return newRecord
		},
		/**
		 *
		 * @param SelectedRecord
		 */
		
		/**
		 *
		 * @param Record
		 */
		addToStore(Record) {
			self.Rdvs.set(Record._id, Record)
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 * @param _id
		 */
		removeFromStore(_id) {
			self.Rdvs.delete(_id)
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 */
		
	}))
	
	.views((self) => ({
		/**
		 *
		 */
		getFormattedDate(date) {
			return days(date).format("YYYY-MM-DD")
		},
		/**
		 *
		 */
		getAll() {
			return values(self.Rdvs)
		},
		/**
		 *
		 */
		getAllWidthFilter(filter) {
			const Records = values(self.Rdvs)
			const query = "SELECT * FROM ?  WHERE _isNew = false ORDER BY date DESC "
			const Rows = alasql(query, [Records]);
			return Rows.map(Row => self.Rdvs.get(Row._id))
		},
		/**
		 *
		 */
		getAllByFormationAndDate(SelectedFormation, selectedDate) {
			
			const Records = values(self.Rdvs)
			let wheres = [
				"_isNew = false",
				"date = '" + self.getFormattedDate(selectedDate) + "'"
			]
			if (SelectedFormation) {
				wheres.push("Formation->_id = '" + SelectedFormation._id + "'")
			}
			const query = "SELECT * FROM ? WHERE " + wheres.join(" AND ") + " ORDER BY Formation->libelleCourt, hourRange "
			const Rows = alasql(query, [Records]);
			return Rows.map(Row => self.Rdvs.get(Row._id))
		},
		getAllByUserId(userId, role) {
			const Rdvs = values(self.Rdvs)
			let formationIds = []
			if (role === USER_ROLE_APPRENANT) {
				const InscriptionStore = getParent(self, 1).InscriptionStore;
				formationIds = InscriptionStore.getAll()
					.filter(Inscription => Inscription.Apprenant.User._id === userId)
					.map(Inscription => Inscription.FormationSouhaitee.FormationChoisieAvantEntretien)
			}
			if (role === USER_ROLE_RECRUTEUR || role === USER_ROLE_RESPONSABLE_FORMATION) {
				const FormationStore = getParent(self, 1).FormationStore;
				formationIds = ["k"]
			}
			return Rdvs.filter(Rdv => formationIds.includes(Rdv.Formation._id))
		},
		/**
		 *
		 */
		getAllByFormation(SelectedFormation) {
			
			const Records = values(self.Rdvs)
			let wheres = [
				"_isNew = false",
			]
			if (SelectedFormation) {
				wheres.push("Formation->_id = '" + SelectedFormation._id + "'")
			}
			const query = "SELECT * FROM ? WHERE " + wheres.join(" AND ") + " ORDER BY Formation->libelleCourt, hourRange "
			const Rows = alasql(query, [Records]);
			return Rows.map(Row => self.Rdvs.get(Row._id))
		},
		/**
		 *
		 */
		
	}));

