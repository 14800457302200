import {Radio} from "antd";
import React from "react";

/**
 *
 *
 */
export const NON_CONCERNE = "NON_CONCERNE"
export const items = {
	OUI: "Oui",
	NON: "Non",
	NON_CONCERNE: "Non concerné"
}
export const SelectYesNoPasConcerne = props => {
	return <Radio.Group {...props}  >
		{
			Object.keys(items).map(key => <Radio key={key} value={key}>{items[key]}</Radio>)
			
		}
	</Radio.Group>
}

/**
 *
 *
 *
 */
