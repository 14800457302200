import {Table, Tag, Tooltip} from "antd";
import {inject, observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {IconUnlink} from "../../../components/IconUnlink";
import {Notify} from "../../../components/Notify";
import {RDV_STATUT_DISPONIBLE} from "../../../config/Constantes.logic";
import {COLOR_GREEN, COLUMN_ICON_WIDTH, DEFAULT_PAGINATION} from "../../../config/Constantes.ui";
import {toLocalDateTime, toLocalDateWidthDayName} from "../../../helpers/Utils";
import {DestroyButton} from "../../common/components/DestroyButton";
import {USER_ROLE_APPRENANT} from "../../../config/Constantes.roles";
import {CONTEXT_PLANNING_RDV_ANNULER_SUPPRIMER} from "../../../config/Constantes.contexts";

;

/**
 *
 */

/**
 *
 */
export const Rdvs = inject("store")(observer(props => {
	//
	const {store} = props
	const {ApprenantStore, RdvStore, UserStore} = store
	//
	//
	
	const AuthenticatedUser = UserStore.AuthenticatedUser
	const SelectedOwner = RdvStore.SelectedOwner
	const SelectedTaker = RdvStore.SelectedTaker
	
	const Apprenant = ApprenantStore.SelectedRecord || {}
	//
	const [selectedResponsable, setSelectedResponsable] = useState(null)
	const [selectedApprenant, setSelectedApprenant] = useState(null)
	/**
	 *
	 */
	useEffect(() => {
		/*
		if (AuthenticatedUser.hasRoles(USER_ROLE_APPRENANT)) {
			//setSelectedApprenant(Apprenant._id)
		}
		if (AuthenticatedUser.hasRoles([USER_ROLE_RESPONSABLE_FORMATION, USER_ROLE_RECRUTEUR])) {
			//setSelectedResponsable(AuthenticatedUser._id)
		}
		
		 */
		
	})
	
	/**
	 *
	 */
	let CreneauxRdvs = RdvStore.getAllWidthFilter()
	
	let roleToChecks = [USER_ROLE_APPRENANT]
	if (AuthenticatedUser.hasRoles(roleToChecks)) {
		CreneauxRdvs = CreneauxRdvs.filter(Rdv => Rdv.Taker && Rdv.Taker._id === AuthenticatedUser._id)
	}
	
	/*
	roleToChecks = [USER_ROLE_RESPONSABLE_FORMATION, USER_ROLE_RECRUTEUR]
	if (AuthenticatedUser.hasRoles(roleToChecks)) {
		//CreneauxRdvs = CreneauxRdvs.filter(Rdv => Rdv.Owner._id === AuthenticatedUser._id)
	}
	
	 */
	
	const Profil = UserStore.getAllowedContext(CONTEXT_PLANNING_RDV_ANNULER_SUPPRIMER)
	/**
	 *
	 */
	const ButtonRdvAnnuler = ({Rdv}) => {
		const display = Profil && Profil.isModifier && Rdv.hasTaker
		if (display) {
			const title = "Annuler le  rdv de " + Rdv.Taker.fullname
			return display && <Tooltip title={title} placement={"topRight"}>
				<IconUnlink
					disabled
					onClick={() => {
						Rdv.update({Taker: null, Statut: {value: RDV_STATUT_DISPONIBLE}})
						Notify()
						Rdv.save((succeedOrFail) => {
							Notify(succeedOrFail)
						})
						
					}}
				/>
			</Tooltip>
		}
		
	}
	/**
	 * affichage des rdv
	 */
	const Columns = [
		{
			dataIndex: "Statut",
			title: "Statut",
			width: COLUMN_ICON_WIDTH,
			className: "center",
			render: (text, Rdv) => Rdv.statutIcon
		},
		
		{
			dataIndex: "Formation",
			title: "Formation",
			width: 150,
			ellipsis: true,
			render: (text, Rdv) => Rdv.Formation.libelleCourt// +" ("+ Rdv.Formation._id+")"
		},
		{
			dataIndex: "date",
			title: "Date",
			width: 200,
			className: "center",
			render: (text, Rdv) => {
				const dateFormatted = toLocalDateWidthDayName(Rdv.date) + " à " + Rdv.hourRange
				return <Tag style={{width: 190}}>{dateFormatted}</Tag>
				
			}
		},
		
		{
			dataIndex: "Owner",
			title: "Resp. formation ou Recruteur",
			width: 200,
			ellipsis: true,
			render: (text, Rdv) => Rdv.Owner.fullname
		},
		{
			dataIndex: "_prisPar",
			title: "Rdv pris par",
			width: 300,
			render: (text, Rdv) => {
				if (Rdv.hasTaker) {
					const Taker = Rdv.Taker
					let prisParText = ""
					if (Taker.ByAgent) {
						prisParText += Taker.ByAgent.fullname
					} else {
						prisParText += Taker.fullname
					}
					prisParText += " le " + toLocalDateTime(Rdv.Taker.date)
					return <Tag>{prisParText}</Tag>
				}
				return <Tag color={COLOR_GREEN}>Disponible</Tag>
			}
		},
		{
			dataIndex: "RdvAnnuler",
			width: COLUMN_ICON_WIDTH,
			fixed: "right",
			className: "center",
			render: (text, Rdv) => (Profil && Profil.isModifier) && <ButtonRdvAnnuler Rdv={Rdv}/>
			
		},
		{
			dataIndex: "RdvDestroy",
			width: COLUMN_ICON_WIDTH,
			fixed: "right",
			className: "center",
			render: (text, Rdv) => (Profil && Profil.isModifier) && <DestroyButton record={Rdv}/>
		}
	]
	/**
	 *
	 */
	return <Table
		showSorterTooltip={false}
		rowKey="_id"
		size="small"
		className={"shadow-2"}
		pagination={DEFAULT_PAGINATION}
		scroll={{y: window.innerHeight - 450}}
		dataSource={CreneauxRdvs}
		columns={Columns}
		t={store._t}
	
	/>
	
}))

/**
 * ResponsableFormationselectFilter

const ResponsableFormationselectFilter = (props) => {
	
	const {AuthenticatedUser, AllRdvs, selectedResponsable, setSelectedResponsable, setSelectedApprenant} = props
	
	if (AuthenticatedUser.hasRoles([USER_ROLE_RESPONSABLE_FORMATION])) {
		//	return false
	}
	//let _selectedResponsable = null
	
	const _RFs = {}
	//on prend que les rfs qui ont des créneaux et enlève les doublons
	AllRdvs.forEach((Rdv, index) => {
		const CurrentUser = Rdv.Owner
		_RFs[CurrentUser._id] = {value: CurrentUser._id, label: CurrentUser.fullname}
		
	})
	const RFs = Object.values(_RFs)
	//
	return <Select
		{...PLANNING_COMMON_SELECT_OPTIONS}
		options={RFs}
		placeholder={"Sélectionnez un responsable de formation"}
		//notFoundContent={"Aucun RF avec un planning"}//
		value={selectedResponsable}
		onChange={value => {
			setSelectedResponsable(value)
			setSelectedApprenant(null)
			
		}}
	/>
}
 */
/**
 * selectedApprenant
 
const ApprenantSelectFilter = (props) => {
	const {AuthenticatedUser, RdvOccupes, selectedResponsable, selectedApprenant, setSelectedApprenant} = props
	if (AuthenticatedUser.hasRoles([USER_ROLE_AGENT])) {
		//return false
	}
	let map = {}
	RdvOccupes.forEach(Rdv => {
		const Apprenant = Rdv.Apprenant
		const key = Apprenant._id
		if (selectedResponsable) {
			if (Rdv.Owner._id !== selectedResponsable) {
				return false
			}
		}
		if (!map[key]) {
			map[key] = []
		}
		map[key].push(Rdv)
		
	})
	const ItemOptions = []
	Object.values(map).forEach(Rdvs => {
		const Apprenant = Rdvs[0].Apprenant
		const Owner = Rdvs[0].Owner
		
		ItemOptions.push({
			value: Apprenant._id,
			label: Apprenant.fullname,
			Responsable: Owner.fullname,
			total: Rdvs.length
		})
	})
	//
	return <Select
		{...PLANNING_COMMON_SELECT_OPTIONS}
		placeholder={"Sélectionnez un apprenant"}
		notFoundContent={"Aucun apprenant avec un rdv"}
		value={selectedApprenant && ItemOptions.find(Item => Item.value === selectedApprenant)}
		disabled={AuthenticatedUser.hasRoles([USER_ROLE_APPRENANT])}
		onChange={value => {
			setSelectedApprenant(value)
		}}
		popupMatchSelectWidth={false}
	>
		{
			ItemOptions.map(ItemOption =>
				<Select.Option key={key()} value={ItemOption.value} label={ItemOption.label}>
					<div className={"flex"}>
						<Space>
							<span>{ItemOption.label} (RF : {ItemOption.Responsable})</span>
						</Space>
						<BadgeCount total={ItemOption.total}/>
					</div>
				</Select.Option>
			)
		}
	</Select>
}
 */
/**
 *
 */
