import {values} from "mobx";
import {types} from "mobx-state-tree";

/**
 * Model
 */

export const CountryModel = types
	.model({
		_id: types.identifier,
		name: types.maybeNull(types.string, ""),
		dialCode: types.maybeNull(types.string, "")
	})
;
/**
 * Store
 */
export const CountryStore = types
	.model({
		Countries: types.map(CountryModel)
	})
	.views((self) => ({
		getAll() {
			return values(self.Countries)
		}
	}))

