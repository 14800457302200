import {Tabs} from "antd";
import React from "react";
import {Diplomations} from "./Diplomations";
/**
 *
 */

//const DEFAULT_ACTIVE_KEY = "activeKey:Referentiel"
//const defaultActiveKey = sessionStorage[DEFAULT_ACTIVE_KEY]
/**
 *
 */
export const Export = (props) => {
	//
	return <Tabs
		type={"card"}
		//tabPosition={"left"}
		//activeKey={"2"}
		onChange={(value) => {
			//sessionStorage[DEFAULT_ACTIVE_KEY] = value
		}}
		items={
			[
				{
					key: "1",
					label: "Diplômation",
					children: <Diplomations/>
				},
			
			]
		}
	/>
}

/**
 *
 *
 *
 */
