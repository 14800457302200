import {CloseOutlined, EditOutlined, SaveOutlined} from "@ant-design/icons";
import {Button, Input, Modal, Select, Switch, Table, Tooltip} from "antd";
import {inject, observer} from "mobx-react";
import React from "react";
import {ActifNotActifIcon} from "../../../components/ActifNotActifIcon";
import {IconButton} from "../../../components/IconButton";
import {Notify} from "../../../components/Notify";
import {YesNoTag} from "../../../components/YesNoTag";
import {PIECE_JOINTE_CATEGORIE_AGES} from "../../../config/Constantes.logic";
import {BUTTON_CIRCLE_DEFAULT_PROPS, COLUMN_BUTTON_WIDTH, COLUMN_ICON_WIDTH} from "../../../config/Constantes.ui";
import {key, ucFirst} from "../../../helpers/Utils";
import {CreateurModificateurIcon} from "../../common/components/CreateurModificateurIcon";
import {DestroyIcon} from "../../common/components/DestroyIcon";

/**
 *
 */
export const ReferentielPieceJointes = inject("store")(observer(props => {
	
	const {store} = props
	const {ReferentielPieceJointeStore} = store
	let Records = ReferentielPieceJointeStore.getAll()
	/**
	 *
	 */
	let Columns = [
		/**
		 *
		 */
		{
			dataIndex: "actionEdit",
			title: <Tooltip title={"Créer"}>
				<Button
					size={"small"}
					shape="circle"
					type={"primary"}
					icon={<IconButton name="PageAdd"/>}
					onClick={(e) => {
						ReferentielPieceJointeStore.create()
						
					}}/></Tooltip>,
			width: COLUMN_BUTTON_WIDTH,
			className: "center editable",
			render: (text, Record) => {
				if (Record._isOnEdition === true) {
					return <div>
						<Tooltip title="Annuler" placement="top">
							<Button
								{...BUTTON_CIRCLE_DEFAULT_PROPS}
								icon={<CloseOutlined/>}
								onClick={(e) => {
									e.preventDefault();
									Record.cancel()
								}}
							/>
						</Tooltip>
						&nbsp;
						<Tooltip title="Enregistrer" placement="top">
							<Button
								
								{...BUTTON_CIRCLE_DEFAULT_PROPS}
								icon={<SaveOutlined/>}
								onClick={(e) => {
									e.preventDefault();
									Notify()
									Record.save(success => Notify(success))
									
								}}
							/>
						</Tooltip>
					</div>
				} else {
					return <Tooltip title="Modifier" placement="right">
						<Button
							{...BUTTON_CIRCLE_DEFAULT_PROPS}
							icon={<EditOutlined/>}
							onClick={(e) => {
								e.preventDefault();
								Record.edit()
								
							}}
						/>
					</Tooltip>
					
				}
			}
		},
		/**
		 *
		 */
		{
			dataIndex: 'CreateurModificateur',
			title: '',
			width: COLUMN_ICON_WIDTH,
			className: "center",
			render: (text, Record) => <CreateurModificateurIcon Record={Record}/>
			
		},
		/**
		 *
		 */
		{
			dataIndex: "active",
			title: "Active",
			width: 50,
			className: "center",
			render: (text, Record) => {
				if (Record._isOnEdition === true) {
					return <Switch
						defaultChecked={Record.active}
						size="small"
						onChange={value => Record.update({active: value})}
					/>
				}
				return <ActifNotActifIcon active={text}/>
				
			}
		},
		/**
		 *
		 */
		{
			dataIndex: "obligatoire",
			title: "Obligatoire",
			width: 80,
			className: "center",
			render: (text, Record) => {
				if (Record._isOnEdition === true) {
					return <Switch
						defaultChecked={Record.obligatoire}
						size="small"
						onChange={value => Record.update({obligatoire: value})}
					/>
				}
				
				return <YesNoTag value={text}/>
				
			}
		},
		/**
		 *
		 */
		{
			dataIndex: "categorieAge",
			title: "Public ciblé",
			width: 150,
			render: (text, Record) => {
				if (Record._isOnEdition === true) {
					const CategorieAges = Object.values(PIECE_JOINTE_CATEGORIE_AGES)
					return <Select
						defaultValue={Record.categorieAge}
						style={{width: "100%"}}
						allowClear
						onChange={value => Record.update({categorieAge: value})}
					>
						{
							CategorieAges.map(item =>
								<Select.Option key={key()} value={item.value}>
									{item.tag}
								</Select.Option>
							)
						}
					</Select>
				}
				return Record.categorieAgeTag
				
			}
		},
		/**
		 *
		 */
		{
			dataIndex: "libelle",
			title: "Libellé",
			width: "50%",
			ellipsis: true,
			render: (text, Record) => {
				if (Record._isOnEdition === true) {
					return (<Input
						defaultValue={text}
						onChange={(e) => Record.update({libelle: ucFirst(e.target.value)})}
					/>)
				}
				
				return text
			}
		},
		/**
		 *
		 */
		{
			dataIndex: "_space_",
			title: '',
		},
		/**
		 * actionDestroy
		 */
		{
			dataIndex: 'actionDestroy',
			title: '',
			width: COLUMN_ICON_WIDTH,
			fixed: "right",
			className: "center",
			render: (text, Record) => {
				return <DestroyIcon
					onClick={(e) => {
						Notify()
						Record.destroy((succeeded, formationAssociees) => {
							if (!formationAssociees) {
								Notify(succeeded)
							} else {
								Notify(false)
								const message = "Ne peut être supprimée car associée à une ou plusieurs formations ci-dessous"
								Modal.error({
									title: message,
									content: (
										<ul>
											{
												formationAssociees.map(libelle => <li key={key()}>{libelle}</li>)
											}
										
										</ul>
									),
									onOk() {
									},
								});
							}
							
						})
					}}/>
			}
		}
		/**
		 *
		 */
	
	]
	
	/**
	 *
	 */
	return <Table
		rowKey="_id"
		dataSource={Records}
		columns={Columns}
		pagination={false}
		scroll={{y: window.innerHeight * 0.5}}
		showSorterTooltip={false}
		size="small"
		className={"shadow-2"}
		_t={store._t}
	/>
	
}))
