import {Button as AntButton} from "antd";
import React from "react";
import {BUTTON_CIRCLE_DEFAULT_PROPS, BUTTON_DEFAULT_PROPS} from "../../config/Constantes.ui";

/**
 *
 */
export const ButtonSmall = (props) => {
	return <AntButton size={"small"}  {...BUTTON_DEFAULT_PROPS} {...props} >{props.children}</AntButton>
}
export const Button = (props) => {
	return <AntButton  {...BUTTON_DEFAULT_PROPS} style={{maxWidth: 300}} {...props}>{props.children}</AntButton>
}

export const ButtonIcon = (props) => {
	return <AntButton   {...BUTTON_CIRCLE_DEFAULT_PROPS} {...props} >{props.children}</AntButton>
}
/**
 *
 */
