import {SyncOutlined} from "@ant-design/icons";
import {Button, Tooltip} from "antd";
import React, {useState} from "react";
import {BUTTON_DEFAULT_PROPS} from "../../config/Constantes.ui";
import {Store} from "../../stores/Store";

/**
 *
 */
export const RefreshDataButton = (props => {
	/**
	 *
	 */
	const [isLoading, setIsLoading] = useState(false)
	/**
	 *
	 */
	const onClick = () => {
		setIsLoading(true)
		Store.loadData(true, (Response) => {
			setIsLoading(false)
		})
	}
	
	/**
	 *
	 */
	return <Tooltip title={"Actualiser toutes les données (ne synchronise pas les formations)"}>
		<Button
			{...BUTTON_DEFAULT_PROPS}
			type={"dashed"}
			size={"small"}
			loading={isLoading}
			icon={<SyncOutlined/>}
			//
			onClick={onClick}>Actualiser</Button>
	</Tooltip>
	
	/**
	 *
	 */
})
