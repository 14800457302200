import {Input} from "antd";
import React from "react";
/**
 *
 */
import {IconInfo} from "./IconInfo";

/**
 *
 * ZInputSearch
 *
 */
export const InputSearch = (props) => {
	
	const style = Object.assign({width: 200}, props.style)
	return <Input
		{...props}
		style={style}
		allowClear
		prefix={<IconInfo name={"Search"} style={{marginRight: 5, opacity: 0.8}}/>}
	
	/>
	
}
/**
 *
 *
 *
 */
