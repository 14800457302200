/**
 *
 */
import {Alert, DatePicker, Form, Input, Tag} from 'antd';
import days from "dayjs";
import {inject, observer} from "mobx-react";
import React, {useEffect} from "react";
import {Button} from "../../../components/antExtendedComponent/Button";
import {SpaceHorizontal} from "../../../components/antExtendedComponent/Space";
import {Notify} from "../../../components/Notify";
import {FORM_LAYOUT} from "../../../config/Constantes.ui";
import {BigTitle} from "../../common/components/BigTitle";
import {SectionTitle} from "../../common/components/SectionTitle";

;

/**
 *
 */

/**
 *
 */
export const Parcours = inject("store")(observer(props => {
	/**
	 *
	 */
	const {isReadOnly, store} = props
	/**
	 *
	 */
	const
		{
			InscriptionStore,
			ApprenantStore,
		} = store
	
	const [form] = Form.useForm();
	/**
	 *
	 */
	/**
	 *
	 */
		
		//
	const Apprenant = ApprenantStore.SelectedRecord
	const Inscription = InscriptionStore.SelectedRecord
	const InscriptionEdited = {...Inscription}
	//
	//console.log(JSON.stringify(Apprenant))
	
	/**
	 *
	 */
	
	/**
	 * populate form
	 */
	useEffect(() => {
		//
		const ParcoursEdited = {...InscriptionEdited.Parcours}
		if (ParcoursEdited.titreAccesFormationAnneeObtention) {
			ParcoursEdited.titreAccesFormationAnneeObtention = days(ParcoursEdited.titreAccesFormationAnneeObtention, "YYYY")
		}
		if (ParcoursEdited.titrePlusEleveFormationAnneeObtention) {
			ParcoursEdited.titrePlusEleveFormationAnneeObtention = days(ParcoursEdited.titrePlusEleveFormationAnneeObtention, "YYYY")
		}
		if (ParcoursEdited.dateArretEtudes) {
			ParcoursEdited.dateArretEtudes = days(ParcoursEdited.dateArretEtudes, 'YYYY-MM-DD')
		}
		
		InscriptionEdited.Parcours = ParcoursEdited
		//
		form.setFieldsValue(InscriptionEdited);
		//
	})
	
	/**
	 * modifie l'objet dans le store avant enregistrement
	 */
	const onFieldsChange = (changedFields, allFields) => {
		//
		const Obj = {}
		//
		const changedField = changedFields[0]
		if (!changedField) {
			return false
		}
		const field = changedField.name[0]
		// cas d'un sous-objet
		const subField = changedField.name[1]
		//
		if (!subField) {
			Obj[field] = changedField.value;
		} else {
			Obj[field] = {};
			const Fields = allFields.filter(item => item.name[0] === field)
			Obj[field] = {}
			Fields.forEach(item => {
				const _subField = item.name[1]
				switch (_subField) {
					
					default:
						break
					case "dateArretEtudes":
						if (item.value) {
							item.value = item.value.format("YYYY-MM-DD")
						}
						break
					case "titreAccesFormationAnneeObtention":
					case "titrePlusEleveFormationAnneeObtention":
						if (item.value) {
							item.value = item.value.format("YYYY")
						}
					
				}
				Obj[field][_subField] = item.value
			})
			//
		}
		//
		Inscription.update(Obj)
		//
	};
	/**
	 *
	 * @param  form values
	 * @returns {boolean}
	 */
	const onFinish = () => {
		
		form.validateFields()
			.then((values) => {
				Notify()
				Inscription.save(succeeded => {
					Notify(succeeded)
				})
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	/**
	 *
	 */
	const ButtonSave = <div className={"center"}>
		<Button block onClick={onFinish}>Enregistrer</Button>
	</div>
	/**
	 *
	 */
	const disabled = isReadOnly || (Inscription && !Inscription.isParcoursActif)
	const FORM_PROPS = {
		form: form,
		disabled: disabled,
		onFieldsChange: onFieldsChange,
	}
	
	/**
	 *
	 */
	return (
		
		<Form  {...FORM_LAYOUT} {...FORM_PROPS}>
			<Alert type={"warning"} showIcon
			       message={"Les diplômes étrangers doivent être traduits par un traducteur assermenté et avoir fait l’objet d’une demande d’équivalence auprès de l’organisme France Éducation International, avant d’être déposés."}/>
			
			<br/>
			<BigTitle title={"Conditions d'accès à la formation (parcours de formation antérieure)"}/>
			{/*  */}
			
			<SectionTitle title={"Intitulé du diplôme (et option) donnant accès à la formation"}/>
			{/*  titre d'accès*/}
			<Form.Item name={["Parcours", "titreAccesFormation"]} label={'Nom du diplôme'}>
				<Input autoComplete='off'/>
			</Form.Item>
			{/*  */}
			<Form.Item name={["Parcours", "titreAccesFormationAnneeObtention"]} label={"Année d'obtention"}>
				<DatePicker
					placeholder={""}
					picker="year"
					inputReadOnly
					allowClear={false}
					className={"year"}
				/>
			</Form.Item>
			{/*
			<Form.Item name={["Parcours", "titreAccesFormationResultatAdmission"]} label={"Résultat d'admission"}>
				<SelectAdmission/>
			</Form.Item> */}
			
			{/*  */}
			<SectionTitle title={"Intitulé du diplôme (et option) le plus élevé"}/>
			{/*  */}
			<Form.Item name={["Parcours", "titrePlusEleveFormation"]} label={"Nom du diplôme"}>
				<Input autoComplete='off'/>
			</Form.Item>
			{/*  */}
			<Form.Item name={["Parcours", "titrePlusEleveFormationAnneeObtention"]} label={"Année d'obtention"}>
				<DatePicker placeholder={""} className={"year"} picker="year" allowClear={false}/>
			</Form.Item>
			{/*
			<Form.Item name={["Parcours", "titrePlusEleveFormationResultatAdmission"]} label={"Résultat d'admission"}>
				<SelectAdmission/>
			</Form.Item>*/}
			{/*  */}
			<SectionTitle title={"Si vous avez arrêté vos études"}/>
			<Alert showIcon message={"Date de proclamation des résultats du dernier diplôme obtenu."}/>
			<br/>
			<Form.Item
				name={["Parcours", "dateArretEtudes"]}
				label={"Date d'arrêt de vos études"}
			>
				<DatePicker placeholder={""} format={'DD/MM/YYYY'} allowClear={false} style={{width: 130}}/>
			</Form.Item>
			{/*  */}
			<SectionTitle title={"Expérience professionnelle donnant accès à la formation"}/>
			{/*  */}
			<Form.Item noStyle label={''}>
				<SpaceHorizontal>
					Total des expériences à la date de la dernière inscription
					<Tag color={"blue"}>{Apprenant.totalExperiencesDurees} mois</Tag>
				</SpaceHorizontal>
			</Form.Item>
			{/*  */}
			<Form.Item>
			</Form.Item>
			{/*  */}
			<BigTitle title={"Dispense"}/>
			<SectionTitle title={"Diplôme permettant la dispense (si différent de celui permettant l'entrée en formation)"}/>
			{/*  */}
			<Form.Item name={["Parcours", "FormationDispense"]} label={"Nom du diplôme"}>
				<Input autoComplete='off'/>
			</Form.Item>
			{/*  */}
			<BigTitle title={"Demande de dérogration"}/>
			<SectionTitle title={"Autre titre ou diplôme de niveau plus élevé soumis à dérogation pour la dispense d'épreuve"}/>
			{/*  */}
			<Form.Item name={["Parcours", "FormationDerogation"]} label={"Nom du diplôme"}>
				<Input autoComplete='off'/>
			</Form.Item>
			<Form.Item>
			</Form.Item>
			{ButtonSave}
		</Form>
	
	);
}))

