import alasql from "alasql";
import {values} from "mobx";
import {getParent, types} from "mobx-state-tree";
import {MICRO_SERVICE_URLS} from "../config/Constantes.urls";
import MicroService from "../helpers/MicroService";
import {newId} from "../helpers/Utils";

/**
 * model
 */

const CURRENT_MICRO_SERVICE_URL = MICRO_SERVICE_URLS.COMMENTAIRE
export const CommentaireModel = types
	.model({
		_id: types.identifier,
		anneeCreation: types.maybeNull(types.integer),
		belongTo: types.optional(types.string, ""),
		texte: types.optional(types.string, ""),
		tags: types.array(types.string, ""),
		Owner: types.frozen({
			_id: "",
			fullname: "",
		}),
		createdAt: types.maybeNull(types.string, ""),
	})
	.actions((self) => ({
		destroy(callback) {
			MicroService.delete(CURRENT_MICRO_SERVICE_URL + "/" + self._id, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					getParent(self, 2).removeFromStore(self._id)
				}
				callback(succeeded)
				
			})
			
		}
	}));
/**
 * store
 */
export const CommentaireStore = types
	.model({
		Commentaires: types.map(CommentaireModel),
	})
	.actions((self) => ({
		
		create(newRecord, callback) {
			newRecord._id = newId()
			MicroService.create(CURRENT_MICRO_SERVICE_URL, {Record: newRecord}, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					let Record = Response.Record
					self.addToStore(Record)
					
				}
				callback(succeeded)
			})
			
		},
		/**
		 *
		 */
		removeFromStore(_id) {
			self.Commentaires.delete(_id)
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 */
		addToStore(Record) {
			self.Commentaires.set(Record._id, Record)
			getParent(self, 1).notifyUIChanges()
		}
		
	}))
	.views((self) => ({
		/**
		 *
		 */
		getAll() {
			let Records = values(self.Commentaires)
			return Records
		},
		/**
		 *
		 * @param belongToId
		 * @returns {Commentaire[]}
		 */
		getAllTags() {
			let Commentaires = values(self.Commentaires)
			let allTags = []
			Commentaires.forEach(Commentaire => {
				allTags = allTags.concat(Commentaire.tags)
			})
			//on élimine les doublons et on trie la liste
			allTags = [...new Set(allTags.sort())]
			return allTags
		},
		getTagsByBelongTo(belongTo) {
			let Commentaires = values(self.Commentaires)
			let allTags = []
			Commentaires.forEach(Commentaire => {
				if (Commentaire.belongTo === belongTo) {
					allTags = allTags.concat(Commentaire.tags)
				}
			})
			//on élimine les doublons et on trie la liste
			allTags = [...new Set(allTags.sort())]
			return allTags
		},
		/**
		 *
		 * @param belongTo
		 * @param filteredTags
		 * @returns {*}
		 */
		getAllByBelongTo(belongTo, filteredTags) {
			let Commentaires = values(self.Commentaires)
			let query = "SELECT _id FROM ?  WHERE belongTo ='" + belongTo + "' ORDER BY  createdAt DESC"
			const Rows = alasql(query, [Commentaires])
			let CommentaireFounds = Rows.map(Row => self.Commentaires.get(Row._id))
			//
			if (filteredTags.length === 0) {
				return CommentaireFounds
			}
			//
			let arr = []
			
			//on cherche les commentaires qui contiennent au moins un tag du filtre
			CommentaireFounds.forEach(Commentaire => {
				filteredTags.forEach(filteredTag => {
					
					for (const tag of Commentaire.tags) {
						if (filteredTag === tag) {
							arr.push(Commentaire)
							continue
							
						}
					}
				})
				
			})
			//on élimine les doublons
			let map = {}
			arr.forEach(Commentaire => {
				map[Commentaire._id] = Commentaire
			})
			return Object.values(map)
		},
		
	}));

