/**
 *
 */
import {Alert, Checkbox, Space, Tag} from 'antd';
import {inject, observer} from "mobx-react";
import React, {useState} from "react";
import {Button} from "../../../../components/antExtendedComponent/Button";
import {SpaceVertical} from "../../../../components/antExtendedComponent/Space";
import {Notify} from "../../../../components/Notify";
import {COLOR_GREEN, COLOR_RED} from "../../../../config/Constantes.ui";
import {MICRO_SERVICE_URLS} from "../../../../config/Constantes.urls";
import microService from "../../../../helpers/MicroService";
import {toLocalDateTime} from "../../../../helpers/Utils";
import {SectionTitle} from "../../../common/components/SectionTitle";
import {Radiation} from "./Radiation";

/**
 *

if (ENV === ENV_DEV) {
	const headers = new Headers({'Authorization' : "Bearer crD2eBDzdXhRIVOB9rUaS4RRcO7hMkmoswJ79lvb", 'Content-Type' : "application/json"});
	const options = {
		method : "get",
		headers : headers,
	}
	
	fetch("https://qualif.api.agrosupdijon.fr/api/dired-oscar/user/6a39dc0dc3c83d427a50a25e95a225cc45a204b77876a45cf15d99266797032f", options)
		.then(function (response) {
			return response.json()////
		})
		.then(function (response) {
			console.log("then",response)
		})
		.catch(function (json) {
			console.log("catch",json)///
		})
}
 */
export const CompteExternes = inject("store")(observer(props => {
	/**
	 *
	 */
	const [isChecked, setChecked] = useState(false)
	const [CompteExternesChecked, setCompteExternesChecked] = useState({})
	/**
	 *
	 */
	const {store} = props
	
	/**
	 *
	 */
	const
		{
			ApprenantStore,
		} = store
	
	/**
	 *
	 */
		
		//
	const Apprenant = ApprenantStore.SelectedRecord
	/**
	 *
	 */
	
	
	const onCheck = () => {
		Notify()
		const url = MICRO_SERVICE_URLS.APPRENANT_COMPTE_EXTERNES + "/" + Apprenant._id
		microService.get(url, Response => {
			/**
			 * on crée un arra associatif car problème de rafraichissemnt des données
			 * ainsi les données sont liées à l'id
			 */
			const _CompteExternesChecked = {}
			_CompteExternesChecked[Apprenant._id] = Response.Record
			setCompteExternesChecked(_CompteExternesChecked)
			Notify(Response.OPERATION_SUCCEED)
			//
			store.refresh()
			//
			
		})
	};
	
	/**
	 *
	 */
	const onSave = () => {
		Notify()
		
		const url = MICRO_SERVICE_URLS.APPRENANT_COMPTE_EXTERNES + "/" + Apprenant._id
		microService.update(url, {}, Response => {
			Notify(Response.OPERATION_SUCCEED)
			//
			store.refresh()
			//
			
		})
	};
	/**
	 *
	 */
	const onDestroy = () => {
		Notify()
		const url = MICRO_SERVICE_URLS.APPRENANT_COMPTE_EXTERNES + "/" + Apprenant._id
		microService.delete(url, Response => {
			Notify(Response.OPERATION_SUCCEED)
		})
	}
	/**
	 *
	 */
	const comptesReadyToCreate = Apprenant.comptesReadyToCreate
	const CompteExternes = Apprenant.CompteExternes
	const comptesExists = CompteExternes.ldap
	/**
	 *
	 */
	const message = "Les comptes Moodle et ldap ne peuvent être créés que si l'apprenant est inscrit avec un dossier accepté."
	const MessageWarning = () => {
		if (!comptesReadyToCreate) {
			return <Alert showIcon type={"warning"} message={message}/>
		}
	}
	/**
	 *
	 */
	
	const ButtonComptesSave = () => <Button disabled={!comptesReadyToCreate} block onClick={onSave}>Créer ou actualiser les comptes externes</Button>
	
	/**
	 * comptesExists &&
	 */
	const ButtonComptesDestroy = () =>
		comptesExists && <SpaceVertical>
			<Checkbox onChange={(value) => setChecked(!isChecked)} checked={isChecked}>Je confirme la suppression des Comptes d'authentification à plateforme et Moodle</Checkbox>
			<Button disabled={!isChecked} danger block onClick={onDestroy}>Supprimer les comptes externes</Button>
		</SpaceVertical>
	/**
	 *
	 */
	const ComptesExistView = () => {
		if (!comptesExists) {
			return <SpaceVertical>
				<Alert showIcon message={<>Rappel de l'email principal de l'apprenant <b>{Apprenant.emailPrincipal || "Aucun"}</b></>}/>
				<Alert showIcon type={"error"} message={"L'apprenant n'a pas encore les comptes Moodle et ldap créés."}/>
			</SpaceVertical>
		}
		return <SpaceVertical>
			<Alert showIcon type={"success"} message={"L'apprenant a les comptes crées."}/>
			<Tag>Comptes créés ou modifiés le <b>{toLocalDateTime(CompteExternes.date)}</b></Tag>
			<Tag>Email principal <b>{Apprenant.emailPrincipal}</b></Tag>
			<Tag>Moodle <b>{CompteExternes.moodle}</b></Tag>
			<Tag>Login <b>{CompteExternes.ldap}</b></Tag>
		</SpaceVertical>
	}
	/**
	 *
	 */
	const getCheckedView = ((value, label) => {
		const color = (value === true ? COLOR_GREEN : COLOR_RED)
		const ouiNon = (value === true ? "oui" : "non")
		return <Tag color={color}>{label} <b>{ouiNon}</b></Tag>
	})
	const VerificationView = () => {
		const _CompteExternesChecked = CompteExternesChecked[Apprenant._id]
		return <Space>
			<Button block onClick={onCheck}>Vérifier les comptes externes</Button>
			{
				_CompteExternesChecked &&
				<Space>
					{getCheckedView(_CompteExternesChecked.hasLdap, "Ldap")}
					{getCheckedView(_CompteExternesChecked.hasPartage, "Partage")}
					{_CompteExternesChecked.hasPartage && getCheckedView(_CompteExternesChecked.hasPasswordModified, "Mot de passe modifié")}
				</Space>
			}
		</Space>
	}
	/**
	 *
	 */
	const classNames = "fadein shadow-2 padding"
	return (
		<SpaceVertical>
			{/* */}
			<SectionTitle title={"Vérification  des comptes d'authentification (plateforme et Moodle)"}/>
			<SpaceVertical className={classNames}>
				<VerificationView/>
				<ComptesExistView/>
			</SpaceVertical>
			{/* */}
			<SectionTitle title={"Création ou modifications des comptes d'authentification (plateforme et Moodle)"}/>
			{/* */}
			<SpaceVertical className={classNames}>
				<MessageWarning/>
				{
					Apprenant.emailPrincipal &&
					<Space>
						<ButtonComptesSave/>
					</Space>
				}
			</SpaceVertical>
			{/* */}
			{
				comptesExists && <SpaceVertical className={classNames}>
					<SectionTitle title={"Suppression des comptes"}/>
					<ButtonComptesDestroy/>
				</SpaceVertical>
			}
			{/* */}
			<SectionTitle title={"Radiation"}/>
			<Radiation/>
			{/* */}
		</SpaceVertical>
	)
	
}))

