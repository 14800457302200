import {Tabs} from "antd";
import days from "dayjs";
import {inject, observer} from "mobx-react";
import React from "react";
import {PreviewCalendar} from "./planning/PreviewCalendar";
import {Rdvs} from "./planning/Rdvs";

/**
 *  communs aux children
 */
export const disabledDate = (current) => {
	return current && current < days().startOf('day');
};
export const PLANNING_COMMON_SELECT_OPTIONS = {
	showSearch: true,
	//allowClear: true,
	style: {width: 300},
	optionFilterProp: "label"
	
}
/**
 *
 */
export const SESSION_STORAGE_PLANNING_ACTIVE_KEY = "SESSION_STORAGE_PLANNING_ACTIVE_KEY"
const defaultActiveKey = sessionStorage[SESSION_STORAGE_PLANNING_ACTIVE_KEY]
/**
 * le planning est affiché différemment pour les agents (recruteurs et RF) avec un context
 */
export const Planning = inject("store")(observer(props => {
	/**
	 *
	 */
	const {context, store, Apprenant} = props
	const {UserStore, FormationStore} = store
	const AuthenticatedUser = UserStore.AuthenticatedUser
	
	//FormationStore.getAllByRecruteur()
	/**
	 *
	 */
	const Panes = [
		{
			key: "1",
			label: "Planning",
			children: <PreviewCalendar Apprenant={Apprenant} context={context}/>,
			
		},
		{
			key: "2",
			label: "Les rendez-vous",
			children: <Rdvs/>,
		}
	]
	//
	//const authorizedItems = Panes.filter(Pane => (AuthenticatedUser && AuthenticatedUser.hasRoles(Pane.authorizedRoles)))
	//
	return AuthenticatedUser && <Tabs
		defaultActiveKey={defaultActiveKey}
		style={{height: window.innerHeight * 0.8, overflow: "auto"}}
		items={Panes}
		onChange={(value) => {
			sessionStorage[SESSION_STORAGE_PLANNING_ACTIVE_KEY] = value
		}}
	/>
}))

/**
 *
 *
 *
 */
