import {values} from "mobx";
import {getParent, types} from "mobx-state-tree";
import {MICRO_SERVICE_URLS} from "../config/Constantes.urls";
import MicroService from "../helpers/MicroService";

/**
 * model
 */
export const FavoriteModel = types
	.model({
		_id: types.identifier,
		belongTo: types.optional(types.string, ""),
	}).actions((self) => ({
		destroy(callback) {
			MicroService.delete(MICRO_SERVICE_URLS.FAVORITE + "/" + self._id, (Response) => {
				const succeedOrFailed = Response.OPERATION_SUCCEED
				if (succeedOrFailed === true) {
					getParent(self, 2).removeFromStore(self._id)
				}
				callback(succeedOrFailed)
			})
			
		}
	}));
/**
 * store
 */
export const FavoriteStore = types
	.model({
		Favorites: types.map(FavoriteModel),
	})
	.actions((self) => ({
		
		create(newRecord, callback) {
			
			MicroService.create(MICRO_SERVICE_URLS.FAVORITE, {Record: newRecord}, (Response) => {
				const succeedOrFailed = Response.OPERATION_SUCCEED
				if (succeedOrFailed === true) {
					self.addToStore(Response.Record)
				}
				callback(succeedOrFailed)
			})
			
		},
		/**
		 *
		 */
		removeFromStore(_id) {
			self.Favorites.delete(_id)
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 */
		addToStore(Record) {
			self.Favorites.set(Record._id, Record)
			getParent(self, 1).notifyUIChanges()
		},
		
		/**
		 *
		 */
		
	}))
	.views((self) => ({
		/**
		 * l'appli n'a que la liste des favoris de l'utilisateur authentifié
		 */
		getAll() {
			let Records = values(self.Favorites)
			return Records
		},
		
		/**
		 *
		 */
		isFavorite(belongTo) {
			return values(self.Favorites).find(Favorite => Favorite.belongTo === belongTo)
			
		}
	}));

