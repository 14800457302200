/**
 *
 */
import {Alert, Checkbox, DatePicker, Form} from 'antd';

import days from 'dayjs';
import {inject, observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Button} from "../../../components/antExtendedComponent/Button";
import {SpaceVertical} from "../../../components/antExtendedComponent/Space";
import {Notify} from "../../../components/Notify";
import {FORM_DEFAULT_RULE_REQUIRED} from "../../../config/Constantes.ui";
import {MICRO_SERVICE_URLS} from "../../../config/Constantes.urls";
import microService from "../../../helpers/MicroService";
import {toLocalDateTime} from "../../../helpers/Utils";

/**
 *
 */

export const Abandon = inject("store")(observer(props => {
	/**
	 *
	 */
	const {isReadOnly, store} = props
	const [form] = Form.useForm();
	const [isFormDisabled, setIsFormDisabled] = useState(false)
	const [checked, setChecked] = useState(false)
	/**
	 *
	 *
	 */
	
	/**
	 *
	 */
	const
		{
			InscriptionStore,
			ApprenantStore,
			UserStore
		} = store
	
	/**
	 *
	 */
	const Apprenant = ApprenantStore.SelectedRecord
	const Inscription = InscriptionStore.SelectedRecord
	const AuthenticatedUser = UserStore.AuthenticatedUser
	const InscriptionEdited = {...Inscription}
	let Abandon = {...InscriptionEdited.Abandon}
	//
	
	//(ENV !== ENV_DEV) &&
	/**
	 *
	 */
	//
	useEffect(() => {
		let _isFormDisabled
		Abandon.date = days(new Date()).format('YYYY-MM-DD');
		if (Abandon.date) {
			
			Abandon.date = days(Abandon.date, 'YYYY-MM-DD');
			_isFormDisabled = (Abandon.date < new Date().toISOString().split("T")[0])
			
		}
		if (isReadOnly) {
			_isFormDisabled = isReadOnly
		}
		setIsFormDisabled(_isFormDisabled)
		form.setFieldsValue(Abandon);
	})
	
	/**
	 *
	 */
	
	/**
	 *
	 * @param  form values
	 * @returns {boolean}
	 */
	const onFinish = () => {
		
		form.validateFields()
			.then((values) => {
				//
				console.log(values.confirme)
				if (values.confirme === false) {
					Notify(false, "Vous devez confirmer l'abandon.")
					return false
				}
				Abandon.date = values.date.format("YYYY-MM-DD")
				Abandon.Agent = AuthenticatedUser.toJSON()
				Abandon.confirme = values.confirme
				Abandon.createdAt = new Date()
				//
				Inscription.update({Abandon: Abandon})
				//
				Notify()
				//
				Inscription.save(succeeded => {
					Notify(succeeded)
					if (succeeded === true) {
						microService.create(MICRO_SERVICE_URLS.INSCRIPTION_ABANDON, {}, (Response) => {
							//
							store.refresh()
							//
						})
					}
				})
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	/**
	 *
	 */
	const ButtonSave = <div className={"center"}>
		<Button block onClick={onFinish}>Enregistrer</Button>
	</div>
	/**
	 *
	 */
	const Message = () => {
		const Abandon = Inscription && Inscription.Abandon
		if (Abandon) {
			const agent = Abandon.Agent.fullname
			const message = <>
				<div>Demande de l'abandon effectuée par <b>{agent}</b> le {toLocalDateTime(Abandon.createdAt)}.</div>
			</>
			return Abandon.date && <Alert type={"warning"} showIcon message={message}/>
		}
		
	}
	
	/**
	 *
	 */
	const FORM_PROPS = {
		disabled: isFormDisabled,
		form: form,
		colon: false,
		style: {width: 700, margin: "auto"}
	}
	return (
		
		<SpaceVertical className={"fadein shadow-2 padding"}>
			{/*  */}
			<Message/>
			{/*  */}
			{/*  */}
			<Form  {...FORM_PROPS} >
				{/*  */}
				<Form.Item name={"confirme"} valuePropName="checked" label={" "} rules={FORM_DEFAULT_RULE_REQUIRED}>
					<Checkbox>Je confirme l'abandon de <b>{Apprenant.fullname}</b></Checkbox>
				</Form.Item>
				
				<Form.Item name={"date"} label={"Date d'effet de l'abandon"} rules={FORM_DEFAULT_RULE_REQUIRED}>
					<DatePicker
						placeholder={""}
						allowClear={false}
						format={'DD/MM/YYYY'}
						disabledDate={d => d && d.isBefore(days().add(-1, 'day'))}
					/>
				</Form.Item>
				{/*  */}
				{ButtonSave}
				{/*  */}
			</Form>
		</SpaceVertical>
	
	);
}))

