/**
 *
 */
import {SearchOutlined} from "@ant-design/icons";
import {AutoComplete, Space, Tag} from "antd";
import axios from "axios";
import React, {useState} from "react";
import {MICRO_SERVICE_URLS} from "../../../config/Constantes.urls";
import {key} from "../../../helpers/Utils";
/**
 *
 */

/**
 *
 */


export const AutoCompleteCity = (props => {
	
	const [Places, setPlaces] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValue, setInputValue] = useState("");
//
	
	const onSearch = (value) => {
		let terms = value.trim()
		setInputValue(terms)
		if (terms.length <= 1) {
			return
		}
		const url = MICRO_SERVICE_URLS.COMMUNE + "/search/" + value
		setIsLoading(true)
		axios.get(url)//,mode:"no-cors"
			.then(Response => {
				const Communes = Response.data.Communes
				setPlaces(Communes)
				setIsLoading(false)
				
			})
	}
	//
	return <AutoComplete
		value={inputValue}
		{...props}
		loading={isLoading}
		allowClear
		popupMatchSelectWidth={false}
		placeholder={<Space><SearchOutlined/>Saisir le nom ou code postal</Space>}
		onSelect={(value, e) => {
			props.onChange(JSON.parse(e.item))
			setInputValue("")
		}}
		onSearch={onSearch}
	>
		{
			Places.map((Place) => (
				<AutoComplete.Option key={key()} value={Place._id} item={JSON.stringify(Place)}>
					<div className={"flex"}>
						<span>{Place.communeNom}</span>
						<Space>
							<Tag>{Place.communeCodePostal}</Tag>
							<Tag>{Place.departementNom}</Tag>
							<Tag color={"blue"}>{Place.regionNom}</Tag>
						</Space>
					
					</div>
				</AutoComplete.Option>
			))
		}
	</AutoComplete>
	
})
