/**
 *
 */
import {Form, Input, Select, Tag} from 'antd';

import {inject, observer} from "mobx-react";
import React, {useEffect} from "react";
import {Button} from "../../../components/antExtendedComponent/Button";
import {SpaceHorizontal} from "../../../components/antExtendedComponent/Space";
import {Notify} from "../../../components/Notify";
import {YesNoTag} from "../../../components/YesNoTag";
import {FORMATION_MODALITES, FORMATION_PROFIL_ACTIVITE_PRO_OU_DEMANDEUR_EMPLOI, FORMATION_PROFIL_ELEVE_OU_ETUDIANT, FORMATION_PROFILS_VALUES, STATUTS_EXAMEN,} from "../../../config/Constantes.logic";
import {COLOR_RED} from "../../../config/Constantes.ui";
import {key} from "../../../helpers/Utils";

/**
 *
 */

/**
 *
 */
export const FormationSuivie = inject("store")(observer(props => {
	/**
	 *
	 */
	const {isReadOnly, store} = props
	
	/**
	 *
	 */
	const
		{
			InscriptionStore,
		} = store
	
	/**
	 *
	 */
	const [form] = Form.useForm();
	/**
	 *
	 */
	const Inscription = InscriptionStore.SelectedRecord
	/**
	 * populate form
	 */
	useEffect(() => {
		form.setFieldsValue(Inscription.FormationSuivie);
	})
	const onValuesChange = (changedValues, FormationSuivie) => {
		Inscription.update({FormationSuivie: FormationSuivie})
	}
	/**
	 *
	 * @param  form values
	 * @returns {boolean}
	 */
	const onFinish = () => {
		
		form.validateFields()
			.then((values) => {
				Notify()
				Inscription.save(succeeded => {
					Notify(succeeded)
				})
				
			})
			.catch((errorInfo) => {
				/*
				// l'attribut for de l'item en error du formulaire
				const fieldFor=errorInfo.errorFields[0].name.join("_")
				const elem=document.querySelector("[for='"+fieldFor+"']")
				const offset= elem.getBoundingClientRect()
				window.scrollTo(0,10)
				console.log("errorFields",offset.y)
				
				 */
				console.log("form errors", errorInfo)
			})
		
	};
	/**
	 *
	 */
	const ButtonSave = <div className={"center"}>
		<Button block onClick={onFinish}>Enregistrer</Button>
	</div>
	/**
	 *
	 */
	const FORM_PROPS = {
		form: form,
		disabled: isReadOnly,
		layout: "vertical",
		className: "fadein shadow-2 padding",
		onValuesChange: onValuesChange
		
	}
	/**
	 *
	 */
	return (
		
		<Form  {...FORM_PROPS} >
			{/*  */}
			<Form.Item name={"Formation"} hidden>
				<Input/>
			</Form.Item>
			<Form.Item label={"Formation visée à l’issue de l’entretien"}>
				<SpaceHorizontal>
					{Inscription._FormationSuivieLibelleTag}</SpaceHorizontal>
			</Form.Item>
			{/*  */}
			<Form.Item name={"statutPourExamen"} label={'Statut pour l\'examen'}>
				<Select>
					{
						Object.values(STATUTS_EXAMEN).map(item =>
							<Select.Option key={key()} value={item.value}>
								{item.label}
							</Select.Option>
						)
					}
				</Select>
			</Form.Item>
			{/*  */}
			<Form.Item name={"modalites"} label={'Modalité de la Formation'}>
				<Select>
					{
						Object.values(FORMATION_MODALITES).map(item =>
							<Select.Option key={key()} value={item.value}>
								{item.label}
							</Select.Option>
						)
					}
				</Select>
			</Form.Item>
			{/*  */}
			<Form.Item label={'Statut ou profil'}>
				{
					!Inscription.FormationSouhaitee.profil && <Tag color={COLOR_RED}>Non défini</Tag>
				}
				{
					Inscription.FormationSouhaitee.profil && <Tag>{FORMATION_PROFILS_VALUES[Inscription.FormationSouhaitee.profil].label}</Tag>
				}
			</Form.Item>
			{/*  */}
			{
				Inscription.FormationSouhaitee.profil === FORMATION_PROFIL_ELEVE_OU_ETUDIANT
				&& <Form.Item label={'Souhait demande de bourse'}>
					<YesNoTag value={Inscription.FormationSouhaitee.souhaitDemandeBourse}/>
				</Form.Item>
			}
			{/*  */}
			{
				
				Inscription.FormationSouhaitee.profil === FORMATION_PROFIL_ACTIVITE_PRO_OU_DEMANDEUR_EMPLOI
				&& <Form.Item label={'Souhait financement de la formation'}>
					<YesNoTag value={Inscription.FormationSouhaitee.souhaitFinancementFormation}/>
				</Form.Item>
				
			}
			{/*  */}
			<Form.Item></Form.Item>
			{ButtonSave}
			{/*  */}
		
		</Form>
	
	);
}))

