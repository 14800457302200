//-----------------------------------------------------------------------------
import {Tag} from "antd";
//
//-----------------------------------------------------------------------------
import {key} from "../helpers/Utils";
import {COLOR_GRAY, COLOR_GREEN, COLOR_ORANGE, COLOR_PRIMARY, COLOR_RED} from "./Constantes.ui";
/**
 *
 */

/**
 *
 * @type {string}
 */

export const TAKER_TOTAL_RDV_AUTORISE = 2
//
export const RDV_STATUT_VALUES = {}
export const RDV_STATUT_DISPONIBLE = "RDV_STATUT_DISPONIBLE"
RDV_STATUT_VALUES[RDV_STATUT_DISPONIBLE] = {
	value: RDV_STATUT_DISPONIBLE,
	label: "Rendu disponible",
	title: "Rendre disponible (attention cela va entraîner l'annulation du rdv)",
	color: COLOR_PRIMARY,
	iconName: "ZeroDayCalendar",
	
}
export const RDV_STATUT_EN_ATTENTE = "RDV_STATUT_EN_ATTENTE"
RDV_STATUT_VALUES[RDV_STATUT_EN_ATTENTE] = {
	value: RDV_STATUT_EN_ATTENTE,
	label: "Mise en attente",
	title: "Rendre en attente",
	color: COLOR_PRIMARY,
	iconName: "Clock",
	
}

export const RDV_STATUT_ACCEPTE = "RDV_STATUT_ACCEPTE"
RDV_STATUT_VALUES[RDV_STATUT_ACCEPTE] = {
	value: RDV_STATUT_ACCEPTE,
	label: "Accepté",
	title: "J'accepte",
	color: COLOR_GREEN,
	iconName: "Like",
	iconNameAccepte: "LikeSolid"
	
}
export const RDV_STATUT_REFUSE = "RDV_STATUT_REFUSE"
RDV_STATUT_VALUES[RDV_STATUT_REFUSE] = {
	value: RDV_STATUT_REFUSE,
	label: "Refusé",
	title: "Je refuse",
	color: COLOR_RED,
	iconName: "Dislike",
	iconNameRefuse: "DislikeSolid"
}
/**
 *
 * @type {null}
 */
/**
 * Attention si renommage , le faire côté back dans sendMailMessage.js
 */
export const MESSAGE_1_2 = "MESSAGE_1_2"
export const MESSAGE_2 = "MESSAGE_2"
export const MESSAGE_2_1 = "MESSAGE_2_1"
export const MESSAGE_3_1 = "MESSAGE_3_1"
export const MESSAGE_3_2 = "MESSAGE_3_2"
export const MESSAGE_3_3 = "MESSAGE_3_3"
export const MESSAGE_3_4 = "MESSAGE_3_4"
export const MESSAGE_3_5 = "MESSAGE_3_5"
export const MESSAGE_3_6 = "MESSAGE_3_6"
export const MESSAGE_4_1 = "MESSAGE_4_1"
export const MESSAGE_4_2 = "MESSAGE_4_2"
export const MESSAGE_5_1 = "MESSAGE_5_1"
export const MESSAGE_5_2 = "MESSAGE_5_2"

/**
 *
 */
export const CONTEXT_FORMULAIRE_CONTACT = "FORMULAIRE_CONTACT"
export const CONTEXT_AUTHENTICATION_WITH_SESSION = "WITH_SESSION"
export const CONTEXT_AUTHENTICATION_WITHOUT_SESSION = "WITHOUT_SESSION"
export const ACCESS_FORMULAIRE_CONTACT_BASE_URL = "https://" + window.location.host + "/?context=" + CONTEXT_FORMULAIRE_CONTACT
export const ACCESS_PLATEFORME_BASE_URL = "https://" + window.location.host + "/?context=" + CONTEXT_AUTHENTICATION_WITHOUT_SESSION
/**
 *
 */
export const APPRENANT_AGE_MINIMUM = 16;
export const AGE_LEGAL = 18;
/**
 * filters constantes
 */
export const FILTER_BY_WORDS = "FILTER_BY_WORDS"
export const FILTER_BY_ROLE = "FILTER_BY_ROLE"
export const FILTER_BY_FAVORITES = "FILTER_BY_FAVORITES"
export const FILTER_BY_ANNEE_CREATION = "FILTER_BY_ANNEE_CREATION"
export const FILTER_BY_VALIDATION_STATUT = "FILTER_BY_VALIDATION_STATUT"
export const FILTER_BY_STATUT = "FILTER_BY_STATUT"
export const FILTER_BY_WITH_INSCRIPTION = "FILTER_BY_WITH_INSCRIPTION"
export const FILTER_BY_PRISE_EN_COMPTE = "FILTER_BY_PRISE_EN_COMPTE"
export const FILTER_BY_FORMATION_SUIVIE = "FILTER_BY_FORMATION_SUIVIE"

export const ORDER_ASCEND = 'ASC';
export const ORDER_DESCEND = 'DESC';
/**
 *
 */
export const UPLOAD_DOCUMENT_MAX = 10 * 1024 * 1024
/**
 * https://developer.mozilla.org/fr/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 * @type {string[]}
 */
export const UPLOAD_DOCUMENT_ACCEPT_DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
export const UPLOAD_DOCUMENT_ACCEPT = [
	"image/jpeg",
	"image/png",
	"application/pdf",
	"application/vnd.oasis.opendocument.text",//odt openoffice
	UPLOAD_DOCUMENT_ACCEPT_DOCX,
	"application/msword",//doc
]

export const UPLOAD_PHOTO_MAX = 5 * 1024 * 1024

export const UPLOAD_PHOTO_ACCEPT = [
	"image/jpeg",
	"image/png",
]
/**
 *
 */
export const DIPLOMATION_RESULTAT_VALUES = {}
export const DIPLOMATION_ADMIS = "DIPLOMATION_ADMIS"
DIPLOMATION_RESULTAT_VALUES[DIPLOMATION_ADMIS] = {
	value: DIPLOMATION_ADMIS,
	label: "Admis"
}
export const DIPLOMATION_AJOURNE = "DIPLOMATION_AJOURNE"
DIPLOMATION_RESULTAT_VALUES[DIPLOMATION_AJOURNE] = {
	value: DIPLOMATION_AJOURNE,
	label: "Ajourné"
}
export const DIPLOMATION_ABSENT = "DIPLOMATION_ABSENT"
DIPLOMATION_RESULTAT_VALUES[DIPLOMATION_ABSENT] = {
	value: DIPLOMATION_ABSENT,
	label: "Absent"
}
Object.values(DIPLOMATION_RESULTAT_VALUES).forEach((item) => {
	return item.tag = <Tag key={key()}>{item.label}</Tag>
	
})
/**
 *
 */
export const DIPLOMATION_CHOIX_INSCRIPTION_VALUES = {}
export const DIPLOMATION_POURSUITE_ETUDES = "DIPLOMATION_POURSUITE_ETUDES"
DIPLOMATION_CHOIX_INSCRIPTION_VALUES[DIPLOMATION_POURSUITE_ETUDES] = {
	value: DIPLOMATION_POURSUITE_ETUDES,
	label: "Poursuite d'études"
}
export const DIPLOMATION_AUDITEUR_LIBRE = "DIPLOMATION_AUDITEUR_LIBRE"
DIPLOMATION_CHOIX_INSCRIPTION_VALUES[DIPLOMATION_AUDITEUR_LIBRE] = {
	value: DIPLOMATION_AUDITEUR_LIBRE,
	label: "Auditeur libre"
}
Object.values(DIPLOMATION_CHOIX_INSCRIPTION_VALUES).forEach((item) => {
	return item.tag = <Tag key={key()} style={{margin: "3px"}}>{item.label}</Tag>
	
})
/**
 *
 */
export const PERIODICITE_ENVOIS = {}
export const PERIODICITE_ENVOI_TRIMESTRIEL = "PERIODICITE_ENVOI_TRIMESTRIEL"
PERIODICITE_ENVOIS[PERIODICITE_ENVOI_TRIMESTRIEL] = {
	value: PERIODICITE_ENVOI_TRIMESTRIEL,
	label: "Envoi trimestriel"
}
export const PERIODICITE_ENVOI_SEMESTRIEL = "PERIODICITE_ENVOI_SEMESTRIEL"
PERIODICITE_ENVOIS[PERIODICITE_ENVOI_SEMESTRIEL] = {
	value: PERIODICITE_ENVOI_SEMESTRIEL,
	label: "Envoi semestriel"
}

/**
 *
 */
export const PROJET_OBJECTIFS = {}
export const OBJECTIF_TROUVER_UN_EMPLOI_DE_SALARIE = "OBJECTIF_TROUVER_UN_EMPLOI_DE_SALARIE"
export const OBJECTIF_TRAVAILLER_A_MON_COMPTE = "OBJECTIF_TRAVAILLER_A_MON_COMPTE"
export const OBJECTIF_POURSUIVRE_MES_ETUDES_OU_PASSER_UN_CONCOURS = "OBJECTIF_POURSUIVRE_MES_ETUDES_OU_PASSER_UN_CONCOURS"
export const OBJECTIF_PASSER_UN_CONCOURS_DU_MA_OU_DU_MEDD = "OBJECTIF_PASSER_UN_CONCOURS_DU_MA_OU_DU_MEDD"

PROJET_OBJECTIFS[OBJECTIF_TROUVER_UN_EMPLOI_DE_SALARIE] = {
	value: OBJECTIF_TROUVER_UN_EMPLOI_DE_SALARIE,
	label: "Trouver un emploi de salarié(e)"
}
//
PROJET_OBJECTIFS[OBJECTIF_TRAVAILLER_A_MON_COMPTE] = {
	value: OBJECTIF_TRAVAILLER_A_MON_COMPTE,
	label: "Travailler à mon compte (ou créer mon entreprise)"
}
//
PROJET_OBJECTIFS[OBJECTIF_POURSUIVRE_MES_ETUDES_OU_PASSER_UN_CONCOURS] = {
	value: OBJECTIF_POURSUIVRE_MES_ETUDES_OU_PASSER_UN_CONCOURS,
	label: "Poursuivre mes études ou passer un concours"
}

PROJET_OBJECTIFS[OBJECTIF_PASSER_UN_CONCOURS_DU_MA_OU_DU_MEDD] = {
	value: OBJECTIF_PASSER_UN_CONCOURS_DU_MA_OU_DU_MEDD,
	label: "Passer un concours du MA ou du MEDD"
}
/**
 *
 */
export const PIECE_JOINTE_CATEGORIE_AGES = {}
//
export const PIECE_JOINTE_CATEGORIE_AGE_1 = "PUBLIC_AGE_1"
PIECE_JOINTE_CATEGORIE_AGES[PIECE_JOINTE_CATEGORIE_AGE_1] = {
	value: PIECE_JOINTE_CATEGORIE_AGE_1,
	label: "Mineur"
}
//
export const PIECE_JOINTE_CATEGORIE_AGE_2 = "PUBLIC_AGE_2"
PIECE_JOINTE_CATEGORIE_AGES[PIECE_JOINTE_CATEGORIE_AGE_2] = {
	value: PIECE_JOINTE_CATEGORIE_AGE_2,
	label: "18-25 ans"
}
//
export const PIECE_JOINTE_CATEGORIE_AGE_3 = "PUBLIC_AGE_3"
PIECE_JOINTE_CATEGORIE_AGES[PIECE_JOINTE_CATEGORIE_AGE_3] = {
	value: PIECE_JOINTE_CATEGORIE_AGE_3,
	label: ">25 ans"
}
Object.values(PIECE_JOINTE_CATEGORIE_AGES).forEach((item) => {
	return item.tag = <Tag key={key()} style={{margin: "3px"}}>{item.label}</Tag>
	
})
/**
 *
 */
export const PIECE_JOINTE_CONFIDENTIALITES_TYPES = {}
//
export const PIECE_JOINTE_CONFIDENTIALITE_OUI = true
PIECE_JOINTE_CONFIDENTIALITES_TYPES[PIECE_JOINTE_CONFIDENTIALITE_OUI] = {
	value: PIECE_JOINTE_CONFIDENTIALITE_OUI,
	label: "Non confidentiel",
	color: COLOR_GREEN
}
//

export const PIECE_JOINTE_CONFIDENTIALITE_NON = false
PIECE_JOINTE_CONFIDENTIALITES_TYPES[PIECE_JOINTE_CONFIDENTIALITE_NON] = {
	value: PIECE_JOINTE_CONFIDENTIALITE_NON,
	label: "confidentiel",
	color: COLOR_RED
}
Object.values(PIECE_JOINTE_CONFIDENTIALITES_TYPES).forEach((item) => {
	return item.tag = <Tag key={key()} color={item.color} style={{margin: "3px"}}>{item.label}</Tag>
	
})

/**
 *
 */
export const MODALITE_PAIEMENT_VALUES = {}
export const MODALITE_PAIEMENT_VIREMENT = "VIREMENT"
export const MODALITE_PAIEMENT_CHEQUE = "CHEQUE"
MODALITE_PAIEMENT_VALUES[MODALITE_PAIEMENT_VIREMENT] = {
	value: MODALITE_PAIEMENT_VIREMENT,
	label: "Virement"
}
MODALITE_PAIEMENT_VALUES[MODALITE_PAIEMENT_CHEQUE] = {
	value: MODALITE_PAIEMENT_CHEQUE,
	label: "Chèque"
}
/**
 *
 */
export const VALIDATION_PAIEMENT_STATUTS = {
	STATUT_EN_ATTENTE: {value: "STATUT_EN_ATTENTE", label: "Paiement en attente", color: COLOR_ORANGE},
	STATUT_ACCEPTE: {value: "STATUT_ACCEPTE", label: "Paiement accepté", color: COLOR_GREEN},
	STATUT_REFUSE: {value: "STATUT_REFUSE", label: "Paiement refusé", color: COLOR_RED}
}
Object.values(VALIDATION_PAIEMENT_STATUTS).forEach((item, index) => {
	return item.tag = <Tag key={key()} color={item.color}>{item.label}</Tag>
	
})

/**
 *
 */
export const VALIDATION_ADMINISTRATIVE_STATUT_ACCEPTE = "VALIDATION_ADMINISTRATIVE_STATUT_ACCEPTE"
export const VALIDATION_ADMINISTRATIVE_STATUTS = {}
VALIDATION_ADMINISTRATIVE_STATUTS[VALIDATION_ADMINISTRATIVE_STATUT_ACCEPTE] = {
	value: VALIDATION_ADMINISTRATIVE_STATUT_ACCEPTE,
	label: "Dossier accepté",
	color: COLOR_GREEN
}

export const VALIDATION_ADMINISTRATIVE_STATUT_REFUSE = "VALIDATION_ADMINISTRATIVE_STATUT_REFUSE"
VALIDATION_ADMINISTRATIVE_STATUTS[VALIDATION_ADMINISTRATIVE_STATUT_REFUSE] = {
	value: VALIDATION_ADMINISTRATIVE_STATUT_REFUSE,
	label: "Dossier refusé",
	color: COLOR_RED
}
Object.values(VALIDATION_ADMINISTRATIVE_STATUTS).forEach((item, index) => {
	return item.tag = <Tag key={key()} color={item.color} style={{margin: "3px"}}>{item.label}</Tag>
	
})
/**
 *
 */
export const CONTACT_CATEGORIE_VALUES = {
	APPRENANT: {value: 'APPRENANT', label: 'Apprenant'},
	PERE: {value: 'PERE', label: 'Père'},
	MERE: {value: 'MERE', label: 'Mère'},
	AUTRES: {value: 'AUTRES', label: 'Autres'},
};
//
export const CIVILITE_VALUES = {
	MONSIEUR: {value: 'MONSIEUR', label: 'Monsieur'},
	MADAME: {value: 'MADAME', label: 'Madame'}
};
export const NATIONALITE_VALUES = {
	FRANCAIS: {value: 'FRANCAIS', label: 'Française'},
	UE: {value: 'UE', label: 'Union européenne'},
	HORS_UE: {value: 'HORS_UE', label: 'Hors union européenne'}
}
export const PARENTE_VALUES = {
	MERE: {value: 'MERE', label: 'Mère'},
	PERE: {value: 'PERE', label: 'Père'},
	TUTEUR_LEGAL: {value: 'TUTEUR_LEGAL', label: 'Tuteur légal'}
}
export const VOIES_TYPES_VALUES = {
	RUE: {value: 'RUE', label: 'Rue'},
	BOULEVARD: {value: 'BOULEVARD', label: 'Boulevard'},
	AVENUE: {value: 'AVENUE', label: 'Avenue'},
	IMPASSE: {value: 'IMPASSE', label: 'Impasse'},
	LIEU_DIT: {value: 'LIEU_DIT', label: 'Lieu-dit'}
}
/*
export const PHONES_TYPE_PORTABLE_PERSONNEL="PORTABLE_PERSONNEL"
export const PHONES_TYPE_PORTABLE_PRO="PORTABLE_PRO"
export const PHONES_TYPE_FIXE_PERSONNEL="FIXE_PERSONNEL"

 */
export const PHONES_TYPES = {
	FIXE_PERSONNEL: {value: 'FIXE_PERSONNEL', label: 'Téléphone personnel fixe'},
	PORTABLE_PERSONNEL: {value: 'PORTABLE_PERSONNEL', label: 'Téléphone personnel portable'},
	FIXE_PRO: {value: 'FIXE_PRO', label: 'Téléphone professionnel fixe'},
	PORTABLE_PRO: {value: 'PORTABLE_PRO', label: 'Téléphone professionnel portable'},
}
/**
 *
 */
export const BTSA = "BTSA"
export const BAC_PRO = "BAC PRO"
export const BAC_TECHNO = "BAC TECHNO"
export const LANGUE_EN_ETABLISSEMENT = "langues en etablissement"
//
export const ESPAGNOL_VALUE = {value: 'ESPAGNOL', label: 'Espagnol'}
export const ALLEMAND_VALUE = {value: 'ALLEMAND', label: 'Allemand'}
export const ITALIEN_VALUE = {value: 'ITALIEN', label: 'Italien'}
export const ANGLAIS_VALUE = {value: 'ANGLAIS', label: 'Anglais'}
//
export const LV1 = {value: 'LV1', label: 'LV1/LVA', color: "cyan"}
export const LV2 = {value: 'LV2', label: 'LV2/LVB', color: "purple"}
export const LV3 = {value: 'LV3', label: 'LV3/LVC', color: "magenta"}
//
export const LANGUES_VIVANTES_VALUES = {}
LANGUES_VIVANTES_VALUES[BTSA] = [LV1]
LANGUES_VIVANTES_VALUES[BAC_PRO] = [LV1, LV2]
LANGUES_VIVANTES_VALUES[BAC_TECHNO] = [LV1, LV2]
LANGUES_VIVANTES_VALUES[LANGUE_EN_ETABLISSEMENT] = [LV1, LV2, LV3]

Object.values(LANGUES_VIVANTES_VALUES).forEach(LVs => {
	
	LVs.forEach(LV => {
		LV.tag = <Tag color={LV.color}>{LV.label}</Tag>
		LV.items = [
			ALLEMAND_VALUE,
			ESPAGNOL_VALUE,
			ITALIEN_VALUE,
			ANGLAIS_VALUE
		]
		
	})
})

/**
 *
 */
export const POSSIBILITES_AIDE_VALUES = {
	PARENTS: {value: "PARENTS", label: "Parents"},
	AMIS: {value: "AMIS", label: "Amis"},
	PROFESSEUR: {value: "PROFESSEUR", label: "Professeur"},
	REFERENT_EN_ETABLISSEMENT: {value: "REFERENT_EN_ETABLISSEMENT", label: "Référent en établissement"},
	ETUDIANT: {value: "ETUDIANT", label: "Étudiant"},
	AUTRE: {value: "AUTRE", label: "Autre"},
}
/**
 *
 */
export const LANGUES_VIVANTES_PARCOURS_ANTERIEUR_VALUES = {
	CNED: {value: "CNED", label: "CNED"},
	COLLEGE: {value: "COLLEGE", label: "Collège"},
	LYCEE: {value: "LYCEE", label: "Lycée"},
	AUTRE: {value: "AUTRE", label: "Autre"},
}
export const LANGUES_VIVANTES_EXPERIENCE_VALUES = {
	VOYAGE: {value: "VOYAGE", label: "voyage"},
	FAMILLE: {value: "FAMILLE", label: "famille"},
	AUTRE: {value: "AUTRE", label: "Autre"},
}
/**
 *
 */
export const PUBLIC_TYPES_VALUES = {
	CHEF_ENTREPRISE: {value: "CHEF_ENTREPRISE", label: "Chef d'entreprise"},
	ARTISAN: {value: 'ARTISAN', label: 'Artisan'},
	COMMERÇANT: {value: 'COMMERÇANT', label: 'Commerçant'},
	TRAVAILLEUR_INDEPENDANT: {value: 'TRAVAILLEUR_INDEPENDANT', label: 'Travailleur indépendant'},
	PROFESSION_LIBERALE: {value: 'PROFESSION_LIBERALE', label: 'Profession libérale'},
}
/**
 *
 */
export const REINSCRIPTION_MOTIFS = {}
export const REINSCRIPTION_MOTIF_AUDITEUR_LIBRE = "MOTIF_AUDITEUR_LIBRE"
REINSCRIPTION_MOTIFS[REINSCRIPTION_MOTIF_AUDITEUR_LIBRE] = {
	value: REINSCRIPTION_MOTIF_AUDITEUR_LIBRE,
	label: "Auditeur libre",
}
export const REINSCRIPTION_MOTIF_PROLONGEMENT = "MOTIF_PROLONGEMENT"
REINSCRIPTION_MOTIFS[REINSCRIPTION_MOTIF_PROLONGEMENT] = {
	value: REINSCRIPTION_MOTIF_PROLONGEMENT,
	label: "Prolongation de formation",
}
/**
 *
 */
export const STATUTS_EXAMEN = {
	STATUT_CANDIDAT_ISOLE: {value: 'STATUT_CANDIDAT_ISOLE', label: 'Candidat isolé (libre : sous réserve d’expérience professionnelle ou individuel : ajournement à l’examen)'},
	STATUT_CANDIDAT_PRESENTE: {value: 'STATUT_CANDIDAT_PRESENTE', label: 'Candidat présenté par l’Institut Agro Dijon – Direction de l’Enseignement à Distance'},
}
/**
 *
 */
export const FORMATION_MODALITES = {
	MODALITE_INSCRIT_PARCOURS_COMPLET: {
		value: 'MODALITE_INSCRIT_PARCOURS_COMPLET',
		label: 'Inscrit au parcours complet'// (modules généraux et modules techniques)
	},
	MODALITE_DISPENSE: {
		
		value: 'MODALITE_DISPENSE',
		label: 'Dispensé d\'épreuve',
		info: "Vous devez joindre obligatoirement la copie du diplôme autorisant réglementairement votre dispense)"
	},
	MODALITE_AUDITEUR_LIBRE: {
		value: 'MODALITE_AUDITEUR_LIBRE',
		label: 'Auditeur libre (ne souhaite pas passer l’examen)'
	},
	
	MODALITE_AUDITEUR_LIBRE_STAV: {
		value: 'MODALITE_AUDITEUR_LIBRE_STAV',
		label: 'Auditeur libre (STAV)'
	},
	
	MODALITE_REDOUBLANT_DIRED: {
		value: 'MODALITE_REDOUBLANT_DIRED',
		label: 'Redoublant Dired'
	},
	MODALITE_REDOUBLANT_EXTERIEUR: {
		value: 'MODALITE_REDOUBLANT_EXTERIEUR',
		label: 'Redoublant extérieur',
		info: "Vous devez joindre obligatoirement la copie du relevé de notes de l’examen et surligner les épreuves à repasser",
		infoParDiplome: {
			"stav": "Vous devez joindre les relevés de notes ou bulletins de notes pour les matières du contrôle continu"
		}
	},
	MODALITE_TRONC_COMMUN: {
		value: 'MODALITE_TRONC_COMMUN',
		label: 'Tronc commun'
	},
}

/**
 *
 */
export const FORMATION_PROFILS_VALUES = {}
export const FORMATION_PROFIL_ELEVE_OU_ETUDIANT = "ELEVE_OU_ETUDIANT"
FORMATION_PROFILS_VALUES[FORMATION_PROFIL_ELEVE_OU_ETUDIANT] = {
	value: FORMATION_PROFIL_ELEVE_OU_ETUDIANT,
	label: "Elève / Etudiant / Poursuite de formation (FI)",
}
export const FORMATION_PROFIL_ACTIVITE_PRO_OU_DEMANDEUR_EMPLOI = "ACTIVITE_PRO_OU_DEMANDEUR_EMPLOI"
FORMATION_PROFILS_VALUES[FORMATION_PROFIL_ACTIVITE_PRO_OU_DEMANDEUR_EMPLOI] = {
	value: FORMATION_PROFIL_ACTIVITE_PRO_OU_DEMANDEUR_EMPLOI,
	label: "Activité professionnelle ou demandeur d'emploi (FC)",
}

/**
 *
 */
export const FORMATION_TYPES_VALUES = {
	FORMATION_INITIALE: {value: "FORMATION_INITIALE", label: "Formation initiale"},
	FORMATION_CONTINUE: {value: "FORMATION_CONTINUE", label: "Formation continue"},
}
export const FORMATION_INITIALE_TYPES_VALUES = {
	MINEUR: {value: "MINEUR", label: "Mineur"},
	MAJEUR: {value: "MAJEUR", label: "Majeur"},
	ETUDIANT_BOURSIER: {value: "ETUDIANT_BOURSIER", label: "Etudiant boursier"},
	ETUDIANT_NON_BOURSIER: {value: "ETUDIANT_NON_BOURSIER", label: "Etudiant non boursier"},
}

/**
 *
 */
export const SITUATION_PROFESSIONNELLES_TYPES_VALUES = {}
//
export const SITUATION_PROFESSIONNELLES_TYPE_ETUDIANT = "ETUDIANT"
SITUATION_PROFESSIONNELLES_TYPES_VALUES[SITUATION_PROFESSIONNELLES_TYPE_ETUDIANT] = {
	value: SITUATION_PROFESSIONNELLES_TYPE_ETUDIANT,
	label: "Etudiant",
}
export const SITUATION_PROFESSIONNELLES_TYPE_DEMANDEUR_EMPLOI = "DEMANDEUR_EMPLOI"
SITUATION_PROFESSIONNELLES_TYPES_VALUES[SITUATION_PROFESSIONNELLES_TYPE_DEMANDEUR_EMPLOI] = {
	value: SITUATION_PROFESSIONNELLES_TYPE_DEMANDEUR_EMPLOI,
	label: "Demandeur d'emploi",
}
export const SITUATION_PROFESSIONNELLES_SALARIE = "SALARIE"
SITUATION_PROFESSIONNELLES_TYPES_VALUES[SITUATION_PROFESSIONNELLES_SALARIE] = {
	value: SITUATION_PROFESSIONNELLES_SALARIE,
	label: "Salarié",
}

export const SITUATION_PROFESSIONNELLES_TYPE_TRAVAILLEUR_INDEPENDANT_OU_PROFESSION_LIBERALE = "TRAVAILLEUR_INDEPENDANT_OU_PROFESSION_LIBERALE"
SITUATION_PROFESSIONNELLES_TYPES_VALUES[SITUATION_PROFESSIONNELLES_TYPE_TRAVAILLEUR_INDEPENDANT_OU_PROFESSION_LIBERALE] = {
	value: SITUATION_PROFESSIONNELLES_TYPE_TRAVAILLEUR_INDEPENDANT_OU_PROFESSION_LIBERALE,
	label: "Travailleur indépendant",
}

/**
 *
 */

export const FINANCEMENT_SOURCES = [
	{value: 'FINANCEMENT_SOURCE_1', label: 'Contrat d’apprentissage'},
	{value: 'FINANCEMENT_SOURCE_2', label: 'Contrat de professionnalisation'},
	{value: 'FINANCEMENT_SOURCE_3', label: 'Congé individuel de formation et projets de transition professionnelle'},
	{value: 'FINANCEMENT_SOURCE_4', label: 'Compte personnel de formation'},
	{value: 'FINANCEMENT_SOURCE_5', label: 'Dispositif spécifique pour les personnes en recherche d’emploi'},
	{value: 'FINANCEMENT_SOURCE_6', label: 'Plan de développement des compétences et autres dispositifs'},
]

/**
 * INSCRIPTION_TYPES
 */
export const INSCRIPTION_TYPES = {}
//
export const INSCRIPTION_TYPE_NOUVELLE = "TYPE_NOUVELLE_INSCRIPTION"
INSCRIPTION_TYPES[INSCRIPTION_TYPE_NOUVELLE] = {
	value: INSCRIPTION_TYPE_NOUVELLE,
	label: "Nouvelle inscription"
}
export const INSCRIPTION_TYPE_REINSCRIPTION = "TYPE_REINSCRIPTION"
INSCRIPTION_TYPES[INSCRIPTION_TYPE_REINSCRIPTION] = {
	value: INSCRIPTION_TYPE_REINSCRIPTION,
	label: "Réinscription"
}
//
Object.values(INSCRIPTION_TYPES).forEach((item, index) => {
	return item.tag = <Tag key={key()}>{item.name}</Tag>
	
})

export const INSCRIPTION_PROJET_STATUTS = {}

export const INSCRIPTION_PROJET_STATUT_EN_ATTENTE = "PROJET_EN_ATTENTE"
INSCRIPTION_PROJET_STATUTS[INSCRIPTION_PROJET_STATUT_EN_ATTENTE] =
	{
		value: INSCRIPTION_PROJET_STATUT_EN_ATTENTE,
		label: 'Avis en attente',
		color: COLOR_GRAY
	}

export const INSCRIPTION_PROJET_STATUT_ACCEPTE = "PROJET_ACCEPTE"
INSCRIPTION_PROJET_STATUTS[INSCRIPTION_PROJET_STATUT_ACCEPTE] =
	{
		value: INSCRIPTION_PROJET_STATUT_ACCEPTE,
		label: 'Avis favorable pour dépôt de pièces administratives',
		color: COLOR_GREEN
	}

export const INSCRIPTION_PROJET_STATUT_REFUSE = "PROJET_REFUSE"
INSCRIPTION_PROJET_STATUTS[INSCRIPTION_PROJET_STATUT_REFUSE] =
	{
		value: INSCRIPTION_PROJET_STATUT_REFUSE,
		label: 'Avis défavorable',
		color: COLOR_RED
	}

Object.values(INSCRIPTION_PROJET_STATUTS).forEach((item) => {
	return item.tag = <Tag key={key()} color={item.color}>{item.label}</Tag>
})
/**
 * INSCRIPTION_STATUTS
 */
export const APPRENANT_STATUTS = {}
//
export const APPRENANT_STATUT_PROSPECT = "STATUT_PROSPECT"
APPRENANT_STATUTS[APPRENANT_STATUT_PROSPECT] = {
	value: APPRENANT_STATUT_PROSPECT,
	label: "Prospect",
	color: COLOR_GRAY
	
}
export const APPRENANT_STATUT_PREINSCRIT = "STATUT_PREINSCRIT"
APPRENANT_STATUTS[APPRENANT_STATUT_PREINSCRIT] = {
	value: APPRENANT_STATUT_PREINSCRIT,
	label: "Préinscrit",
	color: COLOR_ORANGE
}
export const APPRENANT_STATUT_INSCRIT = "STATUT_INSCRIT"
APPRENANT_STATUTS[APPRENANT_STATUT_INSCRIT] = {
	value: APPRENANT_STATUT_INSCRIT,
	label: "Inscrit",
	color: COLOR_GREEN
}
export const APPRENANT_STATUT_ABANDON = "STATUT_ABANDON"
APPRENANT_STATUTS[APPRENANT_STATUT_ABANDON] = {
	value: APPRENANT_STATUT_ABANDON,
	label: "Abandon",
	color: COLOR_RED
}
export const APPRENANT_STATUT_FORMATION_TERMINEE = "STATUT_FORMATION_TERMINEE"
APPRENANT_STATUTS[APPRENANT_STATUT_FORMATION_TERMINEE] = {
	value: APPRENANT_STATUT_FORMATION_TERMINEE,
	label: "Formation terminée",
	color: "blue"
}
Object.values(APPRENANT_STATUTS).forEach((item, index) => {
	return item.tag = <Tag key={key()} color={item.color}>{item.label}</Tag>
})
//
/**
 * INSCRIPTION_STATUTS
 */
export const INSCRIPTION_STATUTS = {}

export const INSCRIPTION_STATUT_VALIDATION_EN_ATTENTE = "STATUT_VALIDATION_EN_ATTENTE"
INSCRIPTION_STATUTS[INSCRIPTION_STATUT_VALIDATION_EN_ATTENTE] = {
	value: INSCRIPTION_STATUT_VALIDATION_EN_ATTENTE,
	label: "En attente"
}
//
export const INSCRIPTION_STATUT_VALIDATION_PEDAGOGIQUE_EN_ATTENTE = "STATUT_VALIDATION_PEDAGOGIQUE_EN_ATTENTE"
INSCRIPTION_STATUTS[INSCRIPTION_STATUT_VALIDATION_PEDAGOGIQUE_EN_ATTENTE] = {
	value: INSCRIPTION_STATUT_VALIDATION_PEDAGOGIQUE_EN_ATTENTE,
	label: "Validation pédago. en attente"
}
//
export const INSCRIPTION_STATUT_VALIDATION_PEDAGOGIQUE_ACCEPTE = "STATUT_VALIDATION_PEDAGOGIQUE_ACCEPTE"
INSCRIPTION_STATUTS[INSCRIPTION_STATUT_VALIDATION_PEDAGOGIQUE_ACCEPTE] = {
	value: INSCRIPTION_STATUT_VALIDATION_PEDAGOGIQUE_ACCEPTE,
	label: "Validation pédago. acceptée",
	status: "process",
	color: COLOR_GREEN
}

export const INSCRIPTION_STATUT_VALIDATION_PEDAGOGIQUE_REFUSE = "STATUT_VALIDATION_PEDAGOGIQUE_REFUSE"
INSCRIPTION_STATUTS[INSCRIPTION_STATUT_VALIDATION_PEDAGOGIQUE_REFUSE] = {
	value: INSCRIPTION_STATUT_VALIDATION_PEDAGOGIQUE_REFUSE,
	label: "Validation pédago. refusée",
	status: "error",
	color: COLOR_RED
}

export const INSCRIPTION_STATUT_VALIDATION_ADMINISTRATIVE_EN_ATTENTE = "STATUT_VALIDATION_ADMINISTRATIVE_EN_ATTENTE"
INSCRIPTION_STATUTS[INSCRIPTION_STATUT_VALIDATION_ADMINISTRATIVE_EN_ATTENTE] = {
	value: INSCRIPTION_STATUT_VALIDATION_ADMINISTRATIVE_EN_ATTENTE,
	label: "Validation admin. en attente"
}
//
export const INSCRIPTION_STATUT_VALIDATION_ADMINISTRATIVE_ACCEPTE = "STATUT_VALIDATION_ADMINISTRATIVE_ACCEPTE"
INSCRIPTION_STATUTS[INSCRIPTION_STATUT_VALIDATION_ADMINISTRATIVE_ACCEPTE] = {
	value: INSCRIPTION_STATUT_VALIDATION_ADMINISTRATIVE_ACCEPTE,
	label: "Validation admin. acceptée",
	status: "process",
	color: COLOR_GREEN
}
//
export const INSCRIPTION_STATUT_VALIDATION_ADMINISTRATIVE_REFUSE = "STATUT_VALIDATION_ADMINISTRATIVE_REFUSE"
INSCRIPTION_STATUTS[INSCRIPTION_STATUT_VALIDATION_ADMINISTRATIVE_REFUSE] = {
	value: INSCRIPTION_STATUT_VALIDATION_ADMINISTRATIVE_REFUSE,
	label: "Validation admin. refusée",
	status: "error",
	color: COLOR_RED
}
//
Object.values(INSCRIPTION_STATUTS).forEach((item, index) => {
	return item.tag = <Tag key={key()}>{item.label}</Tag>
	
})
/**
 *
 */
export const PREPROSPECT_DEMANDE_STATUTS = {}
export const PREPROSPECT_DEMANDE_NON_ENVOYEE = "PREPROSPECT_DEMANDE_NON_ENVOYEE"
export const PREPROSPECT_DEMANDE_ENVOYEE = "PREPROSPECT_DEMANDE_ENVOYEE"
//
PREPROSPECT_DEMANDE_STATUTS[PREPROSPECT_DEMANDE_NON_ENVOYEE] = {
	value: PREPROSPECT_DEMANDE_NON_ENVOYEE,
	label: "Demande non envoyée.",
	color: "red",
	message: "Pour accèder à la plateforme, vous  devez accepter les conditions générales en cochant la case ci-dessous puis \"j'envoie ma demande\". Un lien d'accès à la plateforme vous sera envoyé.",
	type: "info"
}
//
PREPROSPECT_DEMANDE_STATUTS[PREPROSPECT_DEMANDE_ENVOYEE] = {
	value: PREPROSPECT_DEMANDE_ENVOYEE,
	label: "Demande envoyée.",
	color: "",
	message: "Votre demande a été envoyée et sera rapidement traitée.",
	type: "info"
}
//
//

//
Object.values(PREPROSPECT_DEMANDE_STATUTS).forEach((item, index) => {
	return item.tag = <Tag key={key()} color={item.color}>{item.label}</Tag>
	
})
/**
 *
 */

export const MOTIF_ABSENCES = {}
export const MOTIF_ABSENCE_1 = "MOTIF_ABSENCE_1"
export const MOTIF_ABSENCE_2 = "MOTIF_ABSENCE_2"
MOTIF_ABSENCES[MOTIF_ABSENCE_1] = {
	value: MOTIF_ABSENCE_1,
	label: "Absence justifiée arrêt maladie",
}

MOTIF_ABSENCES[MOTIF_ABSENCE_2] = {
	value: MOTIF_ABSENCE_2,
	label: "Absence non justifiée",
}
/**
 *
 */
export let COMPOSANT_TYPES = {}
//
export const COMPOSANT_TYPE_PARCOURS = "PARCOURS"
export const COMPOSANT_TYPE_MODULE = "MODULE"
export const COMPOSANT_TYPE_DISCIPLINE = "DISCIPLINE"
export const COMPOSANT_TYPE_EVALUATION = "EVALUATION"
export const COMPOSANT_TYPE_COURS = "COURS"
export const COMPOSANT_TYPE_DOMAINE_TECHNOLOGIQUE = "DOMAINE_TECHNOLOGIQUE"
export const COMPOSANT_TYPE_BLOC_COMPETENCE = "BLOC_COMPETENCE"
export const COMPOSANT_TYPE_UNITE_ENSEIGNEMENT = "UNITE_ENSEIGNEMENT"
export const COMPOSANT_TYPE_CAPACITE_INTERMEDIAIRE = "CAPACITE_INTERMEDIAIRE"
export const COMPOSANT_TYPE_SESSION = "SESSION"
export const COMPOSANT_TYPE_CLASSE_VIRTUELLE = "CLASSE_VIRTUELLE"
export const COMPOSANT_TYPE_EPREUVE = "EPREUVE"

/**
 *
 * @type {{color: string, name: string, enfantTypes: (string)[], value: string}}
 */
COMPOSANT_TYPES[COMPOSANT_TYPE_PARCOURS] = {
	value: COMPOSANT_TYPE_PARCOURS,
	name: "Parcours",
	color: "#607D8B"
}
/**
 * EVALUATION
 */
COMPOSANT_TYPES[COMPOSANT_TYPE_MODULE] = {
	value: COMPOSANT_TYPE_MODULE,
	name: "Module",
	color: "#6264a7"
}
/**
 * EVALUATION
 */

COMPOSANT_TYPES[COMPOSANT_TYPE_DISCIPLINE] = {
	value: COMPOSANT_TYPE_DISCIPLINE,
	name: "Discipline",
	color: "#ff4d4f"
}
/**
 * EVALUATION
 */

COMPOSANT_TYPES[COMPOSANT_TYPE_EVALUATION] = {
	value: COMPOSANT_TYPE_EVALUATION,
	name: "Evaluation",
	color: "#0091EA"
}

/**
 * COURS
 */
COMPOSANT_TYPES[COMPOSANT_TYPE_COURS] = {
	value: COMPOSANT_TYPE_COURS,
	name: "Cours",
	color: "#eb2f96"
}

/**
 * COMPOSANT_TYPE_DOMAINE_TECHNOLOGIQUE
 */
COMPOSANT_TYPES[COMPOSANT_TYPE_DOMAINE_TECHNOLOGIQUE] = {
	value: COMPOSANT_TYPE_DOMAINE_TECHNOLOGIQUE,
	name: "Domaine technologique",
	color: "#f759ab"
}

/**
 * BLOC_COMPETENCE
 */
COMPOSANT_TYPES[COMPOSANT_TYPE_BLOC_COMPETENCE] = {
	value: COMPOSANT_TYPE_BLOC_COMPETENCE,
	name: "Bloc de Compétences",
	color: "#fa8c16"
}

/**
 * UNITE_ENSEIGNEMENT
 */
COMPOSANT_TYPES[COMPOSANT_TYPE_UNITE_ENSEIGNEMENT] = {
	value: COMPOSANT_TYPE_UNITE_ENSEIGNEMENT,
	name: "Unité d'Enseignement",
	color: "#9C27B0"
}
/**
 * CAPACITE_INTERMEDIAIRE
 */

COMPOSANT_TYPES[COMPOSANT_TYPE_CAPACITE_INTERMEDIAIRE] = {
	value: COMPOSANT_TYPE_CAPACITE_INTERMEDIAIRE,
	name: "Capacité Intermédiaire",
	color: "#6200EA"
}
//
/**
 * SESSION
 */
COMPOSANT_TYPES[COMPOSANT_TYPE_SESSION] = {
	value: COMPOSANT_TYPE_SESSION,
	name: "Session",
	color: "#4dd0e1"
}
//
/**
 * CLASSE_VIRTUELLE
 */
COMPOSANT_TYPES[COMPOSANT_TYPE_CLASSE_VIRTUELLE] = {
	value: COMPOSANT_TYPE_CLASSE_VIRTUELLE,
	name: "Classe virtuelle",
	color: "#00C853",
}

/**
 * CLASSE_EPREUVE
 */
COMPOSANT_TYPES[COMPOSANT_TYPE_EPREUVE] = {
	value: COMPOSANT_TYPE_EPREUVE,
	name: "Epreuve",
	color: "#00BFA5"
}

Object.values(COMPOSANT_TYPES).forEach((COMPOSANT_TYPE, index) => {
	return COMPOSANT_TYPE.tag = <Tag key={key()} color={COMPOSANT_TYPE.color} style={{margin: "3px"}}>{COMPOSANT_TYPE.name}</Tag>
	
})
Object.freeze(COMPOSANT_TYPES);
