import {AppstoreAddOutlined, FolderOpenOutlined} from "@ant-design/icons";
import {Space, Table, Tag, Tooltip} from "antd";
import {inject, observer} from "mobx-react";
import React from "react";
import {Button, ButtonIcon} from "../../../components/antExtendedComponent/Button";
import {BadgeCount} from "../../../components/BadgeCount";
import {Icon} from "../../../components/Icon";
import {IconInfo} from "../../../components/IconInfo";
import {YES_NO_LABEL} from "../../../components/SelectYesNo";
import {APPRENANT_STATUT_INSCRIT} from "../../../config/Constantes.logic";
import {COLOR_GREEN, COLOR_ORANGE, COLOR_RED, COLUMN_ICON_WIDTH, DEFAULT_PAGINATION, DEFAULT_TABLE_SCROLL} from "../../../config/Constantes.ui";
import {simpleSorter} from "../../../helpers/Utils";
import {DRAWERS_NAMES} from "../../../stores/NavigationStore";
import {TAB_KEY_APPRENANT_INSCRIPTIONS} from "../../apprenant/Apprenant";
import {CopyReference} from "../../common/components/CopyReference";
import {CreateurModificateurIcon} from "../../common/components/CreateurModificateurIcon";
import {destroyAllDrawers, openDrawer} from "../../common/components/drawerManager";
import {FavoriteButton} from "../../common/components/FavoriteButton";
import {TAB_KEY_VOEUX} from "../Inscription";
import {TAB_KEY_MAQUETTE} from "../../vieApprenante/VieApprenants";
import {VieApprenant} from "../../vieApprenante/VieApprenant";

/**
 *
 */
export const InscriptionGrid = inject("store")(observer(props => {
	/**
	 *
	 */
	
	const {store, Records, smallVersion} = props
	const {InscriptionStore, ApprenantStore} = store
	const apprenantDrawerName = DRAWERS_NAMES.APPRENANT_FORM_DRAWER
	//
	/*
	InscriptionStore.getAll().forEach(Inscription=>{
		if(!Inscription.reInscription)
		{
			if(!Inscription.FormationSuivie.Formation&& Inscription.ProjetProfessionnelChoisi)
			{
				
				let FormationSuivie={...Inscription.FormationSuivie}
				FormationSuivie.Formation=Inscription.ProjetProfessionnelChoisi.FormationChoisieApresEntretien._id
				Inscription.update({FormationSuivie:FormationSuivie})
				Inscription.save(()=>{})
			}
			
		}
		
		
	})
	
	 */
	/**
	 *
	 */
	const edit = (Inscription) => {
		Inscription.Apprenant.edit()
		InscriptionStore.setSelectedRecord(Inscription)
		ApprenantStore.setApprenantPageTabsActiveKey(TAB_KEY_APPRENANT_INSCRIPTIONS)
		ApprenantStore.setInscriptionPageTabsActiveKey(TAB_KEY_VOEUX)
		
		destroyAllDrawers()
		openDrawer(apprenantDrawerName)
	}
	/**
	 *
	 * permet d'afficher la ligne qui a été éditéé
	 */
	const editInscription = (Inscription) => {
		//Inscription.Apprenant.edit()
		InscriptionStore.setSelectedRecord(Inscription)
		ApprenantStore.setInscriptionPageTabsActiveKey(TAB_KEY_VOEUX)
	}
	const openVieApprenanteDrawer = (Inscription) => {
		InscriptionStore.setSelectedRecord(Inscription)
		const maquetteDrawerName = DRAWERS_NAMES.MAQUETTE_FORM_DRAWER
		ApprenantStore.setVieApprenantePageTabsActiveKey(TAB_KEY_MAQUETTE)
		openDrawer(maquetteDrawerName)
	};
	/**
	 *
	 */
	const DefaultColumns = [
		
		{
			//
			fullVersion: true,
			//
			dataIndex: '_isFavorite',
			title: '',
			width: COLUMN_ICON_WIDTH,
			fixed: "left",
			className: "center",
			render: (text, Record) => <FavoriteButton Record={Record}/>
		},
		{
			smallVersion: true,
			//
			dataIndex: 'actionSelect',
			title: <Tooltip title="Nombre d'inscriptions d'un apprenant" placement="top"><BadgeCount total={Records.length}/></Tooltip>,
			width: COLUMN_ICON_WIDTH,
			fixed: "left",
			className: "center",
			render: (text, Record) => {
				const ghost = !((InscriptionStore.SelectedRecord && InscriptionStore.SelectedRecord._id) === Record._id)
				return <Tooltip title="Modifier" placement={"left"}>
					<ButtonIcon
						ghost={ghost}
						icon={<FolderOpenOutlined/>}
						onClick={(e) => {
							e.preventDefault();
							editInscription(Record)
						}}
					/>
				</Tooltip>
			}
		},
		/*
		{
			fullVersion : true,
			//
			dataIndex : 'actionEdit',
			title : '',
			width : COLUMN_ICON_WIDTH,
			fixed : "left",
			className : "center",
			render : (text, Record) => {
				const ghost = !((InscriptionStore.SelectedRecord && InscriptionStore.SelectedRecord._id) === Record._id)
				return <Tooltip title="Modifier">
					<ButtonIcon
						ghost={ghost}
						icon={<EditOutlined/>}
						onClick={(e) => {
							e.preventDefault();
							edit(Record)
						}}
					/>
				</Tooltip>
			}
		},
		*/
		{
			smallVersion: true,
			//
			dataIndex: 'actionDuplicate',
			title: <Tooltip title="Inscription duplicable" placement="top"><Icon name={"Copy"}/></Tooltip>,
			width: COLUMN_ICON_WIDTH,
			fixed: "left",
			className: "center",
			render: (text, Record) => Record.isDuplicable && <Tooltip title={"duplicable"} placement="right"><IconInfo name={"Copy"}/></Tooltip>
			
		},
		
		/**
		 * Maquette
		 */
		{
			fullVersion: true,
			smallVersion: true,
			//
			dataIndex: "Maquette",
			title: <Tooltip title="Vie apprenant" placement="top"><AppstoreAddOutlined/></Tooltip>,
			width: 50,
			className: "center",
			fixed: "left",
			ellipsis: true,
			render: (text, Record) => {
				if (Record.statutApprenant === APPRENANT_STATUT_INSCRIT) {
					const FormationSuivie = Record._FormationSuivie
					const empty = !FormationSuivie || (FormationSuivie && FormationSuivie.ComposantAssocies.length === 0)
					if (empty) {
						return <Tooltip title={"Édition impossible car La maquette de la formation est vide"}>
							<AppstoreAddOutlined style={{color: COLOR_RED}}/>
						</Tooltip>
					}
					//
					let color = COLOR_ORANGE
					let title = "Sélection des composants à partir de la maquette non effectuée"
					if (Record.Maquette.date) {
						title = "Sélection des composants à partir de la maquette effectuée"
						color = COLOR_GREEN
					}
					return <Tooltip title={title}>
						<Button
							size={"small"}
							type={"dashed"}
							shape="circle"
							style={{color: color, borderColor: color}}
							icon={<AppstoreAddOutlined/>}
							onClick={(e) => {
								e.preventDefault();
								openVieApprenanteDrawer(Record)
							}}
						/>
					</Tooltip>
				}
				
			}
		},
		
		/**
		 * numero
		 */
		{
			
			//
			fullVersion: true,
			smallVersion: true,
			//
			dataIndex: "numero",
			title: 'N°',
			width: 60,
			fixed: "left",
			className: "center",
			sorter: simpleSorter("numero"),
			render: (text, Record) => Record.numeroTag
		},
		/**
		 * fullname
		 */
		{
			fullVersion: true,
			//
			dataIndex: "apprenantFullname",
			title: 'Apprenant',
			width: 200,
			fixed: "left",
			ellipsis: true,
			sorter: simpleSorter("apprenantFullname"),
			render: (text, Record) =>
				<a href="/" onClick={(e) => {
					e.preventDefault();
					edit(Record)
				}}>{Record.Apprenant.fullname}
				</a>
		},
		/**
		 * Moodle
		 */
		{
			
			//
			fullVersion: true,
			//
			dataIndex: "Moodle",
			title: 'moodle',
			width: 90,
			fixed: "left",
			sorter: (a, b) => a.Apprenant.CompteExternes.moodle - b.Apprenant.CompteExternes.moodle,
			render: (text, Record) => {
				const moodle = Record.Apprenant.CompteExternes.moodle
				return moodle > 0 && <Tag>moodle <b>{moodle}</b></Tag>
			}
		},
		/**
		 * age courant
		 */
		{
			
			//
			fullVersion: true,
			smallVersion: true,
			//
			dataIndex: "apprenantAgeCourant",
			title: 'Âge',
			width: 70,
			render: (text, Record) => Record.apprenantAgeCourantTag
		},
		/**
		 * Etape validation
		 */
		{
			
			//
			fullVersion: true,
			//smallVersion : true,
			//
			//
			dataIndex: "statutInscription",
			title: 'Etape',
			width: 200,
			sorter: simpleSorter("statutInscription"),
			//sorter : simpleSorter("iApprenantStatut"),<StatutValidationTag Statut={Inscription.ValidationStatut}/>
			render: (text, Record) => Record.statutInscriptionTag
		},
		/**
		 * statut
		 */
		{
			
			//
			fullVersion: true,
			smallVersion: true,
			//
			dataIndex: "statutApprenantTag",
			title: 'Statut',
			width: 140,
			sorter: simpleSorter("statutApprenant"),
			render: (text, Record) => Record.statutApprenantTag
		},
		/**
		 *
		 */
		{
			
			//
			fullVersion: true,
			smallVersion: true,
			//
			dataIndex: "ValidationAdministrative.boursier",
			title: 'Boursier',
			className: "center",
			width: 80,
			render: (text, Record) => Record.ValidationAdministrative.boursier
				&& <Tag>{YES_NO_LABEL[Record.ValidationAdministrative.boursier]}</Tag>
		},
		/**
		 * statut
		 */
		{
			//
			fullVersion: true,
			smallVersion: true,
			//
			dataIndex: "diplomationResultatTag",
			title: 'Diplomation',
			width: 100,
			render: (text, Record) => Record.diplomationResultatTag
		},
		
		{
			fullVersion: true,
			smallVersion: true,
			//
			dataIndex: '_FormationSuivie',
			title: 'Formation visée',
			ellipsis: true,
			width: 150,
			
			//sorter : simpleSorter("FormationSuivie.Formation.libelleCourt"),
			render: (text, Record) => Record._FormationSuivie && Record._FormationSuivie.libelleCourt
		},
		/**
		 *
		 */
		{
			fullVersion: true,
			smallVersion: true,
			//
			dataIndex: '_formationContinueFinancee',
			title: 'FC financée',
			className: "center",
			width: 90,
			render: (text, Record) => Record._formationContinueFinancee
			
		},
		/**
		 *
		 */
		{
			fullVersion: true,
			smallVersion: true,
			//
			dataIndex: '_etatDossier',
			title: 'Statut dossier',
			width: 270,
			render: (text, Record) => Record._etatDossier
			
		},
		/**
		 *
		 */
		{
			fullVersion: true,
			smallVersion: true,
			//
			dataIndex: '_projetProfessionnelLastStatutTag',
			title: 'Dernier avis RF',
			width: 350,
			render: (text, Record) => Record._projetProfessionnelLastStatutTag
			
		},
		
		/**
		 *
		 */
		{
			
			//
			fullVersion: true,
			smallVersion: true,
			//
			dataIndex: "_FormationSuivieAnneeExamenTag",
			title: 'Année examen',
			width: 100,
			className: "center",
			render: (text, Record) => Record._FormationSuivieAnneeExamenTag
		},
		
		/**
		 *
		 */
		{
			fullVersion: true,
			smallVersion: true,
			//
			dataIndex: 'reInscription',
			title: 'Réinscription',
			width: 250,
			render: (text, Record) => <Space>
				{Record.reInscriptionTag}
				{Record._reInscriptionTypeTag}
			</Space>
		},
		
		/**
		 *
		 */
		{
			//
			fullVersion: true,
			//smallVersion : true,
			//
			dataIndex: "_createdAt",
			title: 'Créée le',
			className: "center",
			width: 200,
			sorter: simpleSorter("createdAt"),
			render: (text, Record) => Record._createdAt
		},
		{
			//
			fullVersion: true,
			//smallVersion : true,
			//
			dataIndex: "_updatedAt",
			title: 'Modifiée le',
			className: "center",
			width: 200,
			sorter: simpleSorter("_updatedAt"),
			render: (text, Record) => Record._updatedAt
		},
		{
			//
			fullVersion: true,
			smallVersion: true,
			//
			dataIndex: "_space_",
			title: '',
		},
		{
			//
			fullVersion: true,
			smallVersion: true,
			//
			dataIndex: '_id',
			title: 'Réf.',
			width: COLUMN_ICON_WIDTH,
			fixed: "right",
			className: "center",
			render: (text, Record) => <CopyReference Record={Record}/>
		},
		/**
		 * CreateurModificateur
		 */
		{
			//
			fullVersion: true,
			smallVersion: true,
			//
			dataIndex: 'CreateurModificateur',
			title: '',
			className: "center",
			width: COLUMN_ICON_WIDTH,
			fixed: "right",
			render: (text, Record) => <CreateurModificateurIcon Record={Record}/>
		},
	
	]
	/**
	 * si appel par la version avec param smallVersion
	 */
	let Columns = []
	let defaultPagination = {}
	let defaultTableScroll = {}
	if (smallVersion) {
		Columns = DefaultColumns.filter(Column => Column.smallVersion === true)
		//
		defaultPagination = false
		defaultTableScroll.y = 150
		//
	} else {
		Columns = DefaultColumns.filter(Column => Column.fullVersion === true)
		defaultPagination = DEFAULT_PAGINATION
		defaultTableScroll = DEFAULT_TABLE_SCROLL
		
	}
	//
	
	/**
	 *
	 */
	
	return (
		<>
			
			{/* */}
			{InscriptionStore.SelectedRecord && <VieApprenant/>}
			{/* */}
			
			<Table
				rowKey="_id"
				columns={Columns}
				dataSource={Records}
				size="small"
				className={"shadow-2"}
				rowClassName={(Record, index) => (Record && Record._id === (InscriptionStore.SelectedRecord && InscriptionStore.SelectedRecord._id)) ? "table-row-selected" : ""}
				pagination={defaultPagination}
				scroll={defaultTableScroll}
				locale={{emptyText: "Aucune inscription"}}
				showSorterTooltip={false}
				//
				_t={store._t}
			/>
		</>
	
	)
}))

