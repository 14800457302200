/**
 *
 */
import {CaretDownOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {Alert, Popconfirm, Space, Tag, Tooltip, Tree} from 'antd';
import {inject, observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Button} from "../../../components/antExtendedComponent/Button";
import {SpaceVertical} from "../../../components/antExtendedComponent/Space";
import {BadgeCount} from "../../../components/BadgeCount";
import {Notify} from "../../../components/Notify";
import {COMPOSANT_TYPE_CLASSE_VIRTUELLE, COMPOSANT_TYPE_COURS, COMPOSANT_TYPE_SESSION, COMPOSANT_TYPES} from "../../../config/Constantes.logic";
import {MICRO_SERVICE_URLS} from "../../../config/Constantes.urls";
import MicroService from "../../../helpers/MicroService";
import {toLocalDateTime} from "../../../helpers/Utils";

/**
 *
 */
const treeTitleStyle = {
	display: 'flex',
	alignItems: 'center',
	verticalAlign: 'middle',
	justifyContent: 'space-between',
	
};
/**
 *
 */
export const Maquette = inject("store")(observer(props => {
	/**
	 *
	 */
	const {store} = props
	const
		{
			InscriptionStore,
			InscriptionSessionStore,
			InscriptionClasseVirtuelleStore,
			UserStore
		} = store
	/**
	 *
	 */
	const AuthenticatedUser = UserStore.AuthenticatedUser
	const Inscription = InscriptionStore.SelectedRecord
	const FormationSuivie = Inscription && Inscription._FormationSuivie
	const MaquetteComposants = Inscription && Inscription.Maquette.Composants
	const OffreFormationComposantAssocies = (FormationSuivie && FormationSuivie.ComposantAssocies) || []
	const Apprenant = Inscription.Apprenant
	/**
	 * allCheckedKeys  si checkStrictly=false
	 */
	let allCheckedKeys = []
	/**
	 * 1. on prend ce qui est obligatoire
	 */
	const defaultCheckedKeys = []
	OffreFormationComposantAssocies.forEach(ComposantAssocie => {
		ComposantAssocie.key = ComposantAssocie._id
		if (ComposantAssocie.Composant.obligatoire) {
			defaultCheckedKeys.push(ComposantAssocie.key)
		}
	})
	
	/**
	 * 2. on ajoute ce qui a été enregistré
	 */
	const savedCheckedKeys = []
	if (MaquetteComposants) {
		for (const MaquetteComposant of MaquetteComposants) {
			
			MaquetteComposant.key = MaquetteComposant._id
			savedCheckedKeys.push(MaquetteComposant.key)
		}
	}
	///
	allCheckedKeys = allCheckedKeys.concat(defaultCheckedKeys, savedCheckedKeys)
	allCheckedKeys = [...new Set(allCheckedKeys)]
	
	const [userCheckedKeys, setUserCheckedKeys] = useState([])
	useEffect(() => {
		setUserCheckedKeys(allCheckedKeys)
	}, [FormationSuivie._id])
	
	/**
	 *
	 */
	
	/**
	 * @param key//
	 */
		//setComposantSelectedKeys(allCheckedKeys)
	const getComposant = (key) => OffreFormationComposantAssocies.find(ComposantAssocie => ComposantAssocie.key === key)
	/**
	 * event du treeview
	 */
	const onCheck = (checkedKeys) => {
		//on vire les doublons
		const _checkedKeys = [...new Set(checkedKeys.checked)]
		setUserCheckedKeys(_checkedKeys)
	}
	/**
	 * sauvegarde de la sélection des composants
	 */
	const onInscriptionSave = () => {
		/**
		 * on enregistre la sélection des composants
		 */
		
		const SelectedComposants = userCheckedKeys.map(_Key => {
			let Composant = {...getComposant(_Key)}
			delete Composant.disableCheckbox
			return Composant
		})
		// on met à jour l'objet
		const data = {
			Maquette: {
				Composants: SelectedComposants,
				date: new Date(),
				Agent: AuthenticatedUser.toJSON()
			}
		}
		Inscription.update(data)
		Inscription.save((succeeddOrFailed) => {
			Notify(succeeddOrFailed)
		})
		/**
		 * on enregistre  les sessions
		 * on enregistre  les classes virtuelles
		 */
		
		SelectedComposants.forEach(SelectedComposant => {
			if (!SelectedComposant.Composant) {
				return false
			}
			const composantType = SelectedComposant.Composant.composantType
			let ComposantStore
			switch (composantType) {
				case COMPOSANT_TYPE_SESSION:
					ComposantStore = InscriptionSessionStore
					break
				case COMPOSANT_TYPE_CLASSE_VIRTUELLE:
					ComposantStore = InscriptionClasseVirtuelleStore
					break
			}
			//
			if (ComposantStore) {
				const ComposantFound = ComposantStore.getById(SelectedComposant._id)
				if (!ComposantFound) {
					const ToInsert = ComposantStore.create(SelectedComposant, Inscription)
					ToInsert.save(() => {
					})
				}
			}
			//
		})
	}
	
	/**
	 *
	
	const onInscriptionSave = () => {
		//InscriptionUpdate(allCheckedKeys)
		//date: new Date()
		Notify()
		const Maquette = {
			Composants: Inscription.Composants,
			date: new Date()
		}
		Inscription.update({Maquette: Maquette})
		Inscription.save((succeeddOrFailed) => {
			Notify(succeeddOrFailed)
		})
		
	} */
	/**
	 * affectation du user moodle aux cours sélectionnés
	 */
	const saveToMoodle = () => {
		const url = MICRO_SERVICE_URLS.INSCRIPTION_MOODLE_ENROL_USER + "/" + Inscription._id
		const Courses = SelectComposantCours.map(ComposantCours => ComposantCours.Composant.moodle)
		const data = {courseIds: Courses}
		//
		MicroService.update(url, data, (Response) => {
				/**
				  on met à jour le champ CoursMoodles de l'inscption
				 */
				if (Response.OPERATION_SUCCEED === true) {
					const CoursMoodles = {
						Courss: Courses,
						date: new Date(),
						Agent: AuthenticatedUser.toJSON(),
					}
					Inscription.update({CoursMoodles: CoursMoodles})
					Inscription.save((Response) => {
						console.log(Response)
					})
				}
				/**
				 *
				 */
			}
		)
		
	}
	/**
	 *
	 */
	
	const COMMON_BUTTON_WIDTH = 180
	
	/**
	 * onInscriptionSave
	 
	 */
	let date = Inscription.Maquette.date
	let maquetteSelectionInfo
	if (date) {
		let agent = Inscription.Maquette.Agent && Inscription.Maquette.Agent.fullname
		maquetteSelectionInfo = <Tag>Sauvegardé le <b>{toLocalDateTime(date)}</b> par <b>{agent}</b></Tag>
	} else {
		maquetteSelectionInfo = <Tag color={"red"}>Non sauvegardé</Tag>
	}
	const ButtonInscriptionSave =
		<BadgeCount total={userCheckedKeys.length}>
			<Button
				onClick={onInscriptionSave}
				disabled={userCheckedKeys.length === 0}
				size={"small"}
				style={{width: COMMON_BUTTON_WIDTH}}
			>Sauvegarder la sélection</Button>
		</BadgeCount>
	
	/**
	 * ButtonMoodleSave
	 */
	let SelectComposantCours = []
	const SelectComposants = userCheckedKeys.map(_key => getComposant(_key))
	SelectComposantCours = SelectComposants && SelectComposants.filter(Item => {
		return Item && Item.Composant.composantType === COMPOSANT_TYPE_COURS
	})
	
	const moodleuserId = Apprenant.CompteExternes.moodle
	let moodleInscriptionInfo
	date = Inscription.CoursMoodles.date
	if (date) {
		let agent = Inscription.CoursMoodles.Agent && Inscription.CoursMoodles.Agent.fullname
		moodleInscriptionInfo = <Tag>Inscrit aux cours le <b>{toLocalDateTime(date)}</b> par <b>{agent}</b></Tag>
	} else {
		moodleInscriptionInfo = <Tag color={"red"}>Non inscrit aux cours</Tag>
	}
	
	const disabledMoodleButton = !(Apprenant.hasCompteMoodle && SelectComposantCours.length > 0)
	const ButtonMoodleSave = <Popconfirm
		title="Attention"
		description="Cette action est irréversible ?"
		onConfirm={saveToMoodle}
		//	onCancel={cancel}
		okText="Oui"
		cancelText="Non"
	>
		<Tooltip title={"Inscrire l'utilisateur moodle " + moodleuserId + " aux cours sélectionnés"} placement={"right"}>
			<BadgeCount total={SelectComposantCours.length}>
				<Button
					//onClick={saveToMoodle}
					disabled={disabledMoodleButton}
					danger
					size={"small"}
					style={{width: COMMON_BUTTON_WIDTH}}
				>
					Inscrire aux cours moodle
				</Button>
			</BadgeCount>
		</Tooltip>
	</Popconfirm>
	/**
	 * reset saubegarde
	 */
	const resetSave = <Button
		onClick={() => {
			Inscription.update({Maquette: {}})
			Inscription.save((Response) => {
			})
		}}
		danger
		size={"small"}
	>
		reset maquette
	</Button>
	/**
	 * si session et classes virtuelles sont enrigistrées
	 */
	const isComposantInserted = (Item) => {
		let StoreComposant
		const Composant = Item.Composant
		switch (Composant.composantType) {
			case COMPOSANT_TYPE_SESSION:
				StoreComposant = InscriptionSessionStore
				break
			case COMPOSANT_TYPE_CLASSE_VIRTUELLE:
				StoreComposant = InscriptionClasseVirtuelleStore
				break
		}
		return StoreComposant && StoreComposant.getById(Item._id)
	}
	/**
	 *
	 */
	const Maquette = (props) => {
		if (!FormationSuivie) {
			return false
		}
		//
		let map = {}
		const getNodes = (arr, parentId) => {
			let Nodes = []
			for (const Item of arr) {
				if (Item.parentId === parentId) {
					
					Item.title = ""
					const children = getNodes(arr, Item.Composant._id)
					if (children.length) {
						Item.children = children
					}
					//hack
					if (!map[Item.key]) {
						Nodes.push(Item)
						map[Item.key] = Item.key
					}
					//
				}
			}
			return Nodes
		}
		//on trie par position
		let _OffreFormationComposantAssocies = [...OffreFormationComposantAssocies]
		_OffreFormationComposantAssocies = _OffreFormationComposantAssocies.sort((a, b) => a.position - b.position)
		//
		const treeData = getNodes(_OffreFormationComposantAssocies, FormationSuivie._id, FormationSuivie._id)
		const AllNodes = [{
			key: "root",
			rootTitle: <Space>{FormationSuivie.copyReferenceView}<b>{FormationSuivie.libelleCourt}</b>{Inscription._FormationSuivieAnneeExamenTag}</Space>,
			isRoot: true,
			checkable: false,
			children: treeData
		}]
		//
		return <>
			{
				!Apprenant.hasCompteMoodle && <Alert type={"error"} showIcon message={"Cet apprenant n'a pas de compte moodle."}/>
			}
			<Tree
				defaultCheckedKeys={userCheckedKeys}
				treeData={AllNodes}
				onCheck={onCheck}
				showLine
				blockNode
				defaultExpandAll
				checkStrictly
				checkable
				switcherIcon={<CaretDownOutlined/>}
				style={{padding: 10}}//height:window.innerHeight*0.7,overflow:"auto"
				className={"shadow-2"}
				titleRender={(Item) => {
					
					if (Item.isRoot) {
						return Item.rootTitle
					} else {
						//console.log(Item.Composant.obligatoire)
						Item.disableCheckbox = Item.Composant.obligatoire
						//
						let stop = ""
						if (isComposantInserted(Item)) {
							Item.disableCheckbox = true
							stop = <Tooltip title={"Pour désélectionner, il faut supprimer son enregistrement d'abord."}>
								<InfoCircleOutlined style={{color: "tomato"}}/>
							</Tooltip>
						}
						
						const ComposantType = COMPOSANT_TYPES[Item.Composant.composantType]
						
						return <div style={treeTitleStyle}>
							<Space title={Item.Composant.intitule + " (" + Item.Composant._id + ")"}>
								<Tag color={ComposantType.color}>
									{ComposantType.name} {Item.Composant.moodle && "(" + Item.Composant.moodle + ")"}
								</Tag>
								{stop}
								{Item.Composant.intituleCourt}
							</Space>
						
						</div>
					}
					
				}}
			/>
			{/* */}
		</>
		
	}
	/**
	 *
	 */
	return <div>
		{/* */}
		<SpaceVertical size={"small"}>
			<Space size={"large"}>
				{ButtonInscriptionSave}
				{maquetteSelectionInfo}
				{resetSave}
			</Space>
			<Space size={"large"}>
				{ButtonMoodleSave}
				{moodleInscriptionInfo}
			</Space>
			{/* */}
			<Maquette/>
			{/* */}
		</SpaceVertical>
	</div>
}))
