/**
 *
 */
import {DatePicker, Form, Select} from 'antd';
import days from "dayjs";

import {inject, observer} from "mobx-react";
import React, {useEffect} from "react";
import {Button} from "../../../components/antExtendedComponent/Button";
import {Notify} from "../../../components/Notify";
import {SelectYesNo} from "../../../components/SelectYesNo";
import {FORM_DEFAULT_RULE_REQUIRED} from "../../../config/Constantes.ui";
import {key} from "../../../helpers/Utils";
import {SectionTitle} from "../../common/components/SectionTitle";
import {InscriptionPieceJointeSpecifiques} from "./pieceJointeSpecifique/PieceJointeSpecifiques";

/**
 *
 */
export const FormationFinancee = inject("store")(observer(props => {
	/**
	 *
	 */
	const {isReadOnly, store} = props
	
	/**
	 *
	 */
	const
		{
			InscriptionStore,
			ReferentielSourceFinancementStore
		} = store
	
	/**
	 *
	 */
	const [form] = Form.useForm();
	/**
	 *
	 */
	const Inscription = InscriptionStore.SelectedRecord
	const ReferentielSourceFinancements = ReferentielSourceFinancementStore.getAll()
	
	/**
	 * populate form
	 */
	const onFill = () => {
		//
		const InscriptionEdited = {...Inscription}
		//
		
		let financementAccordeDate = InscriptionEdited.FormationContinueFinancee.financementAccordeDate || days()
		if (financementAccordeDate) {
			financementAccordeDate = days(financementAccordeDate, "YYYY-MM-DD")
			const FormationContinueFinancee = {...InscriptionEdited.FormationContinueFinancee}
			FormationContinueFinancee.financementAccordeDate = financementAccordeDate
			InscriptionEdited.FormationContinueFinancee = FormationContinueFinancee
		}
		//
		form.setFieldsValue(InscriptionEdited);
		//
	};
	/**
	 *
	 */
	useEffect(() => {
		onFill()
	})
	
	/**
	 * modifie l'objet dans le store avant enregistrement
	 */
	const onFieldsChange = (changedFields, allFields) => {
		//
		const Obj = {}
		//
		const changedField = changedFields[0]
		if (!changedField) {
			return false
		}
		const field = changedField.name[0]
		// cas d'un sous-objet
		const subField = changedField.name[1]
		//
		if (!subField) {
			let value = changedField.value
			
			Obj[field] = value;
		} else {
			Obj[field] = {};
			const Fields = allFields.filter(item => item.name[0] === field)
			Obj[field] = {}
			Fields.forEach(item => {
				const _subField = item.name[1]
				let _value = item.value
				
				switch (_subField) {
					case "financementAccordeDate":
						_value = days(_value).format("YYYY-MM-DD")
						break
					
					//
				}
				Obj[field][_subField] = _value
			})
			//
		}
		
		Inscription.update(Obj)
		// days(values.date).format("YYYY-MM-DD HH:mm")
	};
	/**
	 *
	 * @param  form values
	 * @returns {boolean}
	 */
	const onFinish = () => {
		
		form.validateFields()
			.then((values) => {
				Notify()
				Inscription.save(succeeded => {
					Notify(succeeded)
				})
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	/**
	 *
	 */
	const ButtonSave = <div className={"center"}>
		<Button block onClick={onFinish}>Enregistrer</Button>
	</div>
	/**
	 *
	 */
	const FORM_PROPS = {
		form: form,
		disabled: isReadOnly,
		layout: "vertical",
		className: "fadein shadow-2 padding",
		onFieldsChange: onFieldsChange,
		
	}
	/**
	 *
	 */
	return (
		<>
			<SectionTitle title={"Financement"}/>
			<Form  {...FORM_PROPS} >
				{/*  */}
				<Form.Item name={["FormationContinueFinancee", "FinancementSource"]} label={'Source de financement'} rules={FORM_DEFAULT_RULE_REQUIRED}>
					<Select
						style={{width: 500}}
					>
						{
							ReferentielSourceFinancements.map(item => <Select.Option
								key={key()}
								value={item._id}
								disabled={!item.active}
							>
								{item.libelle}
							</Select.Option>)
						}
					
					</Select>
				</Form.Item>
				{/*  */}
				<Form.Item name={["FormationContinueFinancee", "financementAccorde"]} label={'Accord de financement'}>
					<SelectYesNo/>
				</Form.Item>
				{/*  */}
				<Form.Item name={["FormationContinueFinancee", "financementAccordeDate"]} label={"Date de l'accord de financement"}>
					<DatePicker
						allowClear={false}
						placeholder={""}
						format={"DD/MM/YYYY"}
					
					/>
				</Form.Item>
				{/*  */}
				{ButtonSave}
				{/*  */}
			
			</Form>
			{/*  */}
			<SectionTitle title={"Pièces jointes spécifiques"}/>
			<InscriptionPieceJointeSpecifiques Inscription={Inscription}/>
			{/*  */}
		</>
	
	);
}))

