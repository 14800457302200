export const PLANNING_VIEW_CONTEXT_ACCES = "PLANNING_VIEW_CONTEXT_ACCES"
export const PLANNING_VIEW_CONTEXT_APPRENANT = "PLANNING_VIEW_CONTEXT_APPRENANT"
//-----------------------------------------------------------------------------
/**
 *
 */
export const DISPLAY_BOTH = "DISPLAY_BOTH"
export const DISPLAY_IN_DRAWER = "DISPLAY_IN_DRAWER"
export const DISPLAY_IN_DATAGRID = "DISPLAY_IN_DATAGRID"
/**
 *
 */
export let THEMES = [
	{label: "ant", color: "#1677ff"},
	{label: "zeden", color: "#7b1fa2"},
	{label: "violet", color: "#8250df"},
	{label: "blue 1", color: "#5340ff"},
	{label: "blue 2", color: "#4E36C4"},
	{label: "teams", color: "#6264a7"},
	{label: "figaro", color: "#163860"},

]

export const getCurrentPrimaryColor = () => {
	let themeIndex = localStorage.themeIndex || 0
	let Theme = THEMES[themeIndex] || THEMES[0]
	return Theme.color
}
const currentPrimaryColor = getCurrentPrimaryColor()
export const Theme = {
	token: {
		colorPrimary: currentPrimaryColor,
		colorLink: currentPrimaryColor,
		colorLinkHover: currentPrimaryColor,
		linkHoverDecoration: "underline",
		borderRadius: 2,
		fontSize: 13.5,
		colorBgSpotlight: "#fff",//tooltip popover bg
	},
	
}
//-----------------------------------------------------------------------------
export const COLOR_PRIMARY = getCurrentPrimaryColor();
export const COLOR_GREEN = "#22d0b8"
export const COLOR_RED = "#ff5252"
export const COLOR_ORANGE = "#ffa500";// #f50
export const COLOR_YELLOW = "#fbbd08";
export const COLOR_GRAY = "#9e9e9e94";
//
export const isEnvDev = (process.env.NODE_ENV === 'development')
/**
 *
 */
export const DATE_FORMAT_YYYY_MM_DD = "YYYY-MM-DD"
export const DATE_FORMAT_HH_MM = "HH:mm"
/**
 *
 */
export const ICON_FONT_SIZE = 20
/**
 * pagination
 */
const pageSize = localStorage.pageSize || 20
export const DEFAULT_PAGINATION = {
	showTitle: false,
	defaultPageSize: pageSize,
	hideOnSinglePage: false,
	showSizeChanger: true,
	size: "small",
	//pageSizeOptions:[10, 20, 50, 100],
	onChange: (page, pageSize) => {
		localStorage.pageSize = pageSize
	}
	
}
export const FORM_LAYOUT = {
	className: "fadein shadow-2 padding",
	labelCol: {span: 12},
	wrapperCol: {span: 12},
	colon: false
}
//-----------------------------------------------------------------------------
export const BORDERED_DEBUG = isEnvDev
//-----------------------------------------------------------------------------
export const FLEX_STYLE_MENU = {
	display: "flex",
	alignItems: "center",
	verticalAlign: "middle",
	justifyContent: "space-between",
	columnGap: 10
}

export const FORM_DEFAULT_RULE_REQUIRED = [
	{
		
		required: true,
		message: "Champs requis"
	},
]
export const FORM_RULE_EMAIL = [
	{
		required: true,
		type: "email",
		message: "Le format n'est pas valide",
	},
]
export const FORM_RULE_NUMERIC = [
	{
		required: true,
		pattern: new RegExp(/^[0-9]/),
		message: "Le format n'est pas valide",
	},
]
//-----------------------------------------------------------------------------
//
//-----------------------------------------------------------------------------

export const BUTTON_DEFAULT_PROPS = {
	shape: "round",
	type: "primary",
}
export const BUTTON_CIRCLE_DEFAULT_PROPS = {
	shape: "circle",
	type: "primary",
	size: "middle",
	ghost: true,
	style: {border: "none"}
}
export const COLUMN_ICON_WIDTH = 40
export const COLUMN_BUTTON_WIDTH = 100
export const COLUMN_TAG_WIDTH = 60
export const COLUMN_DATE_TIME = 180
//
export let DEFAULT_TABLE_SCROLL = {y: window.innerHeight - 350}

//

//-----------------------------------------------------------------------------
//
//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
//
//-----------------------------------------------------------------------------

export const LOG_ACTIONS = {
	"CREATE": "Création",
	"UPDATE": "Màj",
	"DELETE": "Suppression",
}
/**
 *
 */

/**
 *
 */
