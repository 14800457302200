import {inject, observer} from "mobx-react";
import React, {useState} from "react";
/**
 *
 */
import {Form, Modal, Popover, Select, Space, Table, TimePicker, Tooltip} from "antd";
import {key, simpleSorter} from "../../../helpers/Utils";
import {COLUMN_ICON_WIDTH, DATE_FORMAT_HH_MM, DEFAULT_PAGINATION, DEFAULT_TABLE_SCROLL, DISPLAY_BOTH, DISPLAY_IN_DATAGRID, DISPLAY_IN_DRAWER, FORM_DEFAULT_RULE_REQUIRED} from "../../../config/Constantes.ui";
import {DestroyButton} from "../../common/components/DestroyButton";
import {Button, ButtonIcon} from "../../../components/antExtendedComponent/Button";
import {EditOutlined} from "@ant-design/icons";
import {SelectYesNo} from "../../../components/SelectYesNo";
import {Notify} from "../../../components/Notify";
import {MOTIF_ABSENCES} from "../../../config/Constantes.logic";
import {IconInfo} from "../../../components/IconInfo";
import {Icon} from "../../../components/Icon";
import {DRAWERS_NAMES} from "../../../stores/NavigationStore";
import {openDrawer} from "../../common/components/drawerManager";
import {TAB_KEY_SESSION} from "../VieApprenants";
import days from "dayjs";

import {FILTER_BY_ANNEE_EXAMEN, FILTER_BY_FORMATION, FILTER_BY_INSCRIPTION} from "../../../stores/Constantes.filters";

/**
 *
 */

const localStorageFiltersName = "FILTERS_SESSION"
//
export const Sessions = inject("store")(observer(props => {
	//
	
	const data = localStorage[localStorageFiltersName]
	const _Filters = (data && JSON.parse(data)) || {}
	const [Filters, setFilters] = useState(_Filters)
	//
	const [formVisible, setFormVisible] = useState()
	const [SelectedRecord, setSelectedRecord] = useState({})
	const [state, setState] = useState({})
	const [form] = Form.useForm();
	//
	const {InscriptionSelected, store} = props
	const {InscriptionStore, InscriptionSessionStore, ApprenantStore} = store
	//
	let AllInscriptionSessions = InscriptionSessionStore.getAll()
	if (InscriptionSelected) {
		let Filter = {name: FILTER_BY_INSCRIPTION, value: InscriptionSelected._id}
		Filters[FILTER_BY_INSCRIPTION] = Filter
	}
	let Records = InscriptionSessionStore.getAllByFilters(Filters)
	/*******************************************************************
	 * datagrid
	 *******************************************************************/
	const editSession = (Record) => {
		setState({participation: Record.participation})
		setSelectedRecord(Record)
		const totalHeuresConsomme = (Record.totalHeuresConsomme ? days(Record.totalHeuresConsomme, DATE_FORMAT_HH_MM) : "")
		form.setFieldsValue({
			participation: Record.participation,
			totalHeuresConsomme: totalHeuresConsomme
		})
		setFormVisible(true)
	};
	const openVieApprenanteDrawer = (Inscription) => {
		InscriptionStore.setSelectedRecord(Inscription)
		const maquetteDrawerName = DRAWERS_NAMES.MAQUETTE_FORM_DRAWER
		ApprenantStore.setVieApprenantePageTabsActiveKey(TAB_KEY_SESSION)
		openDrawer(maquetteDrawerName)
	};
	/*******************************************************************
	 * filtres
	 * *****************************************************************/
	const selectWidth = 150
	const selectDropdownWidth = 300
	/**
	 * formation suivie examen
	 */
	const setFilter = (Filter) => {
		const _Filters = {...Filters}
		if (!("value" in Filter)) {
			delete _Filters[Filter.name];
		} else {
			_Filters[Filter.name] = Filter;
		}
		
		setFilters(_Filters)
		localStorage[localStorageFiltersName] = JSON.stringify(_Filters)
		
	};
	
	const resetFilters = () => {
		Object.values(Filters).forEach(Filter => {
			delete Filter.value
			setFilter(Filter)
		})
		
	}
	/**
	 * Filter formation suivie
	 */
	const FilterByFormationSuivie = () => {
		//
		let Items = {}
		AllInscriptionSessions.forEach(Record => {
			let Formation = Record.Inscription._FormationSuivie
			let value = Formation && Formation._id
			if (value) {
				let libelle = Formation.libelleCourt
				Items[value] = {value: value, libelle: libelle}
			}
			
		})
		Items = Object.values(Items)
		//
		const Filter = Filters[FILTER_BY_FORMATION]
		const value = Filter && Filter.value
		//
		return <Select
			value={value}
			allowClear
			showSearch
			placeholder={"Formation suivie"}
			style={{minWidth: selectWidth}}
			dropdownStyle={{width: selectDropdownWidth}}
			optionFilterProp={"label"}
			onChange={(value) => {
				//
				let Filter = {}
				Filter = {name: FILTER_BY_FORMATION, value: value}
				if (!value) {
					delete Filter.value
				}
				setFilter(Filter)
				//
			}}
		>
			{
				Items.map(item =>
					<Select.Option key={key()} value={item.value} label={item.libelle}>
						{item.libelle}
					</Select.Option>
				)
			}
		
		</Select>
	}
	/**
	 * Filter Année examen
	 */
	const FilterByAnneeExamen = () => {
		//
		let Items = {}
		AllInscriptionSessions.forEach(Record => {
			let value = Record.Inscription._FormationSuivieAnneeExamen
			let libelle = value
			if (!value) {
				libelle = "Sans"
			}
			Items[value] = {value: value, libelle: libelle}
		})
		Items = Object.values(Items)
		//
		const Filter = Filters[FILTER_BY_ANNEE_EXAMEN]
		const value = Filter && Filter.value
		//
		return <Select
			value={value}
			allowClear
			placeholder={"Examen"}
			style={{width: 90}}
			onChange={(value) => {
				//
				let Filter = {}
				Filter = {name: FILTER_BY_ANNEE_EXAMEN, value: value}
				if (!value) {
					delete Filter.value
				}
				setFilter(Filter)
				//
			}}
		>
			{
				Items.map(item =>
					<Select.Option key={key()} value={item.value} label={item.libelle}>
						{item.libelle}
					</Select.Option>
				)
			}
		
		</Select>
	}
	/**
	 *
	 */
	let Columns = [
		
		{
			//
			display: DISPLAY_IN_DRAWER,
			//
			dataIndex: 'actionEdit',
			title: '',
			width: COLUMN_ICON_WIDTH,
			className: "center",
			fixed: "left",
			render: (text, Record) => <ButtonIcon
				ghost
				icon={<EditOutlined/>}
				onClick={(e) => {
					editSession(Record)
				}}
			/>
		},
		
		{
			//
			display: DISPLAY_IN_DATAGRID,
			//
			dataIndex: 'Inscription.apprenantFullname',
			title: 'Apprenant',
			width: 150,
			ellipsis: true,
			fixed: "left",
			sorter: simpleSorter("Inscription.apprenantFullname"),
			render: (text, Record) =>
				<a href="/" onClick={(e) => {
					e.preventDefault();
					openVieApprenanteDrawer(Record.Inscription)
				}}>{Record.Inscription.apprenantFullname}
				</a>
		},
		
		{
			
			//
			display: DISPLAY_IN_DATAGRID,
			//
			dataIndex: 'Inscription.numeroTag',
			title: 'Insc.',
			width: 70,
			className: "center",
			fixed: "left",
			sorter: simpleSorter("Inscription.numero"),
			render: (text, Record) => Record.Inscription.numeroTag
		},
		{
			//
			display: DISPLAY_BOTH,
			//
			dataIndex: 'Inscription._FormationSuivie.libelleCourt',
			title: 'Formation suivie',
			width: 150,
			ellipsis: true,
			sorter: simpleSorter("Inscription._FormationSuivielibelleCourt"),
			render: (text, Record) => Record.Inscription._FormationSuivielibelleCourt
		},
		{
			//
			display: DISPLAY_BOTH,
			//
			dataIndex: 'Inscription._FormationSuivie.anneeExamen',
			title: 'Examen',
			width: 90,
			className: "center",
			sorter: simpleSorter("Inscription._FormationSuivieAnneeExamen"),
			render: (text, Record) => Record.Inscription._FormationSuivieAnneeExamenTag
		},
		
		{
			//
			display: DISPLAY_BOTH,
			//
			dataIndex: 'Session.libelle',
			title: 'Session',
			width: 150,
			ellipsis: true,
			sorter: simpleSorter("Session.libelle"),
			render: (text, Record) => Record.Session.libelle
		},
		{
			//
			display: DISPLAY_BOTH,
			//
			dataIndex: 'Planning.Horaires.Intervenants',
			title: <Tooltip title={"Intervenants et plannings"}><Icon name="Group"/></Tooltip>,
			width: 50,
			className: "center",
			render: (text, Record) => getIntervenantsView(Record.Session, store)
		},
		{
			//
			display: DISPLAY_BOTH,
			//
			dataIndex: 'Session.Planning.totalHeuresPrevisionnel',
			title: 'Prév.',
			width: 80,
			className: "center",
			render: (text, Record) => Record.totalHeuresPrevisionnelTag
		},
		{
			//
			display: DISPLAY_BOTH,
			//
			dataIndex: 'Session.Planning.totalHeuresEffectif',
			title: 'Effec.',
			className: "center",
			width: 80,
			render: (text, Record) => Record.totalHeuresEffectifTag
		},
		{
			//
			display: DISPLAY_BOTH,
			//
			dataIndex: 'totalHeuresConsomme',
			title: 'Consom.',
			width: 80,
			className: "center",
			render: (text, Record) => Record.totalHeuresConsommeTag
		},
		{
			//
			display: DISPLAY_BOTH,
			//
			dataIndex: 'participationTag',
			title: 'A participé',
			width: 110,
			className: "center",
			sorter: simpleSorter("participation"),
		},
		{
			//
			display: DISPLAY_BOTH,
			//
			dataIndex: 'motifAbsenceLibelle',
			title: 'Motif absence',
			width: 200,
			ellipsis: true
		},
		{
			//
			display: DISPLAY_IN_DRAWER,
			//
			dataIndex: 'actionDestroy',
			title: '',
			width: COLUMN_ICON_WIDTH,
			fixed: "right",
			className: "center",
			render: (text, Record) => <DestroyButton record={Record}/>
		}
	]
	if (InscriptionSelected) {
		Columns = Columns.filter(Column => Column.display === DISPLAY_IN_DRAWER || Column.display === DISPLAY_BOTH)
	} else {
		Columns = Columns.filter(Column => Column.display === DISPLAY_BOTH || Column.display === DISPLAY_IN_DATAGRID)
	}
	
	/*******************************************************************
	 * Form
	 *******************************************************************/
		//
	const onValuesChange = (values) => {
			if ("participation" in values) {
				setState(values)
			}
		};
	//
	const onFormFinish = () => {
		form.validateFields()
			.then((values) => {
				//
				if (values.participation === true) {
					values.motifAbsence = ""
				}
				if (values.totalHeuresConsomme) {
					values.totalHeuresConsomme = days(values.totalHeuresConsomme).format(DATE_FORMAT_HH_MM)
				}
				
				//
				SelectedRecord.update(values)
				SelectedRecord.save(succeeded => {
					Notify(succeeded)
					setFormVisible(false)
				})
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	//
	const ButtonSave = <div key={key()} className={"center"}>
		<Button block onClick={onFormFinish}>Enregistrer</Button>
	</div>
	const SessionForm = (props) => {
		
		const FORM_PROPS = {
			form: form,
			onValuesChange: onValuesChange,
			className: "padding",
			labelCol: {span: 10},
			colon: false
		}
		return <Form {...FORM_PROPS}>
			{/*  */}
			<Form.Item name={"participation"} label={'Participation'}>
				<SelectYesNo/>
			</Form.Item>
			<Form.Item label={'Total prévisionnel (heures)'}>
				{SelectedRecord.totalHeuresPrevisionnel}
			</Form.Item>
			<Form.Item label={'Total effectif (heures)'}>
				{SelectedRecord.totalHeuresEffectif}
			</Form.Item>
			
			{
				state.participation === true
				&& <Form.Item name={"totalHeuresConsomme"} label={'Total consommées (heures)'}>
					<TimePicker format={DATE_FORMAT_HH_MM} showNow={false}/>
				</Form.Item>
			}
			{
				state.participation === false
				&& <Form.Item name={"motifAbsence"} label={"Motif del'absence"} rules={FORM_DEFAULT_RULE_REQUIRED}>
					<Select>
						{
							Object.values(MOTIF_ABSENCES).map(item =>
								<Select.Option key={key()} value={item.value}>
									{item.label}
								</Select.Option>
							)
						}
					</Select>
				</Form.Item>
			}
		</Form>
		{/* */
		}
		
	}
	/**
	 *
	 */
	return (
		<>
			
			{
				!InscriptionSelected && <Space size={"middle"}>
					<FilterByFormationSuivie/>
					<FilterByAnneeExamen/>
				</Space>
			}
			
			
			{/*  */}
			<Table
				rowKey="_id"
				dataSource={Records}
				columns={Columns}
				size="small"
				className={"shadow-2"}
				pagination={DEFAULT_PAGINATION}
				scroll={DEFAULT_TABLE_SCROLL}
				showSorterTooltip={false}
				_t={store._t}
			/>
			{/*  */}
			<Modal
				title={SelectedRecord.libelle}
				open={formVisible}
				centered
				mask={false}
				closable
				footer={[ButtonSave]}
				onCancel={() => {
					setFormVisible(false)
				}}
			>
				{/*  */}
				<SessionForm/>
				{/*  */}
			</Modal>
			{/*  */}
		</>
	
	)
	
	/**
	 *
	 */
	
}))
/*******************************************************************
 * Intervenants
 *******************************************************************/
const getIntervenantsView = (Session, store) => {
	const Planning = Session.Planning
	if (!Planning) {
		return false
	}
	const Horaires = Planning.Horaires
	//
	let intervenantTotal = 0
	Horaires.forEach(Horaire => intervenantTotal += Horaire.Intervenants.length)
	//
	const Columns = [
		{
			dataIndex: 'date',
			title: 'Date',
			className: "center",
			render: (text, Record) => toLocalDate(Record.date)
		},
		{
			dataIndex: 'debut',
			title: 'Début - fin',
			className: "center nowrap",
			render: (text, Record) => Record.debut + " - " + Record.fin
		},
		{
			dataIndex: 'totalHeuresEffectif',
			title: 'Effec.',
			className: "center"
		},
		{
			dataIndex: 'totalHeuresPrevisionnel',
			title: 'Prév.',
			className: "center"
		},
		{
			dataIndex: 'Intervenants',
			title: 'Intervenants',
			width: 400,
			render: (text, Record) => Record.Intervenants &&
				<>
					{
						Record.Intervenants.map(Item => {
							const fullName = Item.Intervenant.nom + " " + Item.Intervenant.prenom
							const roles = Item.roles.map(role => INTERVENANT_ROLES[role].label).join(", ")
							const thematique = Item.ReferentielThematique && Item.ReferentielThematique.libelle
							return <div key={key()}>
								<b>{fullName}</b>
								{thematique && <>&nbsp;({thematique})</>}
								{roles && <>&nbsp;({roles})</>}
								&nbsp;
							</div>
						})
						
					}
				</>
		},
	
	]
	//
	//
	const table = <Table
		rowKey="_id"
		dataSource={Horaires}
		columns={Columns}
		size="small"
		pagination={false}
		showSorterTooltip={false}
		_t={store._t}
	/>
	//
	return <Popover
		key={key()}
		content={table}
		placement={"rightTop"}
	>
		<IconInfo name="Group"/><sup style={{fontSize: 11, top: -12, marginLeft: 3}}>{intervenantTotal}</sup>
	</Popover>
}
