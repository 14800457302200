import Fingerprint from "@fingerprintjs/fingerprintjs";
import alasql from "alasql";
import days from "dayjs";
/**
 *
 */
/**
 *
 */
import ShortUniqueId from "short-unique-id";

import * as XLSX from 'xlsx';

alasql.utils.global.XLSX = XLSX;
/**
 * gestion des accents lors de la recherche
 */
export const normalize = function (value) {
	return value && value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
};
/**
 *
 */
alasql.fn.normalize = function (value) {
	return value && value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
};
//alasql.utils.isBrowserify = false;
//alasql.utils.global.XLSX = XLSX;
//
//export const alasqlFn = alasql
/**
 *
 * @param map
 */
const fpPromise = Fingerprint.load({monitoring: false});
export const getFingerPrint = async () => {
	const fp = await fpPromise
	const result = await fp.get()
	return result
};
export const formatPhoneNumber = (str) => {
	let numero = str.toString();//.split("").reverse().join("")
	numero = numero.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
	return numero
}

/**
 *
 */
export const simpleSorter = (_field) => {
	
	//comme ca on peut gérer le sorting sur une colone qui est un objet
	// exmple colonne CoursType : CoursType est un object qui a une prpriété libellé
	const tmp = _field.split(".").reverse()
	let field = tmp[0]
	let objectName = tmp[1] || null
	return (_a, _b) => {
		/*
		if (!_a[field]) {
			console.log("_a[field]")
			return false
		}
		
		 */
		//
		let str
		//
		if (objectName) {
			str = _a[objectName][field] || ""
		} else {
			str = _a[field] || ""
		}
		let a = str.toString().toLowerCase()
		//
		if (objectName) {
			str = _b[objectName][field] || ""
		} else {
			str = _b[field] || ""
		}
		let b = str && str.toString().toLowerCase()
		if (a < b) {
			return -1;
		}
		if (a > b) {
			return 1;
		}
		return 0;
		
	}
	
}
/**
 *
 */
export const ucFirst = (str) => {
	if (str.length > 0) {
		return str[0].toUpperCase() + str.toLowerCase().substring(1)
	}
	return str
}
export const ucFirstAll = (str) => {
	// Convertir en minuscule en utilisant toLocaleLowerCase pour gérer les accents
	const lowerCaseStr = str.toLocaleLowerCase('fr-FR');
	
	// Utiliser une expression régulière pour trouver les débuts de mots et les convertir en majuscule
	return lowerCaseStr.replace(/(?:^|\s|[-'"])\S/g, function (char) {
		return char.toLocaleUpperCase('fr-FR');
	});
}
/**
 *
 */

export const newId = () => {
	//
	const uid = new ShortUniqueId();
	uid.setDictionary('alpha_upper');
	const str = uid.rnd();
	const year = (sessionStorage.anneeCreationSelected || new Date().getFullYear())
	return year + "-" + str;
};
/**
 *
 */
export const log = (obj) => {
	console.log(JSON.stringify(obj))//
}

/**
 *
 * @returns {string}
 */
export const ObjectId = () => {
	const timestamp = (new Date().getTime() / 1000 | 0).toString(16);
	return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
		return (Math.random() * 16 | 0).toString(16);
	}).toLowerCase();
};
/**
 *
 * @returns {*}
 */
export const key = () => ObjectId()
/**
 *
 */
export const toLocalTime = (_value) => {
	return days(_value).format('HH:mm')
}
export const toLocalDateTime = (_value) => {
	return days(_value).format('DD/MM/YYYY à HH:mm:ss')
}
export const toLocalDateWidthDayName = (value) => {
	return days(value).format("ddd DD/MM/YYYY")
}
export const toLocalDate = (_value) => {
	const value = days(_value).format('DD/MM/YYYY')
	return (value === "Invalid date" ? null : value)
}
export const toYear = (_value) => {
	const value = days(_value).format('YYYY')
	return (value === "Invalid date" ? null : value)
}

/**
 * construit una arborescence pour treeView
 * @param items
 * @returns {*[]}
 */

/**
 *



export const hash = (str) => {
	var buffer = new TextEncoder("utf-8").encode(str)
	return crypto.subtle.digest("SHA-256", buffer).then(function (hash) {
		return hex(hash)
	})
	
	function hex(buffer) {
		var digest = ''
		var view = new DataView(buffer)
		for (var i = 0; i < view.byteLength; i += 4) {
			// We use getUint32 to reduce the number of iterations (notice the `i += 4`)
			var value = view.getUint32(i)
			// toString(16) will transform the integer into the corresponding hex string
			// but will remove any initial "0"
			var stringValue = value.toString(16)
			// One Uint32 element is 4 bytes or 8 hex chars (it would also work with 4
			// chars for Uint16 and 2 chars for Uint8)
			var padding = '00000000'
			var paddedValue = (padding + stringValue).slice(-padding.length)
			digest += paddedValue
		}
		
		return digest
	}
}
 */

