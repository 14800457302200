/**
 *
 */
import {Alert, Divider, Spin, Steps} from 'antd';
import axios from "axios";
import React, {useEffect, useState} from "react"
import {SpaceVertical} from "../../components/antExtendedComponent/Space";
import {MICRO_SERVICE_URLS} from "../../config/Constantes.urls";
import {Step_1} from "./forms/Step_1";
import {Step_2} from "./forms/Step_2";
import {Step_3} from "./forms/Step_3";

/**
 *
 */

export const STEP_1 = "STEP_1"
export const STEP_2 = "STEP_2"
export const STEP_3 = "STEP_3"
/**
 *
 */
export const PREPROSPECT_FORM_PROPS = {
	className: "fadein shadow-1 padding",
	layout: "vertical",
	colon: false,
	style: {maxWidth: 800, margin: "auto"},
}

/**
 *
 */
export const PreProspectRegistration = (props => {
	/**
	 *
	 */
	const [currentStep, setCurrentStep] = useState(STEP_1)
	const [PreProspect, setPreProspect] = useState(null);
	const [spinning, setSpinning] = useState(false);
	const [formDisabled, setFormDisabled] = useState(false);
	/**
	 *
	 */
	const searchParams = new URL(window.location.href).searchParams
	const preProspectId = searchParams.get("PreProspect")
	const getData = () => {
		if (preProspectId) {
			setSpinning(true)
			const url = MICRO_SERVICE_URLS.PRE_PROSPECT + "/" + preProspectId
			axios.get(url).then(({data}) => {
				const Record = data.Record
				console.log(Record)
				if (Record) {
					//
					//Record.inscriptionExists = Record.rgpdAccepte
					setPreProspect(Record);
					//
					setFormDisabled(Record.rgpdAccepte)
					setCurrentStep(STEP_3)
					
				}
				setSpinning(false)
			});
		}
		
	};
	
	useEffect(() => {
		getData();
	}, []);
	/**
	 *
	 */
	const StepProps = {
		PreProspect: PreProspect,
		setPreProspect: setPreProspect,
		setCurrentStep: setCurrentStep,
		currentStep: currentStep,
		formDisabled: formDisabled,
		setFormDisabled: setFormDisabled
	}
	const init = () => {
		let StepItems = [
			{
				key: STEP_1,
				disabled: true,
				title: <b>Étape 1</b>,
				description: 'Informations personnelles',
				content: <Step_1 step={STEP_1}  {...StepProps}/>
			},
			{
				key: STEP_2,
				disabled: true,
				title: <b>Étape 2</b>,
				description: 'Mon projet',
				content: <Step_2 step={STEP_2}  {...StepProps}/>,
			},
			{
				key: STEP_3,
				disabled: true,
				title: <b>Étape 3</b>,
				description: 'Envoi de la demande',
				content: <Step_3 step={STEP_3}  {...StepProps}/>,
			},
		];
		//
		const getCurrentStepIndex = (currentStep) => StepItems.findIndex(StepItem => StepItem.key === currentStep)
		//
		const content =
			<Spin
				spinning={spinning}
				size="large"
			>
				<div
					id={"pre-prospect-view"}
					style={{
						margin: "0 auto",
						borderRadius: "4px",
						maxWidth: 1000,
						padding: "6px 20px"
					}}>
					<SpaceVertical>
						{/* */}
						<Divider id={"pre-prospect-view-title"} orientation={"left"}>
							<img alt="" src={"/logo.svg"} width={40}/>
							<b>Oscar - Inscription</b>
							<span>{PreProspect && PreProspect.fullname}</span>
						</Divider>
						{/* */}
						{preProspectId && !PreProspect && <Alert type={"error"} showIcon message={"Cette demande  n'existe pas, merci d'en créer une autre."}/>}
						{/* */}
						<Steps
							current={getCurrentStepIndex(currentStep)}
							size="small"
							items={StepItems}
							onChange={(value) => {
								setCurrentStep(StepItems[value].key)
							}}
						/>
						{/* */}
						<div style={{padding: "10px"}}>
							{StepItems[getCurrentStepIndex(currentStep)].content}
						</div>
						{/* */}
					</SpaceVertical>
				
				
				</div>
			</Spin>
		{/* */
		}
		return content
	}
	
	return (
		init()
	);
})

