import {message} from 'antd';

export const Notify = (status, msg) => {
	let funct
	let content = msg || "Opération en cours..."
	let duration = 0
	if (status === undefined) {
		funct = message.info
	} else if (status === true) {
		funct = message.success
		content = msg || "L'opération a réussi."
		duration = 5
	} else {
		funct = message.error
		content = msg || "L'opération a échouée."
		duration = 5
	}
	//
	funct({
		key: "0",
		closeIcon: false,
		//message : content,
		//description:"",
		content: content,
		duration: duration,
		top: 100,
		placement: "top"
	});
	//
	
}
