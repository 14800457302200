/**
 *
 */
import {Tabs} from 'antd';

import {inject, observer} from "mobx-react";
import React from "react";
import {Adresses} from "./InfosGenerales/Adresses";
import {EtatCivil} from "./InfosGenerales/EtatCivil";
import {Photo} from "./InfosGenerales/Photo";
import {RenseignementsDivers} from "./InfosGenerales/RenseignementsDivers";
import {TelephonesEmails} from "./InfosGenerales/TelephonesEmails";
import {RepresentantsLegaux} from "./InfosGenerales/RepresentantsLegaux";
import {CONTEXT_APPRENANT_INFORMATIONS_PRINCIPALES} from "../../../config/Constantes.contexts";

/**
 *
 */

/**
 *
 */
export const InfosGenerales = inject("store")(observer(props => {
	/**
	 *
	 */
	
	const {store} = props
	/**
	 *
	 */
	const
		{
			ApprenantStore,
			UserStore
		} = store
	
	/**
	 *
	 */
	const Apprenant = ApprenantStore.SelectedRecord || {}
	/**
	 *
	 */
	
	
	const TAB_KEY_ETAT_CIVIL = "TAB_KEY_ETAT_CIVIL"
	const TAB_KEY_REPRESENTANT_LEGAL = "TAB_KEY_REPRESENTANT_LEGAL"
	const TAB_KEY_ADRESSES = "TAB_KEY_ADRESSES"
	const TAB_KEY_TELEPHONES_EMAILS = "TAB_KEY_TELEPHONES_EMAILS"
	const TAB_KEY_PHOTO = "TAB_KEY_PHOTO"
	const TAB_KEY_RENSEIGNEMENTS_DIVERS = "TAB_KEY_RENSEIGNEMENTS_DIVERS"
	//
	const Profil = UserStore.getAllowedContext(CONTEXT_APPRENANT_INFORMATIONS_PRINCIPALES)
	const isReadOnly = !Profil.isModifier
	const Panes =
		[
			
			{
				key: TAB_KEY_ETAT_CIVIL,
				label: "Etat civil",
				children: <EtatCivil isReadOnly={isReadOnly} Apprenant={Apprenant}/>
			},
			{
				key: TAB_KEY_REPRESENTANT_LEGAL,
				label: "Représentant légal",
				disabled: !Apprenant.isMineur,
				children: Apprenant.isMineur && <RepresentantsLegaux isReadOnly={isReadOnly} Apprenant={Apprenant}/>
			},
			{
				key: TAB_KEY_ADRESSES,
				label: "Adresses",
				children: <Adresses isReadOnly={isReadOnly} Apprenant={Apprenant}/>,
				disabled: Apprenant._isNew,
			},
			{
				key: TAB_KEY_TELEPHONES_EMAILS,
				label: "Téléphones et emails",
				children: <TelephonesEmails isReadOnly={isReadOnly} Apprenant={Apprenant}/>,
				disabled: Apprenant._isNew,
			},
			{
				key: TAB_KEY_PHOTO,
				label: "Photo",
				children: <Photo isReadOnly={isReadOnly} Apprenant={Apprenant}/>,
				disabled: Apprenant._isNew,
			},
			{
				key: TAB_KEY_RENSEIGNEMENTS_DIVERS,
				label: "Renseignements divers",
				children: <RenseignementsDivers isReadOnly={isReadOnly} Apprenant={Apprenant}/>,
				disabled: Apprenant._isNew,
			},
		
		]
	/**
	 *
	 */
	return (
		<Tabs
			defaultActiveKey={TAB_KEY_ETAT_CIVIL}
			items={Panes}
		/>
	);
}))

