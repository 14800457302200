import {CaretDownOutlined, RotateLeftOutlined, RotateRightOutlined} from '@ant-design/icons';
import {Alert, Badge, Space, Tabs, Tooltip} from "antd";
import {inject, observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {ButtonIcon} from "../../components/antExtendedComponent/Button";
import {SpaceVertical} from "../../components/antExtendedComponent/Space";
import {APPRENANT_STATUT_FORMATION_TERMINEE, APPRENANT_STATUT_INSCRIT, FORMATION_TYPES_VALUES, INSCRIPTION_PROJET_STATUT_ACCEPTE, VALIDATION_PAIEMENT_STATUTS} from "../../config/Constantes.logic";
import {ENV, ENV_DEV} from "../../config/Constantes.urls";
import {InscriptionGrid} from "./datagrid/InscriptionGrid";
import {Abandon} from "./forms/Abandon";
import {Duplication} from "./forms/Duplication";
import {FormationFinancee} from "./forms/FormationFinancee";
import {FormationSouhaitee} from "./forms/FormationSouhaitee";
import {FormationSouhaiteeLangueEtablissement} from "./forms/FormationSouhaiteeLangueEtablissement";
import {FormationSuivie} from "./forms/FormationSuivie";
import {Paiement} from "./forms/Paiement";
import {Parcours} from "./forms/Parcours";
import {PieceJointes} from "./forms/pieceJointe/PieceJointes";
import {ProjetProfessionnel} from "./forms/ProjetProfessionnel";
import {ResultatEtDiplomation} from "./forms/ResultatEtDiplomation";
import {ValidationAdministrative} from "./forms/ValidationAdministrative";
import {Voeux} from "./forms/Voeux";
import {
	CONTEXT_APPRENANT_INSCRIPTION_ABANDON,
	CONTEXT_APPRENANT_INSCRIPTION_DUPLICATION,
	CONTEXT_APPRENANT_INSCRIPTION_FORMATION_CONTINUE_FINANCEE,
	CONTEXT_APPRENANT_INSCRIPTION_FORMATION_SOUHAITEE,
	CONTEXT_APPRENANT_INSCRIPTION_FORMATION_VISEE,
	CONTEXT_APPRENANT_INSCRIPTION_PAIEMENT,
	CONTEXT_APPRENANT_INSCRIPTION_PARCOURS,
	CONTEXT_APPRENANT_INSCRIPTION_PIECES_JOINTES,
	CONTEXT_APPRENANT_INSCRIPTION_PROJET_PROFESSIONNEL,
	CONTEXT_APPRENANT_INSCRIPTION_RESULTAT_ET_DIPLOMATION,
	CONTEXT_APPRENANT_INSCRIPTION_VALIDATION_ADMIN,
	CONTEXT_APPRENANT_INSCRIPTION_VOEUX
} from "../../config/Constantes.contexts";

/**
 *
 */
export const TAB_KEY_VOEUX = "TAB_KEY_VOEUX"
export const TAB_KEY_PARCOURS = "TAB_KEY_PARCOURS"
export const TAB_KEY_FORMATION_SOUHAITEE = "TAB_KEY_FORMATION_SOUHAITEE"
export const TAB_KEY_FORMATION_SOUHAITEE_LANGUE_ETABLISSEMENT = "TAB_KEY_FORMATION_SOUHAITEE_LANGUE_ETABLISSEMENT"
export const TAB_KEY_PROJET_PROFESSIONNEL = "TAB_KEY_PROJET_PROFESSIONNEL"
export const TAB_KEY_FORMATION_VISEE = "TAB_KEY_FORMATION_VISEE"
export const TAB_KEY_PIECES_JOINTES = "TAB_KEY_PIECES_JOINTES"
export const TAB_KEY_FORMATION_CONTINUE_FINANCEE = "TAB_KEY_FORMATION_CONTINUE_FINANCEE"
export const TAB_KEY_PAIEMENT = "TAB_KEY_PAIEMENT"
export const TAB_KEY_VALIDATION_ADMIN = "TAB_KEY_VALIDATION_ADMIN"
export const TAB_KEY_VALIDATION_ABANDON = "TAB_KEY_VALIDATION_ABANDON"
export const TAB_KEY_DUPLICATION = "TAB_KEY_DUPLICATION"
export const TAB_KEY_ABANDON = "TAB_KEY_ABANDON"
export const TAB_KEY_RESULTAT_ET_DIPLOMATION = "TAB_KEY_RESULTAT_ET_DIPLOMATION"

/**
 *
 */

const INSCRIPTION_TABS_POSITION = "inscriptionTabsPosition"
/**
 *
 */
export const Inscription = inject("store")(observer(props => {
	
	const [tabsPositionLeft, setTabsPositionLeft] = useState(localStorage[INSCRIPTION_TABS_POSITION] || false)
	const {store} = props
	const {ApprenantStore, InscriptionStore, UserStore} = store
	
	//
	let ApprenantSelected = ApprenantStore.SelectedRecord || {}
	const Inscriptions = ApprenantSelected.Inscriptions || []
	let InscriptionSelected = InscriptionStore.SelectedRecord
	//
	
	const AuthenticatedUser = UserStore.AuthenticatedUser
	
	//
	useEffect(() => {
		if (!InscriptionSelected && Inscriptions.length > 0) {
			const lastInscription = Inscriptions[0]
			InscriptionStore.setSelectedRecord(lastInscription)
		}
	});
	
	/**
	 *
	 */
	let Panes = [
		{
			allowedContext: CONTEXT_APPRENANT_INSCRIPTION_VOEUX,
			key: TAB_KEY_VOEUX,
			label: "Voeux",
			isReadOnly: true,
			content: (isReadOnly) => <Voeux isReadOnly={isReadOnly}/>,
		},
		{
			allowedContext: CONTEXT_APPRENANT_INSCRIPTION_PARCOURS,
			key: TAB_KEY_PARCOURS,
			label: "Parcours",
			content: (isReadOnly) => <Parcours isReadOnly={isReadOnly}/>,
		},
		{
			allowedContext: CONTEXT_APPRENANT_INSCRIPTION_FORMATION_SOUHAITEE,
			key: TAB_KEY_FORMATION_SOUHAITEE,
			label: "Formation souhaitée",
			content: (isReadOnly) => (
				<SpaceVertical>
					<FormationSouhaitee isReadOnly={isReadOnly}/>
					{InscriptionSelected && InscriptionSelected.isLangueEtablissement && <FormationSouhaiteeLangueEtablissement isReadOnly={isReadOnly}/>}
				</SpaceVertical>
			),
		},
		{
			allowedContext: CONTEXT_APPRENANT_INSCRIPTION_PROJET_PROFESSIONNEL,
			key: TAB_KEY_PROJET_PROFESSIONNEL,
			label: "Entretien",
			content: (isReadOnly) => <ProjetProfessionnel isReadOnly={isReadOnly}/>,
		},
		{
			allowedContext: CONTEXT_APPRENANT_INSCRIPTION_PIECES_JOINTES,
			key: TAB_KEY_PIECES_JOINTES,
			label: "Pièces jointes",
			content: (isReadOnly) => <PieceJointes isReadOnly={isReadOnly}/>,
		},
		{
			allowedContext: CONTEXT_APPRENANT_INSCRIPTION_FORMATION_CONTINUE_FINANCEE,
			key: TAB_KEY_FORMATION_CONTINUE_FINANCEE,
			label: "FC financée",
			content: (isReadOnly) => <FormationFinancee isReadOnly={isReadOnly}/>,
		},
		{
			allowedContext: CONTEXT_APPRENANT_INSCRIPTION_FORMATION_VISEE,
			key: TAB_KEY_FORMATION_VISEE,
			label: "Formation visée",
			content: (isReadOnly) => <FormationSuivie isReadOnly={isReadOnly}/>,
		},
		{
			allowedContext: CONTEXT_APPRENANT_INSCRIPTION_PAIEMENT,
			key: TAB_KEY_PAIEMENT,
			label: "Paiement",
			content: (isReadOnly) => <Paiement isReadOnly={isReadOnly}/>,
		},
		{
			allowedContext: CONTEXT_APPRENANT_INSCRIPTION_VALIDATION_ADMIN,
			key: TAB_KEY_VALIDATION_ADMIN,
			label: "Validation admin",
			content: (isReadOnly) => <ValidationAdministrative isReadOnly={isReadOnly}/>,
		},
		{
			allowedContext: CONTEXT_APPRENANT_INSCRIPTION_DUPLICATION,
			key: TAB_KEY_DUPLICATION,
			label: "Réinscription",
			content: (isReadOnly) => <Duplication isReadOnly={isReadOnly}/>,
		},
		{
			allowedContext: CONTEXT_APPRENANT_INSCRIPTION_ABANDON,
			key: TAB_KEY_ABANDON,
			label: "Abandon",
			content: (isReadOnly) => <Abandon isReadOnly={isReadOnly}/>,
		},
		{
			allowedContext: CONTEXT_APPRENANT_INSCRIPTION_RESULTAT_ET_DIPLOMATION,
			key: TAB_KEY_RESULTAT_ET_DIPLOMATION,
			label: "Résultats",
			content: (isReadOnly) => <ResultatEtDiplomation isReadOnly={isReadOnly}/>,
		},
	
	];
	
	/**
	 *
	 */
	const TabPanes = [];
	Panes.forEach((Pane) => {
		const Profil = UserStore.getAllowedContext(Pane.allowedContext)
		if (Profil) {
			/**
			 * !Profil.isModifier devient isReadOnly=true
			 */
			
			const content = Pane.content(!Profil.isModifier)
			Pane = {
				key: Pane.key,
				label: Pane.label,
				children: content
			};
			TabPanes.push(Pane)
		}
		
	})
	
	/**
	 * la désactivation estb fonction de règle métier et rôle spécifié ci-dessus : authorizedRoles
	 */
	
	if (InscriptionSelected) {
		
		const ProjetProfessionnelChoisiStatut = (InscriptionSelected.ProjetProfessionnelChoisi && InscriptionSelected.ProjetProfessionnelChoisi.statut) || null
		
		TabPanes.forEach((Pane, index) => {
			//
			let disabled = true
			
			switch (Pane.key) {
				default:
					break
				//
				case TAB_KEY_VOEUX:
					disabled = InscriptionSelected.reInscription
					break
				//
				case TAB_KEY_PARCOURS:
					disabled = !InscriptionSelected.isParcoursActif
					break
				case TAB_KEY_FORMATION_SOUHAITEE:
					disabled = InscriptionSelected._isNew
					break
				//
				case TAB_KEY_FORMATION_SOUHAITEE_LANGUE_ETABLISSEMENT:
					disabled = !InscriptionSelected.isLangueEtablissement
					break
				//
				case TAB_KEY_PROJET_PROFESSIONNEL:
					if (InscriptionSelected.FormationSouhaitee.FormationChoisieAvantEntretien) {
						disabled = false
					}
					if (InscriptionSelected.reInscription) {
						disabled = false
					}
					break
				//
				case TAB_KEY_FORMATION_VISEE:
					disabled = !(ProjetProfessionnelChoisiStatut === INSCRIPTION_PROJET_STATUT_ACCEPTE)
					break
				case TAB_KEY_PIECES_JOINTES:
					disabled = !(ProjetProfessionnelChoisiStatut === INSCRIPTION_PROJET_STATUT_ACCEPTE)
					if (disabled === false) {
						Pane.label = <Badge offset={[6]} dot={!InscriptionSelected.isReadyForValidationAdministrative}>{Pane.label}</Badge>
					}
					
					break
				//
				case TAB_KEY_FORMATION_CONTINUE_FINANCEE:
					
					const souhaitFinancementFormation = InscriptionSelected.FormationSouhaitee && InscriptionSelected.FormationSouhaitee.souhaitFinancementFormation
					const isFormationContinue = InscriptionSelected.formationType === FORMATION_TYPES_VALUES.FORMATION_CONTINUE.value
					disabled = !(
						ProjetProfessionnelChoisiStatut === INSCRIPTION_PROJET_STATUT_ACCEPTE
						&& isFormationContinue
						&& souhaitFinancementFormation
					)
					break
				//
				case TAB_KEY_PAIEMENT:
					disabled = !(ProjetProfessionnelChoisiStatut === INSCRIPTION_PROJET_STATUT_ACCEPTE)
					break
				//
				case TAB_KEY_VALIDATION_ADMIN:
					const paiementStatut = InscriptionSelected.Paiement.statut
					if (
						InscriptionSelected.isReadyForValidationAdministrative === true
						&& (paiementStatut === VALIDATION_PAIEMENT_STATUTS.STATUT_ACCEPTE.value)
					
					) {
						disabled = false
					}
					break
				//
				case TAB_KEY_DUPLICATION:
					disabled = !(InscriptionSelected.statutApprenant === APPRENANT_STATUT_INSCRIT)
					break
				//
				case TAB_KEY_ABANDON:
					disabled = (InscriptionSelected.statutApprenant === APPRENANT_STATUT_FORMATION_TERMINEE)
					break
				//
				case TAB_KEY_RESULTAT_ET_DIPLOMATION:
					disabled = !(InscriptionSelected.statutApprenant === APPRENANT_STATUT_INSCRIT)
					break
				/*
				case TAB_KEY_VALIDATION_ABANDON:
					disabled = false
					break
				
				
				 */
				
			}
			//
			Pane.disabled = disabled
			//
			if (ENV === ENV_DEV) {
				//Pane.disabled = false
			}
		})
	}
	/**
	 * on affiche un formulaire vide mais rempli avec un objet du store dont  _isNew=true
	 * si la personne enregistre un élément du parcours ou fromation souhaitée on créer l'Inscription côté serveur
	 * et on affiche le bouton de crétion classique
	 */
	let customTabsProps = {}
	let ChangeTabsPositionButtonStyle = {margin: "auto 20px"}
	let ChangeTabsPositionButtontitle = "Basculer les onglets en mode vertical"
	if (tabsPositionLeft) {
		customTabsProps.type = "card"
		customTabsProps.tabPosition = "left"
		//
		ChangeTabsPositionButtonStyle = {}
		ChangeTabsPositionButtontitle = "Basculer les onglets en mode horizontal"
	}
	/**
	 *
	 */
	const ChangeTabsPositionButton = (props) => <Tooltip title={ChangeTabsPositionButtontitle}>
		<div style={ChangeTabsPositionButtonStyle}>
			<Space>
				<ButtonIcon
					
					ghost={false}
					icon={(tabsPositionLeft ? <RotateRightOutlined/> : <RotateLeftOutlined/>)}
					onClick={() => {
						const value = !tabsPositionLeft
						setTabsPositionLeft(value)
						if (value === true) {
							localStorage[INSCRIPTION_TABS_POSITION] = value
						} else {
							delete localStorage[INSCRIPTION_TABS_POSITION]
						}
						
					}}
				/>
				{InscriptionSelected && InscriptionSelected.numeroTag}
			</Space>
		
		</div>
	
	</Tooltip>
	/**
	 *
	 */
	return <SpaceVertical size={"large"}>
		
		
		{Inscriptions.length === 0 && <Alert showIcon message={<><b>{ApprenantSelected.fullname}</b><span> n'a aucune inscription, elle sera initiée après saisi des voeux</span></>}/>}
		{/* */}
		{
			Inscriptions.length > 0 && <div className={"shadow-2"}>
				<InscriptionGrid Records={Inscriptions} smallVersion _t={store._t}/>
			</div>
		}
		{/* */}
		{InscriptionSelected && InscriptionSelected._isNew && <Alert showIcon message={"Vous devez saisir au moins un élément des formulaires pour sauvegarder cette inscription"}/>}
		
		{/* */}
		<Tabs
			{...customTabsProps}
			activeKey={ApprenantStore.inscriptionPageTabsActiveKey || TAB_KEY_VOEUX}
			items={TabPanes}
			moreIcon={<CaretDownOutlined/>}
			tabBarExtraContent={{left: <ChangeTabsPositionButton/>}}
			onChange={(value) => {
				ApprenantStore.setInscriptionPageTabsActiveKey(value)
			}}
			_t={store._t}
		/>
		{/*
		
		
			InscriptionSelected && <Row gutter={10}>
				<Col span={20}>
					<Tabs
						items={TabPanes}
						_t={store._t}
					/>
				</Col>
				<Col span={4}>
					{<InscriptionStatutSteps Inscription={InscriptionSelected}/>}
				</Col>
			</Row>
		*/}
	
	</SpaceVertical>
}))

/**
 *
 *
 *
 */
