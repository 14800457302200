/**
 *
 */
import {Input, Upload} from 'antd';
import {inject, observer} from "mobx-react";
import React from "react";
import {DRAWERS_NAMES} from "../../../stores/NavigationStore";
import {closeDrawer} from "../../common/components/drawerManager";
import {UPLOAD_DOCUMENT_ACCEPT_DOCX, UPLOAD_DOCUMENT_MAX} from "../../../config/Constantes.logic";
import {Button} from "../../../components/antExtendedComponent/Button";
import {Notify} from "../../../components/Notify";
import {SpaceVertical} from "../../../components/antExtendedComponent/Space";

/**
 *
 */

export default inject("store")(observer(class ReferentielPubliPostageTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store
	}
	
	//modele
	
	state = {
		fileList: [],
		isUploading: false,
		isFileLoaded: false,
		isLoading: false,
		documentName: "",
		progress: 0
		
	};
	
	reinitializeState = () => {
		this.setState({
			confidentiel: "",
			fileList: [],
			isUploading: false,
			isFileLoaded: false,
			isLoading: false,
			documentName: "",
			progress: 0
		});
	}
	handleUpload = () => {
		//
		this.setState({isUploading: true})
		//
		const that = this
		const {fileList} = this.state;
		const {store} = this.props
		const {ReferentielPubliPostageTemplateStore} = store
		
		let file = fileList[0]
		const reader = new FileReader();
		reader.readAsArrayBuffer(file);
		//
		reader.onload = function (e) {
			const extension = file.name.split(".").reverse()[0]
			let ReferentielPubliPostageTemplate = {}
			ReferentielPubliPostageTemplate.originalName = file.name
			ReferentielPubliPostageTemplate.name = that.state.documentName || file.name.replace(extension, "")
			ReferentielPubliPostageTemplate.type = file.type
			ReferentielPubliPostageTemplate.extension = extension
			ReferentielPubliPostageTemplate.size = file.size
			//
			let formData = new FormData();
			formData.append('File', file);
			formData.append('ReferentielPubliPostageTemplate', JSON.stringify(ReferentielPubliPostageTemplate));
			Notify()
			ReferentielPubliPostageTemplateStore.create(formData, succeeded => {
				Notify(succeeded)
				that.reinitializeState()
				//
				closeDrawer(DRAWERS_NAMES.PUBLIPOSTAGE_TEMPLATE_FORM_DRAWER)
				
				//
			})
			
		}
		
	};
	
	render() {
		/**
		 *
		 */
		const that = this
		const {isUploading, fileList, documentName} = this.state;
		/**
		 *
		 */
		const uploadProps = {
			accept: UPLOAD_DOCUMENT_ACCEPT_DOCX,
			fileList,
			onRemove: file => {
				this.setState(state => {
					return {
						fileList: [],
					};
				});
			},
			onChange(info) {
				const name = info.file.name
				const toRemove = "." + name.split(".").reverse()[0]
				const fileName = name.replace(toRemove, "")
				that.setState({documentName: fileName})
			},
			beforeUpload: file => {
				const sizeMaxMo = (UPLOAD_DOCUMENT_MAX / 1024 / 1024).toFixed(2) + " Mo"
				
				const currentSizeMaxMo = (file.size / 1024 / 1024).toFixed(2) + " Mo"
				if (UPLOAD_DOCUMENT_ACCEPT_DOCX !== file.type) {
					Notify(false, "Le format de ce document n'est pas accepté")
					return false
				}
				if (file.size > UPLOAD_DOCUMENT_MAX) {
					alert("La taille maximale d'un document est de " + sizeMaxMo + ", votre document est de " + currentSizeMaxMo)
					return false
				}
				//on ne veut gèrer qu'un fichier à la fois
				this.setState({isFileLoaded: true})
				this.setState(state => ({
					// fileList: [...state.fileList, file],
					fileList: [file],
				}));
				return false;
			},
			
		};
		//
		
		return (
			<SpaceVertical>
				<Upload {...uploadProps}>
					<Button
						type="primary"
					>1. Sélectionnez un document
					</Button>
				</Upload>
				{
					
					<>
						<Input placeholder={"Libellé"} value={this.state.documentName} onChange={(event) => {
							this.setState({documentName: event.target.value})
						}}/>
						{
							<Button
								type="primary"
								onClick={this.handleUpload}
								disabled={fileList.length === 0 || documentName.length === 0}
								loading={isUploading}
							>
								{isUploading ? 'Ajout du document en cours..' : '2. Envoyer le document'}
							</Button>
						}
					
					
					</>
				}
			
			</SpaceVertical>
		
		);
	}
	
}))

