import {Button, Tooltip} from "antd";
import React from "react";
import {IconButton} from "../../../../components/IconButton";
import {Notify} from "../../../../components/Notify";
import {MICRO_SERVICE_URLS} from "../../../../config/Constantes.urls";
import MicroService from "../../../../helpers/MicroService";

/**
 *
 */

/**
 *
 * composant réutisable pour le téléchargement d'un document lié à un bien
 *
 */
export class PieceJointeSpecifiqueDownloadButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {isLoading: false};
	}
	
	/**
	 *
	 */
	
	render() {
		const {Record} = this.props
		/**
		 *
		 */
		const download = () => {
			this.setState({isLoading: true})
			MicroService.get(MICRO_SERVICE_URLS.INSCRIPTION_PIECE_JOINTE_SPECIFIQUE + "/" + Record._id, (Response) => {
				
				Notify(Response.OPERATION_SUCCEED)
				this.setState({isLoading: false})
				//
				const InscriptionPieceJointeSpecifique = Response.Record
				//
				const uint8tArray = new Uint8Array(InscriptionPieceJointeSpecifique.content.data);
				const blob = new Blob([uint8tArray], {type: InscriptionPieceJointeSpecifique.type});
				const fileAsObjectUrl = URL.createObjectURL(blob);
				//
				const element = document.createElement('a');
				element.setAttribute("href", fileAsObjectUrl);
				element.setAttribute("download", InscriptionPieceJointeSpecifique.name);
				element.setAttribute("target", "_blank");
				element.style.display = 'none';
				document.body.appendChild(element);
				element.click();
				document.body.removeChild(element);
			})
			
		}
		/**
		 *
		 */
		return (
			<Tooltip title="Télécharger le document " placement="right">
				<Button
					loading={this.state.isLoading}
					onClick={() => {
						download()
					}}
					size="small"
					shape="circle"
					type="primary"
					ghost
					icon={<IconButton name={"Download"}/>}
				/>
			</Tooltip>
		)
		
	}
}
