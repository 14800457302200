import {Select, Space, Tag} from "antd";
import {inject, observer} from "mobx-react";
import React from "react";
import {key, normalize} from "../../../helpers/Utils";

/**
 *
 * SelectPays
 *
 */
export const SelectCountry = inject("store")(observer(React.forwardRef((props, refs) => {
	
	const {
		store,
		valuename //valuename : champ de l'item qui va servir de value
	} = props
	const {CountryStore} = store
	const Countries = CountryStore.getAll()
	//
	
	//
	return <Select
		{...props}
		showSearch
		optionFilterProp={"label"}
	>
		{
			Countries.map(item => {
					let value = item._id
					switch (valuename) {
						default:
							break
						case "name":
							value = item.name
							break
						case "dialCode":
							value = item.dialCode + "|" + item.name
							break
						
					}
					
					const valueprops = {
						value: value
					}
					return <Select.Option {...valueprops} key={key()} label={normalize(item.name) + " " + item.name}>
						<Space>
							{
								valuename === "dialCode" && <Tag style={{width: 55}} color={"blue"}>{item.dialCode}</Tag>
							}
							{item.name}
						</Space>
					
					
					</Select.Option>
				}
			)
		}
	
	</Select>
})))
/**
 *
 *
 *
 */
