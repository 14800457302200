/**
 *
 */
import {EditOutlined, LinkOutlined, PartitionOutlined, UserSwitchOutlined} from "@ant-design/icons";
import {Badge, Button, Row, Space, Switch, Table, Tag, Tooltip} from "antd";
import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import {ButtonIcon} from "../../components/antExtendedComponent/Button";
import {BadgeCount} from "../../components/BadgeCount";
import {Icon} from "../../components/Icon";
import {IconButton} from "../../components/IconButton";
/**
 *
 */
import {InputSearch} from "../../components/InputSearch";
import {Notify} from "../../components/Notify";
import {FILTER_BY_FAVORITES, FILTER_BY_PRISE_EN_COMPTE, FILTER_BY_WITH_INSCRIPTION, FILTER_BY_WORDS} from "../../config/Constantes.logic";
import {BUTTON_DEFAULT_PROPS, COLUMN_ICON_WIDTH, DEFAULT_PAGINATION, DEFAULT_TABLE_SCROLL} from "../../config/Constantes.ui";
import {simpleSorter} from "../../helpers/Utils";

import {CopyReference} from "../common/components/CopyReference";
import {CreateurModificateurIcon} from "../common/components/CreateurModificateurIcon";
import {destroyAllDrawers, openDrawer} from "../common/components/drawerManager";
import {FavoriteButton} from "../common/components/FavoriteButton";
import {FavoriteSwitch} from "../common/components/FavoriteSwitch";
import {TAB_KEY_VOEUX} from "../inscription/Inscription";

import {DRAWERS_NAMES} from "./../../stores/NavigationStore";
import {Apprenant, TAB_KEY_INFORMATIONS_PRINCIPALES} from "./Apprenant";
import {SpaceVertical} from "../../components/antExtendedComponent/Space";
import {USER_ROLE_APPRENANT} from "../../config/Constantes.roles";
import {PreProspectButtonFormulaire} from "../preProspect/PreProspectButtonFormulaire";
import {CONTEXT_APPRENANT_CREER_BUTTON, CONTEXT_APPRENANT_INSCRIPTION_PIECES_JOINTES} from "../../config/Constantes.contexts";

/**
 *
 */
const KEY_IN_USER_SETTINGS = "UserSettings.apprenant"
/**
 *
 */
export default inject("store")(observer(class Apprenants extends Component {
	/**
	 * constantes de filtres des Apprenants ( se trouve dans le store Apprenant )
	 */
	
	Filters = {
		FILTER_BY_WORDS: {name: FILTER_BY_WORDS, value: null},
		FILTER_BY_FAVORITES: {name: FILTER_BY_FAVORITES, value: null},
		FILTER_BY_WITH_INSCRIPTION: {name: FILTER_BY_WITH_INSCRIPTION, value: true},
		FILTER_BY_PRISE_EN_COMPTE: {name: FILTER_BY_PRISE_EN_COMPTE, value: false}
	}
	
	constructor(props) {
		super(props);
		const UserStore = this.props.store.UserStore
		this.UserStore = UserStore
		const UserSettings = this.UserStore.getUserSettings(KEY_IN_USER_SETTINGS)
		this.state = {
			Filters: UserSettings.Filters || this.Filters,
			//UserColumns : UserColumns,
			Sorter: {order: null, field: null},
			selectedRows: [],
			showSelection: false,
			showColumnsSelection: false,
			showFilters: JSON.parse((localStorage.showFilters || "false")),
			//
			
		}
	}
	
	/**
	 *
	 * @param Setting
	 */
	setUserSettings = (Setting) => {
		this.UserStore.setUserSettings(Setting, KEY_IN_USER_SETTINGS)
	}
	
	/**
	 * Filters
	 */
	setFilter = (Filter) => {
		const {Filters} = this.state
		const name = Filter.name
		const value = Filter.value
		if (!value) {
			delete Filters[name]
		} else {
			Filters[name] = {name: name, value: value}
		}
		
		this.setState({Filters: Filters}, () => {
			this.setUserSettings({Filters: Filters}, KEY_IN_USER_SETTINGS)
			
		})
	}
	resetFilters = () => {
		const {Filters} = this.state
		Object.values(Filters).forEach(Filter => {
			Filter.value = null
			this.setFilter(Filter)
		})
		
	}
	
	/**
	 *
	 */
	export = () => {
		Notify(true, this.state.selectedRows.map(item => item.nomUsage).join(", "))
		console.log(this.state.selectedRows)
	}
	
	/**
	 *
	 */
	render() {
		/**
		 *
		 */
		const {Filters, Sorter, selectedRows, showFilters, showSelection} = this.state;
		/**
		 *
		 */
		const {store} = this.props
		const {ApprenantStore, UserStore} = store
		
		const AuthenticatedUser = UserStore.AuthenticatedUser
		const Records = ApprenantStore.getAllByFilters(Filters, Sorter)
		
		/**
		 *
		 */
		const drawerName = DRAWERS_NAMES.APPRENANT_FORM_DRAWER
		const edit = (Apprenant) => {
			Apprenant.edit()//
			ApprenantStore.setApprenantPageTabsActiveKey(TAB_KEY_INFORMATIONS_PRINCIPALES)
			ApprenantStore.setInscriptionPageTabsActiveKey(TAB_KEY_VOEUX)
			destroyAllDrawers()
			openDrawer(drawerName)
		}
		const create = () => {
			ApprenantStore.setApprenantPageTabsActiveKey(TAB_KEY_INFORMATIONS_PRINCIPALES)
			ApprenantStore.create()
			openDrawer(drawerName)
		}
		/**
		 * affichage des checkbox de sélection pour l'export
		 */
		const RowSelection = {
			//renderCell:(checked, record, index, originNode)=>originNode,
			onSelect: (record, selected, selectedRows) => {
				this.setState({selectedRows: selectedRows})
			},
			onSelectAll: (selected, selectedRows, changeRows) => {
				this.setState({selectedRows: selectedRows})
			},
		};
		
		/**
		 * FilterByWords
		 */
		const FilterByWords = (
			<InputSearch
				value={Filters.FILTER_BY_WORDS && Filters.FILTER_BY_WORDS.value}
				placeholder=""
				onChange={event => {
					const Filter = this.Filters[FILTER_BY_WORDS]
					Filter.value = event.target.value
					this.setFilter(Filter)
				}}
			/>
		)
		/**
		 * FilterByFavorites
		 */
		const FilterByFavorites = (
			<FavoriteSwitch
				checked={Filters.FILTER_BY_FAVORITES && Filters.FILTER_BY_FAVORITES.value}
				onChange={(value) => {
					const Filter = this.Filters[FILTER_BY_FAVORITES]
					Filter.value = value
					this.setFilter(Filter)
					
				}}
			/>
		)
		/**
		 * FilterByNoInscriptions
		 */
		const FilterByNoInscriptions = (
			<Switch
				checkedChildren="Inscrits"
				unCheckedChildren="Inscrits"
				checked={Filters.FILTER_BY_WITH_INSCRIPTION && Filters.FILTER_BY_WITH_INSCRIPTION.value}
				onChange={(value) => {
					const Filter = this.Filters[FILTER_BY_WITH_INSCRIPTION]
					Filter.value = value
					this.setFilter(Filter)
					
				}}
			/>
		
		)
		
		/**
		 *
		 */
		const FilterPriseEnCompte = <Switch
			checkedChildren="Pris en compte"
			unCheckedChildren="Pris en compte"
			checked={Filters.FILTER_BY_PRISE_EN_COMPTE && Filters.FILTER_BY_PRISE_EN_COMPTE.value}
			onChange={(value) => {
				const Filter = this.Filters[FILTER_BY_PRISE_EN_COMPTE]
				Filter.value = value
				this.setFilter(Filter)
			}}
		/>
		/**
		 *
		 */
		const isExistsFilters = Boolean(Object.keys(Filters).length)
		const ButtonResetFilters = (
			<Tooltip title="Supprimer tous les filtres" placement="topRight">
				<Badge dot={isExistsFilters}><Button
					shape="circle"
					type="primary"
					ghost
					disabled={!isExistsFilters}
					icon={<IconButton name={"EraseTool"} type="button"/>}
					onClick={this.resetFilters}/>
				</Badge>
			</Tooltip>
		)
		/**
		 *
		 */
		const ProfilContextCreerApprenant = UserStore.getAllowedContext(CONTEXT_APPRENANT_CREER_BUTTON)
		const ButtonCreateApprenant = (
			ProfilContextCreerApprenant&&<Button
				size={"small"}
				{...BUTTON_DEFAULT_PROPS}
				icon={<UserSwitchOutlined/>}
				onClick={(e) => create()}>Créer un apprenant</Button>
		)
		
		/**
		 *
		 */
		const ButtonExportToXls = (
			<Badge count={selectedRows.length} overflowCount={selectedRows.length + 1}>
				<Tooltip title="Exporter la liste sous format excel (xlsx)" placement="topRight">
					<Button
						shape="circle"
						type="primary"
						disabled={!Boolean(selectedRows.length)}
						ghost
						icon={<IconButton name={"ExcelDocument"} type="button"/>}
						onClick={(e) => this.export()}
					/>
				</Tooltip>
			</Badge>
		)
		/**
		 *
		 */
		const ButtonShowHideRowSelection = (
			<Tooltip title="Afficher l'outil de sélection" placement="topRight">
				<Button
					shape="circle"
					type={"primary"}
					ghost={!showSelection}
					icon={<IconButton name="MultiSelect"/>}
					onClick={(e) => {
						e.preventDefault();
						this.setState({selectedRows: []})
						this.setState({showSelection: !showSelection})
					}}/>
			</Tooltip>
		)
		
		/**
		 *
		 */
		const Columns = [
			{
				
				dataIndex: '_isFavorite',
				title: '',
				width: COLUMN_ICON_WIDTH,
				fixed: "left",
				className: "center",
				render: (text, Record) => <FavoriteButton Record={Record}/>
			},
			
			{
				default: true,
				dataIndex: 'actionEdit',
				title: '',
				width: 50,
				fixed: "left",
				className: "center",
				render: (text, Record, index) => {
					
					const ghost = !((ApprenantStore.SelectedRecord && ApprenantStore.SelectedRecord._id) === Record._id)
					
					return <Tooltip title="Modifier">
						<ButtonIcon
							ghost={ghost}
							icon={<EditOutlined/>}
							onClick={(e) => {
								e.preventDefault();
								edit(Record)
							}}
						/>
					</Tooltip>
				}
			},
			/**
			 *
			 */
			{
				dataIndex: 'priseEnCompteTag',
				title: <Tooltip title="Prise en compte" placement="top"><Icon name={"Clock"}/></Tooltip>,
				width: 50,
				fixed: "left",
				className: "center",
				sorter: simpleSorter("priseEnCompte"),
				render: (text, Record) => Record.priseEnCompteTag
			},
			/**
			 * fullname
			 */
			{
				dataIndex: "fullname",
				title: "Apprenant",
				width: 200,
				fixed: "left",
				ellipsis: true,
				sorter: simpleSorter("fullname"),
				render: (text, Record) => <Space>
					{Record.warninIfEdited}
					<a href={"/"} onClick={(e) => {
						e.preventDefault();
						edit(Record)
					}}>{Record.fullname}</a>
				</Space>
			},
			
			/**
			 * dateNaissanceFormated
			 */
			{
				dataIndex: "dateNaissanceFormated",
				title: 'Né le',
				className: "center",
				width: 95,
				render: (text, Record) => text
			},
			/**
			 *
			 */
			{
				dataIndex: "telephonePrincipal",
				title: 'Téléphone',
				width: 150,
				ellipsis: true,
				render: (text, Record) => Record.telephonePrincipal
			},
			
			{
				dataIndex: "emailPrincipal",
				title: 'Email',
				width: 250,
				ellipsis: true,
				render: (text, Record) => Record.emailPrincipal
			},
			/**
			 *
			 */
			{
				dataIndex: "_nationalite",
				title: 'Nationalité',
				width: 180,
				ellipsis: true,
				sorter: simpleSorter("_nationalite")
			},
			/**
			 *
			 */
			{
				dataIndex: "_space_",
				title: '',
			},
			{
				dataIndex: "totalInscription",
				title: <Tooltip title="Nombre d'inscriptions" placement="top"><PartitionOutlined/></Tooltip>,
				width: COLUMN_ICON_WIDTH,
				fixed: "right",
				//sorter : simpleSorter("totalInscription"),
				render: (text, Record) => <Tag>{Record.totalInscription}</Tag>
			},
			{
				dataIndex: '_id',
				title: 'Réf.',
				width: COLUMN_ICON_WIDTH,
				fixed: "right",
				className: "center",
				render: (text, Record) => <CopyReference Record={Record} tooltip={"Copier l'identifiant " + Record._id}/>
			},
			/**
			 * CreateurModificateur
			 */
			{
				dataIndex: 'lienConnectionIcon',
				title: <Tooltip title="Lien de connection" placement="top"><LinkOutlined/></Tooltip>,
				width: COLUMN_ICON_WIDTH,
				fixed: "right",
				className: "center",
				render: (text, Record) => Record.lienConnectionIcon
			},
			/**
			 * CreateurModificateur
			 */
			{
				dataIndex: 'CreateurModificateur',
				title: '',
				width: COLUMN_ICON_WIDTH,
				fixed: "right",
				className: "center",
				render: (text, Record) => <CreateurModificateurIcon Record={Record}/>
			},
			/*
			{
				dataIndex: 'actionDestroy',
				title: '',
				width: COLUMN_ICON_WIDTH,
				fixed: "right",
				className: "center",
				render: (text, Record) => <DestroyButton record={Record}/>
			},
			
			 */
		]
		/**
		 *
		 */
		return (
			<SpaceVertical>
				{/* */}
				<Apprenant/>
				{/* */}
				{
					AuthenticatedUser && AuthenticatedUser.hasRoles(USER_ROLE_APPRENANT) === false
					&&
					<>
						<Row gutter={[16, 16]}>
							{/*  */}
							<Space style={{flex: 1}} size="middle">
								<BadgeCount total={Records.length}/>
								{FilterByWords}
								{FilterByFavorites}
								{FilterByNoInscriptions}
								{FilterPriseEnCompte}
							</Space>
							{/*  */}
							<Space size="middle">
								{/*ButtonResetFilters*/}
								{/* ButtonShowHideFilters */}
								{ButtonExportToXls}
								{ButtonShowHideRowSelection}
								<PreProspectButtonFormulaire/>
								{ButtonCreateApprenant}
							</Space>
							{/*  */}
						</Row>
					</>
				}
				{/*  */}
				<Table
					rowKey="_id"
					dataSource={Records}
					columns={Columns}
					size="small"
					className={"shadow-2"}
					rowSelection={showSelection && RowSelection}
					rowClassName={(Record, index) => (Record._id === (ApprenantStore.SelectedRecord && ApprenantStore.SelectedRecord._id)) ? "table-row-selected" : ""}
					pagination={DEFAULT_PAGINATION}
					scroll={DEFAULT_TABLE_SCROLL}
					showSorterTooltip={false}
					_t={store._t}
				/>
				{/*  */}
			</SpaceVertical>
		)
		
	}
}))
