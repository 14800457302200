import {getParent, types} from "mobx-state-tree";

/**
 *
 */

export const DRAWERS_NAMES = {
	//NAVIGATION_MENU : "NAVIGATION_MENU",
	PIECE_JOINTE_FORM_DRAWER: "PIECE_JOINTE_FORM_DRAWER",
	PIECE_JOINTE_SPECIFIQUE_FORM_DRAWER: "PIECE_JOINTE_SPECIFIQUE_FORM_DRAWER",
	PHOTO_FORM_DRAWER: "PHOTO_FORM_DRAWER",
	DOCUMENT_FORM_DRAWER: "DOCUMENT_FORM_DRAWER",
	PREPROSPECT_FORM_DRAWER: "PREPROSPECT_FORM_DRAWER",
	APPRENANT_FORM_DRAWER: "APPRENANT_FORM_DRAWER",
	MAQUETTE_FORM_DRAWER: "MAQUETTE_FORM_DRAWER",
	PROJET_VALIDATION_DRAWER: "PROJET_VALIDATION_DRAWER",
	PUBLIPOSTAGE_TEMPLATE_FORM_DRAWER: "PUBLIPOSTAGE_TEMPLATE_FORM_DRAWER",
}
/**
 *
 */
export const NavigationStore = types
	.model({
		isLoading: false,
		_client: types.maybeNull(types.string, ""),
		_sid: types.maybeNull(types.string, ""),
		//
		Drawers: types.map(types.optional(types.string, "")),
		//
	})
	.views((self) => ({
		get sid() {
			return sessionStorage["sid"] || null
		},
		get userContext() {
			return sessionStorage["userContext"] || null
		},
		get client() {
			return self._client
		},
	}))
	.actions((self) => ({
		setSid(value) {
			sessionStorage["sid"] = value
		},
		setUserContext(value) {
			sessionStorage["userContext"] = value
		},
		setClient(value) {
			self._client = value
		},
		
		setLoading(value) {
			self.isLoading = value
		},
		setVersion(value) {
			self.version = value
		},
		
		/**
		 *
		 * @param value
		 */
		setDrawerVisible(name) {
			self.Drawers.set(name, name)
			getParent(self, 1).notifyUIChanges()
		},
		destroyDrawer(name) {
			self.Drawers.delete(name)
			getParent(self, 1).notifyUIChanges()
		},
		destroyDrawers() {
			self.Drawers.clear()
			getParent(self, 1).notifyUIChanges()
		},
		isDrawerVisible(name) {
			return Boolean(self.Drawers.get(name))
		},
		
	}))



