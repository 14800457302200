import {SyncOutlined} from "@ant-design/icons";
import {Button, Tooltip} from "antd";
import {inject, observer} from "mobx-react";
import React, {useState} from "react";
import {Notify} from "../../components/Notify";
import {BUTTON_DEFAULT_PROPS} from "../../config/Constantes.ui";
import {MICRO_SERVICE_URLS} from "../../config/Constantes.urls";
import microService from "../../helpers/MicroService";

/**
 *
 */
export const RefreshFromApiMoodleButton = inject("store")(observer(props => {
	/**
	 *
	 */
	const {store} = props
	const {InscriptionEvaluationStore} = store
	const [isLoading, setIsLoading] = useState(false)
	/**
	 *
	 */
	const onClick = () => {
		//
		setIsLoading(true)
		//
		microService.get(MICRO_SERVICE_URLS.IMPORT_MOODLE, (Response) => {
			console.log(Response)
			InscriptionEvaluationStore.addBulkToStore(Response.InscriptionEvaluations)
			//
			setIsLoading(false)
			//
			Notify(Response.OPERATION_SUCCEED)
			//
			
		})
		//
	}
	/**
	 *
	 */
	return <Tooltip title={"Synchroniser les évaluations à partir de la plateforme moodle"} placement={"bottomRight"}>
		<Button
			{...BUTTON_DEFAULT_PROPS}
			size={"small"}
			loading={isLoading}
			icon={<SyncOutlined/>}
			//
			onClick={onClick}>
			Synchroniser les évaluations
		</Button>
	</Tooltip>
	/**
	 *
	 */
}))
