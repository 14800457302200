import alasql from "alasql";
import days from "dayjs";
import {values} from "mobx";
import {applySnapshot, getParent, types} from "mobx-state-tree";
import {CIVILITE_VALUES, FORMATION_PROFILS_VALUES, NATIONALITE_VALUES, PREPROSPECT_DEMANDE_NON_ENVOYEE, PREPROSPECT_DEMANDE_STATUTS, PROJET_OBJECTIFS} from "../config/Constantes.logic";
import {MICRO_SERVICE_URLS} from "../config/Constantes.urls";
import MicroService from "../helpers/MicroService";
import {toLocalDateTime} from "../helpers/Utils";

/**
 *
 */
const CURRENT_MICRO_SERVICE_URL = MICRO_SERVICE_URLS.PRE_PROSPECT
/**
 * Model
 */

export const PreProspectModel = types
	.model({
		_id: types.identifier,
		sid: types.maybeNull(types.string, ""),
		Apprenant: types.maybeNull(types.string, ""),
		statut: types.maybeNull(types.string, ""),
		//InformationPersonnelles
		fullname: types.maybeNull(types.string, ""),
		nomUsage: types.maybeNull(types.string, ""),
		prenom: types.maybeNull(types.string, ""),
		dateNaissance: types.maybeNull(types.string, ""),
		email: types.maybeNull(types.string, ""),
		telephone: types.maybeNull(types.string, ""),
		civilite: types.maybeNull(types.string, ""),
		nationalite: types.maybeNull(types.string, ""),
		
		//projet
		profil: types.maybeNull(types.string, ""),
		FormationSouhaiteePremiere: types.maybeNull(types.string, ""),
		FormationSouhaiteeSeconde: types.maybeNull(types.string, ""),
		FormationSouhaitees: types.frozen({}),
		diplomeLePlusEleve: types.maybeNull(types.string, ""),
		objectif: types.maybeNull(types.string, ""),
		domaineActivite: types.maybeNull(types.string, ""),
		details: types.maybeNull(types.string, ""),
		
		//
		createdAt: types.maybeNull(types.string, ""),
		updatedAt: types.maybeNull(types.string, ""),
	})
	.views((self) => ({
		get _createdAt() {
			return toLocalDateTime(self.createdAt)
		},
		get _updatedAt() {
			return toLocalDateTime(self.updatedAt)
		},
		
		get _dateNaissance() {
			return days(self.dateNaissance).format("DD/MM/YYYY");
		},
		
		get _civilite() {
			const civilite = self.civilite
			return civilite && CIVILITE_VALUES[self.civilite].label
		},
		get _nationalite() {
			const nationalite = self.nationalite
			return nationalite && NATIONALITE_VALUES[nationalite].label
		},
		get statutTag() {
			return self.statut && PREPROSPECT_DEMANDE_STATUTS[self.statut].tag
		},
		get _formationSouhaiteePremiere() {
			const Formations = getParent(self, 3).FormationStore.getAll()
			const FormationFound = Formations.find(Formation => Formation._id === self.FormationSouhaiteePremiere)
			return FormationFound && FormationFound.libelle
		},
		get _formationSouhaiteeSeconde() {
			const Formations = getParent(self, 3).FormationStore.getAll()
			const FormationFound = Formations.find(Formation => Formation._id === self.FormationSouhaiteeSeconde)
			return FormationFound && FormationFound.libelle
		},
		
		get _profil() {
			const profil = self.profil
			return profil && FORMATION_PROFILS_VALUES[profil].label
		},
		get _objectif() {
			const objectif = self.objectif
			return objectif && PROJET_OBJECTIFS[objectif].label
		},
		
	}))
	.actions((self) => ({
		isDeletable() {
			return self.statut === PREPROSPECT_DEMANDE_NON_ENVOYEE
		},
		/**
		 *
		 */
		edit() {
			getParent(self, 2).setRecordOnEdition(self);
		},
		update(EditedRecord) {
			applySnapshot(self, Object.assign(self, EditedRecord));
			
		},
		save(callback) {
			
			/**
			 * attention dabs ce store il n y aura jamais de création par l'agent
			 * donc juste update
			 */
			let url = CURRENT_MICRO_SERVICE_URL + "/" + self._id
			let service = MicroService.update
			let data = {Record: self}
			
			//
			service(url, data, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					let Record = Response.Record
					console.log(Record)
					getParent(self, 2).addToStore(Record)
				}
				callback(succeeded)
			})
			
		},
		destroy(callback) {
			MicroService.delete(CURRENT_MICRO_SERVICE_URL + "/" + self._id, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					getParent(self, 2).removeFromStore(self._id)
					
				}
				callback(succeeded)
				
			})
			
		}
	}))
;
/**
 * Store
 */
export const PreProspectStore = types
	.model({
		PreProspects: types.map(PreProspectModel)
	})
	
	.actions((self) => ({
		setRecordOnEdition(SelectedRecord) {
			SelectedRecord._isOnEdition = true
			self.SelectedRecord = SelectedRecord
		},
		addToStore(Record) {
			self.PreProspects.set(Record._id, Record)
			getParent(self, 1).notifyUIChanges()
		},
		removeFromStore(_id) {
			self.PreProspects.delete(_id)
			//self.SelectedRecord = null
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 * @param searchText
		 * @param byFullSearch
		 * @param byFavorites
		 * @returns {*}
		 */
		getAll() {
			let Records = values(self.PreProspects);
			let query = 'SELECT _id FROM ?  ORDER BY updatedAt DESC';
			const Rows = alasql(query, [Records]);
			return Rows.map(Row => self.PreProspects.get(Row._id));
			
		},
		
	}))

