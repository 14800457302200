import {Button, Form, Input, Modal, Select} from 'antd';
import {inject, observer} from "mobx-react";
import React from "react";
import {Notify} from "../../components/Notify";
import {BUTTON_DEFAULT_PROPS, FORM_DEFAULT_RULE_REQUIRED} from "../../config/Constantes.ui";
import {key, ucFirst} from "../../helpers/Utils";

/**
 *
 */
export const Commentaire = inject("store")(observer(props => {
		/**
		 *
		 */
		const {store, belongTo, isFormVisible, setIsFormVisible} = props
		const {CommentaireStore, ReferentielCommentaireTagStore} = store
		const [form] = Form.useForm();
		/**
		 *
		 */
		const Tags = ReferentielCommentaireTagStore.getAll()
		
		/**
		 *
		 */
		
		const onFinish = () => {
			form.validateFields()
				.then((values) => {
					values.belongTo = belongTo
					values.texte = ucFirst(values.texte)
					let tags = values.tags
					if (tags) {
						tags = tags.map(tag => ucFirst(tag)).sort()
						values.tags = tags
					}
					Notify()
					CommentaireStore.create(values, succeeded => {
						Notify(succeeded)
						form.resetFields()
						setIsFormVisible(false)
					})
				})
				.catch((errorInfo) => {
					console.log("form errors", errorInfo)
				})
			
		}
		/**
		 *
		 */
		const ButtonSave = <div key={key()} className={"center"}>
			<Button
				{...BUTTON_DEFAULT_PROPS}
				block
				onClick={onFinish}
			>Enregistrer</Button>
		</div>
		/**
		 *
		 */
		
		
		const SelectTagsProps = {
			mode: "multiple",
			options: Tags.map((Tag) => ({
				label: Tag.libelle,
				value: Tag.libelle,//on stocke le libelle
			})),
		}
		
		/**
		 *
		 */
		return (
			<Modal
				_t={store._t}
				centered
				open={isFormVisible}
				title={"Ajouter un message"}
				footer={[ButtonSave]}
				mask={false}
				maskClosable={false}
				onCancel={() => {
					setIsFormVisible(false)
				}}
			>
				<Form form={form}>
					<Form.Item name={'tags'}>
						<Select {...SelectTagsProps}/>
					</Form.Item>
					<Form.Item name="texte" rules={FORM_DEFAULT_RULE_REQUIRED}>
						<Input.TextArea placeholder={"Votre texte"} autoSize={{minRows: 4}}/>
					</Form.Item>
				</Form>
			</Modal>
		)
		
		/**
		 *
		 */
	}
));
