import React from "react";
import {IconInfo} from "./IconInfo";

/**
 *
 * CloseIcon
 *
 */
export const IconUnlink = (props) => <IconInfo
	{...props}
	name={"RemoveLink"}
	style={Object.assign({color: "red", cursor: "pointer"}, props.style)}
/>
/**
 *
 *
 *
 */
