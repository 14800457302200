import React from "react";
import {Collapse, CollapsePanel} from "../../components/antExtendedComponent/Collapse";
import {ReferentielPieceJointeAssociation} from "./pieceJointe/ReferentielPieceJointeAssociation";
import {ReferentielPieceJointes} from "./pieceJointe/ReferentielPieceJointes";

//const DEFAULT_ACTIVE_KEY = "activeKey:ReferentielPieceJointe"
//const defaultActiveKey = sessionStorage[DEFAULT_ACTIVE_KEY]
/**
 *
 */
export const ReferentielPieceJointe = (props) => <Collapse>
	{/* */}
	<CollapsePanel key={"0"} header={"Paramétrage d'une pièce jointe type"}>
		<ReferentielPieceJointes/>
	</CollapsePanel>
	{/* */}
	<CollapsePanel key={"1"} header={"Association à une formation"}>
		<ReferentielPieceJointeAssociation/>
	</CollapsePanel>
	{/* */}
</Collapse>
