import {Tag} from "antd";
import React from "react";

export const YesNoTag = (props) => {
	if (props.value === true) {
		return <Tag {...props}>Oui</Tag>
	}
	if (props.value === false) {
		return <Tag color={"red"}  {...props}>Non</Tag>
	}
}
