import {Space as AntSpace} from "antd";
import React from "react";

/**
 *
 */
export const SpaceVertical = (props) => <AntSpace direction={"vertical"} size={"middle"} {...props}>{props.children}</AntSpace>
export const SpaceHorizontal = (props) => <AntSpace size={"middle"}  {...props} >{props.children}</AntSpace>
/**
 *
 */
