import {Alert, Button, Checkbox, DatePicker, Form} from "antd";
import days from "dayjs";
import {inject, observer} from "mobx-react";
import React, {useState} from "react";
import {SpaceHorizontal, SpaceVertical} from "../../../components/antExtendedComponent/Space";
import {Notify} from "../../../components/Notify";
import {BUTTON_DEFAULT_PROPS} from "../../../config/Constantes.ui";

;

/**
 *
 */
export const Duplication = inject("store")(observer(props => {
	const {isReadOnly, store} = props
	const
		{
			InscriptionStore
			
		} = store
	
	const Inscription = InscriptionStore.SelectedRecord
	
	const [nextAnneeCreation, setNextAnneeCreation] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [buttonDisabled, setButtonDisabled] = useState(true)
	//
	const duplicate = () => {
		
		setIsLoading(true)
		Inscription.duplicate({nextAnneeCreation}, succeeded => {
			setIsLoading(false)
			Notify(succeeded)
		})
		
	}
	//disabled={isReadOnly}
	console.log(nextAnneeCreation)
	return (
		<>
			{
				!Inscription.isDuplicable && <Alert showIcon message={"Vous ne pouvez dupliquer qu'une inscription au statut 'inscrit'."}/>
			}
			{
				Inscription.isDuplicable &&
				<Form disabled={isReadOnly}>
					<SpaceVertical>
						
						{
							Inscription.duplique
							&& <Alert showIcon type={"warning"} message={
								"Cette inscription a déjà été dupliquée, néanmoins vous pouvez  la mettre à jour. Attention, cela va écraser les anciennes modifications !"
								
							}/>
						}
						
						<Checkbox onChange={(e) => {
							setButtonDisabled(!e.target.checked)
						}}
						>Je souhaite procèder à la réinscription de l'apprenant <b>{Inscription.Apprenant.fullname}</b></Checkbox>
						<SpaceHorizontal>
							<b>Année de réinscription </b>
							
							{/* */}
							<DatePicker
								disabled={buttonDisabled}
								picker="year"
								className={"year"}
								allowClear={false}
								placeholder={""}
								defaultValue={nextAnneeCreation && days(nextAnneeCreation.toString())}
								disabledDate={(value) => {
									return value < days((Inscription.anneeCreation + 1).toString());
								}}
								onChange={(value) => {
									setNextAnneeCreation(days(value).format("YYYY"))
								}}
							/>
							{/* */}
							<Button
								{...BUTTON_DEFAULT_PROPS}
								disabled={buttonDisabled}
								loading={isLoading}
								onClick={duplicate}
							>    {Inscription.duplique ? "mettre à jour" : "Réinscrire"}</Button>
							
							{/* */}
						</SpaceHorizontal>
					</SpaceVertical>
				</Form>
			}
		</>
	);
}))
