import alasql from "alasql";
import {values} from "mobx";
import {applySnapshot, getParent, types} from "mobx-state-tree";
/**
 *
 */
import {FILTER_BY_FAVORITES, FILTER_BY_WORDS} from "../config/Constantes.logic";
import {MICRO_SERVICE_URLS} from "../config/Constantes.urls";
import MicroService from "../helpers/MicroService";
import {toLocalDateTime} from "../helpers/Utils";
import {USER_ROLES} from "../config/Constantes.roles";
import {Space} from "antd";
import {CONTEXT_ROLES} from "../config/Constantes.contexts";
import {SESSION_STORAGE_PROFIL_SWITCHER_KEY, SESSION_STORAGE_ROLE_SWITCHER_KEY} from "../pages/main/ProfilSwitcher";
/**
 *
 */

/**
 *
 */
const CURRENT_MICRO_SERVICE_URL = MICRO_SERVICE_URLS.USER
/**
 *
 */

export const UserModel = types
	.model({
		_id: types.identifier,
		sid: types.maybeNull(types.string, ""),
		active: types.optional(types.boolean, false),
		fullname: types.maybeNull(types.string, ""),
		nom: types.maybeNull(types.string, ""),
		prenom: types.maybeNull(types.string, ""),
		login: types.maybeNull(types.string, ""),
		email: types.maybeNull(types.string, ""),
		
		service: types.maybeNull(types.string, ""),
		//role: types.maybeNull(types.string, ""),
		roles: types.array(types.string, ""),
		//Settings : types.frozen({}),
		//lastConnection: types.maybeNull(types.string, ""),
		lastUse: types.maybeNull(types.string, ""),
		//
		_isOnEdition: types.optional(types.boolean, false),
		_isNew: types.optional(types.boolean, false),
		//
	})
	.views((self) => ({
		/*
		get isDeletable() {
			const Records = getParent(self, 3).RdvStore.getAll()
			const found = Records.find(Record => Record.Owner._id === self._id)
			return !Boolean(found)
		},
		
		 */
		get _createdAt() {
			return toLocalDateTime(self.createdAt)
		},
		get _updatedAt() {
			return toLocalDateTime(self.updatedAt)
		},
		get roleTags() {
			return <Space>{self.roles.map(role => USER_ROLES[role].tag)}</Space>
		},
		get _roles() {
			return self.roles.map(role => USER_ROLES[role] && USER_ROLES[role].label).join(", ")
		}
	}))
	.actions((self) => ({
		edit() {
			getParent(self, 2).setRecordOnEdition(self);
		},
		
		setRoles(roles) {
			self.roles = roles
			getParent(self, 3).notifyUIChanges()
		},
		/**
		 *
		 * @param roleToChecks string or array of string
		 * @returns {boolean}
		 */
		hasRoles(roleToChecks) {
			let roles = self.roles
			
			let found = false
			if (!Array.isArray(roleToChecks)) {
				roleToChecks = [roleToChecks]
			}
			found = roles.filter(element => roleToChecks.includes(element)).length > 0;
			return found
			
		},
		
		/**
		 *
		 * @param EditedRecord
		 */
		update(EditedRecord) {
			//applySnapshot(self, Object.assign(self, EditedRecord));
			
			const Users = values(getParent(self, 2).Users)
			const Found = (Users.find(User => User.email === EditedRecord.email))
			if (Found) {
				return true
			}
			applySnapshot(self, Object.assign(self, EditedRecord));
			
		},
		
		/**
		 *
		 * @param callback
		 */
		save(callback) {
			
			let url = CURRENT_MICRO_SERVICE_URL
			let service = MicroService.create
			let data = {Record: self}
			//
			console.log(self._isNew)
			if (!self._isNew) {
				url = url + "/" + self._id
				service = MicroService.update
			}
			//
			service(url, data, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					let Record = Response.Record
					getParent(self, 2).addToStore(Record)
					//console.log(Record)
					callback(succeeded, Record)
					return false
				}
				callback(succeeded, {})
			})
			//
		},
		/**
		 *
		 */
		
		/**
		 *
		 * @param callback
		 */
		destroy(callback) {
			MicroService.delete(CURRENT_MICRO_SERVICE_URL + "/" + self._id, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					getParent(self, 2).removeFromStore(self._id)
					
				}
				callback(succeeded)
				
			})
			
		}
	}))

;
/**
 * Store
 */
export const UserStore = types
	.model({
		Users: types.map(UserModel),
		SelectedRecord: types.maybeNull(types.reference(UserModel)),
		AuthenticatedUser: types.maybeNull(types.reference(UserModel)),
		
	}).actions((self) => ({
		/**
		 *
		 * @param _newRecord si creation du user de l'apprenant
		 */
		create(_newRecord) {
			const newRecord = UserModel.create(_newRecord)
			self.Users.set(newRecord._id, newRecord)
			return newRecord
		},
		setRecordOnEdition(SelectedRecord) {
			self.removeSetOnEditionForAll()
			SelectedRecord._isOnEdition = true
			self.SelectedRecord = SelectedRecord
			getParent(self, 1).notifyUIChanges()
		},
		
		removeSetOnEditionForAll() {
			getParent(self, 1).notifyUIChanges()
			values(self.Users).forEach((Record) => {
				Record._isOnEdition = false
			})
		},
		
		/**
		 *
		 * @param context
		 * @returns {{isModifier: *,isReader: (*|boolean)}|null, BanIcon: JSX.Element, }
		 */
		getAllowedContext(context) {
			
			const contextConfig = CONTEXT_ROLES[context];
			
			if (!contextConfig) {
				return null;
			}
			/**
			 * storedRoles si test sinon
			 */
				// pour les tests
			const storedRoles = sessionStorage[SESSION_STORAGE_ROLE_SWITCHER_KEY] || null
			let currentRoles = []
			if (storedRoles) {
				currentRoles = JSON.parse(storedRoles)
			} else {
				currentRoles = self.AuthenticatedUser && self.AuthenticatedUser.roles.toJSON() || []
			}
			/**
			 *
			 */
			
			const {readers, modifiers} = contextConfig;
			const isModifier = currentRoles.some(role => modifiers.includes(role))
			//s'il est modifier ,il est aussi reader
			const isReader = (isModifier === false ? currentRoles.some(role => readers.includes(role)) : true)
			const Profil = {
				isModifier: isModifier,
				isReader: isReader,
			}
			if (isModifier === false && isReader === false) {
				return null
			}
			return Profil;
		},
		getContextsByRole(role) {
			const contextsByRole = {};
			
			Object.keys(CONTEXT_ROLES).forEach(context => {
				const roles = CONTEXT_ROLES[context];
				roles.readers.forEach(role => {
					if (!contextsByRole[role]) {
						contextsByRole[role] = {readers: [], modifiers: []};
					}
					contextsByRole[role].readers.push(context);
				});
				
				roles.modifiers.forEach(role => {
					if (!contextsByRole[role]) {
						contextsByRole[role] = {readers: [], modifiers: []};
					}
					contextsByRole[role].modifiers.push(context);
				});
			});
			
			return contextsByRole[role];
		},
		setAuthenticatedUser(UserId) {
			const id = sessionStorage[SESSION_STORAGE_PROFIL_SWITCHER_KEY]
			if (id) {
				UserId = id
			}
			self.AuthenticatedUser = UserId
		},
		
		/**
		 *
		 * @param Setting
		 */
		setUserSettings(Setting, key) {
			const _UserSettings = localStorage[key]
			let Settings = (_UserSettings ? JSON.parse(_UserSettings) : {})
			
			let UserSettings = Object.assign(Settings, Setting)
			localStorage[key] = JSON.stringify(UserSettings)
		},
		getUserSettings(key) {
			const _UserSettings = localStorage[key]
			let Settings = (_UserSettings ? JSON.parse(_UserSettings) : {})
			return Settings
		},
		/*
		addUserSetting(key, value) {
			//
			localStorage[key] = JSON.stringify(value)
			//
			let Settings = {}
			for (let i = 0; i < localStorage.length; i++) {
				const key = localStorage.key(i)
				Settings[key] = localStorage.getItem(key);
			}
			const _id = self.AuthenticatedUser._id
			const data = {
				_id : _id,
				Settings : Settings
			}
			MicroService.update(MICRO_SERVICE_URLS.USER + "/" + _id, {Record : data}, (Response) => {
			
			})
		},
		
		 */
		/**
		 *
		 * @param SelectedRecord
		 */
		/**
		 *
		 * @param Record
		 */
		addToStore(Record) {
			self.Users.set(Record._id, Record)
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 * @param _id
		 */
		removeFromStore(_id) {
			self.Users.delete(_id)
			getParent(self, 1).notifyUIChanges()
		},
		
	}))
	.views((self) => ({
		
		/**
		 *
		 * @returns {any}
		 */
		
		getAllForProfilSwitcher() {
			let Records = values(self.Users)
			let query = "SELECT * FROM ?   ORDER BY fullname"//Responsable->nom
			const Rows = alasql(query, [Records])
			return Rows.map(Row => self.Users.get(Row._id))
		},
		
		/**
		 *
		 */
		findById(userId) {
			return values(self.Users).find(User => User._id === userId)
		},
		/**
		 *
		 */
		getAllByFilters(Filters) {
			let Records = values(self.Users)
			
			/**
			 *
			 */
			const FavoriteStore = getParent(self, 1).FavoriteStore;
			const favoriteIds = FavoriteStore.getAll().map(Favorite => Favorite.belongTo);
			/**
			 *
			 */
			const wheres = []
			for (let key in Filters) {
				const Filter = Filters[key];
				let value = Filter.value;
				if (value === null || value === "") {
					continue;
				}
				switch (Filter.name) {
					//
					default:
						break
					case FILTER_BY_WORDS:
						Filter.value = Filter.value.replace(/[^a-zA-Z0-9À-ÖØ-öø-ÿ\s]+/g, "")
						const fields = [
							"_id",
							"normalize(fullname)",
							"normalize(nom)",
							"normalize(prenom)"
						]
						const words = Filter.value.split(" ")
						let _wheres = []
						words.forEach(word => {
							_wheres = _wheres.concat(fields.map(field => field + " LIKE '%" + word + "%'"))
						})
						
						wheres.push("(" + _wheres.join(" OR ") + ")")
						break
					//
					case FILTER_BY_FAVORITES:
						wheres.push('_id IN (\'' + favoriteIds.join('\',\'') + '\')');
						break;
				}
			}
			
			let query = "SELECT _id FROM ? "
			//
			if (wheres.length > 0) {
				query += "  WHERE " + wheres.join("\n AND \n")
			}
			
			/**
			 * order
			
			let field = Sorter.field
			let order = Sorter.order || ORDER_DESCEND
			let collate = "COLLATE NOCASE"
			//
			switch (Sorter.field) {
				default:
					field = Sorter.field || "createdAt"
					collate = ""
					break
				case "Intitule":
					field = "Intitule->libelle"
					break
				case "ProduitFamille":
					field = "ProduitFamille->libelle"
					break
				
			}
			 */
			query += " ORDER BY  lastUse DESC";
			const Rows = alasql(query, [Records]);
			/**
			 *  on récupère la collection de records associés
			 *  car alasql détruit les objects et lesrenvoie en pure json or mob state tree (mst) a besoin d'object avec ses classes
			 *
			 */
			return Rows.map(Row => self.Users.get(Row._id))
		},
		
	}))

