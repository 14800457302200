/**
 *
 */
import {UndoOutlined} from "@ant-design/icons";
import {Button, Layout, Space, Spin, Tag, Tooltip} from "antd";
import {Content} from "antd/es/layout/layout";
import {inject, observer} from "mobx-react";
import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import "../../assets/css/app.css";
import {LOGO_AGROSUP} from "../../config/Constantes.urls";
import appVersion from "./../../appVersion.json";
import {allRoutes, NavigationSider} from "./NavigationSider";
import {ThemeSelector} from "./ThemeSelector";
/*
document.addRdvListener('click', function (event) {
	console.log("mettre en place use navigator.sendBeacon");
}, false);


;

*/
/**
 *
 */
export const App = inject("store")(observer(props => {
	
	const {store} = props
	const {NavigationStore, UserStore} = store
	//
	const AuthenticatedUser = UserStore.AuthenticatedUser
	
	//
	const ButtonUpdate = () => {
		const version = NavigationStore.version
		if (version && version !== appVersion.version) {
			return <Button onClick={(e) => window.location = ""} danger size="small" icon={<UndoOutlined/>}>Mise à jour {version}</Button>
		}
	}
	//
	const Footer = (props) => {
		return <div>
			<div style={{flex: "1", textAlign: "right", margin: "20px 0px 0px 0px"}}>
				<Space>
					{
						/*
						<RefreshDataButton/>
					<RefreshFromApiFormationButton/>
					*/
					}
					
					<ButtonUpdate/>
					{
						AuthenticatedUser &&
						<Tag><b>{AuthenticatedUser.fullname}</b><span> ({AuthenticatedUser._roles})</span></Tag>
					}
					<Tooltip title={"Mise à jour le " + appVersion.date} placement="topLeft">
						<Tag>{"v" + appVersion.version}</Tag>
					</Tooltip>
					<ThemeSelector/>
					<Tag>Dired</Tag>
					<img width="60" alt="logo" src={LOGO_AGROSUP}/>
				</Space>
			</div>
		</div>
	}
	//
	return (
		<Spin
			spinning={NavigationStore.isLoading}
			size="large"
		>
			<BrowserRouter _t={store._t}>
				<Layout>
					{/* */}
					<NavigationSider/>
					{/* */}
					<Layout>
						{/* */}
						<Content id="content" className={"shadow-1"}>
							<Routes>
								{
									allRoutes.map(_Route => {
										// allowed =  true si pas contexte
										let allowed = true
										if (_Route.allowedContext) {
											allowed = UserStore.getAllowedContext(_Route.allowedContext) !== null
										}
										return allowed && <Route
											exact={true}
											key={_Route.key}
											path={_Route.path}
											element={<_Route.Component/>}
										/>
									})
								}
							
							</Routes>
							{/* */}
							<Footer/>
							{/* */}
						</Content>
					</Layout>
				</Layout>
			</BrowserRouter>
			{/* */}
		</Spin>
	);
	
}))
