import {Popover, Tag, Tooltip} from "antd";
import days from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import {values} from "mobx";
import {applySnapshot, getParent, types} from "mobx-state-tree";
import React from "react";
import {IconInfo} from "../components/IconInfo";
import {MICRO_SERVICE_URLS} from "../config/Constantes.urls";
import MicroService from "../helpers/MicroService";
import {key, toLocalDate} from "../helpers/Utils";
import {CopyReference} from "../pages/common/components/CopyReference";
import {ReferentielPieceJointeModel} from "./ReferentielPieceJointeStore";
import alasql from "alasql";
import {USER_ROLE_APPRENANT} from "../config/Constantes.roles";

/**
 *
 */
days.extend(isBetween)
/**
 *
 */
const CURRENT_MICRO_SERVICE_URL = MICRO_SERVICE_URLS.FORMATION
/**
 * model
 */
export const FormationModel = types
	.model({
		_id: types.identifier,
		ReferentielPieceJointes: types.array(types.reference(ReferentielPieceJointeModel)),
		OffreFormation: types.frozen({
			ReferentielIntitule: {},
			ReferentielFamille: {},
			statut: "",// PUBLIE | ARCHIVE
			anneeCreation: 0,
			anneeExamen: 0,
			sessionDates: [],
			diplomante: false,
			Documents: [],
			ComposantAssocies: [],
			ResponsableFormations: [],
			Recruteurs: [],
			createdAt: ""
		}),
		//
		Createur: types.frozen({_id: "", fullname: ""}),
		Modificateur: types.frozen({_id: "", fullname: ""}),
		createdAt: types.maybeNull(types.string, ""),
		updatedAt: types.maybeNull(types.string, ""),
		_isOnEdition: types.optional(types.boolean, false),
		_isNew: types.optional(types.boolean, false),
		_index: types.optional(types.number, 0),
	})
	.views((self) => ({
		get offreFormationCreatedAt() {
			return toLocalDate(self.OffreFormation.createdAt)
		},
		get anneeCreation() {
			return self.OffreFormation.anneeCreation
		},
		get anneeCreationTag() {
			return <Tooltip title={"Année création"}><Tag>{self.OffreFormation.anneeCreation}</Tag></Tooltip>
			//return <Tag>Année création <b>{self.OffreFormation.anneeCreation}</b></Tag>
		},
		get anneeExamen() {
			return self.OffreFormation.anneeExamen
		},
		get sessionDates() {
			let values = self.OffreFormation.sessionDates || []
			if (values.length > 0) {
				values = [
					days(values[0]).format("DD/MM/YYYY"),
					days(values[1]).format("DD/MM/YYYY")
				]
				return values.join(" - ")
			}
			return null
			
		},
		get statut() {
			if (self.OffreFormation.statut !== "PUBLIE") {
				return <Tag color={"red"}>Non disponible</Tag>
			}
			
		},
		get isAvailable() {
			if (self.OffreFormation.statut !== "PUBLIE") {
				return false
			}
			//
			let values = self.OffreFormation.sessionDates || []
			if (values.length > 0) {
				// date début et fin incluses
				return days().isBetween(days(values[0]), days(values[1]).add(1, 'day'))
			}
			//
			return false
		},
		
		get libelle() {
			const ReferentielIntitule = self.OffreFormation.ReferentielIntitule
			return ReferentielIntitule && ReferentielIntitule.libelle
		},
		get libelleCourt() {
			const ReferentielIntitule = self.OffreFormation.ReferentielIntitule
			return ReferentielIntitule && ReferentielIntitule.libelleCourt
		},
		get famille() {
			const ReferentielFamille = self.OffreFormation.ReferentielFamille
			return ReferentielFamille && ReferentielFamille.libelle
		},
		get ReferentielIntitule() {
			return self.OffreFormation.ReferentielIntitule
		},
		get diplomante() {
			return self.OffreFormation.diplomante
			
		},
		get Documents() {
			return self.OffreFormation.Documents
		},
		get ComposantAssocies() {
			return self.OffreFormation.ComposantAssocies
		},
		get copyReferenceView() {
			const tooltip = "Copier l'identifiant \"" + self.OffreFormation._id + "\" de la formation pour une recherche sur la plateforme de l'offre de formations."
			return <CopyReference Record={self.OffreFormation} tooltip={tooltip}/>
		},
		get ResponsableFormationView() {
			const Records = self.OffreFormation.ResponsableFormations || []
			const popoverContent = Records.map(Record => <div key={key()}>{Record.nom + " " + Record.prenom}</div>)
			const total = Records.length
			return <Popover
				key={key()}
				title={"Les Responsable de formations"}
				content={popoverContent}
				placement={"left"}
			>
				<IconInfo name="Group"/>
				&nbsp;<sup style={{fontSize: 9, top: -12}}>{total}</sup>
			</Popover>
		},
		get RecruteurView() {
			const Records = self.OffreFormation.Recruteurs || []
			const popoverContent = Records.map(Record => <div key={key()}>{Record.nom + " " + Record.prenom}</div>)
			const total = Records.length
			return <Popover
				key={key()}
				title={"Les recruteurs"}
				content={popoverContent}
				placement={"left"}
			>
				<IconInfo name="Group"/>
				&nbsp;<sup style={{fontSize: 9, top: -12}}>{total}</sup>
			</Popover>
		},
		
	}))
	
	.actions((self) => ({
		
		/**
		 *
		 */
		edit() {
			getParent(self, 2).setRecordOnEdition(self);
		},
		/**
		 *
		 * @param EditedRecord
		 */
		update(EditedRecord) {
			applySnapshot(self, Object.assign(self, EditedRecord));
			
		},
		/**
		 *
		 * @param callback
		 */
		save(callback) {
			
			MicroService.update(CURRENT_MICRO_SERVICE_URL + "/" + self._id, {Record: self}, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					getParent(self, 2).addToStore(Response.Record)
				}
				callback(succeeded)
			})
		}
		
	}));
/**
 * store
 */
export const FormationStore = types
	.model({
		Formations: types.map(FormationModel),
		//SelectedRecord : types.maybeNull(types.reference(FormationModel, {})),
	}).actions((self) => ({
		
		/**
		 *
		 * @param Record
		 */
		addToStore(Record) {
			self.Formations.set(Record._id, Record)
			getParent(self, 1).notifyUIChanges()
		},
		removeFromStore(_id) {
			self.Formations.delete(_id)
		},
		
	}))
	
	.views((self) => ({
		/**
		 *
		 */
		getAll() {
			
			let Records = values(self.Formations);
			let query = 'SELECT _id FROM ?  ORDER BY isAvailable = true DESC, OffreFormation->anneeExamen, OffreFormation->intituleCourt';
			const Rows = alasql(query, [Records]);
			return Rows.map(Row => self.Formations.get(Row._id));
		},
		/**
		 *
		 */
		getById(_id) {
			return values(self.Formations).find(item => item._id === _id)
		},
		getAllByUser(User) {
			let AuthorizedFormations = []
			const UserStore = getParent(self, 1).UserStore;
			const AuthenticatedUser = UserStore.findById(User._id)
			if (!AuthenticatedUser) {
				return AuthorizedFormations
			}
			const userId = AuthenticatedUser._id
			const Formations = self.getAll()
			if (AuthenticatedUser.hasRoles(USER_ROLE_APPRENANT)) {
				//
				const InscriptionStore = getParent(self, 1).InscriptionStore;
				const Inscriptions = InscriptionStore.Inscriptions
				const formationIds = []
				Inscriptions.forEach(Inscription => {
					if (userId === Inscription.Apprenant.User._id) {
						formationIds.push(Inscription.FormationSouhaitee.FormationChoisieAvantEntretien)
					}
				})
				AuthorizedFormations = Formations.filter(Formation => formationIds.includes(Formation._id))
				//
			} else {
				//
				AuthorizedFormations = Formations.filter(Formation => {
					const recruteurIds = Formation.OffreFormation.Recruteurs.map(Recruteur => Recruteur._id)
					const responsableFormationIds = Formation.OffreFormation.ResponsableFormations.map(ResponsableFormation => ResponsableFormation._id)
					let arr = []
					arr = arr.concat(recruteurIds, responsableFormationIds)
					return arr.includes(userId)
					
				})
				//
			}
			return AuthorizedFormations
		},
		
	}));

