/**
 *
 */
import {EditOutlined} from "@ant-design/icons";
import {Alert, DatePicker, Form, Input, Modal, Popover, Select, Table, Tooltip} from 'antd';
import days from "dayjs";
import {inject, observer} from "mobx-react";
import React, {useState} from "react";
/**
 *
 */
import {Button, ButtonIcon, ButtonSmall} from "../../../components/antExtendedComponent/Button";
import {SpaceHorizontal, SpaceVertical} from "../../../components/antExtendedComponent/Space";
import {IconInfo} from "../../../components/IconInfo";
import {Notify} from "../../../components/Notify";
import {
	APPRENANT_STATUT_PREINSCRIT,
	APPRENANT_STATUT_PROSPECT,
	INSCRIPTION_PROJET_STATUT_ACCEPTE,
	INSCRIPTION_PROJET_STATUT_EN_ATTENTE,
	INSCRIPTION_PROJET_STATUT_REFUSE,
	INSCRIPTION_PROJET_STATUTS,
	INSCRIPTION_STATUT_VALIDATION_EN_ATTENTE,
	INSCRIPTION_STATUT_VALIDATION_PEDAGOGIQUE_ACCEPTE,
	INSCRIPTION_STATUT_VALIDATION_PEDAGOGIQUE_EN_ATTENTE,
	INSCRIPTION_STATUT_VALIDATION_PEDAGOGIQUE_REFUSE,
	MESSAGE_3_1,
	MESSAGE_3_2,
	MESSAGE_3_3,
	MESSAGE_3_5,
	MESSAGE_3_6,
	VALIDATION_PAIEMENT_STATUTS
} from "../../../config/Constantes.logic";
import {COLOR_GRAY, COLOR_PRIMARY, COLUMN_DATE_TIME, COLUMN_ICON_WIDTH, FORM_DEFAULT_RULE_REQUIRED} from "../../../config/Constantes.ui";
import {ENV, ENV_PROD, MICRO_SERVICE_URLS} from "../../../config/Constantes.urls";
import microService from "../../../helpers/MicroService";
import {key, ObjectId, toLocalDateTime} from "../../../helpers/Utils";
import {DestroyIcon} from "../../common/components/DestroyIcon";
import {SectionTitle} from "../../common/components/SectionTitle";
import {USER_ROLE_RECRUTEUR, USER_ROLE_RESPONSABLE_FORMATION} from "../../../config/Constantes.roles";

;

/**
 *
 */

/**
 *
 */
export const ProjetProfessionnelValidation = inject("store")(observer(props => {
	/**
	 *
	 */
	const {store} = props
	const [isCommentRequired, setCommentRequired] = useState(false);
	const [isFormVisible, setFormVisible] = useState(false);
	/**
	 *
	 */
	const
		{
			UserStore,
			InscriptionStore,
			InscriptionProjetProfessionnelStore,
		} = store
	
	/**
	 *
	 */
	
	const [form] = Form.useForm();
	//
	const AuthenticatedUser = UserStore.AuthenticatedUser
	const ProjetProfessionnelSelected = InscriptionProjetProfessionnelStore.SelectedRecord || {}
	const ProjetProfessionnelEdited = {...ProjetProfessionnelSelected}
	const InscriptionSelected = InscriptionStore.SelectedRecord
	let ValidationStatuts = ProjetProfessionnelEdited.ValidationStatuts.toJSON()
	/**
	 *
	 */
	const onDelete = (_id) => {
		ValidationStatuts = ValidationStatuts.filter(ValidationStatut => ValidationStatut._id !== _id)
		ProjetProfessionnelSelected.update({statut: "", ValidationStatuts: ValidationStatuts})
		//
		Notify()
		ProjetProfessionnelSelected.save(succeeded => {
			Notify(succeeded)
			
		})
		/**
		 *
		 */
		//@todo
		InscriptionSelected.update({
			statutInscription: INSCRIPTION_STATUT_VALIDATION_EN_ATTENTE,
			statutApprenant: APPRENANT_STATUT_PROSPECT
		})
		InscriptionSelected.save(succeeded => {
			//Notify(succeeded)
			
		})
		/**
		 *
		 */
		//
		
	}
	/**
	 *
	 * @param values
	 */
	
	const onSaveAvisRF = (values) => {
		
		form.validateFields()
			.then((values) => {
				values._id = ObjectId()
				values.Responsable = {...AuthenticatedUser}
				values.date = days(values.date).format("YYYY-MM-DD HH:mm:ss")
				//on enlève l'objet s'il existe du tableau ValidationStatuts
				ValidationStatuts = ValidationStatuts.filter(ValidationStatut => ValidationStatut.Responsable._id !== AuthenticatedUser._id)
				//pour le remplacer après
				ValidationStatuts.push(values)
				//
				ProjetProfessionnelSelected.update({
					statut: values.statut,
					ValidationStatuts: ValidationStatuts
				})
				
				//
				Notify()
				ProjetProfessionnelSelected.save(succeeded => {
					Notify(succeeded)
					//
					if (succeeded === true) {
						//
						setFormVisible(false)
						form.resetFields()
						//éléments à modifier pour l'Inscription
						let InscriptionToUpdate = {}
						//
						let MailOption = {}
						let MailOptions = []
						switch (values.statut) {
							default:
								break
							//
							case INSCRIPTION_PROJET_STATUT_EN_ATTENTE:
								InscriptionToUpdate = {
									statutInscription: INSCRIPTION_STATUT_VALIDATION_PEDAGOGIQUE_EN_ATTENTE
								}
								//
								MailOption = {}
								MailOption.action = MESSAGE_3_5
								MailOption.To = [{
									Email: InscriptionSelected.Apprenant.emailPrincipal,
									Name: InscriptionSelected.Apprenant.fullname
								}]
								MailOptions = [MailOption]
								break
							//
							case INSCRIPTION_PROJET_STATUT_ACCEPTE:
								//on ajoute la formation choisie après entretien dans le champs "FormationSuivie" de l'inscption
								let FormationSuivie = {...InscriptionSelected.FormationSuivie}
								FormationSuivie.Formation = ProjetProfessionnelSelected.FormationChoisieApresEntretien._id
								InscriptionToUpdate = {
									statutInscription: INSCRIPTION_STATUT_VALIDATION_PEDAGOGIQUE_ACCEPTE,
									statutApprenant: APPRENANT_STATUT_PREINSCRIT,
									Paiement: {statut: VALIDATION_PAIEMENT_STATUTS.STATUT_EN_ATTENTE.value},
									FormationSuivie: FormationSuivie
								}
								//
								MailOption = {}
								MailOption.action = MESSAGE_3_3
								MailOption.To = [{
									Email: InscriptionSelected.Apprenant.emailPrincipal,
									Name: InscriptionSelected.Apprenant.fullname
								}]
								MailOptions.push(MailOption)
								//
								
								if (InscriptionSelected.FormationSouhaitee && InscriptionSelected.FormationSouhaitee.souhaitFinancementFormation === true) {
									/**
									 * mail à l'apprenant
									 */
									MailOption = {}
									MailOption.action = MESSAGE_3_1
									MailOption.To = [{
										Email: InscriptionSelected.Apprenant.emailPrincipal,
										Name: InscriptionSelected.Apprenant.fullname
									}]
									MailOptions.push(MailOption)
									/**
									 * mail au pôle formation
									 */
									MailOption = {}
									MailOption.action = MESSAGE_3_2
									MailOption.To = [{
										Email: InscriptionSelected.Apprenant.emailPrincipal,
										Name: InscriptionSelected.Apprenant.fullname
									}]
									MailOptions.push(MailOption)
									
								}
								break
							case INSCRIPTION_PROJET_STATUT_REFUSE:
								InscriptionToUpdate = {
									statutInscription: INSCRIPTION_STATUT_VALIDATION_PEDAGOGIQUE_REFUSE
								}
								//
								MailOption = {}
								MailOption.action = MESSAGE_3_6
								MailOption.To = [{
									Email: InscriptionSelected.Apprenant.emailPrincipal,
									Name: InscriptionSelected.Apprenant.fullname
								}]
								MailOptions = [MailOption]
								break
						}
						//
						InscriptionSelected.update(InscriptionToUpdate)
						//
						InscriptionSelected.save(succeeded => {
							if (succeeded === true) {
								MailOptions.forEach(MailOption => {
									microService.post(MICRO_SERVICE_URLS.SEND_MAIL, MailOption, (Response => {
										console.log(Response)
									}))
								})
								
							}
							
						})
						
					}
				})
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	
	/**
	 *  - AuthenticatedUser doit avoir le rôle Responsable
	 *  - l'Inscription  doit  avoir le  statut propect
	 *  - le Responsable ne doit pas avoir déjà validé
	 */
	const canUserAddNewValidation = (() => {
		if (ProjetProfessionnelSelected.readOnly) {
			return false
		}
		
		// l'Inscription  ne doit  avoir que  le  statut propect
		if (InscriptionSelected && InscriptionSelected.statutApprenant !== APPRENANT_STATUT_PROSPECT) {
			return false
		}
		// le Responsable ne doit pas avoir déjà validé
		let ValidationStatut = ValidationStatuts.find(ValidationStatut => ValidationStatut.Responsable._id === AuthenticatedUser._id)
		if (ValidationStatut) {
			return false
		}
		// AuthenticatedUser ne doit  avoir que  le rôle Responsable
		const authorizedRoles = [USER_ROLE_RESPONSABLE_FORMATION, USER_ROLE_RECRUTEUR]
		if (AuthenticatedUser && AuthenticatedUser.hasRoles(authorizedRoles) === false) {
			return false
		}
		return true
	})()
	/**
	 *
	
	const SelectResponsable = (props) => {
		const options = ResponsableFormations.map(User => (
			{
				value : User._id,
				label : User.fullname
			}))
		return <Select
			disabled
			{...props}
			options={options}
			defaultValue={ResponsableFormationselected && ResponsableFormationselected._id}
			onChange={(value) => {
				
				onFill(value)
				
			}}
		/>
	} */
	const SelectValidationStatuts = (props) => {
		const options = Object.values(INSCRIPTION_PROJET_STATUTS).map(Item => (
			{
				value: Item.value,
				label: Item.tag
			}))
		return <Select
			{...props}
			options={options}
			// le onchange est pris par le form validator
			onSelect={(value) => {
				setCommentRequired(value === INSCRIPTION_PROJET_STATUT_REFUSE ? true : false)
			}}
		/>
	}
	/**
	 *
	 */
	const ButtonAddNewValidation = ({Record}) => <SpaceHorizontal>
		<ButtonSmall
			disabled={!canUserAddNewValidation}
			onClick={(e) => {
				e.preventDefault()
				//setResponsable(AuthenticatedUser)
				//onFill(AuthenticatedUser._id)
				//openDrawer(drawerName)
				setFormVisible(true)
			}}
		>Ajouter mon avis</ButtonSmall>
		{
			!canUserAddNewValidation
			&& <Alert showIcon message={"L'ajout d'un avis est réservé au responsable de formation."}/>
		}
	</SpaceHorizontal>
	/**
	 *
	 */
	const onFill = (ValidationStatut) => {
		let ValidationStatutEdited = {...ValidationStatut}
		if (ValidationStatutEdited.date) {
			ValidationStatutEdited.date = days(ValidationStatutEdited.date, 'YYYY-MM-DD');//
		}
		//
		form.setFieldsValue(ValidationStatutEdited);
		
	};
	/**
	 *
	 */
	const ValidationTable = (props) => {
		const ButtonEdit = ({Record}) => <Tooltip
			title="Modifier"
			placement="right"
		>
			<ButtonIcon
				
				disabled={AuthenticatedUser.hasRoles([USER_ROLE_RESPONSABLE_FORMATION, USER_ROLE_RECRUTEUR]) === false}
				icon={<EditOutlined/>}
				onClick={(e) => {
					onFill(Record)
					setFormVisible(true)
				}}
			/>
		</Tooltip>
		//
		const Columns = [
			{
				dataIndex: 'actionEdit',
				title: '',
				width: COLUMN_ICON_WIDTH,
				className: "center",
				render: (text, Record) => {
					
					let displayButtonEdit = (AuthenticatedUser._id === Record.Responsable._id && Record.statut === INSCRIPTION_PROJET_STATUT_EN_ATTENTE)
					
					if (displayButtonEdit) {
						return <ButtonEdit key={key()} Record={Record}/>
					} else {
						return <IconInfo name={"StatusCircleBlock"}/>
					}
					
				}
			},
			{
				dataIndex: "commentaire",
				title: "",
				className: "center",
				width: COLUMN_ICON_WIDTH,
				render: (text, Record) => {
					const commentaire = Record.commentaire
					const hasCommentaire = Boolean(commentaire)
					let color = COLOR_GRAY
					let IconInfoName = "Comment"
					if (hasCommentaire) {
						color = COLOR_PRIMARY
						IconInfoName = "CommentActive"
					}
					return <Popover content={commentaire} title="Commentaire">
						<IconInfo name={IconInfoName} style={{color: color}}/>
					</Popover>
					
				}
			},
			{
				dataIndex: "date",
				title: "Date",
				width: COLUMN_DATE_TIME,
				className: "center",
				render: (text, Record) => toLocalDateTime(Record.date)
			},
			{
				dataIndex: "Responsable",
				title: "Responsable de formation",
				width: 200,
				ellipsis: true,
				render: (text, Record) => Record.Responsable.fullname
			},
			{
				dataIndex: "statut",
				title: "Statut",
				render: (text, Record) => Record.statut && INSCRIPTION_PROJET_STATUTS[Record.statut].tag
			},
			{
				dataIndex: 'actionDestroy',
				title: '',
				width: COLUMN_ICON_WIDTH,
				fixed: "right",
				className: "center",
				render: (text, Record) => ENV !== ENV_PROD
					&& <DestroyIcon key={key()} onClick={(e) => {
						onDelete(Record._id)
					}}/>
			}
		
		]
		/**
		 *
		 */
		return <SpaceVertical size={"small"} className={"shadow-2 padding"}>
			{/* */}
			<SectionTitle title={"Avis du responsable de formation"}/>
			{/* */}
			<ButtonAddNewValidation/>
			{/* */}
			<Table
				rowKey="_id"
				dataSource={ValidationStatuts}
				columns={Columns}
				size="small"
				pagination={false}
				showSorterTooltip={false}
			/>
			{/* */}
		</SpaceVertical>
		
	}
	/**
	 *
	 */
	const ButtonSave = <div key={key()} className={"center"}>
		<Button
			block
			onClick={onSaveAvisRF}
			//disabled={buttonSaveDisabledState}
		>Enregistrer</Button>
	</div>
	
	const ValidationForm = () => {
		/**
		 *
		 */
		return (
			<Form form={form} layout={"vertical"}>
				{/*<Form.Item label={'Sélectionnez un responsable Formation'}>
					<SelectResponsableFormatiosssn/>
				</Form.Item>*/}
				{/* */}
				<Form.Item name={'statut'} label={'Avis'} rules={FORM_DEFAULT_RULE_REQUIRED}>
					<SelectValidationStatuts/>
				</Form.Item>
				{/* */}
				<Form.Item name={'date'} label={'Entretien avec le RF le'} rules={FORM_DEFAULT_RULE_REQUIRED}>
					<DatePicker
						className={"dateTime"}
						placeholder={""}
						allowClear={false}
						
						format={'DD/MM/YYYY HH:mm'}
						showTime={{format: 'HH:mm'}}
						rules={FORM_DEFAULT_RULE_REQUIRED}
					/>
				</Form.Item>
				{/* */}
				<Form.Item name={'commentaire'} label={'Commentaire'} rules={[
					{
						
						required: isCommentRequired,
						message: "Champs obligatoire"
					},
				]}>
					<Input.TextArea autoSize/>
				</Form.Item>
				{/* */}
			
			
			</Form>)
		
	}
	/**
	 *
	 */
	return <>
		{/* */}
		<ValidationTable/>
		{/* */}
		<Modal
			key={"validation_entretien"}
			centered
			footer={[ButtonSave]}
			open={isFormVisible}
			title={"Validation de l'entretien"}
			closable={true}
			maskClosable={false}
			mask={false}
			onCancel={() => {
				setFormVisible(false)
			}}
		>
			{/* */}
			<ValidationForm/>
			{/* */}
		</Modal>
	
	</>
	
}))

