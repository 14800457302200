//
import {CalendarOutlined, CommentOutlined, CopyrightOutlined, DoubleLeftOutlined, DoubleRightOutlined, PartitionOutlined, SafetyOutlined, SolutionOutlined} from "@ant-design/icons";
import {Alert, Drawer, Space, Switch, Tabs, Tag, Tooltip} from 'antd';

import {inject, observer} from "mobx-react";
import React, {useState} from "react";
import {ButtonIcon, ButtonSmall} from "../../components/antExtendedComponent/Button";
import {SpaceHorizontal, SpaceVertical} from "../../components/antExtendedComponent/Space";
/**
 *
 */
import {IconPageHeaderBack} from "../../components/IconPageHeaderBack";
import {Notify} from "../../components/Notify";
import {PLANNING_VIEW_CONTEXT_APPRENANT} from "../../config/Constantes.ui";
import {ENV, ENV_DEV, ENV_PROD} from "../../config/Constantes.urls";
import {key} from "../../helpers/Utils";
import {DRAWERS_NAMES} from "../../stores/NavigationStore";
import {Commentaires} from "../commentaire/Commentaires";
import {CopyReference} from "../common/components/CopyReference";
import {CreateurModificateurIcon} from "../common/components/CreateurModificateurIcon";
import {closeDrawer} from "../common/components/drawerManager";
/**
 *
 */
import {FavoriteButton} from "../common/components/FavoriteButton";
import {RefreshDataButton} from "../refreshButtons/RefreshDataButton";
import {Inscription} from "../inscription/Inscription";
import {Planning} from "../rdv/Planning";
import {Communication} from "./forms/Communication";
import {CompteExternes} from "./forms/compteExternes/CompteExternes";
import {ExperienceProfessionnelles} from "./forms/ExperienceProfessionnelles";
import {InfosGenerales} from "./forms/InfosGenerales";
import {SituationProfessionnelle} from "./forms/SituationProfessionnelle";
import {RefreshFromApiFormationButton} from "../refreshButtons/RefreshFromApiFormationButton";
import {USER_ROLE_AGENT} from "../../config/Constantes.roles";
import {
	CONTEXT_APPRENANT_COMMUNICATION,
	CONTEXT_APPRENANT_COMPTES_EXTERNES,
	CONTEXT_APPRENANT_DISCUSSION,
	CONTEXT_APPRENANT_EXPERIENCE_PROFESSIONNELLE,
	CONTEXT_APPRENANT_INFORMATIONS_PRINCIPALES,
	CONTEXT_APPRENANT_INSCRIPTION_DETAILS,
	CONTEXT_APPRENANT_RDV,
	CONTEXT_INSCRIPTION_CREER_BUTTON
} from "../../config/Constantes.contexts";

/**
 *
 */
export const TAB_KEY_INFORMATIONS_PRINCIPALES = "TAB_KEY_INFORMATIONS_PRINCIPALES"
export const TAB_KEY_APPRENANT_INSCRIPTIONS = "TAB_KEY_APPRENANT_INSCRIPTIONS"
export const TAB_KEY_EXPERIENCE_PROFESSIONNELLE = "TAB_KEY_EXPERIENCE_PROFESSIONNELLE"
export const TAB_KEY_RDV = "TAB_KEY_RDV"
export const TAB_KEY_COMMUNICATION = "TAB_KEY_COMMUNICATION"
export const TAB_KEY_SUPPRESSION = "TAB_KEY_SUPPRESSION"
export const TAB_KEY_DISCUSSION = "TAB_KEY_DISCUSSION"
export const TAB_KEY_COMPTES_EXTERNES = "TAB_KEY_COMPTES_EXTERNES"
/**
 *
 */
const apprenantTabsCollapsedStorageKey = "apprenantTabsCollapsed"
/**
 *
 */
export const Apprenant = inject("store")(observer(props => {
	
	//
	const drawerName = DRAWERS_NAMES.APPRENANT_FORM_DRAWER
	//
	const {store} = props
	const {NavigationStore, ApprenantStore, InscriptionStore, UserStore} = store
	//
	const _tabsCollapsed = JSON.parse(localStorage[apprenantTabsCollapsedStorageKey] || false)
	const [tabsCollapsed, setTabsCollapsed] = useState(_tabsCollapsed)
	//
	const AuthenticatedUser = UserStore.AuthenticatedUser
	const Apprenant = ApprenantStore.SelectedRecord || {}
	const Inscriptions = Apprenant.Inscriptions || []
	//const InscriptionSelected = InscriptionStore.SelectedRecord
	/**
	 *
	 */
	let text = ""
	let AlertMessageRepresentantLegalManquant = ""
	if (Apprenant.isMineur && Apprenant.RepresentantLegaux.length === 0) {
		text = "Attention, l'apprenant étant mineur, vous devez saisir au moins un représentant légal."
		AlertMessageRepresentantLegalManquant = <Alert showIcon type={"error"} message={text}/>
	}
	/**
	 *
	 */
	let AlertMessageEmailPrincipalManquant = ""
	if (!Apprenant.emailPrincipal) {
		text = "Attention, l'apprenant n'a pas d'email principal."
		AlertMessageEmailPrincipalManquant = <Alert showIcon type={"error"} message={text}/>
	}
	/*****************************************************************
	 tabs :  menu gauche
	 *****************************************************************/
	let Panes = [
		{
			allowedContext: CONTEXT_APPRENANT_INFORMATIONS_PRINCIPALES,
			key: TAB_KEY_INFORMATIONS_PRINCIPALES,
			label: "Informations principales",
			icon: <SolutionOutlined/>,
			children: <InfosGenerales/>,
		},
		{
			allowedContext: CONTEXT_APPRENANT_EXPERIENCE_PROFESSIONNELLE,
			key: TAB_KEY_EXPERIENCE_PROFESSIONNELLE,
			label: "Expérience professionnelle",
			icon: <SolutionOutlined/>,
			disabled: Apprenant._isNew,
			children: (
				<Tabs
					items={[
						{key: 1, label: "Situation professionnelle actuelle", children: <SituationProfessionnelle/>},
						{key: 2, label: "Expériences professionnelles passées", children: <ExperienceProfessionnelles/>},
					]}
				/>
			),
		},
		{
			allowedContext: CONTEXT_APPRENANT_INSCRIPTION_DETAILS,
			key: TAB_KEY_APPRENANT_INSCRIPTIONS,
			label: "Les demandes de formation",
			icon: <PartitionOutlined/>,
			disabled: Apprenant._isNew,
			children: <Inscription/>,
		},
		{
			allowedContext: CONTEXT_APPRENANT_DISCUSSION,
			key: TAB_KEY_DISCUSSION,
			label: "Discussion",
			icon: <CommentOutlined/>,
			disabled: Apprenant._isNew,
			children: <Commentaires belongTo={Apprenant._id} Apprenant={Apprenant}/>,
		},
		{
			allowedContext: CONTEXT_APPRENANT_RDV,
			key: TAB_KEY_RDV,
			label: "Prise de rdv",
			icon: <CalendarOutlined/>,
			children: <Planning Apprenant={Apprenant} context={PLANNING_VIEW_CONTEXT_APPRENANT}/>,
		},
		{
			allowedContext: CONTEXT_APPRENANT_COMMUNICATION,
			key: TAB_KEY_COMMUNICATION,
			label: "Communication (renvoi de lien etc)",
			icon: <CopyrightOutlined/>,
			disabled: Apprenant._isNew,
			children: <Communication Apprenant={Apprenant}/>,
		},
		{
			allowedContext: CONTEXT_APPRENANT_COMPTES_EXTERNES,
			key: TAB_KEY_COMPTES_EXTERNES,
			label: "Comptes externes",
			icon: <SafetyOutlined/>,
			disabled: Apprenant._isNew,
			children: <CompteExternes _t={store._t}/>,
		},
		/*
		{
			allowedContext: CONTEXT_APPRENANT_SUPPRESSION,
			key: TAB_KEY_SUPPRESSION,
			label: "Gérer les suppressions",
			icon: <SettingOutlined />,
			disabled: Apprenant._isNew,
			children: <Suppression />,
		},
		
		 */
	];
	
	//
	const TabPanes = Panes.filter(Pane => {
		if (ENV === ENV_PROD && Pane.key === TAB_KEY_SUPPRESSION) {
			return
		}
		Pane.style = {overflow: "auto", height: "80vh"}
		//fermé
		if (tabsCollapsed === true) {
			Pane.icon = <Tooltip title={Pane.label} placement={"right"}>{Pane.icon}</Tooltip>
			Pane.label = ""
		}
		
		const Profil = UserStore.getAllowedContext(Pane.allowedContext)
		if (Profil === null) {
			return false
		}
		
		return Pane//AuthenticatedUser && AuthenticatedUser.hasRoles(Pane.authorizedRoles)
		
	})
	/**
	 *
	 */
	const CollapseIcon = () => <Tooltip title={"Menu large / menu réduit"} placement={"right"}>
		<ButtonIcon
			ghost={false}
			icon={(tabsCollapsed ? <DoubleRightOutlined/> : <DoubleLeftOutlined/>)}
			onClick={() => {
				const _tabsCollapsed = !tabsCollapsed
				setTabsCollapsed(_tabsCollapsed)
				localStorage[apprenantTabsCollapsedStorageKey] = _tabsCollapsed
				
			}}
		/>
	</Tooltip>
	/**
	 *
	 */
	const tabs = <Tabs
		activeKey={ApprenantStore.apprenantPageTabsActiveKey || TAB_KEY_INFORMATIONS_PRINCIPALES}
		items={TabPanes}
		type={"card"}
		tabBarExtraContent={{right: <CollapseIcon/>}}
		tabPosition={"left"}
		onChange={(value) => {
			ApprenantStore.setApprenantPageTabsActiveKey(value)
			//
			if (value === TAB_KEY_APPRENANT_INSCRIPTIONS) {
				if (Inscriptions.length === 0) {
					const NewInscription = InscriptionStore.create(Apprenant)
					InscriptionStore.setSelectedRecord(NewInscription._id)
				}
			}
			
		}}
	/>
	/**
	 *
	 */
	
	/*****************************************************************
	 PageHeader
	 *****************************************************************/
	/**
	 * pageHeaderLeft
	 */
	const moodle = Apprenant.CompteExternes && Apprenant.CompteExternes.moodle
	const pageHeaderLeft = Apprenant && <SpaceHorizontal>
		<IconPageHeaderBack onClick={() => {
			closeDrawer(drawerName)
			Apprenant.cancel()
			ApprenantStore.setApprenantPageTabsActiveKey("")
			ApprenantStore.setInscriptionPageTabsActiveKey("")
		}}/>
		{Apprenant.warninIfEdited}
		<span>{Apprenant.fullname}</span>
		<Tag>Année sélectionnée <b>{sessionStorage.anneeCreationSelected}</b></Tag>
		
		{moodle && <Tag>moodle <b>{moodle}</b></Tag>}
	</SpaceHorizontal>
	/**
	 * pageHeaderRight
	 */
	const PriseEnCompteSwitch = () =>
		AuthenticatedUser.hasRoles(USER_ROLE_AGENT) && <Switch
			checkedChildren="Pris en compte"
			unCheckedChildren="Pris en compte"
			checked={Apprenant.priseEnCompte}
			onChange={(value) => {
				//
				Apprenant.update({priseEnCompte: value})
				Notify()
				Apprenant.save(succeeded => {
					Notify(succeeded)
				})
				//
			}}
		/>
	
	/**
	 * ButtonInscriptionCreate
	 */
	const ProfilButtonInscriptionCreate = UserStore.getAllowedContext(CONTEXT_INSCRIPTION_CREER_BUTTON)
	const ButtonInscriptionCreate = () =>
		ProfilButtonInscriptionCreate
		&& ProfilButtonInscriptionCreate.isModifier
		&& Inscriptions.length > 0
		&& <ButtonSmall
			icon={<PartitionOutlined/>}
			onClick={() => {
				const NewInscription = InscriptionStore.create(Apprenant)
				InscriptionStore.setSelectedRecord(NewInscription._id)
				ApprenantStore.setApprenantPageTabsActiveKey(TAB_KEY_APPRENANT_INSCRIPTIONS)
				
			}}
		>Créer une inscription
		</ButtonSmall>
	
	/**
	 * pageHeaderRight
	 */
	let pageHeaderRight = []
	if (Apprenant._isNew === false) {
		pageHeaderRight = [
			<Space key={key()}>
				<PriseEnCompteSwitch/>
				<RefreshDataButton/>
				<RefreshFromApiFormationButton/>
				<ButtonInscriptionCreate/>
				<CopyReference Record={Apprenant}/>
				<span></span>
				<FavoriteButton Record={Apprenant} placement={"bottomRight"}/>
				<CreateurModificateurIcon Record={Apprenant} placement={"bottomRight"}/>
			</Space>
		]
	}
	/*****************************************************************
			Drawer
	 *****************************************************************/
	return <Drawer
		title={
			<>
				<div className={"flex"}>
					<div>{pageHeaderLeft}</div>
					<div>{pageHeaderRight}</div>
				</div>
			</>
		}
		open={NavigationStore.isDrawerVisible(drawerName)}
		closable={false}
		mask={false}
		width={(ENV === ENV_DEV ? "100%" : "100%")}
		style={{zIndex: 1}}
		_t={store._t}
	>
		<SpaceVertical>
			{/* */}
			{AlertMessageRepresentantLegalManquant}
			{AlertMessageEmailPrincipalManquant}
			
			{/* */}
			{tabs}
			{/* */}
		</SpaceVertical>
	</Drawer>
	
	/**
	 *
	 */
	
}))

