import {BgColorsOutlined} from "@ant-design/icons";
import {Select, Space, Tag} from "antd";
import React from "react";
import {THEMES} from "../../config/Constantes.ui";
import {key} from "../../helpers/Utils";

/**
 *
 * ActifNotActifIcon
 *
 */
export const ThemeSelector = (props) => <div title={"Changer de thème"} placement={"topRight"}>
	<Select
		title={"Changer de thème"}
		popupMatchSelectWidth={false}
		placeholder={<BgColorsOutlined/>}
		style={{width: 50}}
		onChange={(value) => {
			localStorage.themeIndex = value
			window.location = ""
		}}
	>
		{
			THEMES.map((theme, index) => <Select.Option key={key()} value={index}><Space><Tag color={theme.color}>{theme.label}</Tag></Space></Select.Option>)
		}
	</Select>
</div>
/**
 *
 *
 *
 */
