import alasql from "alasql";
import {values} from "mobx";
import {applySnapshot, getParent, types} from "mobx-state-tree";
import {MICRO_SERVICE_URLS} from "../config/Constantes.urls";
import MicroService from "../helpers/MicroService";
import {newId} from "../helpers/Utils";

/**
 *
 */
const CURRENT_MICRO_SERVICE_URL = MICRO_SERVICE_URLS.REFERENTIEL_COMMENTAIRE_TAG
/**
 * model
 */
export const ReferentielCommentaireTagModel = types
	.model({
		_id: types.identifier,
		libelle: types.optional(types.string, ""),
		createdAt: types.maybeNull(types.string, ""),
		updatedAt: types.maybeNull(types.string, ""),
		//
		Createur: types.frozen({_id: "", fullname: ""}),
		Modificateur: types.frozen({_id: "", fullname: ""}),
		//
		_isOnEdition: types.optional(types.boolean, false),
		_isNew: types.optional(types.boolean, false),
		_index: types.optional(types.number, 0),
	})
	.views((self) => ({
		/**
		 * on autorise la suppression sans règle
		 */
		get isDeletable() {
			return true
		},
	}))
	
	.actions((self) => ({
		
		/**
		 *
		 */
		edit() {
			getParent(self, 2).setRecordOnEdition(self);
		},
		/**
		 *
		 * @param EditedRecord
		 */
		update(EditedRecord) {
			applySnapshot(self, Object.assign(self, EditedRecord));
		},
		/**
		 *
		 * @param callback
		 */
		save(callback) {
			/**
			 * create
			 */
			if (self._isNew === true) {
				MicroService.create(CURRENT_MICRO_SERVICE_URL, {Record: self}, (Response) => {
					const succeeded = Response.OPERATION_SUCCEED
					if (succeeded === true) {
						let Record = Response.Record
						getParent(self, 2).addToStore(Record)
					}
					callback(succeeded)
					
				})
			}
			/**
			 * update
			 */
			else {
				MicroService.update(CURRENT_MICRO_SERVICE_URL + "/" + self._id, {Record: self}, (Response) => {
					const succeeded = Response.OPERATION_SUCCEED
					if (succeeded === true) {
						getParent(self, 2).addToStore(Response.Record)
					}
					callback(succeeded)
				})
			}
			
		},
		/**
		 *
		 */
		cancel() {
			getParent(self, 2).removeSetOnEditionForAll();
		},
		/**
		 *
		 * @param callback
		 */
		destroy(callback) {
			
			MicroService.delete(CURRENT_MICRO_SERVICE_URL + "/" + self._id, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				
				if (succeeded === true) {
					getParent(self, 2).removeFromStore(self._id)
					callback(succeeded)
				} else {
					callback(succeeded, Response.formationAssociees)
				}
				
			})
			
		}
		
	}));
/**
 * store
 */
export const ReferentielCommentaireTagStore = types
	.model({
		ReferentielCommentaireTags: types.map(ReferentielCommentaireTagModel),
		SelectedRecord: types.maybeNull(types.reference(ReferentielCommentaireTagModel, {})),
	}).actions((self) => ({
		/**
		 *
		 */
		create() {
			//
			self.removeSetOnEditionForAll()
			//
			let newRecord = ReferentielCommentaireTagModel.create({
				_id: newId(),
				libelle: "ReferentielCommentaireTag N°" + (self.ReferentielCommentaireTags.size + 1),
				//categorieAge : PIECE_JOINTE_CATEGORIE_AGE_2,
				_isOnEdition: true,
				_isNew: true,
				_index: -Date.now()
				
			})
			self.ReferentielCommentaireTags.set(newRecord._id, newRecord)
			self.SelectedRecord = newRecord._id
			return newRecord
		},
		/**
		 *
		 * @param SelectedRecord
		 */
		setRecordOnEdition(SelectedRecord) {
			self.removeSetOnEditionForAll()
			SelectedRecord._isOnEdition = true
			self.SelectedRecord = SelectedRecord
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 * @param Record
		 */
		addToStore(Record) {
			self.ReferentielCommentaireTags.set(Record._id, Record)
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 * @param _id
		 */
		removeFromStore(_id) {
			self.ReferentielCommentaireTags.delete(_id)
			self.SelectedRecord = null
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 */
		removeSetOnEditionForAll() {
			getParent(self, 1).notifyUIChanges()
			values(self.ReferentielCommentaireTags).forEach((Record) => {
				Record._isOnEdition = false
				if (Record._isNew === true) {
					self.ReferentielCommentaireTags.delete(Record._id)
				}
			})
		}
	}))
	
	.views((self) => ({
		/**
		 *
		 */
		getAll() {
			const Records = values(self.ReferentielCommentaireTags)
			const query = "SELECT * FROM ? ORDER BY libelle"
			const Rows = alasql(query, [Records]);
			return Rows.map(Row => self.ReferentielCommentaireTags.get(Row._id))
		},
		
	}));

