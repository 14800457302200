/**
 *
 */
import {Alert, Form, Input, InputNumber, Select, Tag} from 'antd';
import days from "dayjs";

import {inject, observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Button} from "../../../components/antExtendedComponent/Button";
import {Notify} from "../../../components/Notify";
import {MODALITE_PAIEMENT_CHEQUE, MODALITE_PAIEMENT_VALUES, VALIDATION_PAIEMENT_STATUTS} from "../../../config/Constantes.logic";
import {FORM_DEFAULT_RULE_REQUIRED, FORM_LAYOUT, FORM_RULE_NUMERIC} from "../../../config/Constantes.ui";
import {key} from "../../../helpers/Utils";
/**
 *
 */

/**
 *
 */
export const Paiement = inject("store")(observer(props => {
	/**
	 *
	 */
	const {isReadOnly, store} = props
	const {UserStore, InscriptionStore,} = store
	const [form] = Form.useForm();
	const FORMATION_CALCUL_POURCENTAGE = 0.3
	const [formationCoutWithPercent, setFormationCoutWithPercent] = useState(null)
	const [modalitePaiement, setModalitePaiement] = useState(null)
	
	/**
	 *
	 */
	const AuthenticatedUser = UserStore.AuthenticatedUser
	const Inscription = InscriptionStore.SelectedRecord
	const CurrentAgent = AuthenticatedUser.toJSON()
	//
	/**
	 *
	 */
	
	/**
	 * populate form
	 */
	const formationCoutCalcul = (value) => (FORMATION_CALCUL_POURCENTAGE * (value)).toFixed(2)
	//
	useEffect(() => {
		const Paiement = Inscription.Paiement
		//
		setModalitePaiement(Paiement.modalitePaiement)
		setFormationCoutWithPercent(formationCoutCalcul(Paiement.formationCout))
		//
		form.setFieldsValue(Paiement);
	})
	
	/**
	 * @param Paiement===form
	 */
	const regexMontant = /^(\d+,\d+)$/
	const onValuesChange = (changedValues, Paiement) => {
		//
		const field = Object.keys(changedValues)[0]
		let value = Object.values(changedValues)[0]
		switch (field) {
			case "surtaxeAerienne":
			case "pix":
			case "montantRestant":
				value = String(value).replace(regexMontant, "");
				break
			case "formationCout":
				value = String(value).replace(regexMontant, "");
				if (!isNaN(value)) {
					setFormationCoutWithPercent(formationCoutCalcul(value))
				}
				break
			case "modalitePaiement":
				if (value === MODALITE_PAIEMENT_CHEQUE) {
					Paiement.nombreEcheance = 0
				}
				setModalitePaiement(value)
				break
		}
		//
		Paiement[field] = value
		//
		Paiement.Agent = CurrentAgent
		Paiement.date = days().format("YYYY-MM-DD HH:mm")
		Inscription.update({Paiement: Paiement})
		//
	}
	/**
	 * @param  form values ===self de l'objet
	 * @returns {boolean}
	 */
	const onFinish = () => {
		
		form.validateFields()
			.then((values) => {
				Notify()
				const Paiement = Inscription.Paiement
				
				/*
				const CouponPaiement={
					inscription_formation_statut:Inscription._FormationSuivie.libelle,
					inscription_formation_surtaxe:Paiement.surtaxeAerienne,
					inscription_formation_travail:Paiement.suiviPoleEmploi,
					inscription_formation_pix:Paiement.pix,
				}
				
				 */
				
				Inscription.save(succeeded => {
					Notify(succeeded)
				})
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	
	/**
	 *
	 */
	let MessageView = ""
	
	let message = ""
	let agent = Inscription.Paiement.Agent && Inscription.Paiement.Agent.fullname
	const datePaiment = days(Inscription.Paiement.date).format('ddd DD/MM/YYYY à HH:mm:ss')
	if (Inscription.Paiement.statut === VALIDATION_PAIEMENT_STATUTS.STATUT_ACCEPTE.value) {
		//
		//
		message = "Paiement accepté le " + datePaiment + " par " + agent
		MessageView = <Alert showIcon type={"success"} message={message}/>
		
	}
	if (Inscription.Paiement.statut === VALIDATION_PAIEMENT_STATUTS.STATUT_REFUSE.value) {
		//
		//
		message = "Paiement refusé le " + datePaiment + " par " + agent
		MessageView = <Alert showIcon type={"error"} message={message}/>
	}
	/**
	 *
	 */
	const ButtonSave = <div className={"center"}>
		<Button block onClick={onFinish}>Enregistrer</Button>
	</div>
	
	/**
	 *
	 */
	const FORM_PROPS = {
		form: form,
		disabled: isReadOnly,
		onValuesChange: onValuesChange,
		style: {width: 600, margin: "auto"},
	}
	/**
	 *
	 */
	return (
		
		<Form  {...FORM_PROPS} {...FORM_LAYOUT}>
			{MessageView}
			<br/>
			{/*  */}
			<Form.Item name={"statut"} label={"Statut du paiement"}>
				<Select style={{width: 200}}>
					{
						Object.values(VALIDATION_PAIEMENT_STATUTS).map(item =>
							<Select.Option key={key()} value={item.value}>
								{item.tag}
							</Select.Option>
						)
					}
				</Select>
			</Form.Item>
			{/*  */}
			<Form.Item name={"modalitePaiement"} label={"Modalité de paiement"}>
				<Select style={{width: 200}}>
					{
						Object.values(MODALITE_PAIEMENT_VALUES).map(item =>
							<Select.Option key={key()} value={item.value}>
								{item.label}
							</Select.Option>
						)
					}
				</Select>
			</Form.Item>
			{/*  */}
			<Form.Item name={"nombreEcheance"} label={"Nombre d'échéances"}>
				<InputNumber/>
			</Form.Item>
			{/*  */}
			<Form.Item name={"formationCout"} label={"Coût de la formation (€)"} rules={FORM_DEFAULT_RULE_REQUIRED}>
				<InputNumber decimalSeparator={","} maxLength={10} precision={2} rules={FORM_RULE_NUMERIC} style={{width: 100}}/>
			</Form.Item>
			{/*  */}
			{
				!isNaN(Inscription.Paiement.formationCout) && <Form.Item label={"Calcul total"}>
					<Tag>{Inscription.Paiement.formationCout} € x {FORMATION_CALCUL_POURCENTAGE * 100}% = <b>{formationCoutWithPercent} €</b></Tag>
				</Form.Item>
			}
			{/*  */}
			<Form.Item name={"surtaxeAerienne"} label={"Surtaxe aérienne (€) (pour les candidats hors métropole)"}>
				<InputNumber decimalSeparator={","} maxLength={10} rules={FORM_RULE_NUMERIC} style={{width: 100}}/>
			</Form.Item>
			{/*  */}
			<Form.Item name={"suiviPoleEmploi"} label={"Suivi France Travail"}>
				<Input style={{width: 200}}/>
			</Form.Item>
			{/*  */}
			<Form.Item name={"pix"} label={"Pix (€)"}>
				<InputNumber decimalSeparator={","} maxLength={10} precision={2} rules={FORM_RULE_NUMERIC} style={{width: 100}}/>
			</Form.Item>
			{/*  */}
			<Form.Item name={"montantRestant"} label={"Montant restant (€)"}>
				<InputNumber decimalSeparator={","} maxLength={10} precision={2} rules={FORM_RULE_NUMERIC} style={{width: 100}}/>
			</Form.Item>
			
			{/*  */}
			<Form.Item name={"commentaire"} label={"Commentaire"}>
				<Input.TextArea/>
			</Form.Item>
			{/*  */}
			<Form.Item></Form.Item>
			{ButtonSave}
			{/*  */}
		
		</Form>
	
	);
}))

