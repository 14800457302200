import {ArrowLeftOutlined} from "@ant-design/icons";
import {Button, Tooltip} from "antd";
import React from "react";

/**
 *
 * CloseIcon
 *
 */
export const IconPageHeaderBack = (props) => <Tooltip title={"Quitter"} placement={"right"}>
	<Button {...props} shape="circle" type={"primary"} icon={<ArrowLeftOutlined/>}/>
</Tooltip>
/**
 *
 *
 *
 */
