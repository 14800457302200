import {inject, observer} from "mobx-react";
import React, {useState} from "react";
/**
 *
 */
import {Select} from "antd";
import {key} from "../../../../helpers/Utils";

/**
 *
 */

export const PubliPostageDocumentGenerateButton = inject("store")(observer(props => {
	//
	
	const [isLoading, setIsLoading] = useState(false)
	//
	const {InscriptionSelected, store} = props
	const {ReferentielPubliPostageTemplateStore, PubliPostageDocumentStore} = store
	
	const PubliPostageTemplates = ReferentielPubliPostageTemplateStore.getAll()
	/**
	 *
	 */
	const generate = (referentielPubliPostageTemplateId) => {
		//
		setIsLoading(true)
		const data = {
			referentielPubliPostageTemplateId: referentielPubliPostageTemplateId,
			inscriptionId: InscriptionSelected._id
		}
		PubliPostageDocumentStore.create(data, (succeeded, Response) => {
			console.log(Response)
			setIsLoading(false)
		})
		//
	}
	/**
	 *
	 */
	return (
		<>
			<Select
				allowClear
				placeholder={"Sélectionnez le modèle à générer"}
				dropdownStyle={{width: 500}}
				loading={isLoading}
				onSelect={generate}
			>
				{
					PubliPostageTemplates.map(item =>
						<Select.Option key={key()} value={item._id}>
							{item.name}
						</Select.Option>
					)
				}
			</Select>
		</>
	
	)
	
	/**
	 *
	 */
	
}))
