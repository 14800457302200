/**
 *
 */
import {Space, Tabs} from 'antd';
import {inject, observer} from "mobx-react";
import React, {useState} from "react";
import {Sessions} from "./views/Sessions";
import {ClasseVirtuelles} from "./views/ClasseVirtuelles";
import {Evaluations} from "./views/Evaluations";
import {RefreshFromApiMoodleButton} from "../refreshButtons/RefreshFromApiMoodleButton";
import {RefreshFromApiFormationButton} from "../refreshButtons/RefreshFromApiFormationButton";
import {VieApprenant} from "./VieApprenant";
import {PubliPostageDocuments} from "./views/PubliPostageDocuments";

/**
 *
 */

export const TAB_KEY_MAQUETTE = "TAB_KEY_MAQUETTE"
export const TAB_KEY_SESSION = "TAB_KEY_SESSION"
export const TAB_KEY_CLASSE_VIRTUELLE = "TAB_KEY_CLASSE_VIRTUELLE"
export const TAB_KEY_EVALUATION = "TAB_KEY_EVALUATION"
export const TAB_KEY_PUBLIPOSTAGE = "TAB_KEY_PUBLIPOSTAGE"
export const TAB_KEY_STAGE = "TAB_KEY_STAGE"
/**
 *
 */
const TABS_SELECTED_KEY_VIE_APPRENANT = "TABS_SELECTED_KEY_VIE_APPRENANT"
/**
 *
 */
export const VieApprenants = inject("store")(observer(props => {
	/**
	 *
	 */
	const {store} = props
	const {ApprenantStore} = store
	/**
	 *
	 */
		//ENV === ENV_DEV? TAB_KEY_EVALUATION : TAB_KEY_SESSION
	const [currentTab, setCurrentTab] = useState(TAB_KEY_SESSION)
	//
	const tabBarExtraContent = <Space>
		<RefreshFromApiFormationButton/>
		<RefreshFromApiMoodleButton/>
	</Space>
	//
	return (
		<>
			{/* */}
			{<VieApprenant/>}
			{/* */}
			<Tabs
				activeKey={sessionStorage[TABS_SELECTED_KEY_VIE_APPRENANT] || currentTab}
				tabBarExtraContent={{right: tabBarExtraContent}}
				onChange={(value) => {
					ApprenantStore.setVieApprenantePageTabsActiveKey(value)
					setCurrentTab(value)
					sessionStorage[TABS_SELECTED_KEY_VIE_APPRENANT] = value
				}}
				items={[
					{
						key: TAB_KEY_SESSION,
						label: "Sessions",
						children: <Sessions/>
					},
					{
						key: TAB_KEY_CLASSE_VIRTUELLE,
						label: "Classes virtuelles",
						children: <ClasseVirtuelles/>
					},
					{
						key: TAB_KEY_EVALUATION,
						label: "Évaluations",
						children: <Evaluations/>
					},
					/*
					{
						
						key: "2",
						label: "Annexes",
						children: <AnnexeView/>
					},
					
					 */
					{
						key: TAB_KEY_PUBLIPOSTAGE,
						label: "Annexes",
						children: <PubliPostageDocuments/>
					},
					
					{
						key: TAB_KEY_STAGE,
						label: "Stages",
						children: "Stages",
						disabled: true
						
					},
				]}
			/>
		</>
	
	);
}))
