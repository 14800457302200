/**
 *
 */
import {EditOutlined} from "@ant-design/icons";
import {Form, Input, Modal, Select, Table, Tag, Tooltip} from 'antd';
import {inject, observer} from "mobx-react";
import React, {useState} from "react";
import {Button, ButtonIcon} from "../../../../components/antExtendedComponent/Button";
import {IconButton} from "../../../../components/IconButton";
import {Notify} from "../../../../components/Notify";
import {CIVILITE_VALUES, PARENTE_VALUES} from "../../../../config/Constantes.logic";
import {COLUMN_ICON_WIDTH, FORM_DEFAULT_RULE_REQUIRED, FORM_RULE_EMAIL, FORM_RULE_NUMERIC} from "../../../../config/Constantes.ui";
import {formatPhoneNumber, key, newId, ucFirst} from "../../../../helpers/Utils";
import {CreateurModificateurIcon} from "../../../common/components/CreateurModificateurIcon";
import {DestroyIcon} from "../../../common/components/DestroyIcon";
import {SelectCivilite} from "../../common/SelectCivilite";
import {SelectYesNo} from "../../../../components/SelectYesNo";

/**
 *
 */
export const RepresentantsLegaux = inject("store")(observer(props => {
	/**
	 *
	 */
	const {isReadOnly, store} = props
	/**
	 *
	 */
	const
		{
			UserStore,
		} = store
	/**
	 *
	 */
	
	const AuthenticatedUser = UserStore.AuthenticatedUser
	/**
	 *
	 */
	const [form] = Form.useForm();
	const [isFormVisible, setFormVisible] = useState(false);
	const Apprenant = props.Apprenant
	let RepresentantLegaux = Apprenant.RepresentantLegaux && Apprenant.RepresentantLegaux.toJSON()
	/**
	 *
	 */
	const onRecordDelete = (Record) => {
		RepresentantLegaux = RepresentantLegaux.filter(item => item._id !== Record._id)
		Apprenant.update({RepresentantLegaux: RepresentantLegaux})
		Apprenant.save(succeeded => {
			Notify(succeeded)
		})
	};
	
	/**
	 *
	 */
	const edit = (Record) => {
		form.setFieldsValue(Record)
		setFormVisible(true)
	};
	/**
	 *
	 */
	const addNew = () => {
		const Record = {
			_id: newId(),
			_isNew: true
		}
		form.resetFields()
		form.setFieldsValue(Record)
		setFormVisible(true)
	};
	const onFormFinish = () => {
		
		form.validateFields()
			.then((RepresentantLegal) => {
				let _RepresentantLegaux = [...RepresentantLegaux]
				const OldRepresentantLegal = RepresentantLegaux.find(_RepresentantLegal => _RepresentantLegal._id === RepresentantLegal._id)
				const date = new Date().toISOString()
				const CurrentUser = AuthenticatedUser.toJSON()
				if (RepresentantLegal._isNew) {
					RepresentantLegal.createdAt = date
					RepresentantLegal.Createur = CurrentUser
				} else {
					RepresentantLegal.updatedAt = date
					RepresentantLegal.Createur = OldRepresentantLegal.Createur
					RepresentantLegal.Modificateur = CurrentUser
					//on ne l'inclut pas dans le tableau
					_RepresentantLegaux = RepresentantLegaux.filter(_RepresentantLegal => _RepresentantLegal._id !== RepresentantLegal._id)
				}
				//
				RepresentantLegal.telephonePortable = RepresentantLegal.telephonePortable && formatPhoneNumber(RepresentantLegal.telephonePortable)
				RepresentantLegal.telephoneFixe = RepresentantLegal.telephoneFixe && formatPhoneNumber(RepresentantLegal.telephoneFixe)
				RepresentantLegal.nomUsage = RepresentantLegal.nomUsage.toUpperCase()
				RepresentantLegal.nomNaissance = RepresentantLegal.nomNaissance && RepresentantLegal.nomNaissance.toUpperCase()
				RepresentantLegal.prenom = ucFirst(RepresentantLegal.prenom)
				//
				if (RepresentantLegal.principal) {
					_RepresentantLegaux = _RepresentantLegaux.map(_RepresentantLegal => {
						_RepresentantLegal = {..._RepresentantLegal}
						_RepresentantLegal.principal = false
						return _RepresentantLegal
					})
				}
				//
				if (_RepresentantLegaux.length === 0) {
					RepresentantLegal.principal = true
				}
				//
				_RepresentantLegaux.push(RepresentantLegal)
				//
				Apprenant.update({RepresentantLegaux: _RepresentantLegaux})
				Apprenant.save(succeeded => {
					Notify(succeeded)
					form.resetFields()
					setFormVisible(false)
				})
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	
	/**
	 *
	 */
	let Columns = [
		{
			dataIndex: "actionEdit",
			width: COLUMN_ICON_WIDTH,
			className: "center",
			title: () => !isReadOnly && <Tooltip title={"Créer"}>
				<Button
					shape="circle"
					type={"primary"}
					icon={<IconButton name="PageAdd"/>}
					onClick={addNew}/>
			</Tooltip>,
			render: (text, Record) => !isReadOnly && <ButtonIcon
				icon={<EditOutlined/>}
				onClick={(e) => {
					edit(Record)
				}}
			/>
		},
		{
			dataIndex: "CreateurModificateur",
			title: "",
			width: COLUMN_ICON_WIDTH,
			className: "center",
			render: (text, Record) => <CreateurModificateurIcon Record={Record}/>
		},
		{
			dataIndex: "principal",
			title: "",
			width: 80,
			render: (text, Record) => Record.principal && <Tag>principal</Tag>
		},
		{
			dataIndex: "lienParente",
			title: "Type",
			render: (text, Record) => PARENTE_VALUES[text].label
		},
		{
			dataIndex: "civilite",
			title: "Civilité",
			render: (text, Record) => CIVILITE_VALUES[text].label
		},
		{
			dataIndex: "nomUsage",
			title: "Nom d'usage",
		},
		{
			dataIndex: "nomNaissance",
			title: "Nom naisssance",
		},
		{
			dataIndex: "prenom",
			title: "Prénom",
		},
		{
			dataIndex: "telephoneFixe",
			title: "Tél. fixe",
		},
		{
			dataIndex: "telephonePortable",
			title: "Tél. portable",
		},
		{
			dataIndex: "email",
			title: "Email",
		},
		{
			dataIndex: 'actionDestroy',
			title: '',
			width: COLUMN_ICON_WIDTH,
			fixed: "right",
			className: "center",
			render: (text, Record) => !isReadOnly && <DestroyIcon onClick={() => {
				onRecordDelete(Record)
			}}/>
		}
	]
	/**
	 *
	 */
	const ButtonSave = <Button key={key()} onClick={onFormFinish}>Enregistrer</Button>
	
	const FORM_PROPS = {
		form: form,
		disabled: isReadOnly,
		className: "padding",
		labelCol: {span: 8},
		wrapperCol: {span: 10},
		colon: false
	}
	/**
	 *
	 */
	return (
		
		<>
			<Table
				rowKey="_id"
				className={"padding shadow-2"}
				dataSource={RepresentantLegaux}
				columns={Columns}
				size="small"
				pagination={false}
				showSorterTooltip={false}
			
			/>
			{/*  */}
			<Modal
				title={"Représentant légal"}
				centered
				width={700}
				mask={false}
				open={isFormVisible}
				footer={[ButtonSave]}
				onCancel={() => {
					setFormVisible(false)
				}}
			>
				<Form {...FORM_PROPS}  >
					
					{/*  */}
					<Form.Item name={"_isNew"} hidden>
						<Input/>
					</Form.Item>
					{/*  */}
					<Form.Item name={"_id"} hidden>
						<Input/>
					</Form.Item>
					{/*  */}
					<Form.Item name={"principal"} label={'Principal'}>
						<SelectYesNo/>
					</Form.Item>
					{/*  */}
					<Form.Item name={"lienParente"} label={'Type'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<Select>
							{
								Object.values(PARENTE_VALUES).map(item =>
									<Select.Option key={key()} value={item.value}>
										{item.label}
									</Select.Option>
								)
							}
						</Select>
					</Form.Item>
					{/*  */}
					<Form.Item name={"civilite"} label={'Civilité'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<SelectCivilite/>
					</Form.Item>
					{/*  */}
					<Form.Item name={"nomUsage"} label={"Nom d'usage"} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<Input autoComplete='off'/>
					</Form.Item>
					{/*  */}
					<Form.Item name={"nomNaissance"} label={'Nom de naissance'}>
						<Input autoComplete='off'/>
					</Form.Item>
					{/*  */}
					<Form.Item name={"prenom"} label={'Prénom'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<Input autoComplete='off'/>
					</Form.Item>
					
					<Form.Item name={"telephonePortable"} label={'Téléphone portable'} rules={FORM_RULE_NUMERIC}>
						<Input autoComplete='off'/>
					</Form.Item>
					<Form.Item name={"telephoneFixe"} label={'Téléphone fixe'} rules={[
						{
							pattern: new RegExp(/^[0-9]/),
							message: "Le format n'est pas valide",
						},
					]}>
						<Input autoComplete='off'/>
					</Form.Item>
					
					<Form.Item name={"email"} label={'Email'} rules={FORM_RULE_EMAIL}>
						<Input type={'email'} autoComplete='off'/>
					</Form.Item>
					<br/>
					{/*  */}
				
				</Form>
			</Modal>
		</>
	
	);
}))
