/**
 *
 */
import {
	CalendarOutlined,
	CustomerServiceOutlined,
	DoubleLeftOutlined,
	DoubleRightOutlined,
	FileDoneOutlined,
	FileExcelOutlined,
	LogoutOutlined,
	NotificationTwoTone,
	PartitionOutlined,
	SafetyOutlined,
	SettingOutlined,
	SolutionOutlined,
	TeamOutlined
} from "@ant-design/icons";
/**
 *
 */
import {Button, DatePicker, Menu, Space, Tag, Tooltip} from "antd";
import Sider from "antd/es/layout/Sider";
import days from "dayjs";
import {inject, observer} from "mobx-react";
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
/**
 *
 */
import {ENV, ENV_PROD, LOGOUT_URL, SUPPORT_URL} from "../../config/Constantes.urls";
import Apprenants from "../apprenant/Apprenants";
import {destroyAllDrawers} from "../common/components/drawerManager";
import {Export} from "../export/Export";
import Inscriptions from "../inscription/Inscriptions";
import {PreProspects} from "../preProspect/PreProspects";
import {Planning} from "../rdv/Planning";
import {Referentiel} from "../referentiel/Referentiel";
import Users from "../user/Users";
import {VieApprenants} from "../vieApprenante/VieApprenants";
import {COLOR_RED} from "../../config/Constantes.ui";
import {
	CONTEXT_ACCES_DASHBOARD_APPRENANTS,
	CONTEXT_ACCES_EXPORT,
	CONTEXT_ACCES_INSCRIPTIONS,
	CONTEXT_ACCES_PLANNING,
	CONTEXT_ACCES_PREPROSPECTS,
	CONTEXT_ACCES_REFERENTIEL,
	CONTEXT_ACCES_SUPPORT,
	CONTEXT_ACCES_USERS,
	CONTEXT_ACCES_VIE_APPRENANTE
} from "../../config/Constantes.contexts";

/**
 *
 */
export const ROUTE_DEFAULT = "/"
export const allRoutes = [
	{
		path: ROUTE_DEFAULT,
		title: "Apprenants",
		icon: <TeamOutlined/>,
		Component: Apprenants,
		allowedContext: CONTEXT_ACCES_DASHBOARD_APPRENANTS
	},
	{
		path: "/Inscriptions",
		title: "Demandes de formation",
		icon: <PartitionOutlined/>,
		Component: Inscriptions,
		allowedContext: CONTEXT_ACCES_INSCRIPTIONS
	},
	{
		path: "/VieApprenante",
		title: "Vie apprenante",
		icon: <FileDoneOutlined/>,
		Component: VieApprenants,
		allowedContext: CONTEXT_ACCES_VIE_APPRENANTE
	},
	{
		path: "/PreProspects",
		title: "Contacts",
		icon: <SolutionOutlined/>,
		Component: PreProspects,
		allowedContext: CONTEXT_ACCES_PREPROSPECTS
	},
	{
		path: "/Planning",
		title: "Planning",
		icon: <CalendarOutlined/>,
		Component: Planning,
		allowedContext: CONTEXT_ACCES_PLANNING
	},
	{
		
		path: "/Referentiel",
		title: "Référentiel",
		icon: <SettingOutlined/>,
		Component: Referentiel,
		allowedContext: CONTEXT_ACCES_REFERENTIEL
	},
	{
		path: "/Exports",
		title: "Exports",
		icon: <FileExcelOutlined/>,
		Component: Export,
		allowedContext: CONTEXT_ACCES_EXPORT
	},
	{
		
		path: "/Users",
		title: "Utilisateurs",
		icon: <SafetyOutlined/>,
		Component: Users,
		allowedContext: CONTEXT_ACCES_USERS
	},
	
	{
		
		path: "/Support",
		title: "Support",
		icon: <CustomerServiceOutlined/>,
		Component: () => "",
		allowedContext: CONTEXT_ACCES_SUPPORT
	},
	{
		
		path: "/Logout",
		title: "Se déconnecter",
		icon: <LogoutOutlined/>,
		Component: () => ""
	},

]

allRoutes.forEach((Route, index) => {
	Route.key = index.toString()
})
/**
 *
 */
const AnneeCreationSelect = () => <DatePicker
	defaultValue={days(sessionStorage.anneeCreationSelected)}
	picker="year"
	suffixIcon={""}
	inputReadOnly
	className={"year bold"}
	allowClear={false}
	placeholder={""}
	onChange={(value) => {
		sessionStorage.anneeCreationSelected = days(value).format("YYYY")
		window.location = ""
	}}
/>

/**
 *
 */
export const NavigationSider = inject("store")(observer(props => {
	
	const {store} = props
	const {UserStore} = store
	let _collapsed = JSON.parse(localStorage.navigationSiderCollapsed || false)
	const [collapsed, setCollapsed] = useState(_collapsed)
	const navigate = useNavigate();
	const defautMenuKey = sessionStorage.pathKey || allRoutes[0].key
	const [current, setCurrent] = useState(defautMenuKey);
	/**
	 *
	 */
	
	
	const Items = [
		
		{
			title: "",
			label: <Link to={"/"}>
				<Space>
					<img alt="" src={"/logo.svg"} width={30} style={{marginLeft: -6}}/>
					{
						!collapsed && <span id={"appName"}>GRC</span>
					}
				</Space>
			</Link>
			
		},
		{
			title: "Aller vers une autre l'année",
			className: "anneeCreationSelect",
			label: <AnneeCreationSelect/>
			
		},
	]
	if (ENV !== ENV_PROD) {
		Items.unshift({
			title: "Environnement Qualif",
			label: <>
				<NotificationTwoTone twoToneColor={COLOR_RED}/>
				{
					!collapsed && <Tag color={COLOR_RED}>Environnement Qualif</Tag>
				}
			
			</>
			
		})
	}
	//
	allRoutes.forEach((Route, index) => {
		//
		if (Route.allowedContext) {
			const Profil = UserStore.getAllowedContext(Route.allowedContext)
			if (Profil === null) {
				return false
			}
		}
		//
		Items.push({
				key: Route.key,
				label: Route.title && <Tooltip title={Route.title} placement={"right"}>{Route.title}</Tooltip>,
				icon: Route.icon,
				
			}
		)
		
	})
	/**
	 *
	 */
	
	const CollapseIcon = () => {
		const style = {position: "absolute", bottom: 0, width: "100%", padding: 20, margin: "auto", textAlign: "center", cursor: "pointer"}
		
		if (!collapsed) {
			style.textAlign = "right"
		}
		
		return <Tooltip title={"Menu large / menu réduit"} placement={"right"}>
			<div style={style}>
				<Button
					icon={(collapsed ? <DoubleRightOutlined/> : <DoubleLeftOutlined/>)}
					onClick={onCollapse}
				/>
			</div>
		
		</Tooltip>
	}
	/**
	 *
	 */
	
	const onClick = (e) => {
		//
		setCurrent(e.key);
		const Route = allRoutes.find(Route => Route.key === e.key)
		//
		switch (Route.path) {
			
			default:
				destroyAllDrawers()
				navigate(Route.path)
				sessionStorage.pathKey = Route.key
				sessionStorage.pathname = Route.path
				break
			//
			case "/Logout":
				sessionStorage.clear()
				window.open(LOGOUT_URL)
				window.location = "/"
				break
			//
			case "/Support":
				window.open(SUPPORT_URL)
				break
			
		}
		//
	}
	/**
	 * CollapseIcon
	 */
	const onCollapse = () => {
		setCollapsed(!collapsed)
		localStorage.navigationSiderCollapsed = !collapsed
	}
	/**
	 *
	 */
	return <Sider
		_t={store._t}
		trigger={null}
		collapsible
		collapsed={collapsed}
		className={"shadow-1"}
		style={{paddingTop: 10, minHeight: window.innerHeight * 0.8}}
	>
		{/* */}
		<Menu
			id={"navigation"}
			selectedKeys={[current]}
			items={Items}
			onClick={onClick}
		
		/>
		{/* */}
		<CollapseIcon/>
		{/* */}
	
	
	</Sider>
}))
