import {values} from "mobx";
import {getParent, types} from "mobx-state-tree";
import {MICRO_SERVICE_URLS} from "../config/Constantes.urls";
/**
 *
 */
import MicroService from "../helpers/MicroService";
import alasql from "alasql";
import {InscriptionModel} from "./InscriptionStore";
import days from "dayjs";

/**
 * model
 */

const CURRENT_MICRO_SERVICE_URL = MICRO_SERVICE_URLS.PUBLIPOSTAGE_DOCUMENT
/**
 *
 */
export const PubliPostageDocumentModel = types
	.model({
		_id: types.identifier,
		Inscription: types.maybeNull(types.reference(InscriptionModel)),
		anneeCreation: types.maybeNull(types.integer),
		name: types.optional(types.string, ""),
		/**
		 * content : existe dans la base on ne l'alimente pas côté client car trop lourd et non utile
		 * on le récupère lors d'un téléchargement du fichier
		 */
		Createur: types.frozen({
			_id: "",
			fullname: "",
		}),
		updatedAt: types.maybeNull(types.string, ""),
	})
	.views((self) => ({
		get isDeletable() {
			return true
		},
		get _updatedAt() {
			return days(self.updatedAt).format('DD/MM/YYYY à HH:mm:ss')
		},
		//
		get Modificateur() {
			return self.Createur
		},
	}))
	.actions((self) => ({
		destroy(callback) {
			MicroService.delete(CURRENT_MICRO_SERVICE_URL + "/" + self._id, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					getParent(self, 2).removeFromStore(self._id)
				}
				callback(succeeded)
				
			})
			
		}
	}));
/**
 * store
 */
export const PubliPostageDocumentStore = types
	.model({
		PubliPostageDocuments: types.map(PubliPostageDocumentModel),
	})
	.actions((self) => ({
		
		/**
		 *
		 * @param newRecord
		 * @param callback
		 * @returns {boolean}
		 */
		create(data, callback) {
			let url = CURRENT_MICRO_SERVICE_URL
			MicroService.create(url, data, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					let Record = Response.Record
					self.addToStore(Record)
				}
				callback(succeeded, Response)
			})
			
		},
		/**
		 *
		 */
		removeFromStore(_id) {
			self.PubliPostageDocuments.delete(_id)
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 */
		addToStore(Record) {
			self.PubliPostageDocuments.set(Record._id, Record)
			getParent(self, 1).notifyUIChanges()
		}
		
	}))
	.views((self) => ({
		getAll() {
			const Records = values(self.PubliPostageDocuments)
			const query = "SELECT * FROM ? ORDER BY Inscription->Apprenant->nomUsage,  Inscription->Apprenant->prenom, name"
			const Rows = alasql(query, [Records]);
			return Rows.map(Row => self.PubliPostageDocuments.get(Row._id))
		},
		getByInscription(inscriptionId) {
			return values(self.PubliPostageDocuments).filter(PubliPostageDocument => PubliPostageDocument.Inscription._id === inscriptionId)
		},
		
	}));

