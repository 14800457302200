import {values} from "mobx";
import {applySnapshot, getParent, types} from "mobx-state-tree";
import {InscriptionModel} from "./InscriptionStore";
import {MICRO_SERVICE_URLS} from "../config/Constantes.urls";
import MicroService from "../helpers/MicroService";
import {Tag} from "antd";
import {MOTIF_ABSENCES} from "../config/Constantes.logic";
import {YES_NO_LABEL_TAG} from "../components/SelectYesNo";
import alasql from "alasql";

import {FILTER_BY_ANNEE_EXAMEN, FILTER_BY_FORMATION} from "./Constantes.filters";

/**
 *
 */
const CURRENT_MICRO_SERVICE_URL = MICRO_SERVICE_URLS.INSCRIPTION_SESSION
//
export const InscriptionSessionModel = types
	.model({
		_id: types.identifier,
		Inscription: types.maybeNull(types.reference(InscriptionModel)),
		Session: types.frozen({
			_id: "",
			libelle: "",
			//contient d'autres champs, on prends les champ au besoin
			Planning: types.frozen({
				_id: "",
				totalHeuresPrevisionnel: 0,
				totalHeuresEffectif: 0,
				Horaires: types.array(types.frozen({}))
			}),
		}),
		participation: types.optional(types.boolean, false),
		totalHeuresConsomme: types.optional(types.string, ""),
		motifAbsence: types.maybeNull(types.string, ""),
		//
		createdAt: types.maybeNull(types.string, ""),
		updatedAt: types.maybeNull(types.string, ""),
		//
		Createur: types.frozen({_id: "", fullname: ""}),
		Modificateur: types.frozen({_id: "", fullname: ""}),
		//
		_isOnEdition: types.optional(types.boolean, false),
		_isNew: types.optional(types.boolean, false),
		
	})
	.views((self) => ({
		get isDeletable() {
			return true;
		},
		get participationTag() {
			return YES_NO_LABEL_TAG[self.participation];
		},
		get libelle() {
			return self.Session.libelle;
		},
		get totalHeuresPrevisionnel() {
			return self.Session.Planning && self.Session.Planning.totalHeuresPrevisionnel;
		},
		get totalHeuresPrevisionnelTag() {
			return <Tag>{self.totalHeuresPrevisionnel}</Tag>
		},
		get totalHeuresEffectif() {
			return self.Session.Planning && self.Session.Planning.totalHeuresEffectif;
		},
		get totalHeuresEffectifTag() {
			return <Tag>{self.totalHeuresEffectif}</Tag>
		},
		get totalHeuresConsommeTag() {
			return self.participation === true && <Tag>{self.totalHeuresConsomme}</Tag>
		},
		get motifAbsenceLibelle() {
			return self.motifAbsence && MOTIF_ABSENCES[self.motifAbsence].label
		},
		
	}))
	.actions((self) => ({
		
		edit() {
			getParent(self, 2).setRecordOnEdition(self);
		},
		update(EditedRecord) {
			applySnapshot(self, Object.assign(self, EditedRecord));
			
		},
		
		/**
		 *
		 * @param callback
		 */
		save(callback) {
			/**
			 * create
			 */
			let url = CURRENT_MICRO_SERVICE_URL
			let service = MicroService.create
			let data = {Record: self}
			//
			if (self._isNew === false) {
				url = url + "/" + self._id
				service = MicroService.update
			}
			//
			service(url, data, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					let Record = Response.Record
					getParent(self, 2).addToStore(Record)
				}
				callback(succeeded)
			})
			
		},
		destroy(callback) {
			MicroService.delete(CURRENT_MICRO_SERVICE_URL + "/" + self._id, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					getParent(self, 2).removeFromStore(self._id)
				}
				callback(succeeded)
				
			})
			
		}
	}));
/**
 * store
 */
export const InscriptionSessionStore = types
	.model({
		InscriptionSessions: types.map(InscriptionSessionModel),
		SelectedRecord: types.maybeNull(types.reference(InscriptionSessionModel, {})),
	}).actions((self) => ({
		/**
		 *
		 */
		create(Session, Inscription) {
			const Composant = Session.Composant
			let newRecord = InscriptionSessionModel.create({
				_isNew: true,
				_id: Session._id,
				totalHeures: 0,
				Session: {
					_id: Composant._id,
					libelle: Composant.intitule,
					Planning: Composant.Planning
				},
				Inscription: Inscription._id,
			})
			self.addToStore(newRecord)
			return newRecord
		},
		/**
		 *
		 */
		setRecordOnEdition(SelectedRecord) {
			self.removeSetOnEditionForAll()
			SelectedRecord._isOnEdition = true
			self.SelectedRecord = SelectedRecord
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 */
		removeSetOnEditionForAll() {
			const Records = self.InscriptionSessions
			values(Records).forEach((Record) => {
				Record._isOnEdition = false
				if (Record._isNew === true) {
					Records.delete(Record._id)
				}
			})
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 * @param Record
		 */
		addToStore(Record) {
			self.InscriptionSessions.set(Record._id, Record)
			getParent(self, 1).notifyUIChanges()
		},
		removeFromStore(_id) {
			self.InscriptionSessions.delete(_id)
			getParent(self, 1).notifyUIChanges()
		},
	}))
	
	.views((self) => ({
		getAll() {
			const Records = values(self.InscriptionSessions)
			return Records
		},
		getById(_id) {
			const Records = values(self.InscriptionSessions)
			return Records.find(Record => Record._id === _id)
		},
		getByInscription(_id) {
			const Records = values(self.InscriptionSessions)
			return Records.filter(Record => Record.Inscription._id === _id)
		},
		getAllByFilters(Filters) {
			const Records = values(self.InscriptionSessions)
			
			const wheres = [];
			
			for (let key in Filters) {
				const Filter = Filters[key];
				if (!("value" in Filter)) {
					continue
				}
				let value = Filter.value;
				
				switch (Filter.name) {
					
					case FILTER_BY_ANNEE_EXAMEN:
						if (value === 0) {
							wheres.push("Inscription->_FormationSuivie->anneeExamen IS Null");
						} else {
							wheres.push("Inscription->_FormationSuivie->anneeExamen = " + value);
						}
						
						break;
					case FILTER_BY_FORMATION:
						wheres.push("Inscription->_FormationSuivie->_id = '" + value + "'");
						break;
					
				}
			}
			let query = 'SELECT _id FROM ? ';
			//
			if (wheres.length > 0) {
				query += ' WHERE ' + wheres.join('\n AND \n');
			}
			//
			const orderBy = [
				"Inscription->apprenantFullname",
				"Inscription->_FormationSuivie->libelleCourt",
				"Correcteur->fullname",
			].join(",")
			query += " ORDER BY " + orderBy
			//
			//console.log(query)
			//
			const Rows = alasql(query, [Records]);
			return Rows.map(Row => self.InscriptionSessions.get(Row._id));
		},
	}));



