import {Modal} from "antd";
import {inject, observer} from "mobx-react";
import React from "react";
import {closeDrawer} from "../../common/components/drawerManager";
import {DRAWERS_NAMES} from "../../../stores/NavigationStore";
import ReferentielPubliPostageTemplate from "./ReferentielPubliPostageTemplate";

/**
 *
 */

const drawerName = DRAWERS_NAMES.PUBLIPOSTAGE_TEMPLATE_FORM_DRAWER
/**
 *
 */
export const ReferentielPubliPostageTemplateForm = inject("store")(observer(props => {
	
	const {store} = props
	const {NavigationStore} = store;
	return <Modal
		_t={store._t}
		title={"Ajout d'un document modèle pour publipostage"}
		centered
		closable={true}
		width={700}
		open={NavigationStore.isDrawerVisible(drawerName)}
		footer={false}
		/*
		footer={[
			<Button key={key()} {...BUTTON_DEFAULT_PROPS} onClick={() => closeDrawer(drawerName)}>
				Fermer
			</Button>,
		
		]}
		
		
		 */
		onCancel={() => closeDrawer(drawerName)}
	>
		<ReferentielPubliPostageTemplate/>
	</Modal>
	
}))
