import axios from "axios";
import {values} from "mobx";
import {applySnapshot, getParent, types} from "mobx-state-tree";
import {VALIDATION_ADMINISTRATIVE_STATUT_ACCEPTE} from "../config/Constantes.logic";
import {MICRO_SERVICE_URLS} from "../config/Constantes.urls";
/**
 *
 */
import MicroService from "../helpers/MicroService";
import {InscriptionModel} from "./InscriptionStore";
import {ReferentielPieceJointeModel} from "./ReferentielPieceJointeStore";
import {USER_ROLE_AGENT} from "../config/Constantes.roles";

/**
 * model
 */

const CURRENT_MICRO_SERVICE_URL = MICRO_SERVICE_URLS.INSCRIPTION_PIECE_JOINTE
/**
 *
 */
export const InscriptionPieceJointeModel = types
	.model({
		_id: types.identifier,
		anneeCreation: types.maybeNull(types.integer),
		//readOnly : types.optional(types.boolean, false),
		Inscription: types.maybeNull(types.reference(InscriptionModel)),
		//confidentiel : types.maybeNull(types.boolean, false),
		ReferentielPieceJointe: types.maybeNull(types.reference(ReferentielPieceJointeModel)),
		originalName: types.optional(types.string, ""),
		name: types.optional(types.string, ""),
		type: types.optional(types.string, ""),
		extension: types.optional(types.string, ""),
		size: 0,
		// sert pour l'affichage d'une pièce jointe devenue obsolète
		_obsolete: types.maybeNull(types.boolean, null),
		//
		/**
		 * content : existe dans la base on ne l'alimente pas côté client car trop lourd et non utile
		 * on le récupère lors d'un téléchargement du fichier
		 */
		Createur: types.frozen({
			_id: "",
			fullname: "",
		}),
		createdAt: types.maybeNull(types.string, ""),
	})
	.views((self) => ({
		get isDeletable() {
			const UserStore = getParent(self, 3).UserStore
			const AuthenticatedUser = UserStore.AuthenticatedUser
			if (AuthenticatedUser.hasRoles(USER_ROLE_AGENT)) {
				return true
			}
			const _isDeletable = !(self.Inscription.ValidationAdministrative.statut === VALIDATION_ADMINISTRATIVE_STATUT_ACCEPTE)
			
			return _isDeletable
		},
	}))
	.actions((self) => ({
		update(EditedRecord) {
			applySnapshot(self, Object.assign(self, EditedRecord));
		},
		/**
		 *
		 * @param callback
		 */
		destroy(callback) {
			MicroService.delete(CURRENT_MICRO_SERVICE_URL + "/" + self._id, (Response) => {
				const succeeded = Response.OPERATION_SUCCEED
				if (succeeded === true) {
					getParent(self, 2).removeFromStore(self._id)
				}
				callback(succeeded)
				
			})
			
		}
	}));
/**
 * store
 */
export const InscriptionPieceJointeStore = types
	.model({
		InscriptionPieceJointes: types.map(InscriptionPieceJointeModel),
	})
	.actions((self) => ({
		
		/**
		 *
		 * @param newRecord
		 * @param callback
		 * @returns {boolean}
		 */
		create(formData, callback) {
			axios.post(CURRENT_MICRO_SERVICE_URL, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				}
				
			}).then(Response => {
				const data = Response.data
				const succeeded = data.OPERATION_SUCCEED
				if (succeeded === true) {
					let Record = data.Record
					self.removeFromStore(Record)
					self.addToStore(Record)
					
				}
				callback(succeeded)
			}).catch(error => {
				console.log(error)
			})
			
		},
		/**
		 *
		 */
		removeFromStore(_id) {
			self.InscriptionPieceJointes.delete(_id)
			getParent(self, 1).notifyUIChanges()
		},
		/**
		 *
		 */
		addToStore(Record) {
			
			self.InscriptionPieceJointes.set(Record._id, Record)
			getParent(self, 1).notifyUIChanges()
		}
		
	}))
	.views((self) => ({
		getAll() {
			
			return values(self.InscriptionPieceJointes)
		},
		/**
		 *
		 * @param InscriptionId
		 * @returns {InscriptionPieceJointes[]}
		 */
		
		getAllByInscription(InscriptionId) {
			let Records = values(self.InscriptionPieceJointes)
			return Records.filter(Record => Record.Inscription._id === InscriptionId)
		},
		
		/**
		 *
		 */
		
	}));

