import {Store} from "../../../stores/Store";

export const openDrawer = (drawerName) => {
	Store.NavigationStore.setDrawerVisible(drawerName)
}
export const closeDrawer = (drawerName) => {
	Store.NavigationStore.destroyDrawer(drawerName)
}
export const destroyAllDrawers = (drawerName) => {
	Store.NavigationStore.destroyDrawers()
}
