/**
 *
 */
import {DatePicker, Form, Input, InputNumber, Select} from 'antd';
import days from "dayjs";
import {inject, observer} from "mobx-react";
import React, {useEffect} from "react";
/**
 *
 */
import {Button} from "../../../components/antExtendedComponent/Button";
import {Notify} from "../../../components/Notify";
import {SelectYesNo} from "../../../components/SelectYesNo";
import {SITUATION_PROFESSIONNELLES_SALARIE, SITUATION_PROFESSIONNELLES_TYPE_TRAVAILLEUR_INDEPENDANT_OU_PROFESSION_LIBERALE, SITUATION_PROFESSIONNELLES_TYPES_VALUES} from "../../../config/Constantes.logic";
import {FORM_DEFAULT_RULE_REQUIRED} from "../../../config/Constantes.ui";
import {key} from "../../../helpers/Utils";
import {CONTEXT_APPRENANT_EXPERIENCE_PROFESSIONNELLE} from "../../../config/Constantes.contexts";

;
/**
 *
 */
/**
 *
 */
export const SituationProfessionnelle = inject("store")(observer(props => {
	/**
	 *
	 */
	const [form] = Form.useForm();
	/**
	 *
	 */
	const {store} = props
	/**
	 *
	 */
	const
		{
			ApprenantStore,
			UserStore
		} = store
	
	/**
	 *
	 */
	const Record = ApprenantStore.SelectedRecord || {}
	const RecordEdited = {...Record}
	//
	const Profil = UserStore.getAllowedContext(CONTEXT_APPRENANT_EXPERIENCE_PROFESSIONNELLE)
	
	/**
	 *
	 */
	
	/**
	 * populate form
	 */
	const onFill = () => {
		/**
		 * SituationProfessionnelle : formattage dateDebut dateFin
		 */
		RecordEdited.SituationProfessionnelle = {...RecordEdited.SituationProfessionnelle}
		//dateDebut
		const dateDebut = RecordEdited.SituationProfessionnelle.dateDebut
		if (dateDebut) {
			RecordEdited.SituationProfessionnelle.dateDebut = days(dateDebut, 'YYYY-MM-DD');
		}
		//dateFin
		const dateFin = RecordEdited.SituationProfessionnelle.dateFin
		if (dateFin) {
			RecordEdited.SituationProfessionnelle.dateFin = days(dateFin, 'YYYY-MM-DD');
		}
		/**
		 * si changement de type on détruit les autres champs
		 */
		if (
			[
				SITUATION_PROFESSIONNELLES_SALARIE,
				SITUATION_PROFESSIONNELLES_TYPE_TRAVAILLEUR_INDEPENDANT_OU_PROFESSION_LIBERALE
			].includes(RecordEdited.SituationProfessionnelle.type) === false
		) {
			RecordEdited.SituationProfessionnelle = {type: RecordEdited.SituationProfessionnelle.type}
		}
		/**
		 *
		 */
		form.setFieldsValue(RecordEdited);
		/**
		 *
		 */
	};
	/**
	 *
	 */
	useEffect(() => {
		onFill()
	})
	/*
	setTimeout(function () {
		onFill()
	}, 100);
	
	 */
	
	/**
	 * modifie l'objet dans le store avant enregistrement
	 */
	const onFieldsChange = (changedFields, allFields) => {
		//
		const Obj = {}
		//
		const changedField = changedFields[0]
		if (!changedField) {
			return false
		}
		const field = changedField.name[0]
		// cas d'un sous-objet
		const subField = changedField.name[1]
		//
		if (!subField) {
			Obj[field] = changedField.value;
		} else {
			Obj[field] = {};
			const Fields = allFields.filter(item => item.name[0] === field)
			Obj[field] = {}
			Fields.forEach(item => {
				const _subField = item.name[1]
				let _value = item.value
				switch (_subField) {
					default:
						break
					
					case "employeur":
						if (Obj.SituationProfessionnelle.type !== SITUATION_PROFESSIONNELLES_SALARIE) {
							
							_value = ""
						}
						
						break
					case "dateDebut":
					case "dateFin":
						if (_value) {
							_value = _value.format("YYYY-MM-DD")
						}
						
						break
				}
				
				Obj[field][_subField] = _value
			})
			//
		}
		//
		Record.update(Obj)
		//
	};
	/**
	 *
	 * @param  form values
	 * @returns {boolean}
	 */
	const onFinish = () => {
		
		form.validateFields()
			.then((values) => {
				Notify()
				Record.save(succeeded => {
					Notify(succeeded)
				})
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	/**
	 *
	 */
	const ButtonSave = <div className={"center"}>
		<Button block onClick={onFinish}>Enregistrer</Button>
	</div>
	/**
	 *
	 */
	const FORM_PROPS = {
		form: form,
		disabled: !Profil.isModifier,
		layout: "vertical",
		className: "fadein shadow-2 padding",
		style: {width: 600, marginLeft: "auto", marginRight: "auto",},
		onFieldsChange: onFieldsChange,
	}
	return (
		<Form {...FORM_PROPS} >
			{/*  */}
			<Form.Item name={["SituationProfessionnelle", "type"]} label={'Actuellement'} rules={FORM_DEFAULT_RULE_REQUIRED}>
				<Select>
					{
						Object.values(SITUATION_PROFESSIONNELLES_TYPES_VALUES).map(item =>
							<Select.Option key={key()} value={item.value}>
								{item.label}
							</Select.Option>
						)
					}
				</Select>
			</Form.Item>
			{/*  */}
			
			{
				
				(
					Record.SituationProfessionnelle.type === SITUATION_PROFESSIONNELLES_TYPE_TRAVAILLEUR_INDEPENDANT_OU_PROFESSION_LIBERALE
					|| Record.SituationProfessionnelle.type === SITUATION_PROFESSIONNELLES_SALARIE
				)
				&& <>
					
					{
						Record.SituationProfessionnelle.type === SITUATION_PROFESSIONNELLES_SALARIE &&
						<Form.Item name={["SituationProfessionnelle", "employeur"]} label={'Employeur'} rules={FORM_DEFAULT_RULE_REQUIRED}>
							<Input autoComplete='off' /*maxLength={13}*/ />
						</Form.Item>
					}
					{/*  */}
					<Form.Item name={["SituationProfessionnelle", "dateDebut"]} label={'Date de début'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<DatePicker
							disabledDate={(current) => current.valueOf() >= Date.now()}
							//inputReadOnly
							format={'DD/MM/YYYY'}
						/>
					</Form.Item>
					{/*  */}
					<Form.Item name={["SituationProfessionnelle", "dateFin"]} label={'Date de fin'}>
						<DatePicker
							//inputReadOnly
							format={'DD/MM/YYYY'}
						/>
					</Form.Item>
					{/*  */}
					<Form.Item name={["SituationProfessionnelle", "pleinTemps"]} label={'Travail à plein temps'} rules={FORM_DEFAULT_RULE_REQUIRED}>
						<SelectYesNo/>
					</Form.Item>
					{/*  */}
					
					{
						!Record.SituationProfessionnelle.pleinTemps &&
						<Form.Item name={["SituationProfessionnelle", "quotite"]} label={'Pourcentage travail (%)'} rules={FORM_DEFAULT_RULE_REQUIRED}>
							<InputNumber max={100} min={5} step={5} style={{width: 65}}/>
						</Form.Item>
					}
					{/* */}
					<Form.Item name={["SituationProfessionnelle", "duree"]} label={'Durée (mois)'}>
						<InputNumber step={0.25}/>
					</Form.Item>
				</>
			}
			
			
			{/*  */}
			<Form.Item>
			</Form.Item>
			{ButtonSave}
		</Form>
	
	);
}))

