/**
 *
 */
import {Col, Divider, Form, Input, Row, Tooltip} from 'antd';
import {inject, observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Button, ButtonSmall} from "../../../../components/antExtendedComponent/Button";
import {Notify} from "../../../../components/Notify";
import {FORM_LAYOUT} from "../../../../config/Constantes.ui";
import {AutoCompleteCity} from "../../common/AutoCompleteCity";
import {SelectCountry} from "../../common/SelectCountry";
import {SelectVoie} from "../../common/SelectVoie";

/**
 *
 */

/**
 *
 */
export const Adresses = inject("store")(observer(props => {
	/**
	 *
	 */
	
	const [form] = Form.useForm();
	const [autoCompleteVisible, setAutoCompleteVisible] = useState(true);
	const [buttonAdresseCopyEnable, setButtonAdresseCopyEnable] = useState(false);
	/**
	 *
	 */
	const {isReadOnly, Apprenant} = props
	/**
	 *
	 */
	
	//
	//
	/**
	 * devient un objet non mobx
	 * car on a besoin des id dans le formulaire si champ est un objet
	 */
	const ApprenantEdited = {...Apprenant}
	
	/**
	 *
	 */
	
	useEffect(() => {
		form.setFieldsValue(ApprenantEdited);
	})
	
	/**
	 * modifie l'objet dans le store avant enregistrement
	 */
	const onFieldsChange = (changedFields, allFields) => {
		
		setButtonAdresseCopyEnable(false)
	};
	/**
	 *
	 * @param  form values
	 * @returns {boolean}
	 */
	const onFinish = () => {
		
		form.validateFields()
			.then((values) => {
				console.log(values)
				Notify()
				Apprenant.update(values)
				Apprenant.save(succeeded => {
					setButtonAdresseCopyEnable(true)
					Notify(succeeded)
				})
				
			})
			.catch((errorInfo) => {
				console.log("form errors", errorInfo)
			})
		
	};
	/**
	 *
	 */
	
	/**
	 * ButtonAdresseCopy
	 */
	const ButtonAdresseCopy = <Tooltip title={"Copier l'adresse"}>
		<ButtonSmall
			disabled={!buttonAdresseCopyEnable}
			onClick={event => {
				event.stopPropagation();
				Apprenant.update({AdresseExpedition: Apprenant.AdressePrincipale})
			}}
		>Copier</ButtonSmall>
	</Tooltip>
	/**
	 *
	 */
	const FORM_PROPS = Object.assign({
		form: form,
		disabled: isReadOnly,
		colon: false,
		onFieldsChange: onFieldsChange,
	}, FORM_LAYOUT)
	
	/**
	 *
	 */
	
	const ButtonSave = <Button block onClick={event => {
		event.stopPropagation();
		onFinish()
	}}
	>Enregistrer</Button>
	
	/**
	 *
	 */
	return (<Form {...FORM_PROPS}  >
			<Row>
				<Col span={10} style={{flex: "auto"}}>
					<Divider><b>Adresse principale</b></Divider>
					<Form.Item name={["AdressePrincipale", "chez"]} label={'Chez Mr ou Mme'}>
						<Input autoComplete='off'/>
					</Form.Item>
					<Form.Item name={["AdressePrincipale", "voieNumero"]} label={'N° de la voie'}>
						<Input autoComplete='off'/>
					</Form.Item>
					{/*  */}
					<Form.Item name={["AdressePrincipale", "voieType"]} label={'Type de la voie'}>
						<SelectVoie/>
					</Form.Item>
					{/*  */}
					<Form.Item name={["AdressePrincipale", "voieNom"]} label={'Nom de la voie'}>
						<Input autoComplete='off'/>
					</Form.Item>
					{/*  */}
					<Form.Item name={["AdressePrincipale", "complement"]} label={'Complément'}>
						<Input autoComplete='off'/>
					</Form.Item>
					{/*  */}
					<Form.Item name={["AdressePrincipale", "pays"]} label={'Pays'}>
						<SelectCountry
							valuename={"name"}
							onSelect={(value) => {
								setAutoCompleteVisible(value === "France")
								
							}}
						/>
					</Form.Item>
					{
						autoCompleteVisible && <Form.Item label={" "}>
							<AutoCompleteCity
								onChange={(Place) => {
									Apprenant.update({
										AdressePrincipale: {
											codePostal: Place.communeCodePostal,
											commune: Place.communeNom,
											departement: Place.departementNom,
											region: Place.regionNom,
											pays: "France"
										}
									})
								}}
							/>
						</Form.Item>
					}
					{/*  */}
					<Form.Item name={["AdressePrincipale", "codePostal"]} label={'Code postal'}>
						<Input autoComplete='off'/>
					</Form.Item>
					{/*  */}
					<Form.Item name={["AdressePrincipale", "commune"]} label={'Ville'}>
						<Input autoComplete='off'/>
					</Form.Item>
					{/*  */}
					<Form.Item name={["AdressePrincipale", "departement"]} label={'Département'}>
						<Input autoComplete='off'/>
					</Form.Item>
					{/*  */}
					<Form.Item name={["AdressePrincipale", "region"]} label={'Région'}>
						<Input autoComplete='off'/>
					</Form.Item>
					
					{/*  */}
				</Col>
				<Col span={4} className={"center"}>
					<span>{ButtonAdresseCopy}</span>
				</Col>
				<Col span={10} style={{flex: "auto"}}>
					<Divider><b>Adresse Expédition</b></Divider>
					{/*  */}
					<Form.Item name={["AdresseExpedition", "chez"]} label={'Chez Mr ou Mme'}>
						<Input autoComplete='off'/>
					</Form.Item>
					<Form.Item name={["AdresseExpedition", "voieNumero"]} label={'N° de la voie'}>
						<Input autoComplete='off'/>
					</Form.Item>
					{/*  */}
					<Form.Item name={["AdresseExpedition", "voieType"]} label={'Type de la voie'}>
						<SelectVoie/>
					</Form.Item>
					{/*  */}
					<Form.Item name={["AdresseExpedition", "voieNom"]} label={'Nom de la voie'}>
						<Input autoComplete='off'/>
					</Form.Item>
					{/*  */}
					<Form.Item name={["AdresseExpedition", "complement"]} label={'Complément'}>
						<Input autoComplete='off'/>
					</Form.Item>
					{/*  */}
					<Form.Item name={["AdresseExpedition", "pays"]} label={'Pays'}>
						<SelectCountry/>
					</Form.Item>
					{/*  */}
					<Form.Item></Form.Item>
					{/*  */}
					<Form.Item name={["AdresseExpedition", "codePostal"]} label={'Code postal'}>
						<Input autoComplete='off'/>
					</Form.Item>
					{/*  */}
					<Form.Item name={["AdresseExpedition", "commune"]} label={'Ville'}>
						<Input autoComplete='off'/>
					</Form.Item>
					{/*  */}
					<Form.Item name={["AdresseExpedition", "departement"]} label={'Département'}>
						<Input autoComplete='off'/>
					</Form.Item>
					{/*  */}
					<Form.Item name={["AdresseExpedition", "region"]} label={'Région'}>
						<Input autoComplete='off'/>
					</Form.Item>
				
				</Col>
			</Row>
			{/*  */}
			<Row>
				<Col span={10}></Col>
				<Col span={4}>{ButtonSave}</Col>
				<Col span={10}></Col>
			
			</Row>
			
			{/*  */}
		</Form>
	);
}))

