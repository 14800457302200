/**
 *
 */
import {DownloadOutlined} from "@ant-design/icons";
import {Alert, Form, Input, Select, Space, Tag, Tooltip} from 'antd';
import axios from "axios";
import days from "dayjs";

import React, {useEffect, useMemo, useState} from "react";
import {Button} from "../../../components/antExtendedComponent/Button";
import {SpaceVertical} from "../../../components/antExtendedComponent/Space";
import {Notify} from "../../../components/Notify";
import {SelectYesNo} from "../../../components/SelectYesNo";
import {
	ACCESS_FORMULAIRE_CONTACT_BASE_URL,
	ACCESS_PLATEFORME_BASE_URL,
	APPRENANT_STATUT_PROSPECT,
	FORMATION_PROFIL_ACTIVITE_PRO_OU_DEMANDEUR_EMPLOI,
	FORMATION_PROFIL_ELEVE_OU_ETUDIANT,
	FORMATION_PROFILS_VALUES,
	INSCRIPTION_STATUT_VALIDATION_EN_ATTENTE,
	OBJECTIF_TRAVAILLER_A_MON_COMPTE,
	OBJECTIF_TROUVER_UN_EMPLOI_DE_SALARIE,
	PREPROSPECT_DEMANDE_NON_ENVOYEE,
	PROJET_OBJECTIFS
} from "../../../config/Constantes.logic";
import {FORM_DEFAULT_RULE_REQUIRED} from "../../../config/Constantes.ui";
import {MICRO_SERVICE_URLS} from "../../../config/Constantes.urls";
import MicroService from "../../../helpers/MicroService";
import {key} from "../../../helpers/Utils";
import {PREPROSPECT_FORM_PROPS, STEP_1, STEP_3} from "../PreProspectRegistration";
import {USER_ROLE_APPRENANT} from "../../../config/Constantes.roles";

/**
 *
 */
export const Step_2 = (props => {
	/**
	 *
	 */
	
	const {PreProspect, setPreProspect, formDisabled, setCurrentStep, currentStep, setFormDisabled} = props
	const [form] = Form.useForm();
	
	/**
	 * sert à à re render le form
	 */
	const [timestamp, setTimestamp] = useState()
	const [Formations, setFormations] = useState([])
	const [loading, setLoading] = useState(null);
	
	const [FormationSouhaiteePremiereSelected, setFormationSouhaiteePremiereSelected] = useState(PreProspect.FormationSouhaiteePremiere);
	const [FormationSouhaiteeSecondeSelected, setFormationSouhaiteeSecondeSelected] = useState(PreProspect.FormationSouhaiteeSeconde);
	/**
	 *
	 */
	useEffect(() => {
		form.setFieldsValue(PreProspect);
	})
	/**
	 *
	 */
	useMemo(() => {
		setLoading(true)
		return MicroService.get(MICRO_SERVICE_URLS.PRE_PROSPECT_FORMATION, Response => {
			setLoading(false)
			setFormations(Response.Formations)
		})
	}, []);
	/**
	 *
	 */
	const onValuesChange = (changedValues) => {
		const field = Object.keys(changedValues)[0]
		let value = Object.values(changedValues)[0]
		switch (field) {
			case "FormationSouhaiteePremiere":
				setFormationSouhaiteePremiereSelected(value)
				break
			case "FormationSouhaiteeSeconde":
				setFormationSouhaiteeSecondeSelected(value)
				break
			case "souhaitDemandeBourse":
				PreProspect.souhaitFinancementFormation = null
				break
			case "souhaitFinancementFormation":
				PreProspect.souhaitDemandeBourse = null
				
				break
			
		}
		//
		PreProspect[field] = value
		setPreProspect(PreProspect)
		//
		setTimestamp(new Date().toISOString())
		//
		
	}
	
	/**
	 *
	 * @param  form values
	 * @returns {boolean}
	 */
	const onFinish = () => {
		form.validateFields()
			.then((values) => {
				let Record = {...PreProspect}
				Record.step = currentStep
				Record.rgpdAccepte = false
				Record.statut = PREPROSPECT_DEMANDE_NON_ENVOYEE
				Record.statutApprenant = APPRENANT_STATUT_PROSPECT
				Record.statutInscription = INSCRIPTION_STATUT_VALIDATION_EN_ATTENTE
				Record.roles = [USER_ROLE_APPRENANT]
				Record.accessFormulaireContactBaseUrl = ACCESS_FORMULAIRE_CONTACT_BASE_URL
				Record.accessPlateformeBaseUrl = ACCESS_PLATEFORME_BASE_URL
				//
				if (
					[
						OBJECTIF_TROUVER_UN_EMPLOI_DE_SALARIE,
						OBJECTIF_TRAVAILLER_A_MON_COMPTE
					]
						.includes(Record.objectif) === false) {
					Record.domaineActivite = ""
				}
				//
				Record._id = PreProspect._id || null
				let url = MICRO_SERVICE_URLS.PRE_PROSPECT + "/" + Record._id
				
				Notify()
				axios.put(url, {Record: Record})
					.then(response => {
						const Response = response.data
						const ProspectSaved = Response.Record
						setPreProspect(ProspectSaved)
						setCurrentStep(STEP_3)
						history.pushState({}, null, ACCESS_FORMULAIRE_CONTACT_BASE_URL + "&PreProspect=" + ProspectSaved._id);
						Notify(true)
					})
				
			})
			.catch((errorInfo) => {
				
				console.log("form errors", errorInfo)
			})
		
	};
	
	/**
	 * ButtonSave et ButtonPrev
	 */
	const Buttons = <Space className={"flex"}>
		{/*  */}
		<Button disabled={false} onClick={event => {
			event.stopPropagation();
			setCurrentStep(STEP_1)
		}}
		>Retour</Button>
		{/*  */}
		<Button disabled={false} onClick={event => {
			if (PreProspect.rgpdAccepte === true) {
				setCurrentStep(STEP_3)
			} else {
				
				onFinish()
			}
			
		}}
		>Suivant</Button>
	</Space>
	/**
	 *
	 */
	const download = (Document) => {
		//
		Notify()//
		//
		MicroService.get(Document.url, (Response) => {
			//
			const File = Response.Record
			//
			const uint8tArray = new Uint8Array(File.content.data);
			const blob = new Blob([uint8tArray], {type: File.type});
			const fileAsObjectUrl = URL.createObjectURL(blob);
			//
			const element = document.createElement('a');
			element.setAttribute("href", fileAsObjectUrl);
			//element.setAttribute("download", PieceJointe.name);
			element.setAttribute("target", "_blank");
			element.style.display = 'none';
			document.body.appendChild(element);
			element.click();
			document.body.removeChild(element);
			//
			Notify(Response.OPERATION_SUCCEED)
			//
		})
		
	}
	
	/**
	 *
	 */
	const FormationSelect = (props) => {
		const SelectedFormation = Formations.find(Formation => Formation._id === props.selectedformation)
		return <SpaceVertical size={"small"}>
			<Select
				{...props}
				showSearch
				optionFilterProp={"label"}
				loading={loading}
			
			>
				{
					
					Formations.map(Formation => <Select.Option
							key={key()}
							value={Formation._id}
							label={Formation.libelle}
						>
							<div className={"flex"} key={key()}>
								<span>{Formation.libelle}</span>
								<Space>
									<Tag>
										{
											days(Formation.sessionDates[0]).format("DD/MM/YYYY") + " - " + days(Formation.sessionDates[1]).format("DD/MM/YYYY")
										}
									</Tag>
									<span>&nbsp;</span>
								</Space>
							</div>
						</Select.Option>
					)
				}
			</Select>
			{/*  */}
			{
				
				SelectedFormation && SelectedFormation.Documents.map((Document, index) =>
					<Tooltip key={key()} title={"Télécharger la fiche N°" + (index + 1) + " de la formation" + SelectedFormation.libelle} placement={"bottom"}>
						<Button
							disabled={false}
							style={{backgroundColor: "#f5f4f3"}}
							type="link"
							size={"small"}
							onClick={() => {
								download(Document)
							}}
							icon={<DownloadOutlined/>}
						>{Document.name}</Button>
					</Tooltip>
				)
			}
			{/*  */}
		</SpaceVertical>
	}
	/**
	 *
	 */
	const FORM_PROPS = {
		form: form,
		disabled: formDisabled,
		_t: timestamp
	}
	return (
		
		<Form {...FORM_PROPS} {...PREPROSPECT_FORM_PROPS} onValuesChange={onValuesChange}>
			{/* <SelectFormation/> */}
			
			<Form.Item name={'diplomeLePlusEleve'} label={'Quel diplôme le plus élevé possédez-vous ?'}>
				<Input/>
			</Form.Item>
			{/*  */}
			<Form.Item name={'profil'} label={'Quel est votre statut (ou profil) ?'} /*rules={FORM_DEFAULT_RULE_REQUIRED}*/>
				<Select>
					{
						Object.values(FORMATION_PROFILS_VALUES).map(item =>
							<Select.Option key={key()} value={item.value}>
								{item.label}
							</Select.Option>
						)
					}
				</Select>
			</Form.Item>
			{/*  */}
			{
				PreProspect && PreProspect.profil === FORMATION_PROFIL_ELEVE_OU_ETUDIANT
				&& <Form.Item name={"souhaitDemandeBourse"}
				              label={"Souhaitez-vous faire une demande de bourse ?"}
					//  label={FORMATION_PROFIL_ELEVE_OU_ETUDIANT}
				>
					<SelectYesNo/>
				</Form.Item>
			}
			
			{
				PreProspect && PreProspect.profil === FORMATION_PROFIL_ACTIVITE_PRO_OU_DEMANDEUR_EMPLOI
				&& <Form.Item name={"souhaitFinancementFormation"} label={"Souhaitez-vous faire financer votre formation ?"}
				>
					<SelectYesNo/>
				</Form.Item>
			}
			{/*  */}
			<Form.Item name={'FormationSouhaiteePremiere'} label={'1ère Formation souhaitée'} rules={FORM_DEFAULT_RULE_REQUIRED}>
				<FormationSelect selectedformation={FormationSouhaiteePremiereSelected}/>
			</Form.Item>
			
			
			{/* 	{FormationSouhaiteePremiereSelected&&<DocumentLinks SelectedFormation={FormationSouhaiteePremiereSelected}/>} */}
			<Form.Item name={'FormationSouhaiteeSeconde'} label={'2ème Formation souhaitée'}>
				<FormationSelect allowClear selectedformation={FormationSouhaiteeSecondeSelected}/>
			</Form.Item>
			
			{/*  */}
			<Form.Item name={'objectif'} label={'Quel est votre projet professionnel ?'}>
				<Select options={Object.values(PROJET_OBJECTIFS)}></Select>
			</Form.Item>
			{
				(
					PreProspect
					&& (
						PreProspect.objectif === OBJECTIF_TROUVER_UN_EMPLOI_DE_SALARIE
						|| PreProspect.objectif === OBJECTIF_TRAVAILLER_A_MON_COMPTE
					)
				) && <Form.Item name={'domaineActivite'} label={"Précisez le domaine et l’activité"}>
					<Input.TextArea autoSize/>
				</Form.Item>
			}
			{/*  */}
			
			
			{/*  */}
			<Form.Item name={'details'} label={"Préciser, en quelques lignes, votre projet"}>
				<Input.TextArea autoSize/>
			</Form.Item>
			
			<br/>
			<Alert showIcon message={"Après cette étape, un lien de modification vous sera envoyé, il vous permettra de compléter votre saisie."}/>
			
			<br/>
			{Buttons}
		</Form>
	
	);
})

